import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignManagementService } from 'src/app/modules/admin/campaign-management/campaign-management.service';
import { PrimasTableComponent } from 'src/app/shared/components/template-management/primas-table/primas-table.component';
import { PrimasToolbarComponent } from 'src/app/shared/components/template-management/primas-toolbar/primas-toolbar.component';
import { permissionSelectEmailCampaign } from 'src/app/shared/contances/permission';
import { ColumnNgxTable } from 'src/app/shared/models/paging/columns.model';
import { PhoneFormatPipe } from 'src/app/shared/pipes/phone-format.pipe';
import { CampaignDetailDataWithContactId, ReviewCampaignContactResponse } from '../../../email-campaign.model';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Helper } from 'src/app/shared/utility/Helper';
import { DialogAddTagComponent } from 'src/app/shared/components/dialog-add-tag/dialog-add-tag.component';
import { TagService } from 'src/app/shared/components/stand-alone-component/auto-complete-tag/tag.service';

@Component({
  selector: 'app-review-contact-campaign',
  templateUrl: './review-contact-campaign.component.html',
  styleUrls: ['./review-contact-campaign.component.scss']
})
export class ReviewContactCampaignComponent implements OnInit, AfterViewInit {
  @ViewChild("primasToolbar", { static: true }) primasToolbar: PrimasToolbarComponent;
  @ViewChild("primasTable", { static: true }) primasTable: PrimasTableComponent;

  columns: ColumnNgxTable[] = [];
  resource = permissionSelectEmailCampaign
  isReview: boolean = true;
  reviewData: ReviewCampaignContactResponse;
  rowSuccessAddTag: number = null;
  tagsAdd: string;

  constructor(
    public dialModalRef: MatDialogRef<ReviewContactCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CampaignDetailDataWithContactId,
    private phonePipe: PhoneFormatPipe,
    private campaignService: CampaignManagementService,
    private dialog: MatDialog,
    public tagService: TagService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.columns = [
      {
        name: "First Name",
        prop: "contactName",
        filter: true
      },
      {
        name: "Last Name",
        prop: "contactLastName",
        filter: true
      },
      {
        name: "Email",
        prop: "contactEmail",
        filter: true
      },
      {
        name: "Phone Number",
        prop: "contactPhone",
        filter: true,
        pipe: this.phonePipe
      },
      {
        name: "Contact Company",
        prop: "relationship",
        filter: true
      },
      {
        name: "Contact Department",
        prop: "department",
        filter: true
      },
      {
        name: "State/Province",
        prop: "location.state",
        filter: true
      },
      {
        name: "Region",
        prop: "location.region",
        filter: true
      },
      {
        name: "Country",
        prop: "location.country",
        filter: true
      },
    ];
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  refreshData(reset: boolean = false) {
    this.primasTable.isLoading = 1;
    if (reset) {
      this.primasTable.page.pageNumber = 0;
      this.primasTable.cache = {};
      this.primasTable.rows = [];
      this.primasTable.selected = [];
      this.primasTable.table.offset = 0;
      this.primasTable.table.bodyComponent.offsetY = 0;
      this.primasTable.page.pageNumber = 0;
    }

    this.campaignService.reviewCampaignContact(this.data, this.primasTable.page)
      .subscribe({
        next: res => {
          if (res.result) {
            this.reviewData = res.result;
            this.primasTable.setData(this.reviewData.pageData);
          }
        },
        error: (err) => this.primasTable.isLoading--,
      });
  }

  exportInvalidContact() {
    if (this.reviewData.inValids && this.reviewData.inValids.length > 0) {
      var dataExport = this.reviewData.inValids.map(x => Object.assign({}, {
        'First Name': x.contactName,
        'Last Name': x.contactLastName,
        //'Relationship': this.data.typeName,
        'Email': x.contactEmail,
        'Phone Number': x.contactPhone,
        'Contact Company': x.relationship,
        'Contact Department': x.department,
        'State/Province': x.location?.state,
        'Region': x.location?.region,
        'Country': x.location?.country,
        'Failure Reason': x.failureReason,
      }));

      Helper.exportFrontEnd(dataExport);
    }
  }

  exportContactUseSecondaryEmail() {
    if (this.reviewData.contactUseSecondaryEmails && this.reviewData.contactUseSecondaryEmails.length > 0) {
      var dataExport = this.reviewData.contactUseSecondaryEmails.map(x => Object.assign({}, {
        'First Name': x.contactName,
        'Last Name': x.contactLastName,
        //'Relationship': this.data.typeName,
        'SecondaryEmail': x.secondaryEmail,
        'Phone Number': x.contactPhone,
        'Contact Company': x.relationship,
        'Contact Department': x.department,
        'State/Province': x.location?.state,
        'Region': x.location?.region,
        'Country': x.location?.country,
      }));

      Helper.exportFrontEnd(dataExport, "CampaignContactUseSecondary_Export_");
    }
  }

  addTags() {
    const dialog = this.dialog.open(DialogAddTagComponent, {
      disableClose: true,
    });

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.rowSuccessAddTag = -1;
        this.tagsAdd = res;
        var tags: string[] = res.slice().split(",");
        if (tags && tags.length > 0) {
          tags = tags.map(x => `#${x.trim()}`);
          this.tagsAdd = tags.join(", ");
        }

        this.tagService.addTagObjectList(res, this.reviewData.inValids.map(x => x.profileId)).subscribe({
          next: res => this.rowSuccessAddTag = res.result,
          error: () => this.rowSuccessAddTag = 0,
        });
      }
    })
  }
}
