<ng-container *ngIf="fromDialog">
  <div fxLayout="row" fxLayoutAlign="start end">
    <b class="hoverable" (click)="onClickDetail()">{{profileModel.displayName || (profileModel.profileId
      |truncateText: [5,'...']) }} 's files</b>
    <a href="javascript:void(0)" style="font-size: 14px; margin-left: 12px" (click)="fileAddInput.click()"><i>Add more
        file</i></a>
    <a href="javascript:void(0)" style="font-size: 14px; margin-left: 12px"
      (click)="this.selectedRow = null;ChangeURL()"><i>Add more
        url</i></a>
  </div>
</ng-container>
<app-primas-table #profileFileTable (onRefresh)="refreshData($event)" [resource]="perm.table" [columnsTable]="columns"
  [allowSelectRow]="false" [optionHeight]="maxHeight" [customAction3]="customAction3"
  [classAddMore]="'ngx-table-resize'">
</app-primas-table>


<ng-template #fileNameCol let-row="row" let-rowIndex="rowIndex">
  <!-- <span *ngIf="!downloading || (downloading && selectedRowIndex !== rowIndex)"
    (click)="!downloading ? downLoadFile(row,rowIndex): null" [ngClass]="'hoverable'"
    class="d-inline-block text-truncate w-100">
    <b [matTooltip]="displayFormatFileName(row)">{{displayFormatFileName(row)}} </b>
  </span> -->
  <span *ngIf="((!downloading && !row?.isOpenLoading) || ((downloading || row?.isOpenLoading) && (selectedRowIndex !== rowIndex && row?.selectedRowIndex !== rowIndex)))"
    (click)="openDownloadFile(row, rowIndex)" [ngClass]="'hoverable'"
    class="d-inline-block text-truncate w-100 text-decoration-underline">
    <b [matTooltip]="displayFormatFileName(row)">{{displayFormatFileName(row)}} </b>
  </span>
  <ng-container *ngIf="(selectedRowIndex == rowIndex || row?.selectedRowIndex == rowIndex) && (downloading || row?.isOpenLoading)">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <nb-progress-bar *ngIf="!row?.isOpenLoading" [value]="downloadPercent" [status]="status" fxFlex="90" size="large"
        [displayValue]="true">
      </nb-progress-bar>
      <mat-spinner [diameter]="15" fxFlex></mat-spinner>
    </div>
  </ng-container>
</ng-template>

<ng-template #ownersCol let-row="row" let-rowIndex="rowIndex">
  <span>
    {{displayOwnerName(row)}}
  </span>
</ng-template>

<ng-template #customAction3 let-row="row">
  <button mat-menu-item color="warn"
    (click)="this.selectedRow = row;row.dataType == 'URL'? ChangeURL() : fileInput.click()">
    <mat-icon [ngStyle]="{color: '#307abd'}">edit</mat-icon>
    <span>Update</span>
  </button>
  <button mat-menu-item color="warn" (click)="openUserFileHistory(row)">
    <mat-icon style="color:green">history</mat-icon>
    <span>History</span>
  </button>
  <button mat-menu-item color="warn" (click)="openManageOwner(row)">
    <mat-icon [ngStyle]="{color:'#307abd'}">
      manage_accounts
    </mat-icon>
    <span>Edit owner</span>
  </button>
  <button mat-menu-item color="warn" (click)="openMoveEntity(row)">
    <mat-icon style="color:green">
      folder_shared
    </mat-icon>
    <span>Move</span>
  </button>
  <button [disabled]="getManageOwnerPermissions(row)" mat-menu-item color="warn" (click)="onClickDelete(row)">
    <mat-icon [ngStyle]="{color: getManageOwnerPermissions(row)? '' :'#f44336'}">
      delete
    </mat-icon>
    <span>Delete item</span>
  </button>
</ng-template>

<input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="modifyUpload($event)"/>
  <!-- [extension]='{"extensions":["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"]}'
  accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.html,.zip,.csv,.txt" /> -->


<input #fileAddInput hidden="true" type="file" onclick="this.value=null" (change)="addFile($event)" />
  <!-- [extension]='{"extensions":["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"]}'
  accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.html,.zip,.csv,.txt" /> -->

<mat-dialog-actions align="end" *ngIf="fromDialog">
  <button mat-button color="primary" (click)="onCloseClick()">Close</button>
</mat-dialog-actions>
<ng-template #revisionCol let-row="row">
  <b class="hoverable text-center" style="padding: 0px 20px 0px 20px;width: 100%;"
    (click)="openUserFileHistory(row)">{{row.historyFiles}}</b>
</ng-template>

<ng-template #dataType let-row="row">
  <button mat-icon-button (click)="openUserFileHistory(row)">
    <mat-icon style="transform: scale(0.8) !important; color:green" MatBadgeSize="small" matBadgePosition="below after"
      class="button-badge material-icons-outlined">
      {{row.dataType == 'URL' ? 'link' : row.dataType == 'OneDriveURL' ?
      'cloud_queue' : 'insert_drive_file'}}
    </mat-icon>
  </button>
</ng-template>
