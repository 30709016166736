import { ContactActionLog } from './../contact-activity.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from 'src/app/shared/utility/Helper';
import { ContactActivityService } from '../contact-activity.service';

@Component({
    selector: 'app-callflow-transcription-detail',
    templateUrl: './callflow-transcription-detail.component.html',
    styleUrls: ['./callflow-transcription-detail.component.scss']
})
export class CallflowTranscriptionDetailComponent implements OnInit {

    summaryText: string;
    transcription: string;
    userA: string;
    userB: string;
    referenceLogId: string;
    phoneCallDuration: string;
    isLoading: boolean = false;
    phoneCallLog: ContactActionLog;
    conversation: MessageDetail[] = [];

    constructor(
        private contactActivityService: ContactActivityService,
        public dialModalRef: MatDialogRef<CallflowTranscriptionDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.transcription = data?.transcription ?? this.transcription;
        this.summaryText = data?.summaryText ?? this.summaryText;
        this.userA = data?.callerA ?? this.userA;
        this.userB = data?.callerB ?? this.userB;
        this.referenceLogId = data?.referenceLogId ?? this.referenceLogId;
    }

    ngOnInit(): void {
        this.conversation = this.transcription.split('\n')
            .filter(x => !Helper.isNullOrEmpty(x))
            .map(message => {
                let sender = message.startsWith(this.userA) ? this.userA : this.userB;
                return {
                    message: message.replace(`${sender}:`, '').replace(/\s+([.,;?!])/g, "$1").trim(),
                    sender: message.startsWith(this.userA) ? this.userA : this.userB
                };
            });

        this.summaryText = this.summaryText.split('\n')
            .filter(x => !Helper.isNullOrEmpty(x))
            .map(text => `<p>${text}</p>`)
            .join('');

        this.loadReferenceData();
    }

    loadReferenceData() {
        this.isLoading = true;
        this.contactActivityService.getContactActivityDetail(this.referenceLogId).subscribe({
            next: (res) => {
                if (res?.result) this.phoneCallLog = res.result;

                try {
                    let jsonData = JSON.parse(this.phoneCallLog.logInfo1);
                    this.phoneCallDuration = this.getCallTime(jsonData?.duration || 0);
                }
                catch (err) {
                    console.error(err);
                }
            },
            complete: () => { this.isLoading = false; }
        })
    }

    getCallTime(durationSeconds: number): string {
        const days = Math.floor(durationSeconds / 86400);
        const hours = Math.floor((durationSeconds % 86400) / 3600);
        const minutes = Math.floor((durationSeconds % 3600) / 60);
        const seconds = Math.floor(durationSeconds % 60);

        let duration = `${seconds} second${seconds > 1 ? 's' : ''}`;

        if (days > 0 || hours > 0) duration = `${minutes} minute${minutes > 1 ? 's' : ''} ${duration}`;
        else if (minutes > 0) duration = `${minutes} minute${minutes > 1 ? 's' : ''} ${duration}`

        if (days > 0) duration = `${hours} hour${hours > 1 ? 's' : ''} ${duration}`;
        else if (hours > 0) duration = `${hours} hour${hours > 1 ? 's' : ''} ${duration}`

        if (minutes > 0) duration = `${days} day${days > 1 ? 's' : ''} ${duration}`;

        return duration.trim();
      }

    closeDialog() {
        this.dialModalRef.close(false);
    }
}

export class MessageDetail {
    message: string;
    sender: string;
}
