<h2 mat-dialog-title>Detail Changes</h2>
<mat-dialog-content class="mat-typography comparison-content">
  <div class="comparison-container">
    <ng-container *ngIf="!isLoading; else loading">
      <div *ngFor="let item of profileCompareDetailData" class="comparison-item">
        <div class="field-name">
          <b>{{item.fieldName}}</b>
        </div>
        <div class="comparison-row" *ngIf="item.childData?.length === 0; else childData">
          <div class="value-field" style="margin-left: 15px;">
            <ng-container *ngIf="item.isDisplayDownLine">
              <label class="field-label">Old Value</label>
              <quill-editor
                class="content-editor"
                [modules]="editorOptions"
                [(ngModel)]="item.oldValue"
                (onEditorCreated)="(item.fieldName ==='Description Left Blog' || item.fieldName ==='Description Right Blog') ? setDesc($event) : null"
                [readOnly]="true"
                [placeholder]="''"
                [styles]="{ height: 'auto', 'min-height': '100px' }">
              </quill-editor>
            </ng-container>
            
            <mat-form-field *ngIf="!item.isDisplayDownLine" appearance="standard">
              <mat-label>Old Value</mat-label>
              <input matInput [(ngModel)]="item.oldValue" readonly>
            </mat-form-field>
          </div>
          
          <div class="arrow-container">
            <img src="assets/images/right-arrow.png" alt="arrow">
          </div>
          
          <div class="value-field" style="margin-right: 15px;">
            <ng-container *ngIf="item.isDisplayDownLine">
              <label class="field-label">New Value</label>
              <quill-editor
                class="content-editor"
                [modules]="editorOptions"
                (onEditorCreated)="(item.fieldName ==='Description Left Blog' || item.fieldName ==='Description Right Blog') ? setDesc($event) : null"
                [(ngModel)]="item.newValue"
                [readOnly]="true"
                [placeholder]="''"
                [styles]="{ height: 'auto', 'min-height': '100px' }">
              </quill-editor>
            </ng-container>

            <mat-form-field *ngIf="!item.isDisplayDownLine" appearance="standard">
              <mat-label>New Value</mat-label>
              <input matInput [(ngModel)]="item.newValue" readonly>
            </mat-form-field>
          </div>
        </div>
        <ng-template #childData>
          <div *ngFor="let child of item.childData" class="child-data">
            <div class="child-field-name">
              {{child.fieldName}}
            </div>
            <div class="comparison-row">
              <div class="value-field" style="margin-left: 15px;">
                <ng-container *ngIf="child.isDisplayDownLine">
                  <label class="field-label">Old Value</label>
                  <quill-editor
                    class="content-editor"
                    [modules]="editorOptions"
                    [(ngModel)]="child.oldValue"
                    [readOnly]="true"
                    [placeholder]="''"
                    [styles]="{ height: 'auto', 'min-height': '100px' }">
                  </quill-editor>
                </ng-container>

                <mat-form-field *ngIf="!child.isDisplayDownLine" appearance="standard">
                  <mat-label>Old Value</mat-label>
                  <input matInput [(ngModel)]="child.oldValue" readonly>
                </mat-form-field>
              </div>

              <div class="arrow-container">
                <img src="assets/images/right-arrow.png" alt="arrow">
              </div>
              
              <div class="value-field" style="margin-right: 15px;">
                <ng-container *ngIf="child.isDisplayDownLine">
                  <label class="field-label">New Value</label>
                  <quill-editor
                    class="content-editor"
                    [modules]="editorOptions"
                    [(ngModel)]="child.newValue"
                    [readOnly]="true"
                    [placeholder]="''"
                    [styles]="{ height: 'auto', 'min-height': '100px' }">
                  </quill-editor>
                </ng-container>

                <mat-form-field *ngIf="!child.isDisplayDownLine" appearance="standard">
                  <mat-label>New Value</mat-label>
                  <input matInput [(ngModel)]="child.newValue" readonly>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>