import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { permissionUser } from 'src/app/shared/contances/permission';
import { PrimasTextFilterOperator } from 'src/app/shared/enums/primas-text-filter-operator';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { SortOrderType } from 'src/app/shared/enums/sort-order-type.enum';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { CustomView } from 'src/app/shared/models/custom-view';
import { Page } from 'src/app/shared/models/paging/page';
import { CustomViewService } from 'src/app/shared/services/custom-view.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { PrimasTableComponent } from '../primas-table/primas-table.component';
import { AddEditCustomViewComponent } from './add-edit-custom-view/add-edit-custom-view.component';
import { NbAccessChecker } from '@nebular/security';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
// import { AdminTabModeService } from '../../stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-primas-custom-view',
  templateUrl: './primas-custom-view.component.html',
  styleUrls: ['./primas-custom-view.component.scss']
})
export class PrimasCustomViewComponent implements OnInit, OnChanges {

  @Input() table: PrimasTableComponent;
  @Input() columns;
  @Input() resource;
  // 2022-09-08 tienlm add start
  // 2 flag for disable and enable show column filter and clear filter
  @Input() hideColumnFilter: boolean = false;
  @Input() hideClearFilter: boolean = false;
  // 2022-09-08 tienlm add end

  @Output() afterClearFilter: EventEmitter<any> = new EventEmitter<any>();
  viewIdentify: string = null;
  page = new Page();
  customView: CustomView[] = null;
  currentRouter = null;
  currentViewId = null;
  currentActiveView = null;
  //2022-01-25 gnguyen start add
  user: any = {};
  resourceCustomView = permissionUser;
  isAdmin = false;
  //2022-01-25 gnguyen end add
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: CustomViewService,
    private dialog: MatDialog,
    //2022-01-25 gnguyen start add
    public accessChecker: NbAccessChecker,
    private authService: NbAuthService,
    //2022-01-25 gnguyen end add
    // private tabMode: AdminTabModeService,
    private cdr: ChangeDetectorRef,
    private toastr: NbToastrService,
  ) {
    //2022-01-25 gnguyen start add
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {

          this.user = token.getPayload();
        }

      });
    //2022-01-25 gnguyen end add

    activatedRoute.params.subscribe(val => {
      let segmentLength = this.activatedRoute.snapshot.url.length;
      let path = '/';
      if (this.activatedRoute.snapshot.params['viewId']) {
        segmentLength -= 2;
      }

      for (let i = 0; i < segmentLength; i++) {
        path += this.activatedRoute.snapshot.url[i].path + '/';
      }
      this.currentRouter = path;
      this.viewIdentify = path;
      if (!this.activatedRoute.snapshot.paramMap.get('filter')) {
        // this.currentViewId = parseInt(localStorage.getItem(this.currentRouter));
        // let currentActiveTab = this.tabMode.getCurrentActiveTab();
        // let viewId = this.tabMode.getPropExtendData('viewId', currentActiveTab);
        // if (!Helper.isNullOrEmpty(viewId) || val.viewId) {
        //   if (!Helper.isNullOrEmpty(viewId)) {
        //     this.currentViewId = viewId;
        //   } else if (val.viewId) {
        //     this.currentViewId = val.viewId;
        //   }
        // }

        if(val.viewId){
          this.currentViewId = val.viewId;
        }
      }
      if (this.viewIdentify) {
        this.page.filter = Helper.formatFilter(this.page.filter, 'viewIdentify', this.viewIdentify, PrimasFilterType.Text, PrimasTextFilterOperator.IsEqualTo);
      }
      if (this.table && this.table.page) this.table.page.viewId = this.currentViewId
      this.loadCustomView(true);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    let tableChange = changes['table'];
    if (tableChange && tableChange.firstChange) {
      if (this.table.page) {
        this.table.page.viewId = this.currentViewId;
        this.cdr.detectChanges();
      }


    }
  }
  ngOnInit(): void {
    //2022-01-25 gnguyen start add
    this.accessChecker.isGranted('view', this.resourceCustomView.table.remove).subscribe(result => {

      this.isAdmin = result;
    })

    //2022-01-25 gnguyen end add
  }

  loadCustomView(needRefreshTable: boolean = false) {
    // if(this.table && this.table.page)
    // this.table.page.filter = []; //RESET PAGE FILTER TO MAKE SURE THE FILTER KIT WILL GET DATA FROM CUSTOMVIEW

    this.service.getCustomView(this.page).subscribe(resp => {
      this.customView = resp.result;
      //  if(this.currentViewId !== 0 || this.currentViewId == null)
      if (this.currentViewId != null) {
        this.refrestTable(needRefreshTable);
      }
    })
  }
  refrestTable(needRefreshTable: boolean = false) {
    this.currentViewId = typeof this.currentViewId === 'string' ? parseInt(this.currentViewId) : this.currentViewId;
    const currentViewActive = this.customView ? this.customView.find(x => x.viewId === this.currentViewId) : null;
    this.table.page.viewId = this.currentViewId;
    if (currentViewActive) {
      this.currentActiveView = currentViewActive;
      var propColumnDisplay = [];
      if (currentViewActive.columnMapping) {
        this.table.page.filter = [];
        currentViewActive.columnMapping.forEach(column => {
          var tableColumn: any = this.table.columnsTable.find((x: any) => x.prop == column.prop);
          if (tableColumn) {
            propColumnDisplay.push(column.prop);
            tableColumn.filterValue = column.filterValue;
            tableColumn.filterOperator = Helper.createSpaceString(column.filterOperator);
            this.table.page.filter = Helper.formatFilter(this.table.page.filter, column.prop, column.filterValue, column.filterType, column.filterOperator);
          }
        });
      }
      if (currentViewActive.orderMapping) {
        this.table.page.orders = [];
        this.table.table.sorts = [];
        currentViewActive.orderMapping.forEach(column => {
          var tableColumn: any = this.table.columnsTable.find((x: any) => x.prop == column.sort);
          if (tableColumn) {
            this.table.table.sorts.push({
              prop: column.sort,
              dir: column.sortDir == 0 ? "asc" : "desc",
            })
            this.table.page.orders = Helper.formatOrder(this.table.page.orders, column.sort, column.sortDir,column);
          }
        })
      }
      this.table.table.sorts = [...this.table.table.sorts];
      this.table.columns = this.table.actionColumn.concat(this.table.columnsTable.filter((x: any) => propColumnDisplay.includes(x.prop) || propColumnDisplay.includes(x.name)))
      this.table.refreshTable(true);
    } else {
      if (this.currentViewId === 0 || !this.currentViewId) {
        this.table.page.filter = [];
        this.table.columnsTable.forEach((col: any) => {
          col.filterValue = null;
          col.filterOperator = undefined;

        })
        // this.columns.forEach(col => {
        //   if (this.table.columns.find(x => x.name == col.name) == undefined) {
        //     this.table.columns.push(col);
        //   }
        // });

        //remove the filter/sort configuration at column header
        this.table.actionColumn = [];
        this.table.table.sorts = [];
        this.table.columns = [];

        //remove filter/sort configuration at cache data API
        this.table.page.filter = [];
        this.table.page.orders = [];

        this.table.ngOnInit();
        this.table.refreshTable(true);
      }
    }
  }
  onClickDeleteView() {
    if (this.currentViewId) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: 'Do you wish to delete current view?'
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.service.delCustomView(this.currentViewId)
            .subscribe()
            .add(() => {
              this.router.navigate([this.currentRouter]);
            })
          this.toastr.success('Delete customview successfully', 'Success');
        }
      })
    }
  }

  onClickAddEditView(isAdd: boolean = false) {
    const dialogRef = this.dialog.open(AddEditCustomViewComponent, {
      disableClose: true,
      height: '100vh',
      width: '40vw',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: isAdd ? TblActionType.Add : TblActionType.Edit,
        columns: this.columns,
        viewIdentify: this.viewIdentify,
        model: this.currentActiveView
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response != null) {
        this.loadCustomView(true);
      }
    });
  }
  selectView(viewId: any) {
    // localStorage.setItem(this.currentRouter, viewId);
    // let currentTab = this.tabMode.getCurrentActiveTab();
    // let newExtendData = this.tabMode.setExtendData(currentTab.extendData, 'viewId', viewId);
    // let newName = this.tabMode.formatTabBySystemName(this.currentRouter + "view/" + viewId)
    // this.tabMode.editFullTab(null, newName.link, newExtendData);

    // localStorage.removeItem(this.currentRouter);
    //this.router.navigate([this.currentRouter + "view/", viewId])
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([this.currentRouter + "view/", viewId]));
  }

  get intCurrentViewId() {
    if (this.currentViewId) {
      if (typeof this.currentViewId === "string") {
        return parseInt(this.currentViewId);
      } else {
        return this.currentViewId;
      }
    } else return this.currentViewId;
  }
  onNewClearFilterAction() {
    this.refrestTable(true);
    // return sub-action:
    this.afterClearFilter.emit();
  }
}
