import { prop, required } from "@rxweb/reactive-form-validators";
import { Page } from "src/app/shared/models/paging/page";
export class FFCUrl {
    @prop()
    ffcUrlId: number;
    @required()
    url: string;
    @required()
    state: string;
    reponse: string;
    extendData: string;
    seoId: number | null;
    entityId: string;
    issueStatus: string;
    lastIndexed: string | null;
    lastInspected: string | null;
    lastCrawl: string | null;
    deleted: boolean;
    dateDeleted: string | null;
    dateCreated: string | null;
    dateModified: string | null;
    dateExpired: string | null;
}

export class IndexingQueue {
    @prop()
    indexingQueueId: number;
    ffcUrlId: number;
    ffcUrl: FFCUrl;
    @prop()
    status: string;
    @prop()
    type: string;
    @required()
    maxAttempt: number;
    deleted: boolean;
    dateDeleted: string | null;
    dateCreated: string | null;
    dateModified: string | null;
    dateExpired: string | null;
}
export class IndexingProgress {
    @prop()
    indexingQueue: IndexingQueue;
    @prop()
    indexingProgressId: number;
    indexingQueueId: number;
    @prop()
    isSuccess: boolean;
    sendDate: string | null;
    @prop()
    status: string;
    deleted: boolean;
    dateDeleted: string | null;
    dateCreated: string | null;
    dateModified: string | null;
    dateExpired: string | null;
}

export class GDAILYINSPECTCOUNTER {
    InspectQPM: number;
    InspectQPD: number;
    LastInspectDate: string | null;
}
export class GDAILYINDEXCOUNTER {
    IndexQPD: number;
    LastIndexDate: string | null;
}

export interface IndexingJobInfo {
    currentServerTime: Date | string | null;
    isRunning: boolean;
    timeToNextRun: string;
}

export class GSCResponse {
    responseAggregationType: string
    rows: GSCRow[]
    eTag: any
}

export class GSCRow {
    clicks: number
    ctr: number
    impressions: number
    keys: string[]
    position: number
    eTag: any
}
export class GoogleSearchQueryRequestViewModel {
    startDate: Date | string;
    endDate: Date | string;
    dimension: string;
    limitRow: number;
    url: string;
    listUrl: string[]
}
export class RequestSetting {
    dailyIndexLimit: number = 100;
    dailyInspectLimit: number = 1200;
    inspectMinuteQuota: number = 600;
    sitemap: string = ''
}
export class InspectJobSetting {
    isActive: boolean;
    inspectChunk: number;
    inspectTimer: number;
    role: string;
}
export class IndexJobSetting {
    isActive: boolean;
}
export class PutToQueueJobSetting {
    isActive: boolean;
    putToQueueTimer: string = "";
    putToQueueLastRun: string = "";
}
export class CrawlJobSetting {
    isActive: boolean;
    crawlJobTimer: string = "";
    crawlJobLastRun: string = "";
}
export class QueueToProgressJobSetting {
    isActive: boolean;
    queueToProgressJobTimer: string = "";
    queueToProgressJobLastRun: string = "";
}
export class IndexingChartData {
    datas: IndexingStatusRow[];
    totalUrls: number = 0;
    indexed: number = 0;
    notIndexed: number = 0;
    ignored: number = 0;
    requested: number = 0;
}
export class IndexingStatusRow {
    value: number;
    name: string;
    itemStyle: ItemStyle;
}
export class ItemStyle {
    color: string;
}

export class IndexSummaryBlockRequestModel {
    startDate: Date | string | number | null;
    endDate: Date | string | number | null;
    filterCondition: string;
}
export class IndexSummaryBlockViewModel {
    totalIndexed: number;
    indexAdjustment: number;
    totalNotIndexed: number;
    notIndexAdjustment: number;
    totalRequesting: number;
    requestingAdjustment: number;
    requestFailAdjustment: number;
    totalRequestFailed: number;
    startDate: Date | string;
    endDate: Date | string;
}

export class ExpandIndexingPage extends Page {
    targetState: string;
}

export interface IndexingStackChartResponseData {
    new(): IndexingStackChartResponseData;
    series: SingleStackChartData[];
    dateSeries: Date | string[];
}
export interface SingleStackChartData {
    name: string;
    data: number[];
    type: string;
    stack: string;
    itemStyle: ItemStyle;
    emphasis: Emphasis;

}
export interface Emphasis {
    focus: string;
}
export interface MobileFriendlyTestResponse {
  MobileFriendliness: string;
  MobileFriendlyIssues: MobileFriendlyIssue[]
  ResourceIssues: ResourceIssue[]
  Screenshot: {
    Data: string;
    MimeType: string;
    ETag: string;
  }
  TestStatus: {
    Detail: string;
    Status: string;
    ETag: string;
  }
  ETag: string;
}
interface MobileFriendlyIssue {
  Rule: string,
  Etag: string;
}
interface ResourceIssue {
  BlockedResource: {
    Url: string;
    ETag: string;
  }
  ETag: string;
}
export const TestStatusEnum = {
  PAGE_UNREACHABLE: "Google cannot access the URL because of a user error such as a robots.txt blockage, a 403 or 500 code etc. Please make sure that the URL provided is accessible by Googlebot and is not password protected.",
  INTERNAL_ERROR: "Inspection terminated in an error state. This indicates a problem in Google's infrastructure, not a user error. Please try again later.",
  TEST_STATUS_UNSPECIFIED: "Internal error when running this test. Please try running the test again."
}

export const FfcUrlStatus = [
  { text: "INDEXED", value: "INDEXED" },
  { text: "NOTINDEXED", value: "NOTINDEXED" },
  { text: "QUEUE", value: "QUEUE", },
  { text: "REQUESTING", value: "REQUESTING" },
  { text: "REQUESTED FAILED", value: "REQUESTED FAILED" },
  { text: "REQUESTED SUCCESS", value: "REQUESTED SUCCESS" },
  { text: "CRAWL", value: "CRAWL" },
  { text: "RECRAWL", value: "RECRAWL" },
  { text: "IGNORED", value: "IGNORED" },
  { text: "REMOVED", value: "REMOVED" },
]

export interface IndexingHistory {
  IndexingHistoryId: number;
  FFCUrlId: number;
  FFCUrl: FFCUrl;
  State: string;
  QueueStatus: string;
  Reponse: string;
  ExtendData: string;
  Type: string;
  IssueStatus: string;
  Deleted: boolean;
  DateDeleted: string | null;
  DateCreated: string | null;
  DateModified: string | null;
  DateExpired: string | null;
}

export class SeoActivityLogViewModel {
    startDate: Date | string;
    endDate: Date | string;
    type: string;
    objId: string;
    hyperDataIdList: number[];
    seoInfoId: string;
}
export class SeoActivityLogRespond {
    action: string;
    logInfo1: string;
    logInfo2: string;
    propertyName: string;
    subObjName: string;
    userName: string;
    dateCreated: Date | string;
}