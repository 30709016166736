<mat-dialog-content fxLayout="column">
    <p [style.margin-bottom]="0"></p>
    <mat-form-field appearance="standard">
        <mat-label>Select tags</mat-label>
        <input matInput [formControl]="tagsFrm" [matAutocomplete]="autoTags" required placeholder="e.g tag1, tag2, ..."
            cdkFocusInitial>
        <mat-autocomplete autoActiveFirstOption #autoTags="matAutocomplete">
            <mat-option *ngFor="let option of filteredTag | async" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="tagsFrm.invalid">This field is required</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="tagsFrm.value" [disabled]="tagsFrm.invalid">Save</button>
</mat-dialog-actions>