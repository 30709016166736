import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { required, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { map } from 'rxjs/operators';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { UserUploadManagementService } from 'src/app/modules/admin/user-upload-management/user-upload-management.service';
import { UserUpload } from 'src/app/modules/admin/user-upload-management/user-upload.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { Helper } from 'src/app/shared/utility/Helper';

@Component({
  selector: 'app-profile-file-manage-owner',
  templateUrl: './profile-file-manage-owner.component.html',
  styleUrls: ['./profile-file-manage-owner.component.scss']
})
export class ProfileFileManageOwnerComponent implements OnInit {

  @Input() userUpload: UserUpload;
  @Input() showActionDialog: boolean = false;
  ownerInputModel: OwnerFileInput;
  ownerFrm: FormGroup;
  formatUser = { value: 'userName', key: 'id' };
  getOwnerApi;
  selectOwners: string[] = [];
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProfileFileManageOwnerComponent>,
    private frmBuilder: RxFormBuilder,
    private userService: UserService,
    private userUploadService: UserUploadManagementService,
    private toast: NbToastrService
  ) {
    this.userUpload = data.userUpload ?? this.userUpload;
    this.showActionDialog = data.showActionDialog ?? this.showActionDialog;
    this.ownerInputModel = new OwnerFileInput();
    this.getOwnerApi = this.userService.getAllUserModel().pipe(
      map((val: ReturnResult<UserModel[]>) => Helper.mapArrayToFormat(this.formatUser, val.result))
    );
  }

  ngOnInit() {
    this.ownerFrm = this.frmBuilder.formGroup(OwnerFileInput, this.ownerInputModel);
    this.setOwners();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  onSelectedOwner(event) {
    this.ownerFrm.controls.owners.setValue(event);
    this.selectOwners = event;
    if (this.ownerFrm.controls.owners.value?.length == 0) {
      this.ownerFrm.setErrors({ 'incorrect': true });
    } else {
      this.ownerFrm.setErrors(null);
    }
  }
  setOwners() {
    if (this.userUpload && this.userUpload.owners) {
      this.selectOwners = this.userUpload.owners.split(',').filter(x => x && x.trim() !== "");
      this.ownerFrm.controls.owners.setValue(this.selectOwners);
    }
  }
  saveOwners(): void {
    this.loading = true;
    if (this.userUpload && this.selectOwners.length > 0) {
      let newUserUploadModel: UserUpload = Object.assign({}, this.userUpload);
      newUserUploadModel.owners = this.selectOwners.join(',');
      this.userUploadService.updateUserUploadRecord(newUserUploadModel).subscribe(res => {
        if (res.result) {
          this.toast.success('Update owners successfully', 'Success');
          this.dialogRef.close(true);
        } else {
          this.dialogRef.close(false);
        }
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }
}

class OwnerFileInput {
  @required()
  owners: string[] = [];
}