import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { UserUploadManagementService } from 'src/app/modules/admin/user-upload-management/user-upload-management.service';
import { UserUpload, changeURLUpload } from 'src/app/modules/admin/user-upload-management/user-upload.model';
import { ProfileFileTabComponent } from '../profile-file-tab.component';

@Component({
  selector: 'app-upload-url',
  templateUrl: './upload-url.component.html',
  styleUrls: ['./upload-url.component.scss']
})
export class UploadUrlComponent implements OnInit {

  fileTab: ProfileFileTabComponent;
  @ViewChild('profileFileTab', { static: false }) set setProfileFileTab(content: ProfileFileTabComponent) { if (content) this.fileTab = content }

  @Input() profileId: string;
  @Input() nameId: string;
  @Input() modelUpload: UserUpload;
  @Input() referenceType:string;

  isEdit = false;
  
  webUrl = "((https?|ftp|gopher|telnet|file):((//)|(\\\\))+[\\w\\d:#@%/;$()~_?\\+-=\\\\\\.&]*)"
  urlInput = this.formBuilder.group({
    uploadURLInput: ['', Validators.pattern(this.webUrl)]
  })
  isUploadURL:boolean = false;
  constructor(
    public dialModalRef: MatDialogRef<UploadUrlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private userUploadService: UserUploadManagementService,
    private toast: NbToastrService,
  ) {
      this.profileId = data.profileId;
      this.nameId = data.nameId;
      this.modelUpload = data.modelUpload;
      this.referenceType = data.referenceType;
      if(this.modelUpload){
        this.isEdit = true;
        this.urlInput.controls.uploadURLInput.setValue(this.modelUpload.physicalPath);
      }
   }

  ngOnInit(): void {
  }
  addURLInput(){
    if (this.urlInput.controls.uploadURLInput.valid) {
      let submitModel = new UserUpload();
      submitModel.fileName = this.urlInput.controls.uploadURLInput.value;
      submitModel.referenceType = this.referenceType;
      submitModel.referenceId = this.profileId;
      submitModel.owners = this.nameId;
      submitModel.physicalPath = this.urlInput.controls.uploadURLInput.value;
      this.userUploadService.uploadURL(submitModel).subscribe(resp=>{
        if(resp.result){
            this.toast.success('Upload URL successfully', 'Success');
            this.fileTab?.refreshData(true);
            this.dialModalRef.close(true);
        }
      })
    }
  }
  ChangeURL(){
    if(this.modelUpload){
      let changeURLModel = new changeURLUpload();
      changeURLModel.newURL = this.urlInput.controls.uploadURLInput.value;
      changeURLModel.userUploadHistoryId = this.modelUpload.userUploadHistories[0].userUploadHistoryId;
      this.userUploadService.changeURLUpload(changeURLModel).subscribe(resp=>{
        if(resp.result){
          this.toast.success('Change URL successfully', 'Success');
          this.fileTab?.refreshData(true);
          this.dialModalRef.close(true);
        }
      })
    }
  }
  closeURLDialogRef() {
    if (this.dialModalRef) {
      this.dialModalRef.close();
    }
  }
}
