import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedEventService {
  public sendNewMessage = new EventEmitter<any>();
  public openChatIframe = new EventEmitter<any>();
  public refreshConversation = new EventEmitter<any>();
  public newNotify = new EventEmitter<any>();
  public completeLoadRecentlyConversationList = new EventEmitter<any>();
  constructor() { }
}
