<ng-container [ngSwitch]="operationMode">
  <mat-form-field *ngSwitchDefault>
    <mat-label>{{columnName}}</mat-label>
    <input matInput type="text" (change)="onTextChanged($event.target.value)" [value]="value??''" trim="blur" />
  </mat-form-field>
  <mat-form-field
    *ngSwitchCase=" operationMode == 'Email Actions' || operationMode== 'EmailActions'? operationMode : ''">
    <mat-label>{{columnName}}</mat-label>
    <mat-select [formControl]="actions" multiple (openedChange)="onClickDropdown($event)">
      <mat-option *ngFor="let action of mailActions" [value]="action.value">{{action.text}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>