import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetGeneralCountByProfileId, ProfileAdditionDetail, ProfileAdditionDetailForOpportunity } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { environment } from 'src/environments/environment';
import { ReturnResult } from '../models/return-result';

@Injectable({
  providedIn: 'root'
})
export class ProfileAdditionDetailService {
  baseUrl = environment.apiProfileAdditionDetailManagement;
  constructor(private http: HttpClient) { }
  saveSingleProfileAdditionByPropName(propName: string, model: ProfileAdditionDetail | ProfileAdditionDetailForOpportunity): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveSingleProfileAdditionByPropName`, {
      propName,
      profileAddition: model
    });
  }

  sumIncomeAndExpense(id: string): Observable<ReturnResult<{}>> {
    return this.http.get<ReturnResult<{}>>(`${this.baseUrl}/SumIncomeAndExpense?id=${id}`);
  }
  getGeneralCountByProfileId(profileId: string): Observable<ReturnResult<GetGeneralCountByProfileId[]>> {
    return this.http.get<ReturnResult<GetGeneralCountByProfileId[]>>(`${this.baseUrl}/GetGeneralCountByProfileId?profileId=${profileId}`);
  }
  updateProfileAdditionDetail(profileId: string, propName: string, value: string): Observable<ReturnResult<ProfileAdditionDetail>> {
    return this.http.post<ReturnResult<ProfileAdditionDetail>>(`${this.baseUrl}/${profileId}/${propName}`, {
      value
    });
  }
}
