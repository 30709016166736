import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { Helper } from 'src/app/shared/utility/Helper';
import { NoteDetailCardComponent } from '../../note-details/note-detail-card/note-detail-card.component';
import { NoteDetailsComponent } from '../../note-details/note-details.component';
import { NoteManagementService } from '../note-management.service';
import { Note, NoteDetails } from '../noteManagement.model';

@Component({
  selector: 'app-overlay-note-details',
  templateUrl: './overlay-note-details.component.html',
  styleUrls: ['./overlay-note-details.component.scss']
})
export class OverlayNoteDetailsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() listNote: NoteDetails[] = [];
  // @Input() referenceType: ShadowProfileEnum = null;
  // @Input() referenceId: string;
  @Input() maxNoteCard: number = 1;

  @Output() listNoteChange: EventEmitter<NoteDetails[]> = new EventEmitter<NoteDetails[]>();
  listNoteChanged: () => Observable<NoteDetails[]> = () => this.listNoteChange.asObservable();

  @Output() onRefresh: EventEmitter<{ id: number, isDeleted: boolean }> = new EventEmitter<{ id: number, isDeleted: boolean }>();
  onRefreshed: () => Observable<{ id: number, isDeleted: boolean }> = () => this.onRefresh.asObservable();

  @Output() onLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  isLoading: Observable<boolean> = this.onLoading.asObservable();
  completedLoading: () => void = () => this.onLoading.emit(false);

  @Output() onChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  isChanged: () => Observable<boolean> = () => this.onChanged.asObservable();

  @ViewChild("noteDetails", { static: true }) noteDetailsTemplate: TemplateRef<ElementRef>;

  noteCardTemplates: QueryList<NoteDetailCardComponent>;
  @ViewChildren("noteCardTemplates") set setNoteCardTemplates(content: QueryList<NoteDetailCardComponent>) {
    if (content) this.noteCardTemplates = content;
  }

  allNoteQueueBackup: NoteDetails[] = [];
  listNoteOnQueue: NoteDetails[] = [];
  user;

  overlayConfig: OverlayConfig = new OverlayConfig({ panelClass: 'note-details-panel', });
  private overlayRef: OverlayRef;
  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private overlayService: Overlay,
    public viewContainerRef: ViewContainerRef,
    private noteManagementService: NoteManagementService,
    private authService: NbAuthService,
    private toast: NbToastrService,
    private cd: ChangeDetectorRef
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) this.user = token.getPayload();
    });
  }

  ngOnInit(): void {
    this.onLoading.emit(false);
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.setUpNote();
    this.noteManagementService.listNoteChanged().pipe(takeUntil(this._destroy$))
      .subscribe(resp => {
        if (resp) {
          this.listNote = resp;
          var checkChange = [...this.listNote, ...this.listNoteOnQueue];
          if (JSON.stringify(checkChange) != JSON.stringify(this.allNoteQueueBackup))
            this.validateQueueNote();
        }
      })

    this.listNoteChanged().pipe(takeUntil(this._destroy$))
      .subscribe(resp => this.noteManagementService.setNoteDetails(resp))
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeListNote = changes.listNote;
    if (changeListNote) {
      // if (changeListNote.firstChange)
      //   this.setUpNote();

      var checkChange = [...changeListNote.currentValue, ...this.listNoteOnQueue];
      if (JSON.stringify(checkChange) != JSON.stringify(this.allNoteQueueBackup))
        this.validateQueueNote();
    }
  }

  ngOnDestroy(): void {
    if (this.overlayRef) this.overlayRef.detach();
    if (this._destroy$) {
      this._destroy$.next();
      this._destroy$.complete();
    }

  }

  setUpNote() {
    if (this.overlayRef) this.overlayRef.detach();

    let globalStrategy = this.overlayService.position().global();
    this.overlayConfig.positionStrategy = globalStrategy;
    this.overlayRef = this.overlayService.create(this.overlayConfig);
    this.overlayRef.attach(new TemplatePortal(this.noteDetailsTemplate, this.viewContainerRef));
    var overlayNoteDetails = window.document.querySelector<any>('.note-details-panel');
    if (overlayNoteDetails) {
      overlayNoteDetails.parentNode.classList.add("overlay-note-stack");
      overlayNoteDetails.parentNode.style.zIndex = 1005;
    }
  }

  validateQueueNote() {
    if (this.listNote && this.listNote.length > 0) {
      this.checkExistedNoteInQueue();
      let distinctItem = this.listNote.filter(
        (item, i, arr) => arr.findIndex(t => t.id === item.id) === i
      );

      if (distinctItem && distinctItem.length > this.maxNoteCard) {
        let firstItem = distinctItem.shift();
        this.listNoteOnQueue.push(firstItem);
        this.listNoteOnQueue = [...this.listNoteOnQueue];
      }

      this.listNote = [...distinctItem];
      if (this.listNote && this.listNote.length == 1 && this.noteCardTemplates && this.noteCardTemplates.get(0))
        this.noteCardTemplates.get(0).setFocusClick();

      this.allNoteQueueBackup = [...this.listNote.slice(), ...this.listNoteOnQueue.slice()];
      setTimeout(() => this.listNoteChange.emit(this.listNote), 50);
    }
  }

  checkExistedNoteInQueue() {
    if (this.listNote.length > this.maxNoteCard) {
      const lastNote = this.listNote.pop();

      if (lastNote) {
        let indexShow = this.listNote.findIndex(x => x.id == lastNote.id);
        if (indexShow > -1 && this.noteCardTemplates.get(indexShow))
          this.noteCardTemplates.get(indexShow).setFocusClick();

        let indexQueue = this.listNoteOnQueue.findIndex(x => x.id == lastNote.id);
        if (indexQueue > -1) this.showNoteItemDetailsFromQueue(lastNote)

        if (indexShow == -1 && indexQueue == -1)
          this.listNote.push(lastNote);
      }
    }
  }

  //new version FUNC: removeNoteItem
  closeNoteDetails(note: NoteDetails, index: number): void {
    if (index >= 0) this.listNote = this.listNote.filter((item, i) => i != index);
    if (this.listNoteOnQueue.length > 0) {
      var firstStack = this.listNoteOnQueue.pop();
      this.listNote.unshift(firstStack);
    }

    this.listNote = [...this.listNote];
    this.listNoteChange.emit(this.listNote);
    this.onLoading.emit(true);
    setTimeout(() => this.onLoading.emit(false), 2000);
  }

  //new version FUNC: noteBusiness
  saveNote(note: NoteDetails, action: string, index: number, noteLoading: NoteDetails) {
    try {
      if (noteLoading) noteLoading["loading"] = true;

      const model = Object.assign(note);
      this.noteManagementService.saveNote(model, this.user.nameid).subscribe({
        next: async (res) => {
          if (res.result) {
            let noteDetail: NoteDetails = (await this.noteManagementService.getNoteById(res.result).toPromise()).result;

            var editNote = this.listNote.filter((x, i) => i === index && x.id == model.id);
            if (editNote)
              editNote = editNote.map(x => Object.assign(x, {
                id: res.result,
                title: model.title,
                note: model.note,
                lastUserId: noteDetail?.lastUserId,
                lastUser: noteDetail?.lastUser,
                ownerId: noteDetail?.ownerId,
                user: noteDetail?.user,
                "ownerFullName": (`${noteDetail?.user?.firstName || ''} ${noteDetail?.user?.lastName || ''}`).trim() || 'Unknown',
                "ownerUserName": noteDetail?.user?.userName,
                "lastUserFullName": (`${noteDetail?.lastUser?.firstName || ''} ${noteDetail?.lastUser?.lastName || ''}`).trim() || 'Unknown',
                "lastUserName": noteDetail?.lastUser?.userName,
                "lastUserAvatar": noteDetail?.lastUser?.pictureURL?.replace(/\\/g, '/'),
              }));

            //refresh table when add new or edit title.
            if (action != "autosave" || model.id == 0) {
              this.toast.success(`Save note successfully`, "Success");
              this.onRefresh.emit({ id: res.result, isDeleted: false });
            }

            //refresh table when edit content and close.
            if (action == "autosave" && (!editNote || editNote.length == 0))
              this.onRefresh.emit({ id: res.result, isDeleted: false });
          }
        },
        error(err) {
          if (noteLoading) noteLoading["loading"] = false;
        },
        complete: () => {
          if (this.noteCardTemplates.get(index))
            this.noteCardTemplates.get(index).autoSaveCompleted();

          if (noteLoading) noteLoading["loading"] = false;
        }
      });
    }
    catch (ex) {
      console.error(ex);
      if (noteLoading) noteLoading["loading"] = true;
    }
  }

  deleteNote(note: NoteDetails, index: number) {
    this.noteManagementService.deleteNote(note.id, this.user.nameid).subscribe(
      (res) => {
        if (res.result) {
          this.toast.success(`Delete note successfully`, "Success");
          this.closeNoteDetails(note, index);
          this.onRefresh.emit({ id: note.id, isDeleted: true });
        }
      },
    );
  }

  showNoteItemDetailsFromQueue(note: NoteDetails) {
    this.listNoteOnQueue = this.listNoteOnQueue.filter((i) => i.id !== note.id);
    this.listNoteOnQueue.push(this.listNote.shift());
    this.listNote.push(note);
    this.listNote = [...this.listNote];
    this.listNoteChange.emit(this.listNote);
  }

  removeNoteFromQueue(note: NoteDetails) {
    if (this.listNoteOnQueue && this.listNoteOnQueue.length > 0) {
      this.listNoteOnQueue = this.listNoteOnQueue.filter(
        (i) => i.id !== note.id
      );
      this.listNoteOnQueue = [...this.listNoteOnQueue];
    }
  }

  deleteNoteFromOutSide(id: number) {
    if (id && id > 0) {
      this.onRefresh.emit({ id: id, isDeleted: true });
      var findIndexShow = this.listNote.findIndex(x => x.id == id);
      if (findIndexShow > -1) {
        this.closeNoteDetails(this.listNote[findIndexShow], findIndexShow);
        return;
      }

      var findIndexQueue = this.listNoteOnQueue.findIndex(x => x.id == id);
      if (findIndexQueue > -1)
        this.removeNoteFromQueue(this.listNoteOnQueue[findIndexQueue]);
    }
  }
}
