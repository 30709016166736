export enum TabProfile {
    General = 0,
    ReasonsAndBio = 1,
    Contacts = 2,
    Locations = 3,
    Multimedia = 4,
    Files = 5,
    SEO = 6,
    Note = 7,
    Activities = 8,
    Relationship = 9,
    MoreInfo = 10
}