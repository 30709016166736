import { SupportTicketPage } from './../../../../shared/models/paging/page';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { SupportTicket } from '../../support-ticket-management/support-ticket.model';

@Injectable({
  providedIn: 'root'
})
export class SupportTicketService {
baseUrl = environment.apiSupportTicket;
constructor(private http: HttpClient) { }
  uploadScreenShot(image: any): Observable<ReturnResult<string>>{
    const bodyFormData = new FormData();
    bodyFormData.append('upload', image);
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/UploadFile`,bodyFormData);
  }
  getSupportTicketPaging(page: SupportTicketPage) {
    return this.http.post<PagedData<SupportTicket>>(`${this.baseUrl}/GetSupportTicketPaging`,page);
  }
//2022-25-05 thoainda add start
  saveSupportTicKet(ticketId : number, resolutionCode : string, systemComment :string):Observable<ReturnResult<Boolean>>{
    return this.http.post<ReturnResult<Boolean>>(`${this.baseUrl}/SaveSupportTicket`,{ticketId,resolutionCode,systemComment});
  }

  // deleteSupportTicket(ticketId : number){
  //   return this.http.delete<ReturnResult<Boolean>>(`${this.baseUrl}/DeleteSupportTicKet/${ticketId}`);
  // }
  // deleteListSupportTicket(ticketId:number[]){
  //   return this.http.post<ReturnResult<Boolean>>(`${this.baseUrl}`,ticketId);
  // }
  //2022-25-05 thoainda add end
}
