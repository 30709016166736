import { SettingService } from 'src/app/shared/services/setting.service';
import { ProfileAdditionDetail, ProfileAdditionDetailForOpportunity, ProfileContact } from './../../profile-management/profile-detail.model';
import { CurrencyFormatPipe } from './../../../../shared/pipes/currency-format.pipe';
import { NumberFormatPipe } from './../../../../shared/pipes/number-format.pipe';
import { SaleAccountService } from './../../sale-account-management/sale-account.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, take, takeUntil, tap } from 'rxjs/operators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { CallService } from 'src/app/shared/services/call.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { Contact, KeyPairsValueSelectDropDown, ProfileDetailModel } from '../../profile-management/profile-detail.model';
import { LinkscopeEmailComponent } from '../../profile-management/profile-detail/action-block/linkscope-email/linkscope-email.component';
import { AutomateDatastateService } from '../../profile-management/profile-detail/automate-datastate-log/automate-datastate.service';
import { ProfileService } from '../../profile-management/profile.service';
import { AddEditTaskComponent } from '../../task-management/add-edit-task/add-edit-task.component';
import { TaskBoardService } from '../../task-management/task-board/task-board.service';
import { UserService } from '../../user-management/user.service';
import { OpportunityManagementService } from '../opportunity-management.service';
import { ProposalNonArtistFeeInputModel, ProposalRevision, SaleOpportunity } from '../opportunity.model';
import dateFormat, { masks } from "dateformat";
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { EntityColorEnums } from 'src/app/shared/enums/entity-color.enums';
import { NoteTabComponent } from 'src/app/shared/components/note-tab/note-tab.component';
import { Note, NoteDetails } from 'src/app/shared/components/note-management/noteManagement.model';
import { ContactComponent } from 'src/app/shared/components/stand-alone-component/contact/contact.component';
import { TableVariable } from 'src/app/shared/contances/data-table-contance';
import { ContactRelationshipGridComponent } from 'src/app/shared/components/contact-relationship-grid/contact-relationship-grid.component';
import { AddExistingContactComponent } from '../../profile-management/profile-detail/contact-tab/add-existing-contact/add-existing-contact.component';
import { ActivatedRoute, Router } from '@angular/router';
import { permissionGridNoteOpportunity, permissionNonArtistExpenses, permissionNonArtistIncome, permissionSaleOpportunityFile } from 'src/app/shared/contances/permission';
import { OverlayNoteDetailsComponent } from 'src/app/shared/components/note-management/overlay-note-details/overlay-note-details.component';
import { NoteManagementService } from 'src/app/shared/components/note-management/note-management.service';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { UserModel } from '../../user-management/user-model';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { DynamicContentModel } from 'src/app/shared/models/dynamic-content.model';
import { ProfileAdditionDetailService } from 'src/app/shared/services/profile-addition-detail.service';
import { HotToastService } from '@ngneat/hot-toast';
import { ProfileFileTabComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/profile-file-tab.component';
import { UserUploadManagementService } from '../../user-upload-management/user-upload-management.service';
import { UserUpload } from '../../user-upload-management/user-upload.model';
import { PopOverSelectContactsComponent } from 'src/app/shared/components/stand-alone-component/pop-over-select-contacts/pop-over-select-contacts.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { TaskGridByProfileIdComponent } from '../../sale-lead-management/sale-lead-details/task-grid-by-profile-id/task-grid-by-profile-id.component';
import { ContactPage } from 'src/app/shared/models/paging/page';
import { ReviewContactCampaignComponent } from '../../email-campaign-management/email-campaign-detail/create-new-campaign/review-contact-campaign/review-contact-campaign.component';
// import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
// import { TabTagIndexModel } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { OpportunityContactTabComponent } from './opportunity-contact-tab/opportunity-contact-tab.component';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
import { environment } from 'src/environments/environment';
import { PrimasSendSMSComponent } from 'src/app/shared/components/template-management/primas-send-sms/primas-send-sms.component';
import { AddEditProposalNonArtistIncomeComponent } from '../non-artist-income/add-edit-proposal-non-artist-income/add-edit-proposal-non-artist-income.component';
import { NonArtistIncomeComponent } from '../non-artist-income/non-artist-income.component';
import { AddSaleAccountComponent } from '../../sale-account-management/add-sale-account/add-sale-account.component';
import { ArtistTabComponent } from './artist-tab/artist-tab.component';
import { AddEditArtistComponent } from './artist-tab/add-edit-artist/add-edit-artist.component';
import { NbAccessChecker } from '@nebular/security';
import { ProposalArtistTemplateComponent } from './proposal-artist-template/proposal-artist-template.component';
import { ProposalRevisionTabComponent } from './proposal-revision-tab/proposal-revision-tab.component';
import { UploadUrlComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/upload-url/upload-url.component';
import { UserExperienceTrackingService } from 'src/app/shared/components/user-experience-tracking/user-experience-tracking.service';
import { MicrosoftOffice365Service } from '../../../../shared/services/microsoft-office-365.service';

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrls: ['./opportunity-details.component.scss']
})
export class OpportunityDetailsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() id: string = null;
  @Input() openByDialog = true;
  @Input() optionalTaskId: string;
  @Input() creatableTask = true;

  @Output() saleOpportunityEmit: EventEmitter<SaleOpportunity> = new EventEmitter<SaleOpportunity>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Output() onAdvancedImport: EventEmitter<any> = new EventEmitter();
  @Output() refreshTask: EventEmitter<void> = new EventEmitter();

  @ViewChild(MatMenuTrigger, { static: false }) menuStage: MatMenuTrigger;
  noteTab: NoteTabComponent;
  @ViewChild('gridNoteTab', { static: false }) set setGridNoteTab(content: NoteTabComponent) { if (content) this.noteTab = content };
  contactTab: OpportunityContactTabComponent;
  @ViewChild('contactGrid', { static: false }) set contactAccount(content: OpportunityContactTabComponent) { if (content) this.contactTab = content };
  artistTab: ArtistTabComponent;
  @ViewChild('artistGrid', { static: false }) set artistGrid(content: ArtistTabComponent) { if (content) this.artistTab = content };
  fileTab: ProfileFileTabComponent;
  @ViewChild('profileFileTab', { static: false }) set setProfileFileTab(content: ProfileFileTabComponent) { if (content) this.fileTab = content }
  nbMailPopover: NbPopoverDirective;
  @ViewChild('sendMailBtn', { static: false }) set setSendMailBtn(content: NbPopoverDirective) { if (content) this.nbMailPopover = content }
  makeCallPopover: NbPopoverDirective;
  @ViewChild('makeCallPopoverBtn', { static: false }) set setMakeCallBtn(content: NbPopoverDirective) { if (content) this.makeCallPopover = content }
  sendSMSPopover: NbPopoverDirective;
  @ViewChild('sendSMSBtn', { static: false }) set setSendSMSBtn(content: NbPopoverDirective) { if (content) this.sendSMSPopover = content }

  taskGrid: TaskGridByProfileIdComponent;
  @ViewChild('taskGrid', { static: false }) set setTaskGrid(content: TaskGridByProfileIdComponent) { if (content) this.taskGrid = content };

  proposalNonArtistTab: NonArtistIncomeComponent;
  @ViewChild('proposalNonArtistTab', { static: false }) set setProposalNonArtist(content: NonArtistIncomeComponent) { if (content) this.proposalNonArtistTab = content };

  proposalRevision: ProposalRevisionTabComponent;
  @ViewChild('proposalRevision', { static: false }) set setproposalRevision(content: ProposalRevisionTabComponent) { if (content) this.proposalRevision = content };

  @ViewChild('contactSelectPop', { static: true }) contactSelectPop: TemplateRef<any>;

  nbFilesPopover: NbPopoverDirective;
  @ViewChild('nbFilesBtn', { static: false }) set setFilesPopoverBtn(content: NbPopoverDirective) { if (content) this.nbFilesPopover = content }

  resourceProposalNonArtistIncome = permissionNonArtistIncome;
  resourceProposalNonArtistExpenses = permissionNonArtistExpenses;
  environment = environment;
  handleAutomate: boolean = false;
  isCopy = false;
  opportunityModel: ProfileDetailModel;
  proposalNonArtist: ProposalNonArtistFeeInputModel;
  user: any = null;
  isLoading: boolean = false;
  isRefreshing: boolean = false;
  isLoadingEdit: boolean = false;
  isCalling: boolean = false;
  isCallLoading: boolean = false;
  selectTab: TabOpportunity = TabOpportunity.Contact;
  lostTypeId: number = 0;
  wonTypeId: number = 0;
  calculatePercentProfit: number = 0;
  getPercentageProfitSetting: number = 0;
  colorTextDisplay: string = '';
  public get TypeEditModelOpportunity(): typeof TypeEditModelOpportunity { return TypeEditModelOpportunity; }
  formatValueQueryType = { key: 'automateDataStateId', value: 'dataStateName', color: 'colorCode', order: 'order', groupPlaceholder: 'groupPlaceholder' };
  listStage: KeyPairsValueSelectDropDown[] = [];
  currentStage: KeyPairsValueSelectDropDown = null;
  opportunityEnums = EntityColorEnums;
  validProperties = {
    opportunityName: { valid: [RxwebValidators.required()], message: `${this.environment.titleOpportunity} name is required` },
    probability: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "Probability is not valid" },
    amount: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: `${environment.titleEstimate} is not valid` },
    oneTimeProduct: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: `${environment.title1xProduct} is not valid` },
    oneTimeService: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: `${environment.title1xService} is not valid ` },
    arr: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "ARR is not valid" },
    termOfContract: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "Term Of Contract is not valid" },
    artistFeesToClient: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: `Artist Fees To Client is not valid ` },
  }


  private routeSub: Subscription;
  currentUrl: string = '';
  fullUrl = null;
  apiGetSearchRelationship: (data) => void = null;
  contactDialogRef: any;
  existingContactDialogRef: MatDialogRef<any, any>;
  private destroy$: Subject<void> = new Subject<void>();
  isHasPrimaryContact: boolean = false;
  resource = permissionGridNoteOpportunity;
  overlayNoteDetails: OverlayNoteDetailsComponent;
  prefixCurrency: string;
  lastNote: NoteDetails;
  ownerModel: UserModel;
  isCalculate: boolean = false;
  dynamicContentTypeAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  dynamicContentCompanyAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  dynamicContentLeadSourceAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  mathEquation: string = '';
  uploadedFile: any;
  uploading: boolean = false;
  fileResource = permissionSaleOpportunityFile;
  apiGetSearchOwner: (data) => void = (data) => this.userService.searchUser(data);
  isTabMode = Helper.checkTabMode();
  isShowButton: boolean = false;
  contacts: Contact[] = null;
  resetContacts: () => void = () => this.contacts = null;

  // vunh start 4/12/2022
  listChange = []; //this list is for the new logic placeholder value
  // vunh end 4/12/2022
  isDisableProduct: boolean = true;
  backOfficeUrl: string;
  isCheckingProposalPrevision: boolean = false;
  useNewUI: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<OpportunityDetailsComponent>,
    private opportunityService: OpportunityManagementService,
    private profileAdditionService: ProfileAdditionDetailService,
    private taskService: TaskBoardService,
    private clipboard: Clipboard,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private userService: UserService,
    private callService: CallService,
    private authService: NbAuthService,
    private profileService: ProfileService,
    private settingService: SettingService,
    private automateDataStateService: AutomateDatastateService,
    private accountService: SaleAccountService,
    public numPipe: NumberFormatPipe,
    private router: Router,
    private route: ActivatedRoute,
    public noteService: NoteManagementService,
    public currencyFormat: CurrencyFormatPipe,
    public dynamicContentService: DynamicContentService,
    private userUploadService: UserUploadManagementService,
    private hotToast: HotToastService,
    private numberPipe: NumberFormatPipe,
    // private tabMode: AdminTabModeService,
    private settingPoolService: SettingPoolService,
    private accessChecker: NbAccessChecker,
    private userExperienceTrackingService: UserExperienceTrackingService,
    private microsoftOffice365Service: MicrosoftOffice365Service
  ) {
    this.id = this.data?.model?.profileId ?? this.id;
    this.isLoading = true;
    if (this.id) {
      this.refreshData();
    }

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    this.apiGetSearchRelationship = (data) => this.accountService.searchSaleAccount(data);

    this.automateDataStateService.getOpportunityDataState()
      .pipe(map((vals: ReturnResult<any[]>) => Helper.mapArrayToFormat(this.formatValueQueryType, vals.result)))
      .subscribe(res => {
        if (res && res.length > 0) {
          this.listStage = [...res.map(x => x as KeyPairsValueSelectDropDown)];
          if (!this.currentStage && this.opportunityModel)
            this.currentStage = this.listStage.find(x =>
              parseInt(x.key) == this.opportunityModel?.automateDataStateId ?? 0
            );

          this.checkStateOpportunity();
          // this logic already comment in the sale-lead (add placeholder value in the list)
          // var alreadyAddFlag = []
          // this.listStage.forEach(x => {
          //   var order = x.order;
          //   var sameOrderList = this.listStage.filter(x => x.order == order);

          //   if (alreadyAddFlag.filter(x => x == order)?.length <= 0) {
          //     if (sameOrderList?.length > 1) {
          //       var index = this.listStage.findIndex(x => x.order == order || x.groupPlaceholder == sameOrderList[0].groupPlaceholder);

          //       if (this.listStage[index]) {
          //         alreadyAddFlag.push(this.listStage[index]?.order);
          //         if (order < this.currentStage?.order) {
          //           index += 1;
          //         }
          //         this.listStage.splice(index, 0, { value: this.listStage[index]?.groupPlaceholder, order: this.listStage[index]?.order } as KeyPairsValueSelectDropDown)
          //       }
          //     }
          //   }
          // })
          // //the list already had the placeholder logic
          // this.listChange = [...this.listStage];
        }
      });
    this.dynamicContentTypeAPI = this.dynamicContentService.getDynamicContentByType('opportunity-type');
    this.dynamicContentLeadSourceAPI = this.dynamicContentService.getDynamicContentByType('opportunity-leadsource');
    this.dynamicContentCompanyAPI = this.dynamicContentService.getDynamicContentByType('general_company');
    if (this.router.url.includes('/configuration/opportunity') ||
      this.router.url.includes('/configuration/email-history') ||
      this.router.url.includes('/configuration/email-inbox') ||
      this.router.url.includes('/configuration/history-data') ||
      this.router.url.includes('/configuration/email-campaign') ||
      this.router.url.includes('/configuration/dashboard-contact-map') ||
      this.router.url.includes('/configuration/sale-convert-history') ||
      this.router.url.includes('/configuration/import-contact') ||
      this.router.url.includes('/configuration/activity-log')
    ) {
      this.isShowButton = true;
    }

    this.settingService.getSettingByKeyAndGroup("IS_USE_NEW_UI_DETAIL", "SYSTEM").subscribe(resp => {
      if (resp?.result) this.useNewUI = parseInt(resp?.result?.value) > 0;
    })
  }

  ngOnInit(): void {
    this.userService.getAllUser();
    this.getBackOfficeURL();
    if (this.openByDialog && this.dialModalRef?.componentInstance) {
      if (this.isTabMode) this.dialModalRef.afterOpened().subscribe(() => {
        var overlayBackdrops = window.document
          .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
        for (var i = 0; i < overlayBackdrops.length; i++)
          overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
      })

      this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
      if (this.data.advancedImport) {
        this.dialModalRef.updateSize(this.openByDialog ? '1450px' : '1600px', Helper.heightDialog());
      }
      else {
        this.dialModalRef.updateSize(this.openByDialog ? '1200px' : '1600px', Helper.heightDialog());
      }
    }
    // this.taskService.getAllTaskStatus();
    // this.taskService.getAllPriority();
    // this.taskService.getAllTaskType();
    this.formatCurrencyPrefix();
    this.getLastNote();
  }

  ngAfterViewInit(): void {
    this.accessChecker.isGranted('view', 'total-client-fee')
      .pipe(take(1))
      .subscribe((granted: boolean) => {
        this.isDisableProduct = granted;
      });

    this.overlayNoteDetails = this.noteService.overlayNoteDetailsComponent;
    if (this.router.url.includes('/configuration/opportunity')) {
      // get cache tab index in local storage:
      let tag = this.getCacheTab();
      let patternRegex = /#([\d]+)/;
      if (!Helper.isNullOrEmpty(tag)) {
        this.selectTab = parseInt(tag.replace('#', ''));
        Helper.handleTabChangedUrl(this.selectTab, '/configuration/opportunity');
      } else
        // if end with #tabIndex then move to that tab
        if (patternRegex.test(this.router.url)) {
          let matchTabIndex = this.router.url.match(patternRegex).length > 0 ? Number.parseInt(this.router.url.match(patternRegex)[0].replace('#', '')) : TabOpportunity.Contact;
          this.selectTab = matchTabIndex;
        }
    } else {
      this.selectTab = this.openByDialog ? TabOpportunity.Contact : TabOpportunity.ActivityLog;
    }

    this.opportunityService.getOpportunityById()
      .pipe(
        tap(resp => this.profileService._profileDetailData$.next(resp)),
        takeUntil(this.destroy$)
      ).subscribe({
        next: resp => { if (resp.result) this.setupOpportunity(resp.result); },
        complete: () => this.isLoading = false,
      });

    this.opportunityService.isRefreshOpportunityGrid$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isRefresh: boolean) => this.isRefreshing = isRefresh);

    this.overlayNoteDetails.onRefreshed().pipe(
      takeUntil(this.destroy$),
      debounceTime(2000)
    ).subscribe(resp => {
      this.overlayNoteDetails.completedLoading();
      if (resp && this.noteTab) this.noteTab.refreshData();
      this.getLastNote(resp?.id, resp?.isDeleted);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeId = changes.id;
    if (changeId && changeId.currentValue != changeId.previousValue)
      this.refreshData();
  }

  ngOnDestroy(): void {
    if (this.routeSub != null)
      this.routeSub.unsubscribe();

    if (this.opportunityService.opportunityDetail$)
      this.opportunityService.opportunityDetail$.next(new ReturnResult<any>());
    this.destroy$.next();
    this.destroy$.complete();
  }

  refreshData() {
    this.opportunityService.refreshOpportunityById(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => {
          if (resp.result) {
            this.setupOpportunity(resp.result);
            this.getSumIncomeAndExpense();
            /*Get New Setting */
            // this.settingService.getSettingByKeyAndGroup("TOTAL_RE_MATCH", "SALE_CONFIG").subscribe(setting => {
            //   if (setting.result) {
            //     this.mathEquation = setting.result.value;
            //     this.calculateTotalValue(this.opportunityModel.profileAdditionDetail);
            //   }
            // });
            this.getIdLossReason();
            this.calculatePercent();
          }
        },
        complete: () => {
          this.isLoading = false;
          this.getOwner();
        },
      });
  }

  parseMathStrToValue(str) {
    return Function(`'use strict'; return (${str})`)()
  }
  calculatePercent() {
    var totalValue = this.opportunityModel?.profileAdditionDetail?.totalValue;
    var totalClientFee = this.opportunityModel?.profileAdditionDetail?.totalClientFee;

    if (totalValue && totalClientFee) {
      this.calculatePercentProfit = parseFloat(((totalValue / totalClientFee) * 100).toFixed(2));
      if (this.calculatePercentProfit < this.getPercentageProfitSetting) {
        this.colorTextDisplay = 'red';
      }
      else {
        this.colorTextDisplay = 'green';
      }

    }

  }

  calculateTotalValue(model: ProfileAdditionDetail) {
    this.mathEquation = this.mathEquation.replace('{ARR}', (model?.arr ?? 0).toString())
      .replace('{OneTimeProduct}', (model?.oneTimeProduct ?? 0).toString())
      .replace('{OneTimeService}', (model?.oneTimeService ?? 0).toString())
      .replace('{TermOfContract}', (model?.termOfContract ?? 0).toString());
    if (this.opportunityModel.profileAdditionDetail)
      this.opportunityModel.profileAdditionDetail.totalValue = this.parseMathStrToValue(this.mathEquation);
  }

  setupOpportunity(data: ProfileDetailModel) {
    if (data) {
      this.opportunityModel = Helper.copyRemoveHeap(data);
      //this.contacts = this.opportunityModel.profileContacts.map((contact) => contact.contact);

      this.currentStage = this.listStage.find(x =>
        parseInt(x.key) == this.opportunityModel?.automateDataStateId
      ) ?? this.listStage.find(x => x != null);
      if (this.opportunityModel.saleOpportunity)
        this.saleOpportunityEmit.emit(this.opportunityModel.saleOpportunity);

      this.isHasPrimaryContact = this.opportunityModel.contact && !this.opportunityModel.contact.deleted ? true : false;
      this.checkStateOpportunity();
      //vunh start 30/12/2022
      // this.listStage.forEach(item => {
      //   //get the first index in the list
      //   var index = this.listChange.findIndex(x => x.order == item.order)
      //   //get the first index of the placeholder value
      //   var placeholderIndex = this.listChange.findIndex(x => x.order == item.order && !x.key)

      //   if (this.currentStage?.order) {
      //     if (item.order > this.currentStage.order) {
      //       if (placeholderIndex > index) {
      //         //swap placeholder value and the first item in the same order
      //         [this.listChange[index]] = this.listChange.splice(placeholderIndex, 1, this.listChange[index])
      //       }
      //     }
      //     else if (item.order < this.currentStage.order) {
      //       if (placeholderIndex == index) {
      //         //swap placeholder value and the value behind it
      //         [this.listChange[index]] = this.listChange.splice(index + 1, 1, this.listChange[index])
      //       }
      //     }
      //   }
      // })
      // //re-render the progress bar
      // this.listStage = [...this.listChange]
      // //vunh end 3/1/2023
    }
  }

  checkStateOpportunity() {
    if (this.opportunityModel?.automateDataStateId && this.lostTypeId && this.wonTypeId)
      switch (this.opportunityModel?.automateDataStateId) {
        case this.lostTypeId:
        case this.wonTypeId:
          this.opportunityModel['disabledProbability'] = true;
          this.opportunityModel['tooltipProbability'] = `Can not modify the field because of the ${this.currentStage?.value} state`;
          console.log(this.opportunityModel['tooltipProbability']);
          break;
        default:
          break;
      }
  }

  editOpportunity(value: any, prop: string, typeModel: TypeEditModelOpportunity = TypeEditModelOpportunity.Opportunity) {
    let callCRMService: boolean = false;
    let oldSaleAccountId: string = "";
    let newSaleAccountId: string = "";

    if (!prop) {
      this.toast.danger('Failed', "Error");
      return;
    }

    if (prop == 'displayName') this.refreshTask.emit();
    if ((prop == 'probability' || prop == 'amount' || prop == 'oneTimeService'
      || prop == 'oneTimeProduct' || prop == 'aRR' || prop == 'termOfContract'
      || prop == 'totalValue')) {
      value = this.numPipe.transform(value);
    }

    if (prop == 'referenceId') {
      callCRMService = true;
      newSaleAccountId = value;
      oldSaleAccountId = this.opportunityModel.saleOpportunity.referenceId;
    }

    let saleOpportunityProfileModel: ProfileDetailModel = Object.assign({}, this.opportunityModel, { [prop]: value });
    let saleOpportunityModel: SaleOpportunity = Object.assign({}, this.opportunityModel.saleOpportunity, { [prop]: value }, { profileParentId: this.id });
    let profileAdditionDetailModel: ProfileAdditionDetailForOpportunity = Object.assign({}, this.opportunityModel.profileAdditionDetail, { [prop]: value ?? 0 });

    this.isLoadingEdit = true;

    if (prop == 'oneTimeService' || prop == 'oneTimeProduct' || prop == 'aRR' || prop == 'termOfContract' || prop == 'totalValue') {
      if (this.opportunityModel && this.opportunityModel.profileAdditionDetail)
        this.opportunityModel.profileAdditionDetail.totalValue = 0;
      this.isCalculate = true;
      profileAdditionDetailModel.profileId = this.opportunityModel.profileId;
      this.profileAdditionService.saveSingleProfileAdditionByPropName(prop, profileAdditionDetailModel)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: resp => {
            if (resp.result) {
              if (prop != 'totalValue')
                this.toast.success(`Edit ${this.environment.titleOpportunity} success!`, "Success");
              this.refreshData();
            }
          },
          error: err => this.isLoadingEdit = false,
          complete: () => {
            this.isLoadingEdit = false;
          }
        });

      this.isCalculate = false;
      return;
    }

    this.opportunityService.editOpportunity({
      id: typeModel == TypeEditModelOpportunity.OpportunityProfile
        ? this.opportunityModel.profileId
        : this.opportunityModel.saleOpportunity.saleOpportunityId.toString(),
      saleOpportunityProfileModel: typeModel == TypeEditModelOpportunity.OpportunityProfile
        ? saleOpportunityProfileModel : null,
      saleOpportunityModel: typeModel == TypeEditModelOpportunity.Opportunity
        ? saleOpportunityModel : null,
      opportunityId: this.id
    }).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => {
          if (resp.result) {
            if (prop != 'displayName')
              this.toast.success(`Edit ${this.environment.titleOpportunity} success!`, "Success");

            if (prop == "ownerId") this.ownerModel = null;

            // if (callCRMService) {
            //   this.moveFolderOneDrive(this.id, oldSaleAccountId, newSaleAccountId);
            // }

            this.refreshData();
          }
        },
        error: err => this.isLoadingEdit = false,
        complete: () => this.isLoadingEdit = false
      });
    this.handleAutomate = false;
  }

  moveFolderOneDrive(opportunityId: string, oldSaleAccountId: string, newSaleAccountId: string) {
    try {
      if (!Helper.isNullOrEmpty(newSaleAccountId)) {
        this.microsoftOffice365Service.moveFolderOneDriveProposal(opportunityId, oldSaleAccountId, newSaleAccountId, true).subscribe(result => {
          if (result && !result.result) {
            this.toast.danger("Can't move folder OneDrive");
          }
        })
      }
      else {
        this.microsoftOffice365Service.moveFolderOneDriveProposal(opportunityId, oldSaleAccountId, newSaleAccountId, false).subscribe(result => {
          if (result && !result.result) {
            this.toast.danger("Can't move folder OneDrive");
          }
        })
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  async closeDialog() {
    if (this.dialModalRef?.componentInstance)
      this.dialModalRef.close(this.isRefreshing);
    this.onClose.emit(this.isRefreshing);

    if (Helper.checkUrlDetailObj('opportunity')) {
      // var urlBack = Helper.popBackURL() || '/configuration/opportunity';
      // this.router.navigate([urlBack]);

      var urlBack = Helper.popBackURL() || `/configuration/opportunity`;
      this.userExperienceTrackingService.navigatePrevious(`/configuration/opportunity`);
    }
  }

  copyToClipboard() {
    const url = window.location.href.split('configuration')[0] + `configuration/opportunity/${this.id}`;
    this.clipboard.copy(url);
    this.toast.info(`Copied this ${this.environment.titleOpportunity} url to clipboard`, 'Success');
  }

  sendMailClick(profile: ProfileDetailModel = null) {
    const dialogRef = this.dialog.open(LinkscopeEmailComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        profileModel: profile ?? this.opportunityModel,
        entity: 'profile',
        optionTaskId: this.optionalTaskId,
        isShowSenderId: true,
        lastSentEmailEntity: this.environment.titleOpportunity
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response != null) {
        // return when closed
      }
    });
  }

  async onClickCall(alternativeContact: Contact = null) {
    this.isCallLoading = true;
    await this.callService.makeACall(
      alternativeContact ?? this.opportunityModel.contact,
      this.opportunityModel.profileContacts,
      this.user.nameid,
      this.opportunityModel.profileId,
      this.creatableTask,
      this.optionalTaskId ? parseInt(this.optionalTaskId) : null,
      this.isCallLoading,
      this.toast,
      this.isCalling,
      'profile'
    );
    setTimeout(() => {
      this.isCallLoading = false;
    }, 3000);
  }

  createTask() {
    const dialogRef = this.dialog.open(AddEditTaskComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: TblActionType.Add,
        profile: this.opportunityModel,
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (this.taskGrid) this.taskGrid.refreshData();
        this.refreshData();
      }
    });
  }
  createProposalNonArtist(className: string) {
    const dialogRef = this.dialog.open(AddEditProposalNonArtistIncomeComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: TblActionType.Add,
        model: this.proposalNonArtist,
        proposalId: this.opportunityModel.profileId,
        className: className
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (this.proposalNonArtistTab)
          this.proposalNonArtistTab.refreshData();
        this.editOpportunity('', 'totalValue', TypeEditModelOpportunity.Opportunity);
      }
    });
  }
  apiToSearchAccount(input: string) {
    return this.accountService.searchSaleAccount(input);
  }
  saveAccount(data) {
    if (typeof data == 'string' && data == 'NEW') {
      this.newAccount();
      return;
    }

    if (this.opportunityModel.saleOpportunity) {
      this.opportunityModel.saleOpportunity.referenceType = "SALEACCOUNT";
    }
    this.editOpportunity(data.profileId, 'referenceId', TypeEditModelOpportunity.Opportunity)
  }

  copyGUIDToClipboard() {
    this.clipboard.copy(this.id);
    this.toast.info(`Copied this ${this.environment.titleOpportunity} ID to clipboard`, 'Success');
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }

  updatePrimaryContact(contactLst: Contact[]) {
    if (this.opportunityModel) {
      let primaryContact = contactLst.find(x => x.primaryContact == true);
      this.contacts = contactLst ? contactLst.map((x: any) => x.contact) : this.contacts;
      if (primaryContact) {
        this.opportunityModel.contact = primaryContact;
        this.opportunityModel.contactId = primaryContact.contactId;
        this.opportunityModel.accountContactId = primaryContact.contactId;
        this.isHasPrimaryContact = this.opportunityModel.contact && !this.opportunityModel.contact.deleted ? true : false;
      } else {
        this.opportunityModel.contact = null;
        this.opportunityModel.contactId = null;
        this.opportunityModel.accountContactId = 0;
        this.isHasPrimaryContact = false;
      }
    }
  }
  editStageOpportunity(data) {
    if (!this.handleAutomate) {
      this.editOpportunity(data.key, 'automateDataStateId', TypeEditModelOpportunity.OpportunityProfile)
    }
  }
  handleAutomateDataState(data: boolean) {
    this.handleAutomate = data;
  }
  tabIndexChanged(tabIndex: number) {
    if (this.router.url.includes('configuration/opportunity')) {
      Helper.handleTabChangedUrl(tabIndex, '/configuration/opportunity');
      this.userExperienceTrackingService.saveTagIndexChangeInUrl(tabIndex);
    }
  }
  formatCurrencyPrefix() {
    this.prefixCurrency = `<div class="currency-color">${localStorage.getItem("currency") ?? ""}</div>`
  }

  getLastNote(resp?: number, isDelete: boolean = false) {
    if (this.lastNote && this.lastNote.id == resp && isDelete)
      this.lastNote = null;

    if ((this.lastNote && this.lastNote.id <= resp && this.lastNote.referenceId == this.id) || !this.lastNote)
      this.noteService.lastNoteByReferenceId(this.id, ShadowProfileEnum[ShadowProfileEnum.OPPORTUNITY])
        .subscribe({
          next: resp => {
            if (resp.result)
              this.lastNote = Object.assign(resp.result, {
                innerTextNote: Helper.removeStyleHtml(resp.result.note),
                ownerNote: (`${resp.result.user?.firstName || ''} ${resp.result.user?.lastName || ''}`).trim() || resp.result.user?.userName || 'Unknown',
                avatarNote: resp.result.user?.pictureURL?.replace(/\\/g, '/'),
                //referenceName: this.opportunityModel.displayName
              })
          },
          //complete: () => this.overlayNoteDetails.completedLoading()
        });
  }

  getOwner() {
    if (!this.opportunityModel && Helper.isNullOrEmpty(this.opportunityModel?.ownerId) || Helper.isEmptyOrSpaces(this.opportunityModel?.ownerId))
      this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel);

    if (!this.ownerModel && this.opportunityModel?.ownerId)
      this.userService.getUserById(this.opportunityModel?.ownerId).pipe(takeUntil(this.destroy$))
        .subscribe(
          resp => {
            if (resp.result) {
              this.ownerModel = resp.result;
              let fullName = `${this.ownerModel.firstName} ${this.ownerModel.lastName}`;
              this.ownerModel.fullName = fullName.trim() ? fullName : "Unknown";
              this.ownerModel.pictureURL = Helper.userURL(this.ownerModel.pictureURL);
            };
          },
          err => this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel));
  }

  getIdLossReason() {
    // 2022-02-02 ducqm start add
    // this.settingService.getSettingByGroupName("SALE_CONFIG").subscribe(e => {
    //   if (e.result) {
    //     var lostType = e.result.find(x => x.key == "LOST_TYPE_ID")?.value || '';
    //     this.lostTypeId = lostType ? +lostType : 0;

    //     var wonType = e.result.find(x => x.key == "WON_TYPE_ID")?.value || '';
    //     this.wonTypeId = wonType ? +wonType : 0;
    //     this.checkStateOpportunity();
    //   }
    // })
    this.settingPoolService.getSettingByGroupName("SALE_CONFIG").subscribe(e => {
      if (e.result) {
        var lostType = e.result.find(x => x.key == "LOST_TYPE_ID")?.value || '';
        this.lostTypeId = lostType ? +lostType : 0;

        var wonType = e.result.find(x => x.key == "WON_TYPE_ID")?.value || '';
        this.wonTypeId = wonType ? +wonType : 0;

        this.getPercentageProfitSetting = e.result.find(x => x.key == "PERCENTAGE_PROFIT")?.value || 0;
        this.checkStateOpportunity();
      }
    })
    // 2022-02-02 ducqm end add
  }
  async addSaleOpportunityFile(fileInputEvent: any) {
    this.uploading = true;
    this.uploadedFile = fileInputEvent.target.files[0];
    let acceptanceExtension = ["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"];
    let extension = Helper.getFileExtension(fileInputEvent.target.files[0].name) ? Helper.getFileExtension(fileInputEvent.target.files[0].name)[0] : null;
    // let IsAccept = extension ? (acceptanceExtension.indexOf(extension) == -1 ? false : true) : false;
    let IsAccept = true; // Accept all extensions
    if (IsAccept) {
      let submitModel = new UserUpload();
      submitModel.fileName = this.uploadedFile.name;
      submitModel.referenceType = this.opportunityModel.typeName;
      submitModel.referenceId = this.opportunityModel.profileId;
      submitModel.owners = this.user.nameid;

      var toastRef = this.hotToast.loading('Uploading file...', { autoClose: false });
      try {
        const uploadFile = await this.userUploadService.uploadFile(this.uploadedFile, submitModel).toPromise();
        if (uploadFile?.result) {
          this.fileTab?.refreshData(true);
          toastRef.close();
        }
        else {
          toastRef.close();
        }
      }
      catch (ex) {
        console.log(ex);
        toastRef.close();
        this.hotToast.error('An error occurred while uploading...', { duration: 2000 });
      }

    } else {
      this.toast.warning('Please select file with these extensions: doc,docx,xls,xlsx,ppt,pdf,html,zip,csv,txt', "Warning");
    }
    this.nbFilesPopover.hide();
  }
  getSelectedContactMail(contact: Contact) {
    if (contact) {
      let newProfileModel = Object.assign(this.opportunityModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.sendMailClick(newProfileModel);
    } else {
      // skip mode (select primary contact)
      this.sendMailClick(null);
    }
  }
  getSelectedContactCall(contact: Contact) {
    if (contact) {
      let newProfileModel = Object.assign(this.opportunityModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.onClickCall(newProfileModel.contact);
    } else {
      // skip mode (select primary contact)
      this.onClickCall(null);
    }
  }
  get checkNumberOfContact() {
    return this.opportunityModel?.profileContacts?.length ?? 0;
  }
  confirmSendMailClick() {
    let convertContact;
    if ((this.opportunityModel?.contact as any)?.contact != null) {
      convertContact = (this.opportunityModel?.contact as any).contact;
    } else {
      convertContact = this.opportunityModel.contact;
    }
    this.opportunityModel.contact = convertContact;
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to send email to ${Helper.getEmailFromContact(convertContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) {
        this.sendMailClick();
      }
    });
  }
  confirmMakeACall() {
    let convertContact;
    if ((this.opportunityModel?.contact as any)?.contact != null) {
      convertContact = (this.opportunityModel?.contact as any).contact;
    } else {
      convertContact = this.opportunityModel.contact;
    }
    this.opportunityModel.contact = convertContact;
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to make a call to number ${Helper.getPhoneFromContact(convertContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) {
        this.onClickCall();
      }
    });
  }
  createContactCampaign() {
    if (!this.contactTab) {
      this.toast.warning('Please select to contact tab to start creating campaign');
      return;
    }
    const dialogRef1 = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to create campaign with ${this.numberPipe.transform((this.contactTab?.primasTable.selected && this.contactTab?.primasTable.selected.length != 0) ? this.contactTab?.primasTable.selected.length : this.contactTab?.primasTable.table.rowCount)} item(s)?`
      }
    });
    dialogRef1.afterClosed().subscribe(response => {
      if (response) {
        const campaignData: ContactPage = Object.assign({}, this.contactTab.primasTable.page, { relationshipId: this.opportunityModel.profileId, type: "Profile" });
        const ComponentOpen: any = ReviewContactCampaignComponent


        const dialogRef = this.dialog.open(ComponentOpen, {
          width: '1300px',
          disableClose: true,
          autoFocus: false,
          data: {
            contactPage: campaignData,
            isSelectAll: true,
            externalComponent: true,
          }
        });
        dialogRef.afterClosed().subscribe(e => {
          this.refreshData();
          this.contactTab.primasTable.selected = [];
        });
      }
    });
  }
  getCacheTab() {
    let tag: string = this.userExperienceTrackingService.getTagIndexInUrl() || "0";
    return tag;
  }

  async onClickAdvanceImport(action: string) {
    if (action == "Skip") {
      const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `Do you wish to skip 1 item(s)?`
        }, disableClose: true,
      })
      const result = await dialogConfirm.afterClosed().toPromise();
      if (result) {
        this.onAdvancedImport.emit(action);
        this.dialModalRef.close();
      }
    }
    else
      this.onAdvancedImport.emit(action);
  }

  openSMS(contact: Contact) {
    if (contact) {
      const dialogSMS = this.dialog.open(PrimasSendSMSComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'custom-dialog-SMS',
        data: {
          id: this.id,
          referenceType: 'OPPORTUNITY',
          outboundPhone: contact.contactPhone,
        },
      })
    }
  }

  getSumIncomeAndExpense() {
    if (this.id) {
      this.profileAdditionService.sumIncomeAndExpense(this.id).subscribe({
        next: resp => {
          if (resp.result) {
            if (!this.opportunityModel?.profileAdditionDetail)
              this.opportunityModel.profileAdditionDetail = new ProfileAdditionDetail();

            if (this.opportunityModel?.profileAdditionDetail) {
              this.opportunityModel.profileAdditionDetail.nonArtistIncome = (+resp.result["INCOME"]) || 0;
              this.opportunityModel.profileAdditionDetail.nonArtistExpense = (+resp.result["EXPENSES"]) || 0;
            }
          }
        },
      })
    }
  }

  newAccount() {
    const dialogRef = this.dialog.open(AddSaleAccountComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: TblActionType.Add,
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response != null) this.saveAccount({ profileId: response });
    });
  }

  addArtistToProposal() {
    const dialogRef = this.dialog.open(AddEditArtistComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: TblActionType.Add,
        model: { proposalId: this.id }
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response != null) {
        if (this.artistTab?.primasTable)
          typeof response == "boolean"
            ? this.artistTab.primasTable.refreshTable(response)
            : this.artistTab.primasTable.refreshTable();

        this.editOpportunity('', 'totalValue', TypeEditModelOpportunity.Opportunity);
      }
    });
  }

  async proposalArtistTemplate() {
    try {
      this.isCheckingProposalPrevision = true;
      var revisionCur: ProposalRevision;
      var isWhiteLabel: boolean = false;
      var resProposal = await this.opportunityService.getProposalRevisionById(this.id).toPromise();
      this.isCheckingProposalPrevision = false;
      if (resProposal.result) revisionCur = resProposal.result;
      var content: {} = revisionCur?.content ? JSON.parse(revisionCur.content) : null;

      if(!content) {
        const confirmDialog = this.dialog.open(ConfirmModalComponent, {
          data: {
            message: 'Select Proposal Template',
            yesTitle: 'White-Label',
            falseTitle: 'Default'
          }
        });
        const confirmResult = await confirmDialog.afterClosed().toPromise();
        if(confirmResult === undefined) return;
        if(confirmResult) {
          isWhiteLabel = true;
        }
      }

      const dialog = this.dialog.open(ProposalArtistTemplateComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'custom-proposal',
        data: { id: this.id,isWhiteLabel: isWhiteLabel }
      });

      const closeResult = await dialog.afterClosed().toPromise();
      if (this.proposalRevision)
        this.proposalRevision.refreshData(closeResult);
    } catch(error) {
      console.error(error);
    }
  }

  getBackOfficeURL() {
    this.settingService.getSettingByKeyAndGroup("BACK-OFFICE", "DOMAIN").subscribe(resp => {
      if (resp.result && resp.result.value) this.backOfficeUrl = resp.result.value;
    });
  }
  async copyPublicURLProposal() {
    const confirm = this.dialog.open(ConfirmModalComponent, {
      data:
        { message: 'Please note: This is a published proposal link. When you open this URL, we will track your activity in the activity log. If you only wish to view this proposal, please use the preview feature provided by the preview button next to it.' }
    });
    const resultConfirm = await confirm.afterClosed().toPromise();
    if (resultConfirm) {
      this.clipboard.copy(`${this.backOfficeUrl}proposal-views/${this.id}`);
      this.toast.info(`Copied url success`, 'Success')
    }
  }
  onRefresh(bool) {
    this.refreshData();
  }
  nbPopoverCLick() {
    this.nbFilesPopover.show();
  }
  openURLDialogRef() {
    const dialogRef = this.dialog.open(UploadUrlComponent, {
      width: '30vw',
      maxWidth: '30vw',
      data: {
        profileId: this.opportunityModel.profileId,
        nameId: this.user.nameid,
        referenceType: "OPPORTUNITY"
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.fileTab?.refreshData(true);
      }
    });
  }
  openWishList() {
    const url = `${environment.host}/InteractiveProposal/WishList/${this.id}`;
    window.open(url, "blank")
  }

  openRelationshipAccount: () => void = () => {
    var saleAccountId: string = this.opportunityModel?.saleOpportunity?.opportunityProfile?.profileId;
    if (saleAccountId) {
      let relationship = 'sale-account'
      relationship = Helper.getRelationshipURL(this.useNewUI, relationship)
      Helper.pushBackURL();
      Helper.circleDetail(saleAccountId, relationship, null, this.router, this.userExperienceTrackingService);
    }
  }
}

export enum TabOpportunity {
  Contact,
  Files,
  Note,
  Tasks,
  ActivityLog
}

export enum TypeEditModelOpportunity {
  Opportunity,
  OpportunityProfile
}

export enum ProfileType {
  SALEPROFILE,
  SALEACCOUNT,
  LEADS,
  OPPORTUNITY,
  LEADS_VENUE
}
