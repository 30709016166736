<ng-template #addBtn>
  <div class="premium-container glowing">
    <button mat-raised-button (click)="clickToBtn()" class="btn-upgrade">
      <mat-icon style="color: #ffcc00" class="mr-1">star</mat-icon>Upgrade
    </button>
  </div>
</ng-template>

<ng-template #seoAuditBtn>
  <ng-container *ngIf="!isMobileAndTablet; else seoAuditMobileBtn">
    <div class="premium-container glowing icon">
      <button mat-icon-button [ngClass]="'0' | seoColorScore: '100'" class="btn-upgrade"
        [matTooltip]="'Click to open SEO audit'" (click)="clickToBtn()">
        <mat-icon svgIcon="seo-audit"></mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-template #seoAuditMobileBtn>
    <button mat-menu-item class="btn-upgrade"
      [matTooltip]="'Click to open SEO audit'" (click)="clickToBtn()">
      <mat-icon class="text-dark" svgIcon="seo-audit"></mat-icon>
      <span class="glowing-text">SEO audit</span>
    </button>
  </ng-template>
</ng-template>

<ng-template #readAbilityBtn>
  <ng-container *ngIf="!isMobileAndTablet; else readAbilityMobileBtn">
    <div class="premium-container glowing icon">
      <button mat-icon-button [ngClass]="'0' | readabilityScore: '100'" class="btn-upgrade" [matTooltip]="'Readability'"
        (click)="clickToBtn()">
        <mat-icon>menu_book</mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-template #readAbilityMobileBtn>
    <button mat-menu-item class="btn-upgrade" [matTooltip]="'Readability'" (click)="clickToBtn()">
        <mat-icon class="text-dark">menu_book</mat-icon>
        <span class="glowing-text">Readability Score</span>
      </button>
  </ng-template>
</ng-template>

<ng-template #themeBtn>
  <ng-container *ngIf="!isMobileAndTablet; else themeMobileBtn">
    <div class="premium-container glowing">
      <button mat-raised-button (click)="clickToBtn()" matTooltip="Premium Themes" class="btn-upgrade">
        <mat-icon style="color: #ffcc00;" class="mr-1">star</mat-icon>Premium Themes
      </button>
    </div>
  </ng-container>
  <ng-template #themeMobileBtn>
      <button mat-menu-item (click)="clickToBtn()" matTooltip="Premium Themes" class="btn-upgrade">
        <mat-icon style="color: #ffcc00;">star</mat-icon>
        <span class="glowing-text">Premium Themes</span>
      </button>
  </ng-template>
</ng-template>

<ng-template #requestToPublishBtn>
  <ng-container *ngIf="!isMobileAndTablet; else requestToPublishMobileBtn">
    <div class="premium-container glowing">
      <button mat-raised-button (click)="clickToBtn()" class="btn-upgrade">
        Request To Publish
      </button>
    </div>
  </ng-container>
  <ng-template #requestToPublishMobileBtn>
    <button mat-menu-item (click)="clickToBtn()" class="btn-upgrade">
      <mat-icon class="text-dark"> publish</mat-icon>
      <span class="glowing-text">Request To Publish</span>
    </button>
  </ng-template>
</ng-template>


<ng-template #cardUpgradePlan>
  <ng-container
    *ngIf="planValue?.[1] === true || planValue?.[1] === false; then cardUpgradePlanWithAbility; else cardUpgradePlanWithAmount"></ng-container>
</ng-template>

<ng-template #cardUpgradePlanWithAmount>
  <nb-card class="mb-0">
    <nb-card-header>Information for plan feature</nb-card-header>
    <nb-card-body>
      <div class="mb-3">
        Based on our records, you have created <span style="color: #307ABD"><b>{{planValue?.[1]}} {{perksName?.[perk]}}(s)</b></span> that is equal to or over the limit of only <span style="color: red"><b>{{valueVerify?.[1]}} {{perksName?.[perk]}}(s)</b></span>.
        If you need additional {{perksName?.[perk]}} creation capabilities, please consider upgrading to a higher plan that supports more {{perksName?.[perk]}}.
      </div>
      <div class="mb-3">By clicking the "Continue" button, you will close this pop-up.</div>
      <div class="mb-3">Are you sure you want to continue?</div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #cardUpgradePlanWithAbility>
  <nb-card class="mb-0">
    <nb-card-header>Information for plan feature</nb-card-header>
    <nb-card-body>
      <div class="mb-3">
        Unfortunately, based on your current plan, you are not allowed to use the feature <span
          style="color: red"><b>{{perksName?.[perk]}}</b></span>.
          If you would like to take advantage of this feature, please consider upgrading to a higher plan that includes the {{perksName?.[perk]}} functionality.
          We apologize for any inconvenience this may cause
      </div>
      <div class="mb-3">By clicking the "Continue" button, you will close this pop-up.</div>
      <div class="mb-3">Are you sure you want to continue?</div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #loadingAPI>
  <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
</ng-template>
