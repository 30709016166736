import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddExternalActivityLogComponent } from '../components/add-external-activity-log/add-external-activity-log.component';
import { TblActionType } from '../enums/tbl-action-type.enum';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { ActivityLogService } from '../services/activity-log.service';
import { NbToastrService } from '@nebular/theme';

@Directive({
  selector: '[appAddActivityLogBtn]'
})
export class AddActivityLogBtnDirective {

  @Input() id: string;
  @Input() type: string;

  @Output() onRefresh: EventEmitter<void> = new EventEmitter();
  constructor(
    private dialog: MatDialog,
    private activityLogService: ActivityLogService,
    private toast: NbToastrService
  ) { }

  @HostListener('click', ['$event'])
  async clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.id && this.type) {
      const dialogRef = this.dialog.open(AddExternalActivityLogComponent, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        position: { right: '0' },
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          actionDialog: TblActionType.Add,
          objId: this.id,
          objName: this.type,
        }
      })

      const result = await dialogRef.afterClosed().toPromise();
      if (result?.result) {
        if (result.type === 'ExternalOther') {
          await this.openSecondDialog(result);
        }
      }
      this.onRefresh.emit();
    }
  }

  private async openSecondDialog(data: any) {
    const confirmDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        message: "Do you wish to update the last contact?"
      }
    })

    const confirmResult = await confirmDialog.afterClosed().toPromise();
    if (confirmResult) {
      var updateLastContactResult = await this.activityLogService.updateLastContact(data.activityLogId).toPromise();
      if (!updateLastContactResult.result) this.toast.danger("Can't update the last contact");
    }
  }
}
