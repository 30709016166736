import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { ReturnResult } from "src/app/shared/models/return-result";
import { environment } from "src/environments/environment";
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { Category, Media } from "../profile-management/profile-detail.model";
import { MediaModel } from "./media.model";
import { MediaType } from "../profile-management/profile-detail/multi-media/multi-media.model";
import { MediaResize } from "../media-resize-management/media-resize.model";
import { timeout } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class MediaManagementService {

  baseUrl = environment.mediaManagement;
  constructor(private http: HttpClient) { }

  getMediaPaging(page: Page): Observable<ReturnResult<PagedData<MediaModel>>> {
    return this.http.post<ReturnResult<PagedData<MediaModel>>>(`${this.baseUrl}/GetMediaPaging`, page);
  }
  addEditMedia(media: Media): Observable<ReturnResult<boolean>> {
    if (media.media1 == null) {
      media.media1 = ' ';
    }
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AddEditMedia`, media);
  }
  deleteMedia(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteMedia/${id}`);
  }
  deleteMediaRange(id: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteMediaRange`, id);
  }
  getMediaType(): Observable<ReturnResult<MediaType[]>> {
    return this.http.get<ReturnResult<MediaType[]>>(`${this.baseUrl}/GetMediaType`);
  }

  uploadImageAsset(files: File[]): Observable<ReturnResult<string[]>> {
    const data = new FormData();
    if (files && files.length > 0)
      files.forEach(item => {
        data.append('photos', item);
      });

    return this.http.post<ReturnResult<string[]>>(`${this.baseUrl}/UploadImageAsset`, data);
  }
  getMediaResizePaging(page: Page): Observable<ReturnResult<PagedData<MediaResize>>> {
    return this.http.post<ReturnResult<PagedData<MediaResize>>>(`${this.baseUrl}/GetMediaResizePaging`, page);
  }
  SeoMediaPaging(page: Page): Observable<ReturnResult<PagedData<Media>>> {
    return this.http.post<ReturnResult<PagedData<Media>>>(`${this.baseUrl}/SeoMediaPaging`, page);
  }
  EditMediaTitleByMediaId(media: Media): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/EditMediaTitleByMediaId`, media);
  }
  categoryMediaPaging(page: Page): Observable<ReturnResult<PagedData<Category>>> {
    return this.http.post<ReturnResult<PagedData<Category>>>(`${this.baseUrl}/CategoryMediaPaging`, page);
  }
  getMediaByProfileId(profileId, mediaType) {
    return this.http.post<ReturnResult<Media[]>>(`${this.baseUrl}/GetMediasByTypeAndId`, {profileId, mediaType});
  }
  exportMediaSeoInfo(page: Page): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ExportMediaSeoInfo`, page).pipe(
      timeout(1200000)
    );
  }
  exportCategoryMediaSeoInfo(page: Page): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ExportCategoryMediaSeoInfo`, page).pipe(
      timeout(1200000)
    );
  }
  importMedia(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ImportMedia`, formData);
  }
}
