import { MediaResize } from "../../../media-resize-management/media-resize.model";
import { Helper } from 'src/app/shared/utility/Helper';

export class Media {
    mediaId: number;
    profileId: string;
    media1: string;
    mediaTypeId: number;
    deleted: boolean;
    dateDeleted: Date | string | null;
    public replacePath() {
        this.media1 = this.media1.replace('\\\\', ' / ');
    }
    published: boolean;
    mediaResizes: MediaResize[] | null;
    thumbnailMedia: string; // Using mapping to media1
    bkThumbnailMedia: string;
    originalMedia: string; // Backup for tempMedia1
    isLoading: boolean = false;
    isUpdatingAltText: boolean = false;
    title: string;
    extendData: string;
}

export class MediaType {
    mediaTypeId: number;
    mediaType1: string;
    deleted: boolean;
    dateDeleted: Date | string | null;
    medias: Media[];
}

export enum MediaResizeConfigEnums {
    Size90x112 = "90x112",
    Size160x160 = "160x160",
    Size165x125 = "165x125",
    Size300x300 = "300x300",
    Size320x191 = "320x191",
    Size360x300 = "360x300",
    Size418x200 = "418x200",
    Size665x443 = "665x443",
    SizeOriginal = "org"
}
