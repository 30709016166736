import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbDatepickerModule, NbDialogModule, NbMenuModule, NbSidebarModule, NbTimepickerModule, NbWindowModule, NbToastrModule, NbGlobalPhysicalPosition } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThemeModule } from './@theme/theme.module';
import { CoreModule } from './@core/core.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NbAuthJWTInterceptor, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { environment } from 'src/environments/environment';
import { ErrorInterceptor, MultiTenantInterceptor } from './error.interceptor';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { QuillModule } from 'ngx-quill';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { ColorPickerModule } from 'ngx-color-picker';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalModule } from '@azure/msal-angular';
import { HotToastModule } from '@ngneat/hot-toast';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu'
import { MentionModule } from 'angular-mentions';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const isIframe = window !== window.parent && !window.opener;


export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: false,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    MentionModule, 
    AppRoutingModule,
    BrowserAnimationsModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot({
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true
    }),
    MDBBootstrapModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    CoreModule.forRoot(),
    NbEvaIconsModule,
    QuillModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
    NgxCurrencyModule.forRoot({
      align: "left",
      allowNegative: false,
      allowZero: true,
      decimal: ".",
      precision: 2,
      prefix: "",
      suffix: "",
      thousands: ",",
      nullable: false,
      min: null,
      max: null,
      inputMode: CurrencyMaskInputMode.NATURAL
    }),

    ColorPickerModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.clientId, // Application (client) ID from the app registration
        authority: environment.authority, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        redirectUri: environment.redirectUri// This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), null, null),
    HotToastModule.forRoot({
      position: 'bottom-right',
      reverseOrder: true,
      dismissible: true,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MultiTenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: function (req: HttpRequest<any>) {
        if (req.url === `${environment.apiAuth}/login`) {
          return true;
        }
        return false;
      }
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

