import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { KeyPairsValue } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { AddEditProfileModel } from 'src/app/modules/admin/profile-management/profile-model';
import { SaleLeadsViewModel } from 'src/app/modules/admin/sale-lead-management/add-sale-leads.model';
import { EntityNameEnums } from 'src/app/shared/enums/entity-color.enums';
import { ContactService } from 'src/app/shared/services/contact.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { environment } from 'src/environments/environment';
import { ConfirmModalComponent } from '../../../confirm-modal/confirm-modal.component';
// import { AdminTabModeService } from '../../admin-tab-mode/admin-tab-mode.service';
// import { TabTypeModeEnums } from '../../admin-tab-mode/tab-mode.model';
import { Contact, SimpleCovertContact, SimpleCovertStepModel } from '../contact.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserExperienceTrackingService } from '../../../user-experience-tracking/user-experience-tracking.service';

@Component({
  selector: 'app-simple-convert-contact',
  templateUrl: './simple-convert-contact.component.html',
  styleUrls: ['./simple-convert-contact.component.scss']
})
export class SimpleConvertContactComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() contactId: number;

  environment = environment;
  contact: Contact;
  model: SimpleCovertStepModel;
  entity: string;
  isProfile = false;
  isLead = false;
  entityEnums = EntityNameEnums;
  inputForm: FormGroup;
  addProfileForm: FormGroup;
  addLeadForm: FormGroup;
  // exclude controls: 
  excludeProfileControls = ['categories', 'profileId', 'tags'];
  excludeLeadControls = ['categories', 'profileId'];
  // order controls:
  sortingProfileControls = ['displayName', 'realName', 'webPage'].reverse();
  sortingLeadControls = ['saluation', 'firstName', 'lastName'].reverse();
  // disable controls:
  disabledProfileFields = [];
  disabledLeadFields = [];
  // autoComplete by array:
  autoCompleteLead: AutoCompleteControl[] = [
    {
      controlName: 'saluation',
      options: [
        {
          key: 'Mr.',
          value: 'Mr.'
        },
        {
          key: 'Mrs.',
          value: 'Mrs.'
        }, {
          key: 'Ms.',
          value: 'Ms.'
        }, {
          key: 'Miss.',
          value: 'Miss.'
        }, {
          key: 'Dr.',
          value: 'Dr.'
        },
      ]
    }
  ];
  autoCompleteProfile: AutoCompleteControl[] = [];
  // form control references from form: This use to display
  sortedProfileFormControls: string[] = [];
  sortedLeadFormControls: string[] = [];
  stepLoading = false;
  isSubmitSuccess = false;
  helper = Helper;
  newPid: string = '';
  private _destroy: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SimpleCovertContact,
    private dialogRef: MatDialogRef<SimpleConvertContactComponent>,
    private dialog: MatDialog,
    private frmBuilder: RxFormBuilder,
    private contactService: ContactService,
    private toast: NbToastrService,
    // private tabMode: AdminTabModeService,
    private router: Router,
    private userExperienceTrackingService: UserExperienceTrackingService,
  ) {
    if (data) {
      this.contactId = data.contactId;
      this.contact = data.contact ?? this.contact;
      this.model = new SimpleCovertStepModel();
      this.model.contactId = data.contactId;
      let convertProfile = AddEditProfileModel.convertContactToProfile(this.contact);
      this.addProfileForm = this.frmBuilder.formGroup(AddEditProfileModel, convertProfile);
      // sort profile props:
      this.sortedProfileFormControls = Object.keys(this.addProfileForm.controls)
        .sort((a, b) => this.sortingProfileControls.indexOf(a) - this.sortingProfileControls.indexOf(b)).reverse();

      this.addLeadForm = this.frmBuilder.formGroup(SaleLeadsViewModel, SaleLeadsViewModel.contactToLeadModel(this.contact));
      this.sortedLeadFormControls = Object.keys(this.addLeadForm.controls)
        .sort((a, b) => this.sortingLeadControls.indexOf(b) - this.sortingLeadControls.indexOf(a));
    }
  }

  ngOnInit() {
    this.inputForm = this.frmBuilder.formGroup(SimpleCovertStepModel, this.model);
  }

  ngAfterViewInit(): void {
    if (this.addLeadForm) {
      this.addLeadForm.controls['contactEmail'].valueChanges
        .pipe(takeUntil(this._destroy), debounceTime(1000))
        .subscribe(() => this.addLeadForm.controls['contactEmail'].setValue(
          Helper.removeNonAscii(this.addLeadForm.controls['contactEmail'].value)
        ));
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  closeDialog(closeValue: boolean = false) {
    this.dialogRef.close(closeValue);
  }
  radioCheck(value: MatCheckboxChange, entityBox: string) {
    switch (entityBox) {
      case this.entityEnums.Profile:
        if (value.checked) {
          this.entity = this.entityEnums.Profile;
          this.isLead = false;
          this.isProfile = true;
        } else {
          this.entity = this.entityEnums.SaleLead;
          this.isLead = true;
          this.isProfile = false;
        }
        break;
      case this.entityEnums.SaleLead:
        if (value.checked) {
          this.isProfile = false;
          this.isLead = true;
          this.entity = this.entityEnums.SaleLead;
        } else {
          this.entity = this.entityEnums.Profile;
          this.isProfile = true;
          this.isLead = false;
        }
        break;
      default:
        break;
    }
    if (this.inputForm) {
      this.inputForm.controls.entity.setValue(this.entity);
      this.inputForm.markAllAsTouched();
      this.inputForm.controls.entity.updateValueAndValidity();
    }
  }

  navigateStep(stepper: MatStepper) {
    if (stepper) {
      switch (stepper.selectedIndex) {
        case 0:
          {
            if (this.entity == this.entityEnums.Profile) {
              this.addProfileForm.markAllAsTouched();
            } else if (this.entity == this.entityEnums.SaleLead) {
              this.addLeadForm.markAllAsTouched();
            }
            return this.entity != null;
          }
          break;
        case 1:
          {
            if (this.entity == this.entityEnums.Profile) return this.addProfileForm.valid;
            else if (this.entity == this.entityEnums.SaleLead) return this.addLeadForm.valid;
          }
        default:
          break;
      }
    }
  }
  isRequired(formControl: FormControl | AbstractControl): boolean {
    return formControl.validator && formControl.validator['required'];
  }
  // exclude fields given
  isExcluded(controlName: string, entity: string): boolean {
    if (entity == EntityNameEnums.Profile) {
      return this.excludeProfileControls.includes(controlName);
    } else if (entity == EntityNameEnums.SaleLead) {
      return this.excludeLeadControls.includes(controlName);
    }
  }
  orderColumn(entity: string, name: string) {
    if (entity == EntityNameEnums.Profile) {
      if (this.sortingProfileControls.includes(name)) {
        return this.sortingProfileControls.indexOf(name);
      } else return;
    } else if (entity == EntityNameEnums.SaleLead) {
      if (this.sortingLeadControls.includes(name)) {
        return this.sortingLeadControls.indexOf(name);
      } else return;
    }
    return;
  }
  errorMessage(controlName: string, form: FormGroup) {
    return form.controls[controlName]['errorMessage'];
  }
  convertContact(stepper: MatStepper) {
    let nameEntity = this.entity == EntityNameEnums.Profile ? 'Profile' : environment.titleLead;
    this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Confirm using this contact to convert to ${nameEntity}?`
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.stepLoading = true;
        if (this.entity === this.entityEnums.Profile) {
          this.inputForm.controls.profile.setValue(this.addProfileForm.value);
        } else if (this.entity === this.entityEnums.SaleLead) {
          this.inputForm.controls.lead.setValue(this.addLeadForm.value);
        }
        this.contactService.convertContactToEntity(this.inputForm.value).subscribe(result => {
          if (result.result) {
            this.isSubmitSuccess = true;
            this.newPid = result.result;
            stepper.next();
          }
          this.stepLoading = false;
        });
      }
    })
  }
  get entityName() {
    return this.entity == EntityNameEnums.Profile ? 'Profile' : environment.titleLead;
  }
  openNewEntity() {
    let entityUrl = this.entity == EntityNameEnums.Profile ? 'profile' : 'sale-lead';
    let tabUrl = `/configuration/${entityUrl}/${this.newPid}`;
    // this.tabMode.CreateTabOtherwiseSetActive(tabUrl, null, TabTypeModeEnums.Link, true);
    // this.router.navigate([tabUrl]);

    this.userExperienceTrackingService.navigateNext(tabUrl, null, null, true);
  }
  isDisabled(controlName: string, entity: string): boolean {
    if (entity == EntityNameEnums.Profile) {
      return this.disabledProfileFields.includes(controlName);
    } else if (entity == EntityNameEnums.SaleLead) {
      return this.disabledLeadFields.includes(controlName);
    }
  }
  isAutoComplete(controlName: string, entity: string) {
    if (entity == EntityNameEnums.Profile) {
      return this.autoCompleteProfile.find(x => x.controlName == controlName) ? true : false;
    } else if (entity == EntityNameEnums.SaleLead) {
      return this.autoCompleteLead.find(x => x.controlName == controlName) ? true : false;
    }
  }
  getAutoCompleteOptions(controlName: string, entity: string): KeyPairsValue[] {
    let auto: KeyPairsValue[] = [];
    if (entity == EntityNameEnums.Profile) {
      auto = this.autoCompleteProfile.find(x => x.controlName == controlName) ? this.autoCompleteProfile.find(x => x.controlName == controlName).options : [];
    } else if (entity == EntityNameEnums.SaleLead) {
      auto = this.autoCompleteLead.find(x => x.controlName == controlName) ? this.autoCompleteProfile.find(x => x.controlName == controlName).options : [];
    }
    return auto;
  }
}

class AutoCompleteControl {
  controlName: string;
  options: KeyPairsValue[];
}