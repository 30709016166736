export class ProfileCompareModel {
    mainDifferences: {key: string, value: string}[];
    diffDifferences: {key: string, value: string}[];
}
export interface ProfileCompareDetailModel {
    fieldName: string;
    oldValue: string;
    newValue: string;
    isDisplayDownLine: boolean;
    childData?: Array<{
        clientName: string;
        oldValue: string;
        newValue: string;
        isDisplayDownLine: boolean;
    }>;
}
export interface DifferenceItem {
    key: string;
    value: string;
}