import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-range-picker-projection',
  templateUrl: './date-range-picker-projection.component.html',
  exportAs: 'dateRangePickerProjection',
  styleUrls: ['./date-range-picker-projection.component.scss']
})
export class DateRangePickerProjectionComponent implements OnInit {

  @ViewChild(MatDateRangePicker) public dateRangePicker: MatDateRangePicker<any>;

  @Input() projection: boolean = false;
  @Input() startDate: string | Date;
  @Input() endDate: string | Date;

  // always true:
  @Input() usingWeekPicker: boolean = true;
  @Output() dateChanged: EventEmitter<{
    start: string,
    end: string
  }> = new EventEmitter();
  constructor(private injector: Injector) {
    if (!this.startDate && !this.endDate) {
      this.initDate();
    } else if (this.startDate && this.endDate) {
      this.range.controls['start'].setValue(this.startDate);
      this.range.controls['end'].setValue(this.endDate);
    }
  }
  ngAfterViewInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    const startDateChanged = changes.startDate;
    if (startDateChanged?.currentValue !== startDateChanged?.previousValue) {
      this.startDate = startDateChanged.currentValue;
      this.range.controls['start'].setValue(this.startDate);
    }

    const endDateChanged = changes.endDate;
    if (endDateChanged?.currentValue !== endDateChanged?.previousValue) {
      this.endDate = endDateChanged.currentValue;
      this.range.controls['end'].setValue(this.endDate);
    }
  }
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  ngOnInit() {
  }

  emitResult(data: MatDatepickerInputEvent<any, any>, type: string) {
    this.range.controls[type].setValue(data.value);
    if (this.range.controls.start.value && this.range.controls.end.value && (this.range.controls.end.value > this.range.controls.start.value)) {
      if (this.projection && type == 'end') this.dateChanged.emit(this.range.value);
      else if (!this.projection) {
        this.dateChanged.emit(this.range.value);
      }
    }
  }
  initDate() {
    this.range.controls.start.setValue(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
    this.range.controls.end.setValue(new Date(Date.now()).toISOString().split('T')[0]);
    this.dateChanged.emit(this.range.value);
  }

}
