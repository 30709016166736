<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;"
    [nbSpinner]="isLoading">
    <button mat-button color="primary" (click)="saveData()" [disabled]="!frmExternalLog.valid || isLoading">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
    </button>
    <button mat-button color="primary" mat-dialog-close>
        <mat-icon>clear</mat-icon>Cancel
    </button>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="ml-auto">
        <button mat-icon-button mat-dialog-close aria-label="Close the dialog" class="ml-auto">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-divider></mat-divider>

<mat-dialog-content *ngIf="frmExternalLog" class="mat-typography">
    <form [formGroup]="frmExternalLog" class="container" (ngSubmit)="saveData()"
        style="padding-top: 25px; padding-right: 50px; padding-left: 50px">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Activity Log</mat-label>
                    <mat-select formControlName="subObjName" (selectionChange)="getSelectActivityLog($event)" required>
                        <mat-option value="ExternalEmail">Email</mat-option>
                        <mat-option value="ExternalCall">Call</mat-option>
                        <mat-option value="ExternalOther">Other</mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmExternalLog?.controls?.subObjName?.errors &&
                        (frmExternalLog?.controls?.subObjName?.dirty ||
                        frmExternalLog?.controls?.subObjName?.touched)">
                        {{ frmExternalLog?.controls?.subObjName["errorMessage"] }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>{{infoActivity.label}}</mat-label>
                    <input *ngIf="infoActivity.control == 'email'" matInput formControlName="email" autocomplete="off"
                        required trim="blur" />
                    <input *ngIf="infoActivity.control == 'phoneNumber'" matInput formControlName="phoneNumber"
                        autocomplete="off" required trim="blur" />
                    <input *ngIf="infoActivity.control == 'other'" matInput formControlName="logInfo1"
                        autocomplete="off" required trim="blur" />
                    <mat-error *ngIf="frmExternalLog?.controls[infoActivity.control]?.errors &&
                        (frmExternalLog?.controls[infoActivity.control]?.dirty ||
                        frmExternalLog?.controls[infoActivity.control]?.touched)">
                        {{ frmExternalLog?.controls[infoActivity.control]["errorMessage"] }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12" *ngIf="isHideAction">
                <mat-form-field appearance="standard">
                    <mat-label>Action</mat-label>
                    <mat-select formControlName="action" required (selectionChange)="getSelectChange($event)">
                        <mat-option *ngFor="let item of actions[infoActivity.control]"
                            [value]="item">{{item}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmExternalLog.controls.action.errors &&
                        (frmExternalLog.controls.action.dirty || frmExternalLog.controls.action.touched)">
                        {{ frmExternalLog.controls.action["errorMessage"] }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Action Date</mat-label>
                    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a action date" readonly required
                        formControlName="dateCreated" [errorStateMatcher]="matcher" [max]="currentDate"
                        (click)="picker ? picker.open() : ''">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker>
                        <ng-template>
                            <span>OK</span>
                        </ng-template>
                    </ngx-mat-datetime-picker>
                    <mat-error *ngIf="frmExternalLog.controls.dateCreated.errors &&
                        (frmExternalLog.controls.dateCreated.dirty || frmExternalLog.controls.dateCreated.touched)">
                        {{ frmExternalLog.controls.dateCreated["errorMessage"] }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12" *ngIf="isHideLogInfo1">
                <mat-form-field *ngIf="!isSelectUser">
                    <mat-label *ngIf="!actionChange">Choose Action</mat-label>
                     <mat-label> {{labelLogInfo1}}</mat-label>
                    <textarea matInput placeholder={{placeLogInfo1}} formControlName="logInfo1"></textarea>
                </mat-form-field>
                <mat-form-field *ngIf="isSelectUser" appearance="standard">
                    <mat-label>{{labelLogInfo1}}</mat-label>
                    <input type="text" matInput [formControl]="frmControl" placeholder={{placeLogInfo1}} [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete"  (optionSelected)="onSelected($event)" [displayWith]="displayFn">
                      <mat-option #userOptions *ngFor="let user of filteredOptions | async" [value]="user.id">
                        <div fxLayout="row" fxLayoutAlign="start center" style="padding: 10px 16px" >
                          <nb-user [onlyPicture]="true" color="#8f9bb37a" [picture]="user.pictureURL" size="small"
                          [name]="(userNameImage.length > 0 ? userNameImage : user.userName) ?? user.userName " >
                          </nb-user>
                          <span >
                            {{ user.userName }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
            <div class="col-12" *ngIf="isHideLogInfo2">
                <mat-form-field>
                    <mat-label *ngIf="!actionChange">Choose Action</mat-label>
                    <mat-label>{{labelLogInfo2}}</mat-label>
                    <textarea matInput placeholder={{placeLogInfo2}} formControlName="logInfo2"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12" *ngIf="isHideLogInfo3">
                <mat-form-field>
                    <mat-label>{{labelLogInfo3}}</mat-label>
                    <input matInput type="number" placeholder={{placeLogInfo3}} formControlName="callTime" autocomplete="off" trim="blur">
                </mat-form-field>
            </div>
            <button type="submit" style="display: none"></button>
        </div>
    </form>
</mat-dialog-content>
