import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';



import { finalize, takeUntil, tap } from 'rxjs/internal/operators';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './headshot.dialog.html',
  styleUrls: ['./headshot.dialog.scss']
})
export class HeadshotDialog implements OnInit {


  isLoading = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  resultShown = false;
  userFullName: string;
  userID: string;
  isSaving = false;
  croppedEvent: ImageCroppedEvent;

  //2022-02-10 vuonglqn add start
  aspectRatioWidth = 1;
  aspectRatioHeight = 1;
  //2022-02-10 vuonglqn add end

  constructor(
    private dialModalRef: MatDialogRef<HeadshotDialog>,
    private toast: NbToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.imageChangedEvent = data.event;
    this.aspectRatioWidth = data.aspectRatioWidth ?? 1;
    this.aspectRatioHeight = data.aspectRatioHeight ?? 1;
  }

  ngOnInit() {
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedEvent = event;
    this.croppedImage = event.base64;
    this.resetResult();
  }

  imageLoaded() {
    this.isLoading = !this.isLoading;
    this.resetResult();
  }

  cropperReady() {
  }

  loadImageFailed() {
    this.resultShown = false;
    this.toast.danger("Sorry, the system has an unexpected technical issue.", 'Error');
    this.dialModalRef.close();
  }

  saveImage() {
    if (!this.croppedImage) {
      return;
    }

    const file = this.dataURLtoFile(this.croppedEvent.base64, this.userID);
    this.isSaving = false;
    this.dialModalRef.close({ file: file, base64: this.croppedEvent.base64 });

  }

  closeDialog() {
    this.dialModalRef.close();
  }
  saveData() {

  }
  private resetResult() {
    this.resultShown = false;
    setTimeout(() => {
      this.resultShown = true;
    }, 1);
  }

  private dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "temp.png", { type: mime });
  }
}
