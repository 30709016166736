<mat-form-field [appearance]="appearance">
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [formControl]="control" placeholder="{{placeholder}}" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [nbSpinner]="loading">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option[displayKey]">
            {{option[displayKey]}}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.errors && (control.dirty ||control.touched)">
        {{!!errorMessage ? errorMessage : control['errorMessage']}}</mat-error>
</mat-form-field>