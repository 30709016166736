<!-- <div *ngIf="!isLoading" appChangeUrlObjects [id]="id" [isOpenDialog]="openByDialog" [topic]="'profile'"
    [name]="profileModel?.displayName"></div> -->

<div class="d-flex flex-wrap align-items-space-between" mat-dialog-title style="margin: unset;" *ngIf="isShowButton">
    <div [fxFlex]="'15.05'" [hidden]="isLoading" class="d-flex flex-row flex-wrap">
        <div class="profile-detail-avatar-container">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <mat-icon style="transform: scale(1.5);" [ngStyle]="{'color':colorCode.Profile}">
                    account_circle</mat-icon>
            </div>
            <div style="align-items: flex-start;" class="entity-name-id">
                <span class="entity-name d-inline-block text-truncate" fxLayout="row"
                    fxLayoutAlign="space-around center">
                    {{dynamicName}} Profile
                </span>
                <span *ngIf="!isCopy;else copied" [matTooltip]="'Click to copy this profile ID: '+ id" class="entity-id"
                    (click)="copyGUIDToClipboard()" class="underline-id d-inline-block text-truncate">#{{id}}</span>
            </div>
        </div>
        <mat-divider [vertical]="true" style="height:100%"></mat-divider>
    </div>

    <div [fxFlex]="'74.95'" class="profile-detail-action-button-container" fxLayout="row" fxLayoutAlign="end">
        <div fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ngIf="!inPendingRevision; else pendingRevision">
                <button [nbSpinner]="revisionLoading" mat-raised-button color="primary" (click)="submitRevision()"
                    [disabled]="revisionLoading">
                    Request to publish
                </button>
            </ng-container>
            <div>
                <button mat-icon-button class="menu-action" (click)="openRevisionManagement()">
                    <mat-icon [matTooltip]="'Click to open all revision'">vertical_split</mat-icon>
                </button>
            </div>
            <div>
                <button mat-icon-button [nbSpinner]="isLockedProfileLoading" [disabled]="isLockedProfileLoading"
                    (click)="updateProfileLockStatus()">
                    <mat-icon *ngIf="isLockedProfile; else unlockIcon" [matTooltip]="'Click to Unlock Profile'"
                        style="color: #dc3545;">lock</mat-icon>
                    <ng-template #unlockIcon>
                        <mat-icon [matTooltip]="'Click to lock Profile'" style="color: #28a745">lock_open</mat-icon>
                    </ng-template>
                </button>
            </div>
            <div>
                <button mat-icon-button [nbSpinner]="keywordMappingLoading" [disabled]="keywordMappingLoading"
                    (click)="onClickKeywordMapping()"
                    [matTooltip]="!keywordMappingData ? 'Add to keyword mapping' : 'Open keyword mapping'"
                    [ngClass]="keywordMappingData ? 'text-success' : 'text-warning'"><mat-icon>{{!keywordMappingData ?
                        'font_download_off' : 'font_download'}}</mat-icon>
                </button>
            </div>

            <ng-container *ngIf="!refreshColorAIDetect; else aiDetectBtnLoading">
                <div *nbIsGranted="['view', resourceItem.requestAIChecker]">
                    <button mat-icon-button [ngClass]="primarySeo?.seoInfo?.aiScore | aiDetectionPipe: 'color'"
                        class="ml-auto float-start" [matTooltip]="'Click to open AI Detection tool'"
                        (click)="clickAIChecker()">
                        <mat-icon>smart_toy</mat-icon>
                    </button>
                </div>
            </ng-container>


            <div *nbIsGranted="['view', resourceItem.requestSEOAudit]">
                <button mat-icon-button [ngClass]="primarySeo?.seoInfo?.overallScore | seoColorScore: '100'"
                    class="ml-auto float-start" [matTooltip]="'Click to open SEO audit'" (click)="clickSEOAudit()">
                    <mat-icon svgIcon="seo-audit"></mat-icon>
                </button>
            </div>

            <div *nbIsGranted="['view', resourceItem.requestSEOAudit]">
                <button mat-icon-button [ngClass]="primarySeo?.seoInfo?.readabilityScore | readabilityScore: '100'"
                    [nbSpinner]="readabilityLoading" class="ml-auto float-start" [matTooltip]="'Readability'"
                    (click)="handleSeoAudit()">
                    <mat-icon>menu_book</mat-icon>
                </button>
            </div>

            <!-- <div *nbIsGranted="['view', resourceItem.openProfileAndShowSensitive]">
                <button mat-icon-button class="ml-auto float-start" [matTooltip]="isHiddenData ? 'Click to hidden the internal data' : 'Click to show the internal data'" *ngIf="profileModel.profileId" [color]="isHiddenData ? 'primary' : ''" matTooltipPosition="left" (click)="toggleHiddenData()">
                    <mat-icon>{{isHiddenData ? 'visibility' : 'visibility_off'}}</mat-icon>
                </button>
            </div>

            <ng-container *nbIsGranted="['view', resourceItem.openProfileOnFrontFacing]">
                <button mat-icon-button class="ml-auto float-start" matTooltip="Copy consent link this profile on front-facing" matTooltipPosition="left" (click)="copyConsentLink()" [nbSpinner]="loadingSeoLinkBtn">
                    <mat-icon>copyright</mat-icon>
                </button>
            </ng-container> -->

            <div *nbIsGranted="['view', resourceItem.openProfileOnFrontFacing]">
                <button mat-icon-button class="ml-auto float-start" matTooltip="Open this profile on front-facing"
                    matTooltipPosition="left" (click)="openSeoLink()" [disabled]="loadingSeoLinkBtn || disableSeoLink"
                    [nbSpinner]="loadingSeoLinkBtn">
                    <mat-icon>open_in_browser</mat-icon>
                </button>
            </div>

            <!-- <button mat-icon-button class="ml-auto float-start" [matTooltip]="'Copy this ' + subjectProfile + ' profile url to clipboard'" matTooltipPosition="left" (click)="copyToClipboard()">
                <mat-icon style="transform: scale(0.9);">share</mat-icon>
            </button>

            <div *nbIsGranted="['view', resourceItem.copyPreviewPageLink]">
                <button mat-icon-button class="ml-auto float-start" matTooltip="Copy preview page to clipboard" [disabled]="toggleReviewableState()" matTooltipPosition="left" (click)="copyPreviewFrontFacingSite()">
                    <mat-icon style="transform: scale(0.9);">wysiwyg</mat-icon>
                </button>
            </div> -->

            <div>
                <button mat-icon-button [matMenuTriggerFor]="toolbarActions">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #toolbarActions="matMenu"
                    class="toolbar-action-menu d-flex flex-column align-items-start justify-content-start">
                    <ng-container *nbIsGranted="['view', resourceItem.openProfileAndShowSensitive]">
                        <button mat-menu-item class="menu-action" *ngIf="profileModel.profileId"
                            (click)="toggleHiddenData()">
                            <mat-icon style="color: #000;"
                                [ngStyle]="isHiddenData | toolbarActionMenuPipe : 'style' : 'InternalData'">
                                {{isHiddenData ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                            {{ isHiddenData | toolbarActionMenuPipe : 'label' : 'InternalData' }}
                        </button>
                    </ng-container>
                    <ng-container *nbIsGranted="['view', resourceItem.openProfileOnFrontFacing]">
                        <button mat-menu-item class="menu-action" (click)="copyConsentLink()"
                            [nbSpinner]="loadingSeoLinkBtn">
                            <mat-icon style="color: #000;">copyright</mat-icon>
                            Copy consent link
                        </button>
                    </ng-container>
                    <ng-container>
                        <button mat-menu-item class="menu-action" (click)="copyToClipboard()">
                            <mat-icon style="transform: scale(0.9); color: #000;">share</mat-icon>
                            Copy Artist Profile link
                        </button>
                    </ng-container>
                    <ng-container *nbIsGranted="['view', resourceItem.copyPreviewPageLink]">
                        <button mat-menu-item class="menu-action" [disabled]="toggleReviewableState()"
                            (click)="copyPreviewFrontFacingSite()">
                            <mat-icon style="transform: scale(0.9); color: #000;">wysiwyg</mat-icon>
                            Copy preview link
                        </button>
                    </ng-container>
                </mat-menu>
            </div>

            <mat-divider [vertical]="true" style="height:100%">
            </mat-divider>

            <app-next-or-previous-detail #btnNextOrPre [modelNextOrPre]="profileNextOrPre"
                (onClick)="clickNextOrPre($event[0] , $event[1])">
            </app-next-or-previous-detail>
            <button *ngIf="openByDialog" mat-icon-button matTooltip="Close" (click)="closeDialog()">
                <mat-icon style="color: red">close</mat-icon>
            </button>
        </div>
    </div>
</div>
<mat-divider *ngIf="isShowButton"></mat-divider>

<div *ngIf="!isLoading && id == profileModel?.profileId; then content else skeleton"></div>

<ng-template #content>
    <mat-dialog-content [ngStyle]="{
    'display': !openByDialog?'contents':'block',
    'overflow' : isAutomateDataState && currentUrl.startsWith('/configuration/profile') ? 'hidden' : 'auto'
  }" *ngIf="profileModel.profileId; else empty" style="max-height: 100% !important;">
        <div
            *ngIf="isAutomateDataState && isHasDataField && currentUrl.startsWith('/configuration/profile'); else noAutomateBody">
            <div fxLayout="row" fxLayoutAlign="start start" style="overflow: hidden; ">
                <div fxFlex="15" appDetectTabMode [typeElement]="'BodyDetail'" class="scroll-automate-datastate"
                    style="overflow: hidden; height: 100vh;">
                    <ng-container [ngTemplateOutlet]="bodyAutoDataStateLog"></ng-container>
                </div>
                <mat-divider appDetectTabMode [typeElement]="'BodyDetail'" style="height: 100vh;" [vertical]="true">
                </mat-divider>
                <div fxFlex="85" appDetectTabMode [typeElement]="'BodyDetail'" style="overflow: auto; height: 100vh;"
                    class="full-height">
                    <ng-container [ngTemplateOutlet]="bodyProfile"></ng-container>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <ng-template #noContent>
        <app-inline-edit></app-inline-edit>
    </ng-template>
    <ng-template #notPublished>
        <span class="material-icons" style="font-size: 24px; color: red" matTooltip="Not published"
            matTooltipPosition="above">cancel</span>
    </ng-template>
</ng-template>

<ng-template #skeleton>
    <div class="fb-item">
        <div class="first-section-wrapper">
            <div class="gravatar">
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '80px', height: '80px' }">
                </ngx-skeleton-loader>
            </div>
            <div class="gravatar-title">
                <div>
                    <ngx-skeleton-loader [theme]="{
          width: '200px',
          'border-radius': '0',
          height: '15px',
          'margin-bottom': '10px'
        }"></ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="second-section-wrapper">
            <div class="wrapper">
                <ngx-skeleton-loader [theme]="{
        width: '80%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px'
      }"></ngx-skeleton-loader>
            </div>
            <div class="wrapper">
                <ngx-skeleton-loader [theme]="{
        width: '90%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px'
      }"></ngx-skeleton-loader>
            </div>
            <div class="wrapper">
                <ngx-skeleton-loader [theme]="{
        width: '60%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px'
      }"></ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="item">
        <ngx-skeleton-loader count="6" animation="progress" [theme]="{
    height: '50px'
  }"></ngx-skeleton-loader>
    </div>
</ng-template>

<mat-menu class="mat-data-state-menu" #menu="matMenu" focusFirstItem="false"
    style="max-width: fit-content !important; ">
    <button mat-menu-item mat-chip *ngFor="let state of (isAutomateDataState ? autoDataState : dataState)"
        class="chip-menu"
        (click)="isAutomateDataState ? editAutoDataState(state.automateDataStateId) : editDataState(state.dataStateId)"
        [style.backgroundColor]="state.colorCode"><strong>{{state.dataStateName}}</strong> </button>
</mat-menu>

<button mat-fab color="primary"
    style="width: 50px;height: 50px;position: absolute;top: 90vh;z-index: 1000000000000;right: 6px;"
    *ngIf="!openByDialog1 && (theLastAsteriskCallStatus!=null && !this.isPopupAsteriskOpen)"
    (click)="onShowLastAsteriskCallStatus()">
    <mat-icon>phone_in_talk</mat-icon>
</button>
<ng-template #empty>
    <mat-dialog-content>
        <app-blank-page [message]="'The profile with id ' + id +  ' is not exist or deleted'"></app-blank-page>
    </mat-dialog-content>
</ng-template>

<ng-template #bodyProfile>
    <!-- <div class="col-12" *ngIf="readonlyProfile">
        <br>
        <div class="alert alert-warning" role="alert">
            This profile was created by the another user. You can read-only.
        </div>
    </div>
    <div class="col-12" *ngIf="bypassPermissions">
        <br>
        <div class="alert alert-info" role="alert">
            This profile was initially set up by another user, but the system enables you to make modifications.
        </div>
    </div> -->

    <div class="d-flex flex-row w-100 justify-content-between all-profile-detail-container">
        <div class="profile-detail-highlight-media-container">
            <!-- PROFILE DETAIL -->
            <div class="profile-detail-container">
                <mat-chip-list hidden></mat-chip-list>
                <div class="w-100 d-flex flex-column" style="gap: 24px;">
                    <div class="d-flex flex-column">
                        <div class="w-100 d-flex flex-row justify-content-between pl-2" style="gap: 14px;">
                            <!--Avatar-->
                            <div class="w-30" style="min-width: 160px;">
                                <app-profile-avatar [medias]="profileModel.medias" [profileId]="profileModel.profileId"
                                    [media]="getAvt()" [mediaId]="profileModel.primaryImage" [table]="'Profile'"
                                    [readonlyAvatar]="readonlyProfile" [profile]="profileModel"
                                    [isLockedProfile]="isLockedProfile">
                                </app-profile-avatar>
                            </div>

                            <div class="w-70">
                                <!--Edit inline -->
                                <h5 #displayNameProp
                                    [matTooltip]="findItemByKey(tooltipProp, 'displayName') ?? 'Display name'"
                                    matTooltipPosition="left" class="h5-custom">
                                    <span class="d-flex">
                                        <app-inline-edit
                                            [inputData]="profileModel?.displayName || 'Click to add content' "
                                            (handleSave)="editDisplayName($event); refreshTask.emit()"
                                            [readonlyInline]="(readonlyProfile || isLockedProfile)"
                                            (cacheInputChange)="dataChange($event, 'displayName')">
                                        </app-inline-edit>
                                        <mat-icon *ngIf="duplicateIconLoading"
                                            [nbSpinner]="duplicateIconLoading">error_outline</mat-icon>
                                        <mat-icon class="duplicate-display-name"
                                            [matTooltip]="'The ' + (findItemByKey(tooltipProp, 'displayName') ?? 'display name') + ' is duplicated'"
                                            *ngIf="isDuplicate" matTooltipPosition="right">error_outline</mat-icon>

                                        <mat-icon [nbPopover]="isShowPopover ? emailRelationship : null"
                                            [nbSpinner]="isLoadingDataDuplicated" class="duplicate-data"
                                            [ngStyle]="{'color': isLoadingDataDuplicated ? '': 'red'}"
                                            style="cursor: pointer;"
                                            [matTooltip]="'We discover potential duplicate data using the same contact information!'"
                                            *ngIf="isContactsRelationship"
                                            matTooltipPosition="right">warning_amber</mat-icon>
                                    </span>
                                </h5>

                                <h6 #alternativeProp class="h6-custom d-flex">
                                    <mat-icon svgIcon="h1-icon"
                                        matTooltip="H1 replacement: If H1 replacement is empty, the system will use the display name as H1 on the front-facing website">
                                    </mat-icon>
                                    <span class="middle-align ml-2" style="max-width: calc(100% - 35px);">
                                        <app-inline-edit
                                            [inputData]="profileModel?.profileAdditionDetail?.alternativeDisplayName"
                                            [width]="'100%'" [display]="'inline-block'"
                                            (handleSave)="updateH1Title($event, 'AlternativeDisplayName')"
                                            [readonlyInline]="(readonlyProfile || isLockedProfile)">
                                        </app-inline-edit>
                                    </span>
                                </h6>

                                <h6 [matTooltip]="findItemByKey(tooltipProp, 'realName') ?? 'Real name'"
                                    matTooltipPosition="left" class="h6-custom d-flex">
                                    <span class="material-icons-outlined blue-color middle-align"
                                        style="font-size: 24px;">account_circle</span>
                                    <span class="middle-align ml-2" style="max-width: calc(100% - 35px);">
                                        <app-inline-edit [inputData]="profileModel?.realName"
                                            (handleSave)=editRealName($event) [width]="'100%'"
                                            [display]="'inline-block'"
                                            [readonlyInline]="(readonlyProfile || isLockedProfile)"
                                            (cacheInputChange)="dataChange($event, 'realName')">
                                        </app-inline-edit>
                                    </span>
                                </h6>

                                <h6 [matTooltip]="findItemByKey(tooltipProp, 'producer.producerName') ?? 'Production company'"
                                    matTooltipPosition="left" class="h6-custom d-flex">
                                    <span class="material-icons-outlined blue-color middle-align"
                                        style="font-size: 24px; ">apartment</span>
                                    <span class="middle-align ml-2" style="max-width: calc(100% - 35px); height: 36px;">
                                        <app-add-producer-inline [inputData]="profileModel?.producer?.producerName"
                                            [id]="profileModel.profileId"
                                            [readonlyInlineProducer]="(readonlyProfile || isLockedProfile)">
                                        </app-add-producer-inline>
                                    </span>
                                </h6>
                                
                                <h6 [matTooltip]="findItemByKey(tooltipProp, 'webPage') ?? 'Website'"
                                    matTooltipPosition="left" class="h6-custom d-flex">
                                    <span class="material-icons-outlined blue-color middle-align"
                                        style="font-size: 24px;">language</span>
                                    <span class="middle-align ml-2" style="max-width: calc(100% - 35px);">
                                        <app-inline-edit [inputData]="profileModel?.webPage" isAccess="true"
                                            [width]="'100%'" [display]="'inline-block'"
                                            (handleSave)="editWebPage($event)"
                                            [readonlyInline]="(readonlyProfile || isLockedProfile)"
                                            (cacheInputChange)="dataChange($event, 'webPage')">
                                        </app-inline-edit>
                                    </span>
                                </h6>
                                <!-- 2022-06-07 gnguyen start add -->
                                <h6 style="margin-bottom: 0px;" class="h6-custom" *ngIf="profileModel?.profileCode">
                                    <span class="material-icons-outlined blue-color middle-align"
                                        style="font-size: 24px;" matTooltip="Profile Code">qr_code_2</span>
                                    <span class="middle-align ml-2">
                                        {{profileModel?.profileCode}}
                                    </span>
                                </h6>
                                <!-- 2022-06-07 gnguyen end add -->
                            </div>
                        </div>

                        <div class="w-100 d-flex flex-row justify-content-between">
                            <div class="w-50 d-flex justify-content-center align-items-center"
                                style="min-width: 240px;">
                                <app-action-block [(readonlyProfile)]="readonlyProfile"
                                    [optionalTaskId]="optionalTaskId" [(profileModel)]="profileModel"
                                    [creatableTask]="creatableTask" [tooltipProp]="tooltipProp"
                                    [(contacts)]="totalContacts">
                                </app-action-block>
                            </div>

                            <div class="w-50 d-flex justify-content-center">
                                <!--Data state-->
                                <div class="data-state-editable" style="padding: 0.5rem 0 0.5rem 0"
                                    [matMenuTriggerFor]="(!readonlyProfile && !isLockedProfile) ? menu : ''">
                                    <mat-chip class="chip-data-state"
                                        [style.backgroundColor]="currentState?.colorCode || '#000'" selected
                                        [disabled]="(readonlyProfile || isLockedProfile)">
                                        {{currentState?.dataStateName || 'Unknown'}}
                                    </mat-chip>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-100 d-flex justify-content-center align-items-center social-block">
                        <nb-toggle *nbIsGranted="['view', resourceItem.socialBlock]" [nbSpinner]="revisionLoading"
                            status="success" [checked]="profileModel.isActive"
                            [disabled]="readonlyProfile || isLockedProfile || revisionLoading" debouncedClick
                            (throttleClick)="readonlyProfile || isLockedProfile || revisionLoading ? '' : activeToggle()">
                            <span [matTooltip]="numberTruncateToggle == 1 ? 'Active' : ''">
                                <!-- {{'Active' | truncateText:[numberTruncateToggle, '']}} -->
                                Active
                            </span>
                        </nb-toggle>

                        <nb-toggle *nbIsGranted="['view', resourceItem.socialBlock]" [nbSpinner]="revisionLoading"
                            status="success" [checked]="profileModel.published"
                            [disabled]="readonlyProfile || isLockedProfile || revisionLoading" debouncedClick
                            (throttleClick)="readonlyProfile || isLockedProfile || revisionLoading ? '' : clickToPublish()">
                            <span [matTooltip]="numberTruncateToggle == 1 ? 'Published' : ''">
                                <!-- {{'Published' | truncateText: [numberTruncateToggle, '']}} -->
                                Published
                            </span>
                        </nb-toggle>

                        <nb-toggle status="success" [checked]="profileModel.isMaster"
                            (click)="readonlyProfile || isLockedProfile ? '' : masterToggle($event)"
                            [disabled]="readonlyProfile || checkIsMaster || isLockedProfile">
                            <span [nbSpinner]="checkIsMaster" nbSpinnerSize="tiny"
                                [matTooltip]="numberTruncateToggle == 1 ? 'Master' : ''">
                                <!-- {{'Master' | truncateText: [numberTruncateToggle, '']}} -->
                                Master
                            </span>
                        </nb-toggle>
                    </div>
                </div>
            </div>
            <!-- HIGHLIGHT & SOCIAL MEDIA BUTTON LIST -->
            <div *nbIsGranted="['view', resourceItem.hotTrend]" class="highlight-media-container">
                <div class="altus-card highlight-card">
                    <div class="highlight-title mb-1">Highlights</div>
                    <div class="d-flex flex-column justify-content-evenly h-90">
                        <div class="d-flex justify-content-between">
                            <span class="d-flex">
                                <mat-icon class="highlight-icon">favorite_border</mat-icon>
                                <span class="highlight-text">Favorite</span>
                            </span>
                            <mat-slide-toggle color="primary" [checked]="profileModel.favoriteArt"
                                [disabled]="isLockedProfile"
                                (click)="readonlyProfile ? '' : changeArtToggle('favoriteArt')"></mat-slide-toggle>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="d-flex">
                                <mat-icon class="highlight-icon">thumb_up</mat-icon>
                                <span class="highlight-text">Popular</span>
                            </span>
                            <mat-slide-toggle color="primary" [checked]="profileModel.isPopularAct"
                                [disabled]="isLockedProfile"
                                (click)="readonlyProfile ? '' : changeArtToggle('isPopularAct')"></mat-slide-toggle>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="d-flex">
                                <mat-icon class="highlight-icon">local_fire_department</mat-icon>
                                <span class="highlight-text">Top</span>
                            </span>
                            <mat-slide-toggle color="primary" [checked]="profileModel.isTopAct"
                                [disabled]="isLockedProfile"
                                (click)="readonlyProfile ? '' : changeArtToggle('isTopAct')"></mat-slide-toggle>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="d-flex">
                                <mat-icon class="highlight-icon">trending_up</mat-icon>
                                <span class="highlight-text">Feature Act</span>
                            </span>
                            <mat-slide-toggle color="primary" [checked]="featureActHP?.homePageId > 0"
                                [disabled]="featureActHP?.homePageId == -1 || isLockedProfile"
                                [nbSpinner]="featureActHP?.homePageId == -1"
                                (click)="readonlyProfile ? '' : saveFeatureOrSeasonalAct(featureActHP)"></mat-slide-toggle>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <mat-icon class="highlight-icon">celebration</mat-icon>
                                <span class="highlight-text">Seasonal Act</span>
                            </div>
                            <mat-slide-toggle color="primary" [checked]="seasonalActHP?.homePageId > 0"
                                [disabled]="seasonalActHP?.homePageId == -1 || isLockedProfile"
                                (click)="readonlyProfile ? '' : saveFeatureOrSeasonalAct(seasonalActHP)"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div *nbIsGranted="['view', resourceItem.socialMedia]" class="altus-card social-media-card">
                    <div class="social-media-title mb-1">Social Media</div>
                    <div class="d-flex flex-column justify-content-evenly h-90">
                        <app-inline-edit-socical icon="facebook" [isEditInlineBlock]="true"
                            [socialAccount]="profileModel.facebook" fieldName="facebook"
                            (handleSave)=editSocialAccount($event) [readonlyInlineSocial]="readonlyProfile"
                            (cacheInputChange)="dataChange($event, 'facebook')" [isLockedProfile]="isLockedProfile">
                        </app-inline-edit-socical>
                        <app-inline-edit-socical icon="youtube" [isEditInlineBlock]="true"
                            [socialAccount]="profileModel.youtube" fieldName="youtube"
                            (handleSave)=editSocialAccount($event) [readonlyInlineSocial]="readonlyProfile"
                            (cacheInputChange)="dataChange($event, 'youtube')" [isLockedProfile]="isLockedProfile">
                        </app-inline-edit-socical>
                        <app-inline-edit-socical icon="instagram" [isEditInlineBlock]="true"
                            [socialAccount]="profileModel.instagram" fieldName="instagram"
                            (handleSave)=editSocialAccount($event) [readonlyInlineSocial]="readonlyProfile"
                            (cacheInputChange)="dataChange($event, 'instagram')" [isLockedProfile]="isLockedProfile">
                        </app-inline-edit-socical>
                        <app-inline-edit-socical icon="skype" [isEditInlineBlock]="true"
                            [socialAccount]="profileModel.skype" fieldName="skype"
                            (handleSave)=editSocialAccount($event) [readonlyInlineSocial]="readonlyProfile"
                            (cacheInputChange)="dataChange($event, 'skype')" [isLockedProfile]="isLockedProfile">
                        </app-inline-edit-socical>
                        <app-inline-edit-socical icon="twitter" [isEditInlineBlock]="true"
                            [socialAccount]="profileModel.twitter" fieldName="twitter"
                            (handleSave)=editSocialAccount($event) [readonlyInlineSocial]="readonlyProfile"
                            (cacheInputChange)="dataChange($event, 'twitter')" [isLockedProfile]="isLockedProfile">
                        </app-inline-edit-socical>
                        <app-inline-edit-socical icon="tiktok" [isEditInlineBlock]="true"
                            [socialAccount]="profileModel.tiktok" fieldName="tiktok"
                            (handleSave)=editSocialAccount($event) [readonlyInlineSocial]="readonlyProfile"
                            (cacheInputChange)="dataChange($event, 'tiktok')" [isLockedProfile]="isLockedProfile">
                        </app-inline-edit-socical>
                    </div>
                </div>
            </div>
        </div>

        <div class="status-note-container">
            <!-- STATUS  -->
            <div class="status-container">
                <div class="w-100 status-card-container-header">
                    Content Status
                </div>
                <div class="status-card-container">
                    <div *ngIf="isLockedProfile" class="status-card w-100">
                        <div class="d-flex align-items-center justify-content-between g-8px">
                            <div class="d-flex flex-row align-items-center w-100 g-8px">
                                <mat-icon class="status-section-icon">lock</mat-icon>
                                <div class="d-flex flex-column w-100">
                                    <span class="status-card-title">Profile Status</span>
                                    <span class="status-card-sub-title">Editing restricted</span>
                                </div>
                            </div>
                            <div class="status-section-label-noti lock">LOCKED</div>
                        </div>
                    </div>

                    <div class="status-card w-100 d-flex flex-column g-8px">
                        <div *ngIf="!isPublishedRevisionLoading; else publishRevisionLabelLoader"
                            class="d-flex align-items-center justify-content-between g-8px">
                            <div class="d-flex flex-row align-items-center w-100 g-8px">
                                <mat-icon class="status-section-icon">update</mat-icon>
                                <div class="d-flex flex-column w-100">
                                    <span class="status-card-title">Revision Status</span>
                                    <ng-container *ngIf="inPublishedRevision, else noPublishedRevision">
                                        <span class="status-card-sub-title d-flex flex-column">
                                            <div>
                                                Published By: {{inPublishedRevision?.reviewer?.userName ?? ''}}
                                            </div>
                                            <div>
                                                Last Published Date: {{inPublishedRevision?.reviewedDate | date:
                                                'short'}}
                                            </div>
                                        </span>
                                    </ng-container>

                                    <ng-template #noPublishedRevision>
                                        <div class="status-card-sub-title">
                                            No revision has been published
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="text-right">
                                <!-- <button mat-icon-button [matMenuTriggerFor]="moreRevision"><mat-icon>more_horiz</mat-icon></button> -->
                                <div class="status-section-label-noti"
                                    [ngClass]="inPublishedRevision | revisionDetectPublishPipe : 'class'">
                                    {{inPublishedRevision | revisionDetectPublishPipe : 'label'}}
                                </div>
                                <!-- <mat-menu #moreRevision="matMenu">
                                    <button mat-menu-item class="menu-action" (click)="openRevisionManagement()">
                                        Open all revision
                                    </button>
                                </mat-menu> -->
                            </div>
                        </div>

                        <div *ngIf="inPendingRevision" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="8px">
                            <div class="notify-reviewed-profile alert alert-primary" role="alert">
                                <div class="mt-2"> This profile is being reviewed. If you want to submit a new one,
                                    please <a href="javascript:void(0)" (click)="removeRevision()"> remove this revision
                                        from review</a>
                                </div>
                            </div>
                            <div class="d-flex flex-column justify-content-between g-8px">
                                <button mat-stroked-button *nbIsGranted="['view','reviewer-revision-version']"
                                    (click)="approveRevision()" class="text-start h-50">
                                    <mat-icon style="color: #42d719">check_circle</mat-icon>Approve
                                </button>
                                <button mat-stroked-button *nbIsGranted="['view','reviewer-revision-version']"
                                    (click)="rejectChanges()" class="text-start h-50">
                                    <mat-icon style="color: #ee2b2b">cancel</mat-icon>Reject
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(!inPendingRevision && previousRejectRevision)" fxLayout="row"
                            fxLayoutAlign="center" fxLayoutGap="8px">
                            <div class="notify-reviewed-profile py-2 alert alert-danger" role="alert">
                                <div>
                                    <b>{{previousRejectRevision?.reviewer?.userName}}</b> has rejected the latest
                                    revision of this profile, please review the reason for rejection and submit a new
                                    revision
                                </div>
                                <div>
                                    <b>Rejected Date: </b>
                                    {{previousRejectRevision?.reviewedDate | date: 'short'}}
                                </div>
                                <div class="reject-reason-detail">
                                    <b>Reason: </b>
                                    <ng-container *ngIf="previousRejectRevision?.description; else noDataDisplay">
                                        {{previousRejectRevision?.description}}
                                    </ng-container>
                                    <ng-template #noDataDisplay>
                                        <i>No data display</i>
                                    </ng-template>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="status-card w-100">
                        <div *ngIf="!isProfileChangeLoading; else revisionDetectChangeLoader"
                            class="d-flex align-items-center justify-content-between g-8px">
                            <div class="d-flex flex-row align-items-center w-100 g-8px">
                                <mat-icon class="status-section-icon">refresh</mat-icon>
                                <div class="d-flex flex-column w-100">
                                    <span class="status-card-title">Changes Status</span>
                                    <span class="status-card-sub-title d-flex flex-column">
                                        <div>
                                            Last Detected: {{lastDetectDate | date: 'short'}}
                                        </div>
                                        <div class="status-card-sub-title-action d-flex flex-row">
                                            <div (click)="getComparisonWithRevision()"
                                            [matTooltip]="'Click here to refresh the change detection status'">
                                                Refresh
                                            </div>
                                            <div (click)="openProfileComparisonDetail()" style="margin-left: 15px;">
                                                Review Changes
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="status-section-label-noti"
                                [ngClass]="isProfileChangeWithRevision | revisionDetectChangePipe : 'class'">
                                {{isProfileChangeWithRevision | revisionDetectChangePipe : 'label'}}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isHasUpdateNew" class="status-card w-100">
                        <div class="d-flex align-items-center justify-content-between g-8px">
                            <div class="d-flex flex-row align-items-center g-8px">
                                <mat-icon class="status-section-icon">notes</mat-icon>
                                <div class="d-flex flex-column w-100">
                                    <span class="status-card-title">Onboarding Form</span>
                                    <span class="status-card-sub-title d-flex flex-column">
                                        <div>
                                            New pending changes from onboarding form
                                        </div>
                                        <div class="status-card-sub-title-action" (click)="showMergeChange()">
                                            Review changes
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="status-section-label-noti pending">PENDING</div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- NOTE -->
            <div class="note-container" fxLayoutAlign="space-evenly center"
                [ngStyle]="{'padding-top': lastNote ? '0.5rem' : '4rem'}">
                <ng-container *ngIf="lastNote; else blankLastNote">
                    <nb-card class="body-last-note-card" [nbSpinner]="overlayNoteDetails?.isLoading | async"
                        (click)="noteService.pushNoteDetails(lastNote, profileModel.displayName)">
                        <nb-card-header fxLayout="row" fxLayoutAlign="space-between center"
                            style="padding: 0 1rem; background-color: #f5da65;">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <button mat-icon-button debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                            referenceId: id,
                            referenceType: 'SALEPROFILE',
                            referenceName: profileModel.displayName
                        })">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <nb-user *ngIf="lastNote.ownerId" [onlyPicture]="true" color="#8f9bb37a"
                                    [matTooltip]="lastNote.user?.userName" [name]="lastNote['ownerNote']"
                                    [picture]="lastNote['avatarNote']" size="small">
                                </nb-user>
                                <span class="limit-line" style="--line: 1;" [matTooltip]="lastNote.title"
                                    matTooltipPosition="left">
                                    Last note: {{lastNote.title}}
                                </span>
                            </div>

                            <span fxFlex="20" class="limit-line"
                                style="--line: 1; width: auto; font-weight: normal; text-align: end;"
                                matTooltip="Last created" matTooltipPosition="left">
                                {{ (lastNote.dateCreated | date: 'short') || 'None' }}
                            </span>
                        </nb-card-header>
                        <nb-card-body style="background-color: #fef9b7;" [innerHTML]="lastNote.note">
                        </nb-card-body>
                    </nb-card>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="basic-container" style="margin:25px" #tabGroupContainer>
        <mat-tab-group mat-stretch-tabs animationDuration="0ms" #tabGroup (selectedIndexChange)="contactTab = null"
            (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedIndex"
            *ngIf="flag == false ;else flagTrue">
            <mat-tab label="General" *nbIsGranted="['view', resourceTab.general]">
                <ng-template matTabContent>
                    <app-general-tab [data]='profileModel' [readonlyProfile]="readonlyProfile"
                        [readonly]="isLockedProfile"></app-general-tab>
                </ng-template>
            </mat-tab>
            <mat-tab #tabIndexReasonBio label="Reasons & Bio" *nbIsGranted="['view', resourceTab.reason_bio]">
                <ng-template matTabContent>
                    <app-bio-n-reason [id]='profileModel.profileId' [readonlyProfile]="readonlyProfile"
                        [readonly]="isLockedProfile" (isLoadingOutput)="onValueReasonBioChanged($event)">
                    </app-bio-n-reason>
                </ng-template>
            </mat-tab>
            <mat-tab label="Contacts" *nbIsGranted="['view', resourceTab.contacts]">
                <ng-template mat-tab-label>
                    Contact
                    <!-- ({{profileModel?.profileAdditionDetail?.totalContact || 0}}) -->
                    <app-add-contact-button [id]="id" [type]="'Profile'" [isActivePrimary]="true" (onRefresh)="
                        !isHasPrimaryContact && !contactTab ? refreshData() : '';
                        contactTab ? contactTab.refreshData(true) : ''; resetContacts()"
                        (onRefreshData)="onRefresh($event)" [isLockedProfile]="isLockedProfile">
                    </app-add-contact-button>
                </ng-template>
                <ng-template matTabContent>
                    <app-contact-relationship-grid #contactGrid [relationshipId]="id" [relationshipType]="'Profile'"
                        [isHidePrimaryContact]="false" [formatData]="formatContactType" [showContactType]="true"
                        (getPrimaryContactRelationship)="updatePrimaryContact($event); resetContacts()"
                        [maxHeight]="'60vh'" (refreshDataValue)="onRefresh($event)" [readonlyGrid]="isLockedProfile">
                    </app-contact-relationship-grid>
                    <!-- (refreshParentData)="refreshData()" -->
                    <!-- (getPrimaryContactRelationship)="updatePrimaryContact($event)" -->
                </ng-template>
            </mat-tab>
            <mat-tab label="More Locations" *nbIsGranted="['view', resourceTab.locations]">
                <ng-template mat-tab-label>
                    Locations
                    <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                        (throttleClick)="openLocationDialog()" [disabled]="isLockedProfile">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-location-grid #locationGrid [relationshipId]="id" maxHeight="60vh"
                        [relationshipType]="'SALEPROFILE'" [crmMode]="crmMode" [readonlyGrid]="isLockedProfile"
                        [isUseLocationMajorMockup]="true">
                    </app-location-grid>
                </ng-template>
            </mat-tab>
            <!-- <mat-tab label="Locations" *nbIsGranted="['view', resourceTab.locations]">
                <ng-template matTabContent>
                    <app-location-tab [profileDetails]="profileModel" [readonlyProfile]="readonlyProfile">
                    </app-location-tab>
                </ng-template>
            </mat-tab> -->
            <mat-tab #tabIndexMedia label="Multimedia" *nbIsGranted="['view', resourceTab.multimedia]">
                <ng-template matTabContent>
                    <app-multi-media [profileId]="profileModel.profileId" (refreshData)="refreshData()"
                        [primaryImage]="profileModel.primaryImage" [primaryVideo]="profileModel.primaryVideo"
                        [readonlyMultiMedia]="readonlyProfile" [displayName]="profileModel.displayName"
                        [readonly]="isLockedProfile">
                    </app-multi-media>
                </ng-template>
            </mat-tab>
            <mat-tab label="Files" *nbIsGranted="['view', 'table-profile-file']">
                <ng-template mat-tab-label>
                    Files
                    <button *nbIsGranted="['view', 'add-profile-file']" #nbFilesBtn="nbPopover" mat-icon-button
                        color="primary" debouncedClick [throttleTime]="500" (throttleClick)="nbPopoverCLick()"
                        [nbPopover]="addFilesMenu" nbPopoverPlacement="left">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-profile-file-tab #profileFileTab [profileModel]="profileModel" [perm]="fileResource">
                    </app-profile-file-tab>
                </ng-template>
            </mat-tab>
            <mat-tab #tabIndexSeo label="SEO" *nbIsGranted="['view', resourceTab.seo]">
                <ng-template mat-tab-label>
                    SEO
                    <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                        (throttleClick)="addNewProfileSeo()" [disabled]="isLockedProfile">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-seo-tab-table #profileSeo [seoInfoId]="seoInfoId" [profileId]="profileModel.profileId"
                        [displayName]="profileModel.displayName" [tooltipProp]="tooltipProp"
                        [readonlyGrid]="isLockedProfile">
                    </app-seo-tab-table>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Tasks ({{profileModel?.profileAdditionDetail?.totalTask || 0}})
                    <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                        (throttleClick)="createTask()">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-task-grid-by-profile-id #taskGrid [profileId]="id" [saleModel]="profileModel"
                        (refreshDataValue)="onRefresh($event)"></app-task-grid-by-profile-id>
                </ng-template>
            </mat-tab>
            <mat-tab label="Note" *nbIsGranted="['view', 'table-note-profile']">
                <ng-template mat-tab-label>
                    Note
                    <button mat-icon-button color="primary" debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                    referenceId: id,
                    referenceType: 'SALEPROFILE',
                    referenceName: profileModel.displayName
                })">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-note-tab #gridNoteTab [id]="id" [referenceType]="'SALEPROFILE'" [resource]="resource"
                        (editChange)="noteService.pushNoteDetails($event.row, profileModel.displayName)"
                        (deletedChange)="overlayNoteDetails ? overlayNoteDetails.deleteNoteFromOutSide($event) : '';getLastNote($event, true)"
                        [nbSpinner]="overlayNoteDetails?.isLoading | async">
                    </app-note-tab>
                </ng-template>
            </mat-tab>
            <mat-tab label="Activities" *nbIsGranted="['view', resourceTab.activities]">
                <ng-template mat-tab-label>
                    Activities
                    <button mat-icon-button color="primary" appAddActivityLogBtn [id]="profileModel.profileId"
                        type="PROFILE">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-activity-tab [profileId]="profileModel.profileId" [profileModel]="profileModel"
                        [tooltipProp]="tooltipProp"></app-activity-tab>
                </ng-template>
            </mat-tab>
            <mat-tab
                [label]="'Relationship ' + '(' + (('TotalRelationship' | findItem: generalCount : 'typeName')?.totalNumber || 0) + ')'"
                *nbIsGranted="['view', resourceTab.relationship]">
                <ng-template matTabContent>
                    <app-profile-relationship-tab (callBackRefresh)="reloadProfile($event)"
                        [profileId]="profileModel.profileId" [isOpenDialog]="openByDialog"
                        [readonlyProfile]="readonlyProfile" [tooltipProp]="tooltipProp"
                        [isLockedProfile]="isLockedProfile">
                    </app-profile-relationship-tab>
                </ng-template>
            </mat-tab>
            <mat-tab *nbIsGranted="['view', resourceTab.proposal]">
                <ng-template mat-tab-label>
                    Proposal
                    <app-button-add-proposal [profileId]="profileModel.profileId"
                        (onRefresh)="profileProposal ? profileProposal.refreshData($event) : null"></app-button-add-proposal>
                </ng-template>
                <ng-template matTabContent>
                    <app-proposal-tab #profileProposal [artistId]="profileModel.profileId" [actGroupId]="profileModel.actGroupId"></app-proposal-tab>
                </ng-template>
            </mat-tab>
            <!-- 2022-05-17 gnguyen start add -->
            <mat-tab label="More Info" *nbIsGranted="['view', resourceTab.more_info]">
                <ng-template matTabContent>
                    <app-extend-data [profileId]="profileModel.profileId" [extendData]="profileModel?.extendData"
                        (onRefresh)="resetContacts()">
                    </app-extend-data>
                </ng-template>
            </mat-tab>

            <!-- 2022-05-17 gnguyen end add -->
        </mat-tab-group>
        <ng-template #flagTrue>
            <mat-tab-group mat-stretch-tabs animationDuration="0ms" #tabGroup (selectedIndexChange)="contactTab = null"
                (selectedTabChange)="tabChanged($event)" [selectedIndex]="2">
                <mat-tab label="General" *nbIsGranted="['view', resourceTab.general]">
                    <ng-template matTabContent>
                        <app-general-tab [data]='profileModel' [readonlyProfile]="readonlyProfile"></app-general-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab #tabIndexReasonBio label="Reasons & Bio" *nbIsGranted="['view', resourceTab.reason_bio]">
                    <ng-template matTabContent>
                        <app-bio-n-reason [id]='profileModel.profileId' [readonlyProfile]="readonlyProfile">
                        </app-bio-n-reason>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Contacts">
                    <ng-template mat-tab-label>
                        Contact ({{profileModel?.profileAdditionDetail?.totalContact || 0}})
                        <app-add-contact-button [id]="id" [type]="'Profile'" [isActivePrimary]="true"
                            (onRefresh)="contactTab ? contactTab.refreshData(true) : ''; isHasPrimaryContact ? '': refreshData(); resetContacts()"
                            (refreshDataValue)="onRefresh($event)">
                        </app-add-contact-button>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-contact-relationship-grid #contactGrid [relationshipId]="id" [relationshipType]="'Profile'"
                            [formatData]="formatContactType" [isHidePrimaryContact]="false"
                            (refreshDataValue)="onRefresh($event)" (getPrimaryContactRelationship)="updatePrimaryContact($event);
                                resetContacts()" [maxHeight]="'60vh'">
                            <!-- (refreshParentData)="refreshData()" -->
                            <!-- (getPrimaryContactRelationship)="updatePrimaryContact($event)" -->
                        </app-contact-relationship-grid>
                    </ng-template>
                </mat-tab>
                <mat-tab label="More Locations" *nbIsGranted="['view', resourceTab.locations]">
                    <ng-template mat-tab-label>
                        Locations
                        <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                            (throttleClick)="openLocationDialog()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-location-grid #locationGrid [relationshipId]="id" maxHeight="60vh"
                            [relationshipType]="'SALEPROFILE'" (refreshParentData)="refreshData()" [crmMode]="crmMode"
                            [isUseLocationMajorMockup]="true">
                        </app-location-grid>
                    </ng-template>
                </mat-tab>
                <!-- <mat-tab label="Locations" *nbIsGranted="['view', resourceTab.locations]">
                    <ng-template matTabContent>
                        <app-location-tab [profileDetails]="profileModel" [readonlyProfile]="readonlyProfile">
                        </app-location-tab>
                    </ng-template>
                </mat-tab> -->
                <mat-tab #tabIndexMedia label="Multimedia" *nbIsGranted="['view', resourceTab.multimedia]">
                    <ng-template matTabContent>
                        <app-multi-media [profileId]="profileModel.profileId" (refreshData)="refreshData()"
                            [primaryImage]="profileModel.primaryImage" [primaryVideo]="profileModel.primaryVideo"
                            [readonlyMultiMedia]="readonlyProfile" [displayName]="profileModel.displayName">
                        </app-multi-media>
                    </ng-template>
                </mat-tab>
                <mat-tab #tabIndexSeo label="SEO" *nbIsGranted="['view', resourceTab.seo]">
                    <ng-template matTabContent>
                        <app-seo-tab [profileId]="profileModel.profileId" [isOpenDialog]="openByDialog"
                            [displayName]="profileModel.displayName" [readonlySEO]="readonlyProfile"
                            [isDuplicate]="isDuplicate" [seoInfoId]="seoInfoId" [tooltipProp]="tooltipProp">
                        </app-seo-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Note" *nbIsGranted="['view', 'table-note-profile']">
                    <ng-template mat-tab-label>
                        Note
                        <button mat-icon-button color="primary" debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                        referenceId: id,
                        referenceType: 'SALEPROFILE',
                        referenceName: profileModel.displayName
                    })">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-note-tab #gridNoteTab [id]="id" [referenceType]="'SALEPROFILE'" [resource]="resource"
                            (editChange)="noteService.pushNoteDetails($event.row, profileModel.displayName)"
                            (deletedChange)="overlayNoteDetails ? overlayNoteDetails.deleteNoteFromOutSide($event) : ''; "
                            [nbSpinner]="overlayNoteDetails?.isLoading | async">
                        </app-note-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Activities" *nbIsGranted="['view', resourceTab.activities]">
                    <ng-template mat-tab-label>
                        Activities
                        <button mat-icon-button color="primary" appAddActivityLogBtn [id]="profileModel.profileId"
                            type="PROFILE">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-activity-tab [profileId]="profileModel.profileId" [profileModel]="profileModel"
                            [tooltipProp]="tooltipProp"></app-activity-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    [label]="'Relationship ' + '(' + (('TotalRelationship' | findItem: generalCount : 'typeName')?.totalNumber || 0) + ')'"
                    *nbIsGranted="['view', resourceTab.relationship]">
                    <ng-template matTabContent>
                        <app-profile-relationship-tab #tabRelationship [profileId]="profileModel.profileId"
                            [isOpenDialog]="openByDialog" [readonlyProfile]="readonlyProfile"
                            [tooltipProp]="tooltipProp">
                        </app-profile-relationship-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab *nbIsGranted="['view', resourceTab.proposal]">
                    <ng-template mat-tab-label>
                        Proposal
                        <app-button-add-proposal [profileId]="profileModel.profileId"
                            (onRefresh)="profileProposal ? profileProposal.refreshData($event) : null"></app-button-add-proposal>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-proposal-tab #profileProposal [artistId]="profileModel.profileId"></app-proposal-tab>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-template>
    </div>
</ng-template>

<ng-template #bodyAutoDataStateLog>
    <app-automate-datastate-log [profileId]="profileModel.profileId ?? id" [profileModel]="profileModel"
        [readonlyProfile]="readonlyProfile" [tooltipProp]="tooltipProp" [isLockedProfile]="isLockedProfile">
    </app-automate-datastate-log>
</ng-template>

<ng-template #blankLastNote>
    <app-blank-page fxFlex="auto" [linkSrc]="'assets/images/notetaking.gif'"
        [message]="'Do you wish to add a new note?'" [contentTemplate]="contentEmptyLastNote">
    </app-blank-page>
</ng-template>

<ng-template #contentEmptyLastNote>
    <span class="mb-2"><strong>Do you wish to add a new note?</strong></span>
    <button class="mb-2" mat-stroked-button color="primary" (throttleClick)="noteService.pushNoteDetails({
      referenceId: id,
      referenceType: 'SALEPROFILE',
      referenceName: profileModel.displayName
  })" debouncedClick [throttleTime]="500">
        Add new
        <mat-icon>add</mat-icon>
    </button>
</ng-template>


<ng-template #noAutomateBody>
    <div class="opacity-scroll" [ngStyle]=" isAutomateDataState && currentUrl.startsWith('/configuration/profile')
    ? {'overflow': 'auto', 'height': '100vh'}
    : {'overflow': 'auto', 'height': '100%'}">
        <ng-container [ngTemplateOutlet]="bodyProfile"></ng-container>
    </div>
</ng-template>

<ng-template #copied>
    <span class="copied">
        Copied!!
    </span>
</ng-template>


<ng-template #addFilesMenu class="column d-flex flex-column align-items-start">
    <input #fileInput hidden="true" type="file" onclick="this.value =null" (change)="addProfileFile($event)" />
    <!-- [extension]='{"extensions":["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"]}'
        accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.html,.zip,.csv,.txt" /> -->

    <button mat-flat-button class="col float-start" debouncedClick [throttleTime]="500" matTooltipPosition="below"
        (throttleClick)="fileInput.click()">
        <mat-icon style="color: #007100;">add</mat-icon> Add New Files
    </button>

    <hr class="custom-divider">
    <button mat-flat-button class="col d-flex flex-row" debouncedClick [throttleTime]="500" matTooltipPosition="below"
        (click)="openURLDialogRef()">
        <mat-icon style="color: #007100;">add</mat-icon> Add URL
    </button>
</ng-template>

<ng-template #emailRelationship>
    <nb-card class="nb-card-contact">
        <nb-card-header style="background-color: #307abd;color: white;text-align: center;">
            Potential duplicate data
        </nb-card-header>
        <nb-card-body class="body-card-contact">
            <div *ngFor="let item of listEmailRelationship">
                <div class="email-details" (click)="openProfileRelationshipDetail(item)">
                    <div class="container-display-name" fxFlex>
                        {{item?.displayName | truncateText: [27,'...']}}
                    </div>
                    <div class="container-type-name">
                        <button mat-flat-button class="type-name-detail" style="font-size: xx-small;"
                            [ngStyle]="{'background-color':item?.colorCode}">{{item?.displayTypeName
                            | truncateText: [15,'...']}}</button>
                    </div>
                    <!-- <button mat-flat-button style="line-height: 25px;color: white;"
                            [ngStyle]="{'background-color':event.eventInfo.task?.taskStatus.colorCode}"
                            matTooltip="{{event.eventInfo.task?.taskStatus?.taskStatusName}}">{{event.eventInfo.task?.taskStatus?.taskStatusName
                            | truncateText: [20,'...']}}</button> -->
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</ng-template>

<ng-template #SEOAuditTemp>
    <div style="max-height: 40vh; max-width: 420px;">
        <app-seo-audit [id]="id" [focusSEOAudit]="focusSEOAuditElement" [focusProp]="focusSEOAudit"
            (overviewScore)="primarySeo?.seoInfo?.overallScore ? primarySeo.seoInfo.overallScore = $event: ''">
        </app-seo-audit>
        <hr>
    </div>
</ng-template>

<ng-template #AICheckerTemplate>
    <div style="max-height: 40vh; max-width: 560px;">
        <app-ai-checker [id]="id" [type]="'Profile'" [focusAIDetect]="focusAICheckElement"
            (aiScore)="getAIScore($event)" (orgScore)="getOrgScore($event)"
            (isFinishDetection)="onAIDetectionChanged($event)">
        </app-ai-checker>
        <hr>
    </div>
</ng-template>

<ng-template #buttonIconLoader>
    <ngx-skeleton-loader count="1" animation="progress" appearance="circle"></ngx-skeleton-loader>
</ng-template>

<ng-template #pendingRevision></ng-template>

<ng-template #publishCheckbox>
    <mat-checkbox class="mt-3" color="primary" [(ngModel)]="isPublishNow">Publish Immediately</mat-checkbox>
</ng-template>

<ng-template #publishRevisionLabelLoader>
    <div class="w-100">
        <ngx-skeleton-loader count="2" animation="progress" [theme]="{ margin: '0' }"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #revisionDetectChangeLoader>
    <div class="w-100">
        <ngx-skeleton-loader count="2" animation="progress"
            [theme]="{ margin: '0', height: '10px' }"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #statusChangeLoader>
    <div style="width: 40px;">
        <ngx-skeleton-loader count="1" animation="progress" [theme]="{ margin: '0' }"></ngx-skeleton-loader>
    </div>
</ng-template>
<ng-template #lockCheckbox>
    <mat-checkbox class="mt-3" color="primary" [(ngModel)]="lockProfileImmediately">Lock this profile</mat-checkbox>
</ng-template>

<ng-template #rejectReasonTemplate let-row="row">
    <mat-form-field appearance="outline" class="mt-3" style="width: 400px;">
        <mat-label>Reject Reason</mat-label>
        <textarea matInput cdkTextareaAutosize [(ngModel)]="row.description" placeholder="Reject Reason"
            cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
    </mat-form-field>
</ng-template>

<ng-template #aiDetectBtnLoading>
    <div style="width: 24px;">
        <ngx-skeleton-loader count="1" animation="progress" [theme]="{ margin: '0' }"></ngx-skeleton-loader>
    </div>
</ng-template>