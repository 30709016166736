import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { PrimasTableComponent } from 'src/app/shared/components/template-management/primas-table/primas-table.component';
import { permissionSeoInfo } from 'src/app/shared/contances/permission';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { DatastateManagementService } from '../../datastate-management/datastate-management.service';
import { takeUntil } from 'rxjs/operators';
import { CategoryManagementService } from '../category-management.service';
import { FfcUrlColor } from '../../profile-management/profile-detail/seo-tab/seo-info/seo-info.component';

@Component({
  selector: 'app-seo-category-history',
  templateUrl: './seo-category-history.component.html',
  styleUrls: ['./seo-category-history.component.scss']
})
export class SeoCategoryHistoryComponent implements OnInit {
  @ViewChild('primasTable', { static: true }) primasTable: PrimasTableComponent;
  @ViewChild('status', { static: true }) status: TemplateRef<any>;

  columns = []
  resource = permissionSeoInfo;
  categoryId: string;
  ffcUrlColor = FfcUrlColor;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private categoryService: CategoryManagementService,
    private toastr: NbToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateFormat: DateTimeFormatPipe,
    public automatService: DatastateManagementService) {

    if (this.data) {
      this.categoryId = this.data.categoryId;
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.columns = [
      {
        name: 'Status',
        prop: 'status',
        filter: false,
        sortable: false,
        cellTemplate: this.status
      },
      {
        name: 'Friendly Url',
        prop: 'seoInfo.friendlyUrl',
        filter: false,
        sortable: false
      },
      {
        name: 'Date Created',
        prop: 'seoInfo.dateCreated',
        filter: false,
        sortable: false,
        pipe: this.dateFormat
      },
    ]
    this.primasTable.page.pageNumber = 1;
  }

  async refreshData(reset: boolean = false, data?: any) {
    let offSetX;
    this.primasTable.isLoading = 1;
    if (reset) {
      this.primasTable.page.pageNumber = 0;
      this.primasTable.cache = {};
      this.primasTable.rows = [];
      this.primasTable.selected = [];
      this.primasTable.tableAction = false;
      this.primasTable.table.offset = 0;
      this.primasTable.table.bodyComponent._offset = 0;
      offSetX = 0;
    }
    this.primasTable.isLoading = 1;
    let pageRes = await this.categoryService.getSeoCategoryByCategoryId(this.primasTable.page, this.categoryId).toPromise();

    this.primasTable.setData(pageRes.result);
    setTimeout(() => {
      this.primasTable.table.recalculate();
      this.primasTable.table['cd'].markForCheck();
      document.body.style.width = 'auto';
      this.primasTable.table._offsetX.next(offSetX);
    }, 200);
    this.primasTable.isLoading = 0;
  }
}
