import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-blank-page',
  templateUrl: './blank-page.component.html',
  styleUrls: ['./blank-page.component.scss']
})
export class BlankPageComponent implements OnInit {

  constructor() { }
  @Input() iconSize = '120px';
  @Input() message = 'This page is Empty';
  @Input() messageSize: string;
  @Input() linkSrc: string = "assets/images/empty.gif";
  @Input() contentTemplate: TemplateRef<any> = null;

  ngOnInit() { }
}
