<div class="d-flex flex-wrap align-items-center mb-0" mat-dialog-title>
  Call Detail (to: {{((contactActionLog.logInfo1 | jsonParse)?.outboundPhoneNumber || 'Unknown') | phoneFormat}})
  <div fxLayout="row" fxLayoutAlign="space-between center" class="ml-auto">
    <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div fxLayout="column" class="mb-2">
  <p class="mb-0"><b><i>{{contactActionLog?.dateCreated | date: 'EEEE, MMMM dd, y, hh:mm a'}}</i></b></p>
  <p class="mb-0"><b><i>{{phoneCallDuration}}</i></b></p>
</div>
<mat-dialog-content class="border border-left-0 border-right-0 pt-4">
  <ng-container *ngIf="!isLoading; else loading">
    <div class="list-event">
      <ng-container *ngFor="let data of listCallEvents; let i = index" [ngTemplateOutlet]="callEvent"
        [ngTemplateOutletContext]="{callEvent: data}"></ng-container>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="closeDialog()">Close</button>
</mat-dialog-actions>
<ng-template #loading>
  <ngx-skeleton-loader count="7" appearance="line"></ngx-skeleton-loader>
</ng-template>


<ng-template #callEvent let-callEvent="callEvent">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="call-status my-1" [ngClass]="{
    'agent-no-answer': callEvent.callEvent === 'AGENT_NO_ANSWER',
    'agent-busy': callEvent.callEvent === 'AGENT_BUSY',
    'agent-answered': callEvent.callEvent === 'AGENT_ANSWERED',
    'no-make-call': callEvent.callEvent === 'NO_MAKE_CALL',
    'no-answer': callEvent.callEvent === 'NO_ANSWER',
    'answered': callEvent.callEvent === 'ANSWERED',
    'busy': callEvent.callEvent === 'BUSY',
    'unavailable': callEvent.callEvent.includes('UNAVAILABLE'),
    'congestion': callEvent.callEvent.includes('CONGESTION'),
    'call-ended': callEvent.callEvent === 'CALL_ENDED',
    'leave-voicemail': callEvent.callEvent === 'LEAVE_VOICEMAIL'
  }">
    <div fxFlex="auto" class="d-flex p-3 justify-content-start align-items-center">
      <p class="mb-0 text-wrap">
        <b>{{callEvent.callEvent}}</b>
      </p>
    </div>
    <div fxFlex="150px">{{callEvent.dateCreated | date: 'MM/dd/yy, hh:mm a'}}</div>
  </div>
</ng-template>