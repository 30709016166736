import { SettingCompanyViewModel } from 'src/app/modules/admin/setting-management/setting-company/setting-company';
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { SettingService } from "./setting.service";
import { Setting } from "../models/setting.model";
import { ReturnResult } from "../models/return-result";

@Injectable({
  providedIn: 'root'
})
export class SettingPoolService {

  constructor(private settingService: SettingService) {
  }

  async getAllSettingAllow(callbackFunction = async () => {}) {
    let isSettingExist = window.localStorage.getItem('allSetting');
    if (isSettingExist) {
      await callbackFunction();
    }
    else {
      try{
        var settingAllow = await this.settingService.getAllSettingAllow().toPromise();
        if (settingAllow && settingAllow.result) {
          let returnData = settingAllow.result;
          returnData = returnData.map(setting => {
            return {
              ...setting,
              settingLevel: undefined,
            }
          })
          window.localStorage.setItem('allSetting', JSON.stringify(returnData));

          await callbackFunction();
        }
        else {
          console.log("Fetching all Setting Fail...");
        }
      }
      catch (error){
        console.log(error);
      }
    }
  }

  async getConfigCompany() {
    let dataReturn: SettingCompanyViewModel = new SettingCompanyViewModel();
    try {
      this.getAllSettingAllow(async () => {
        let listSettingAllowed = window.localStorage.getItem('allSetting');
        let listSettings = JSON.parse(listSettingAllowed) as Setting[];
        listSettings = listSettings?.filter(setting => (setting.group === 'COMPANY'))

        if (listSettings) {
          dataReturn.companyName = this.getSettingValueClientSide(listSettings, 'COMPANY_NAME');
          dataReturn.themeColor = this.getSettingValueClientSide(listSettings, 'THEME_COLOR');
          dataReturn.urlCompanyBackGround = this.getSettingValueClientSide(listSettings, 'COMPANY_BACKGROUND');
          dataReturn.urlCompanyLogo = this.getSettingValueClientSide(listSettings, 'COMPANY_LOGO');
          dataReturn.urlCompanyLogoPortal = this.getSettingValueClientSide(listSettings, 'COMPANY_LOGO_PORTAL');
          dataReturn.urlDefaultAvatar = this.getSettingValueClientSide(listSettings, "DEFAULT_AVATAR"),
          dataReturn.urlCompanyFavicon = this.getSettingValueClientSide(listSettings, "COMPANY_FAVICON")

          dataReturn.urlCompanyBackGround = (dataReturn.urlCompanyBackGround != null) ? dataReturn.urlCompanyBackGround?.replace(/\\/g, '/') : null;
          dataReturn.urlCompanyLogoPortal = (dataReturn.urlCompanyLogoPortal != null) ? dataReturn.urlCompanyLogoPortal?.replace(/\\/g, '/') : null;
          dataReturn.urlCompanyLogo = (dataReturn.urlCompanyLogo != null) ? dataReturn.urlCompanyLogo?.replace(/\\/g, '/') : null;
          dataReturn.urlDefaultAvatar = (dataReturn.urlDefaultAvatar != null) ? dataReturn.urlDefaultAvatar?.replace(/\\/g, '/') : null;
        }
        else {
          try{
            dataReturn = await this.settingService.getConfigCompany().toPromise();
          }
          catch (error){
            console.log("Fetch config company setting failed");
          }
        }
      })
    }
    catch (error) {
      console.log(error)
      console.log("Get ConfigCompany");

      dataReturn = await this.settingService.getConfigCompany().toPromise();
    }

    return of(dataReturn);
  }

  getSettingByKeyAndGroup(key: string, group: string): Observable<ReturnResult<Setting>> {
    // check null [x]
    // call setting get data
    // try catch parse setting [x]
    // log key and group value [x]
    let dataReturn;
    try {
      this.getAllSettingAllow(async () => {
        let listSettingAllowed = window.localStorage.getItem('allSetting');
        let listSettings = JSON.parse(listSettingAllowed) as Setting[];
        listSettings = listSettings?.filter(setting => (setting.group === group && setting.key === key));
        if (listSettings) {
          let returnResult : ReturnResult<Setting> = new ReturnResult<Setting>();
          returnResult.result = listSettings[0];
          dataReturn = of(returnResult);
        }
        else {
          dataReturn = this.settingService.getSettingByKeyAndGroup(key, group).subscribe(setting => {
            return of((setting) ? setting : null)
          })
        }
      });
    }
    catch (error) {
      console.log(error)
      console.log("Query: Key ", key, " and Group ", group);

      dataReturn = this.settingService.getSettingByKeyAndGroup(key, group).subscribe(setting => {
        return of((setting && setting.result) ? setting.result : null)
      })
    }

    return dataReturn;
  }

  getSettingByGroupName(group: string){
    let dataReturn;
    try {
      this.getAllSettingAllow(async () => {
        let listSettingAllowed = window.localStorage.getItem('allSetting');
        let listSettings = JSON.parse(listSettingAllowed) as Setting[];
        listSettings = listSettings?.filter(setting => (setting.group === group));
        if (listSettings) {
          let returnResult : ReturnResult<Setting[]> = new ReturnResult<Setting[]>();
          returnResult.result = listSettings;
          dataReturn = of(returnResult);
        }
        else {
          dataReturn = this.settingService.getSettingByGroupName(group).subscribe(setting => {
            return of((setting) ? setting : null)
          })
        }
      });
    }
    catch (error) {
      console.log(error)
      console.log("Query: Group ", group);

      dataReturn = this.settingService.getSettingByGroupName(group).subscribe(setting => {
        return of((setting && setting.result) ? setting.result : null)
      })
    }

    return dataReturn;
  }

  private getSettingValueClientSide(settingList: Setting[], key: string) {
    let setting = settingList.find(setting => setting.key === key);
    return (setting) ? setting.value : null;
  }
}
