<!-- <div appChangeUrlObjects [id]="id" [isOpenDialog]="openByDialog" [topic]="'sale-account'" [name]="saleAccountModel?.displayName"></div> -->
<mat-chip-list></mat-chip-list>
<ng-container *ngIf="isShowButton">
    <div class="d-flex flex-wrap align-items-space-between" mat-dialog-title [nbSpinner]="isLoadingEdit"
        style="margin: unset;padding: 8px 10px 1px 28px;">
        <div [hidden]="isLoading" class="d-flex flex-wrap" class="d-flex flex-wrap" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <mat-icon style="transform: scale(1.5);" [ngStyle]="{'color':saleAccountColorCode.SaleAccount}">
                    account_circle</mat-icon>
            </div>
            <div class="entity-name-id">
                <span class="entity-name" fxLayout="row" fxLayoutAlign="space-around center">
                    {{environment.titleAccount}}
                </span>
                <span *ngIf="!isCopy;else copied"
                    [matTooltip]="'Click to copy this '+ environment.titleAccount +' ID: '+ id" class="entity-id"
                    (click)="copyGUIDToClipboard()" class="underline-id">#{{id | truncateText:[6,
                    '...']}}</span>
            </div>
            <mat-divider [vertical]="true">
            </mat-divider>

        </div>
        <div class="ml-auto" fxLayout="row">
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button class="ml-auto float-start" matTooltipPosition="left"
                    [matTooltip]="'Copy this ' + environment.titleAccount + ' url to clipboard'"
                    (click)="copyToClipboard()">
                    <mat-icon style="transform: scale(0.9);">
                        share</mat-icon>
                </button>
                <mat-divider [vertical]="true" style="height:100%">
                </mat-divider>
            </div>
            <app-next-or-previous-detail #btnNextOrPre>
            </app-next-or-previous-detail>
            <button *ngIf="openByDialog" mat-icon-button matTooltip="Close"
                (click)="closeDialog()">
                <mat-icon style="color: red;">close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
</ng-container>

<mat-dialog-content style="max-height: 100vh" *ngIf="isLoading; then skeleton else bodyDialog"></mat-dialog-content>

<!--#region ng-template-->
<ng-template #bodyDialog>
    <div *ngIf="saleAccountModel; else notExistedById" appDetectTabMode [typeElement]="'BodyDetail'" fxLayout="column"
        class="body-detail-container px-4" [ngClass]="!openByDialog ? 'embed-parent' : ''">
        <div fxLayout="row" fxLayoutAlign="start start">
            <div *ngIf="data && data.advancedImport" style="width:224px;">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px"
                    *ngIf="!data.externalTemplate; else externalTemplate" style="width:200px;padding-right: 24px;">
                    <button mat-menu-item (click)="onClickAdvanceImport('Add')" [matTooltip]="'Create new entry'">
                        <mat-icon style="color:#28a745">add</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onClickAdvanceImport('Update')"
                        [matTooltip]="'Update existing entry'">
                        <mat-icon color="primary">edit</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onClickAdvanceImport('Skip')" [matTooltip]="'Skip Entry'">
                        <mat-icon style="color: red;">close</mat-icon>
                    </button>
                </div>
                <div style="overflow:auto; height: 80vh;width:200px;">
                    <div *ngFor="let item of data.importColumn" style=" margin-right: 24px;">
                        <div class="import-advanced-label">
                            {{item.name}}:
                        </div>
                        <div [matTooltip]="item.value" style="white-space: nowrap;">
                            {{item.value || 'No data display'}}
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
            <div fxFlexFill class="content-container">
                <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-3">
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'account_circle', toolTip: environment.titleAccount + ' Name',
                property: 'displayName',
                value: saleAccountModel.displayName,
                typeModel: TypeEditModelSaleAccount.SaleAccount,
                validProp: validProperties.accountName
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="accountOwner" [ngTemplateOutletContext]="{}"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'phone', toolTip: 'Phone',
                property: 'contactPhone',
                value: saleAccountModel.saleLeadsContact?.contactPhone | phoneFormat,
                typeModel: TypeEditModelSaleAccount.Contact,
                observable :dynamicContentPhoneNumber,
                searchProp: 'content',
                searchArray: [],
                suffixFunc: resetContacts
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'alternate_email', toolTip: 'Email',
                property: 'contactEmail',
                value: saleAccountModel.saleLeadsContact?.contactEmail,
                typeModel: TypeEditModelSaleAccount.Contact,
                validProp: validProperties.contactEmail,
                observable :dynamicContentEmail,
                searchProp: 'content',
                searchArray: [],
                suffixFunc: resetContacts,
                isAscii: true
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="!isLoadingEdit ? searchEditInline : loadingInline"
                        [ngTemplateOutletContext]="{
                icon: 'supervised_user_circle', toolTip: 'Parent ' + environment.titleAccount,
                property: 'Relationship',
                value: saleAccountModel?.profileAdditionDetail?.primaryRelationshipName,
                apiSearching: apiGetSearchRelationship,
                func: savePrimaryResellerOrRelationship
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'language', toolTip: 'Website',
                property: 'webPage',
                value: saleAccountModel.webPage,
                typeModel: TypeEditModelSaleAccount.SaleAccount,
                isAccess: true,
                observable :dynamicContentWebsite,
                searchProp: 'content',
                searchArray: []
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'factory', toolTip: 'Industry',
                property: 'industry',
                value: saleAccountModel.industry,
                typeModel: TypeEditModelSaleAccount.SaleAccount,
                observable :dynamicContentIndustryAPI,
                searchProp: 'content',
                searchArray: []
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'paid', toolTip: 'Annual Revenue',
                property: 'annualRevenue',
                value: saleAccountModel.annualRevenue,
                typeModel: TypeEditModelSaleAccount.SaleAccount,
                validProp: validProperties.annualRevenue,
                pipe: numberPipe,
                prefix: prefixCurrency
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'compress', toolTip: 'Channel',
                property: 'channel',
                value: saleAccountModel.profileAdditionDetail?.channel,
                typeModel: TypeEditModelSaleAccount.SaleAccount,
                observable :undefined,
                searchProp: 'value',
                searchArray: channelList,
                addition: true
            }"></ng-container>
                    <!-- primary reseller -->
                    <!-- <ng-container [ngTemplateOutlet]="!isLoadingEdit ? searchEditInline : loadingInline"
                        [ngTemplateOutletContext]="{
                icon: 'person', toolTip: 'Primary Reseller',
                property: 'Reseller',
                value: saleAccountModel?.profileAdditionDetail?.primaryResellerName,
                apiSearching: apiGetSearchRelationship,
                func: savePrimaryResellerOrRelationship
            }"></ng-container> -->
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'domain', toolTip: 'State/Province',
                property: 'state',
                value: saleAccountModel.saleLeadsContact?.location?.state,
                typeModel: TypeEditModelSaleAccount.Location,
                observable :dynamicContentStateAPI,
                searchProp: 'content',
                searchArray: [],
                suffixFunc: resetContacts
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'place', toolTip: 'Country',
                property: 'country',
                value: saleAccountModel.saleLeadsContact?.location?.country,
                typeModel: TypeEditModelSaleAccount.Location,
                observable :dynamicContentCountryAPI,
                searchProp: 'content',
                searchArray: [],
                suffixFunc: resetContacts
            }"></ng-container>
                    <!-- <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'people_alt', toolTip: 'Employees',
                property: 'noOfEmployee',
                value: saleAccountModel.noOfEmployee,
                typeModel: TypeEditModelSaleAccount.SaleAccount,
                validProp: validProperties.employees,
                pipe: numberPipe
            }"></ng-container>
            <ng-container [ngTemplateOutlet]="actionButtonTemplate" [ngTemplateOutletContext]="{}">
            </ng-container> -->
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div fxFlex="48" fxFlex.lt-md="100" fxLayout="column" class="pr-2">
                        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                            Description
                        </span>
                        <h6 fxLayout="row" fxLayoutAlign="start start" class="h6-custom" matTooltip="Description"
                            matTooltipPosition="left">
                            <span fxFlex="30px" class="material-icons-outlined blue-color" style="font-size: 24px;">
                                description
                            </span>
                            <span fxFlex="1 1 calc(100%-30px)" class="hover-popup-editor limit-line" style="--line: 2;"
                                (click)="openComponentEditor('saleLeadsContact', 'description')">
                                {{ convertHtmlToNormalStyle(saleAccountModel.saleLeadsContact?.description
                                ?? 'Click to add description') || 'Click to add description' }}
                            </span>
                        </h6>
                        <!-- old multi select dropdown -->
                        <!-- <app-multi-select-drop-down #saleAccountType class="border-select-type" [readonly]="false"
                    [inputValues]="getContactType" [selectedValues]="selectType" [placeholder]="'Please select type...'"
                    [isMatChip]="true" [maxHeight]="'2.25rem'" matTooltip="Sale account type" matTooltipPosition="left"
                    (selected)="editSaleAccountType($event)">
                </app-multi-select-drop-down> -->
                        <div class="account-type-layout" fxLayout="row" fxLayoutAlign="space-between center">
                            <app-dropdown-searching #parentContactTypeSearch
                                [placeholder]="environment.titleAccount + ' Type'" [DisplayAsChip]="true"
                                [(dropDownList)]="parentContactTypeAsDropdownModel"
                                (emitData)="editNewSingleContactType($event,'parent')"
                                [(selectedValue)]="selectedContactTypeParent" [showNoneOption]="true">

                            </app-dropdown-searching>
                            <ng-container
                                *ngIf="!(!selectedContactTypeParent || subContactTypeAsDropdownModel.length == 0)">
                                <app-dropdown-searching #childContactTypeSearch [DisplayAsChip]="true"
                                    [placeholder]="(!selectedContactTypeParent || subContactTypeAsDropdownModel.length == 0)?'No Sub Type available':'Sub Type'"
                                    [(dropDownList)]="subContactTypeAsDropdownModel"
                                    [(selectedValue)]="selectedContactTypeChild"
                                    (emitData)="editNewSingleContactType($event,'child')"
                                    [disable]="!selectedContactTypeParent || subContactTypeAsDropdownModel.length == 0"
                                    [showNoneOption]="true">
                                </app-dropdown-searching>
                            </ng-container>
                        </div>
                        <div class="border-tags mt-3">
                            <app-auto-complete-tag [(tagsList)]="saleAccountModel.tagList" [profileId]="id"
                                [(profileDetail)]="saleAccountModel" [readonlyTags]="false" matTooltip="Tags"
                                matTooltipPosition="left" (onChange)="refreshData()">
                            </app-auto-complete-tag>
                        </div>
                    </div>

                    <div class="area-last-note" fxFlex="52" fxFlex.lt-md="100" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <div fxFlex="100" fxFlex.lt-md="auto" fxFlexOrder="1" fxFlexOrder.lt-md="2" fxLayout="row wrap"
                            fxLayoutAlign="start end">
                            <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                        icon: 'people_alt', toolTip: 'Employees',
                        property: 'noOfEmployee',
                        value: saleAccountModel.noOfEmployee,
                        typeModel: TypeEditModelSaleAccount.SaleAccount,
                        validProp: validProperties.employees,
                        pipe: numberPipe,
                        flexArea: 46
                    }"></ng-container>
                            <ng-container [ngTemplateOutlet]="actionButtonTemplate" [ngTemplateOutletContext]="{}">
                            </ng-container>
                        </div>
                        <ng-container *ngIf="lastNote; else blankLastNote">
                            <nb-card class="body-last-note-card" fxFlex="100" fxFlex.lt-md="72" fxFlex.lt-sm="60"
                                fxFlexOrder="2" fxFlexOrder.lt-md="1"
                                [nbSpinner]="overlayNoteDetails?.isLoading | async"
                                (click)="noteService.pushNoteDetails(lastNote, saleAccountModel.displayName)">
                                <nb-card-header fxLayout="row" fxLayoutAlign="space-between center"
                                    style="padding: 0 1rem; background-color: #f5da65;">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <button mat-icon-button debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                                            referenceId: id,
                                            referenceType: 'SALEACCOUNT',
                                            referenceName: saleAccountModel.displayName
                                        })">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                        <nb-user *ngIf="lastNote.ownerId" [onlyPicture]="true" color="#8f9bb37a"
                                            [matTooltip]="lastNote.user?.userName" [name]="lastNote['ownerNote']"
                                            [picture]="lastNote['avatarNote']" size="small">
                                        </nb-user>
                                        <span class="limit-line" style="--line: 1;" [matTooltip]="lastNote.title"
                                            matTooltipPosition="left">
                                            Last note: {{lastNote.title}}
                                        </span>
                                    </div>
                                    <span fxFlex="20" class="limit-line"
                                        style="--line: 1; width: auto; font-weight: normal; text-align: end;"
                                        matTooltip="Last created" matTooltipPosition="left">
                                        {{ (lastNote.dateCreated | date: 'short') || 'None' }}
                                    </span>
                                </nb-card-header>
                                <nb-card-body style="background-color: #fef9b7;" [innerHTML]="lastNote.note">
                                    <!-- {{lastNote.innerTextNote}} -->
                                </nb-card-body>
                            </nb-card>
                        </ng-container>
                    </div>
                </div>
                <mat-tab-group #tabGroup mat-stretch-tabs animationDuration="0ms" [selectedIndex]="selectTab"
                    (selectedIndexChange)="cleanComponentTab(); tabIndexChanged($event)">
                    <mat-tab label="Contact">
                        <ng-template mat-tab-label>
                            Contact ({{(saleAccountModel?.profileAdditionDetail?.totalContact > 0) ? (saleAccountModel?.profileAdditionDetail?.totalContact - 1) : 0 || 0}})
                            <app-add-contact-button #contactButton [id]="id" [type]="'Profile'"
                                [showCampaignButton]="currentTab == 0? true : false"
                                [showCompanyName]="saleAccountModel.displayName"
                                [disableCampaignButton]="contactTab?.primasTable?.rows?.length == 0"
                                (onRefresh)="contactTab ? contactTab.refreshData(true) : ''; resetContacts()"
                                (onClickCampaign)="createContactCampaign()" [showImportButton]="true"
                                (onImport)="onClickImport($event)" typeName="SALEACCOUNT" primaryKey="profileId">
                            </app-add-contact-button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-contact-relationship-grid #contactGrid [relationshipId]="id"
                                [relationshipType]="'Profile'" (getPrimaryContactRelationship)="resetContacts()"
                                (refreshDataValue)="onRefresh($event)">
                                <!-- (getPrimaryContactRelationship)="updatePrimaryContact($event)" -->
                            </app-contact-relationship-grid>
                        </ng-template>
                    </mat-tab>
                    <mat-tab [label]="environment.titleOpportunity" *ngIf="!isHideOpportunity">
                        <ng-template mat-tab-label>
                            {{environment.titleOpportunity}}
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                                (throttleClick)="onAddOpportunityClick()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <!-- <app-sale-account-opportunity-tab #opportunityTab [saleAccountId]="id">
                    </app-sale-account-opportunity-tab> -->
                            <app-tab-opportunity-grid #opportunityTab [accountId]="id">
                            </app-tab-opportunity-grid>
                        </ng-template>
                    </mat-tab>
                    <!-- <mat-tab label="Location">
                <ng-template matTabContent>
                    <app-sale-account-location-tab [id]="id" [locations]="saleAccountModel.profileLocations">
                    </app-sale-account-location-tab>
                </ng-template>
            </mat-tab> -->
                    <!-- <mat-tab label="Reseller" *nbIsGranted="['view', 'table-reseller-account-tab']">
                        <ng-template mat-tab-label>
                            Reseller
                            <button *nbIsGranted="['view', 'add-reseller-account-tab']" mat-icon-button color="primary"
                                debouncedClick [throttleTime]="500"
                                (throttleClick)="openPopupFindingResellerOrRelationship()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-sale-account-reseller-tab #resellerTab [id]="id"
                                (onRefresh)="isLoadingEdit = true; refreshData()"
                                (onSetPrimary)="savePrimaryResellerOrRelationship($event, 'Reseller')">
                            </app-sale-account-reseller-tab>
                        </ng-template>
                    </mat-tab> -->
                    <mat-tab label="More Locations">
                        <ng-template mat-tab-label>
                            More Locations
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                                (throttleClick)="openLocationDialog()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-location-grid #locationGrid [relationshipId]="id" [relationshipType]="'Profile'"
                                (refreshParentData)="refreshData()">
                            </app-location-grid>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Extend Data">
                        <ng-template mat-tab-label>
                            Extend Data
                            <app-add-extend-data-button [id]="id" [extendData]="saleAccountModel?.extendData"
                                (onRefresh)="refreshData()">
                            </app-add-extend-data-button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-sale-account-additional-tab [id]="id" [model]="saleAccountModel"
                                (onRefresh)="refreshData(); resetContacts()">
                            </app-sale-account-additional-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Relationship" *nbIsGranted="['view', 'table-relationship-account-tab']">
                        <ng-template mat-tab-label>
                            Relationship
                            <button *nbIsGranted="['view', 'add-relationship-account-tab']" mat-icon-button
                                color="primary" debouncedClick [throttleTime]="500"
                                (throttleClick)="openPopupFindingResellerOrRelationship(false)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <!-- <app-sale-account-relationship-tab [id]="id" [actGroupId]="saleAccountModel.actGroupId">
                    </app-sale-account-relationship-tab> -->
                            <app-sale-account-relationship-tab-v2 #relationshipTab [id]="id"
                                (onRefresh)="isLoadingEdit = true; refreshData()"
                                (onSetPrimary)="savePrimaryResellerOrRelationship($event, 'Relationship')">
                            </app-sale-account-relationship-tab-v2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Files" *nbIsGranted="['view', 'table-sale-account-file']">
                        <ng-template mat-tab-label>
                            Files
                            <button *nbIsGranted="['view', 'add-profile-file']" #nbFilesBtn="nbPopover" mat-icon-button color="primary" debouncedClick
                                [throttleTime]="500" (throttleClick)="nbPopoverCLick()" [nbPopover]="addFilesMenu" nbPopoverPlacement="left">
                                <mat-icon>add</mat-icon>
                            </button>
                            <!-- <button *nbIsGranted="['view', 'add-sale-account-file']" mat-icon-button color="primary"
                                debouncedClick [throttleTime]="500" (throttleClick)="fileInput.click()">
                                <mat-icon>add</mat-icon>
                            </button> -->
                        </ng-template>
                        <ng-template matTabContent>
                            <app-profile-file-tab #profileFileTab [profileModel]="saleAccountModel"
                                [perm]="fileResource">
                            </app-profile-file-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Note" *nbIsGranted="['view', 'table-note-sale-account']">
                        <ng-template mat-tab-label>
                            Note
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                            referenceId: id,
                            referenceType: 'SALEACCOUNT',
                            referenceName: saleAccountModel.displayName
                        })">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-note-tab #gridNoteTab [id]="id" [referenceType]="'SALEACCOUNT'" [resource]="resource"
                                (editChange)="noteService.pushNoteDetails($event.row, saleAccountModel.displayName)"
                                (deletedChange)="overlayNoteDetails ? overlayNoteDetails.deleteNoteFromOutSide($event) : '';
                        getLastNote($event, true)" [nbSpinner]="overlayNoteDetails?.isLoading | async">
                            </app-note-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Tasks ({{saleAccountModel?.profileAdditionDetail?.totalTask || 0}})
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                                (throttleClick)="createTask()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-task-grid-by-profile-id #taskGrid [profileId]="id" [saleModel]="saleAccountModel" (refreshDataValue)="onRefresh($event)">
                            </app-task-grid-by-profile-id>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Activity Log">
                        <ng-template mat-tab-label>
                            Activity Log
                            <button mat-icon-button color="primary" appAddActivityLogBtn [id]="id" type="SALEACCOUNT">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-activity-tab [profileId]="id" [profileModel]="saleAccountModel"></app-activity-tab>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notExistedById>
    <app-blank-page [message]="'The ' + environment.titleAccount + ' with id ' + id +  ' is not exist or deleted'">
    </app-blank-page>
</ng-template>

<ng-template #skeleton>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="48" count="4" animation="progress"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="48" count="4" animation="progress"></ngx-skeleton-loader>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="px-4">
        <ngx-skeleton-loader fxFlex="48" count="2" [theme]="{ height: '50px' }" animation="progress">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="48" count="3" animation="progress"></ngx-skeleton-loader>
    </div>
    <mat-divider class="mt-2 mx-4"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
        <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
        <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
    </div>
    <mat-divider class="mx-4"></mat-divider>
    <div fxLayout="column" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="100" [theme]="{
            height: '50vh'
        }" animation="progress"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #defaultProperty let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-typeModel="typeModel" let-validProp="validProp" let-disabled="disabled" let-isAccess="isAccess" let-pipe="pipe"
    let-prefix="prefix" let-flexArea="flexArea">
    <div [fxFlex]="flexArea ?? 24" fxFlex.lt-md="32" fxLayout="column" class="mb-2 pr-2"
        [fxHide.lt-md]="flexArea && flexArea == 46 ? true : false"
        [fxHide.gt-sm]="flexArea && flexArea == 24 ? true : false" [matTooltip]="toolTip" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <app-inline-edit fxFlex="1 1 calc(100%-30px)" [inputData]="value"
                [ngStyle]="(property == 'displayName') && {'font-weight': '700', 'font-size': '17px', 'color': '#307abd'}"
                (handleSave)="editAccountContact($event, property, typeModel)" [removePaddingBottom]="true"
                [widthDisplay]="'100%'" [validatorValue]="validProp ? validProp.valid : null"
                [messageError]="validProp ? validProp.message : null" [usingMessageParent]="true" [enterToSubmit]="true"
                [escapeToCancel]="true" [readonlyInline]="disabled ?? false" [isAccess]="isAccess ?? false"
                [pipe]="pipe" [prefix]="prefix">
            </app-inline-edit>
        </div>
    </div>
</ng-template>

<ng-template #popupEditor let-data>
    <mat-dialog-content style="height: 45vh; overflow-x: hidden;">
        <div style="min-height: 40vh; width: calc(60vw - 48px);">
            <quill-editor #quillEditor class="content-editor" [modules]="editorOptions"
                (onEditorCreated)="setFocus($event, data)" [ngModel]="data"
                (onContentChanged)="contentQuillEditor = $event.html">
            </quill-editor>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button (click)="closeDialogEditor()">Cancel</button>
        <button mat-raised-button color="primary" class="float-right" (click)="saveEditor()">
            <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
        </button>
    </mat-dialog-actions>
</ng-template>




<ng-template #actionButtonTemplate>
    <div fxFlex="auto" fxLayoutGap="1rem" fxLayoutAlign.lt-md="center start" style="margin-left: -7px">
        <button #sendMailBtn="nbPopover" [nbPopover]="contactSelectPop" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
            mat-icon-button matTooltip="Send an email" (click)="nbMailPopover.show()">
            <!--  debouncedClick (throttleClick)="sendMailClick()" -->
            <mat-icon class="material-icons-outlined green-color">email</mat-icon>
        </button>
        <button #makeCallPopoverBtn="nbPopover" mat-icon-button matTooltip="Make a call"
            [nbPopover]="contactSelectPopCall" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
            (click)="makeCallPopover.show()" [disabled]="isCallLoading || isCalling "
            [nbSpinner]="isCallLoading || isCalling">
            <mat-icon class="material-icons-outlined green-color">call</mat-icon>
            <!--  debouncedClick (throttleClick)="onClickCall()" -->
        </button>
        <button *nbIsGranted="['view', 'send-sms-button']" #sendSMSBtn="nbPopover" mat-icon-button matTooltip="Send SMS"
            [nbPopover]="contactSelectPopSMS" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
            (click)="sendSMSPopover.show()">
            <mat-icon class="material-icons-outlined green-color">sms</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Add task" debouncedClick (throttleClick)="createTask()">
            <mat-icon class="material-icons-outlined green-color">
                add_task
            </mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #accountOwner>
    <div fxFlex="24" fxFlex.lt-md="32" *ngIf="ownerModel; else avatarSkeleton" fxLayout="column" class="mb-2 pr-2"
        matTooltip="Owner" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            Owner
        </span>
        <div [style.color]="ownerModel?.id ? '' : 'rgb(0 0 0 / 60%)'">
            <nb-user class="custom-avatar-outline" fxFlex="30px" size="small" [onlyPicture]="true"
                [picture]="ownerModel?.pictureURL" [name]="ownerModel?.fullName">
            </nb-user>
            <app-search-edit-inline fxFlex="1 1 calc(100%-30px)" [inputData]="ownerModel?.id
                ? (ownerModel?.userName + ' (' + ownerModel?.fullName + ')')
                : 'Unassigning'" [apiSearching]=" apiGetSearchOwner" [widthDisplay]="'100%'"
                [externalTemplate]="optionOwnerTemplate"
                (handleSave)="editAccountContact($event.id, 'ownerId', TypeEditModelSaleAccount.SaleAccount)">
            </app-search-edit-inline>
        </div>
    </div>
</ng-template>
<!--#endregion-->

<ng-template #avatarSkeleton>
    <ngx-skeleton-loader fxFlex="24" fxFlex.lt-md="32" class="pr-2" count="1" appearance="line" animation="progress"
        [theme]="{ 'margin-bottom': '0px' }">
    </ngx-skeleton-loader>
</ng-template>

<ng-template #copied>
    <span class="copied">
        Copied!!
    </span>
</ng-template>

<ng-template #blankLastNote>
    <app-blank-page fxFlex="100" fxFlex.lt-md="72" fxFlex.lt-sm="60" fxFlexOrder="2" fxFlexOrder.lt-md="1"
        [linkSrc]="'assets/images/notetaking.gif'" [message]="'Do you wish to add a new note?'"
        [contentTemplate]="contentEmptyLastNote">
    </app-blank-page>
</ng-template>

<ng-template #contentEmptyLastNote>
    <span class="mb-2"><strong>Do you wish to add a new note?</strong></span>
    <button class="mb-2" mat-stroked-button color="primary" (throttleClick)="noteService.pushNoteDetails({
        referenceId: id,
        referenceType: 'SALEACCOUNT',
        referenceName: saleAccountModel.displayName
    })" debouncedClick [throttleTime]="500">
        Add new
        <mat-icon>add</mat-icon>
    </button>
</ng-template>

<ng-template #optionOwnerTemplate let-item="item">
    <div fxLayout="row" fxLayoutAlign="start end">
        <div style="font-size:14px" fxFlex="40" class="limit-line" style="--line:1;font-size:12px; margin-right:15px;">
            <b>{{item?.userName || "No name display"}}</b> ({{item?.fullName || ""}})
        </div>
        <div class="text-secondary limit-line" [matTooltip]="" style="--line:1;font-size:12px; margin-right:15px ;"
            fxFlex="35">
            {{item?.email || "No email display" }}
        </div>
        <div class="text-secondary limit-line" style="--line:1;font-size:12px" fxFlex="25">
            {{(item?.phoneNumber | phoneFormat) || "No phone display" }}
        </div>
    </div>
</ng-template>

<ng-template #autocompleteInline let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-typeModel="typeModel" let-validProp="validProp" let-disabled="disabled" let-isAccess="isAccess" let-pipe="pipe"
    let-prefix="prefix" let-flexArea="flexArea" let-observable="observable" let-searchProp="searchProp"
    let-searchArray="searchArray" let-suffixFunc="suffixFunc" let-addition="addition" let-isAscii="isAscii">
    <div [fxFlex]="flexArea ?? 24" fxFlex.lt-md="32" fxLayout="column" class="mb-2 pr-2"
        [fxHide.lt-md]="flexArea && flexArea == 46 ? true : false"
        [fxHide.gt-sm]="flexArea && flexArea == 24 ? true : false" [matTooltip]="toolTip" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <app-auto-complete-inline-edit fxFlex="1 1 calc(100%-30px)" [inputData]="value"
                (handleSave)="addition? saveIndividualProfileAdditionDetail($event, property): editAccountContact($event, property, typeModel); suffixFunc ? suffixFunc() : ''"
                [removePaddingBottom]="true" [widthDisplay]="'100%'"
                [validatorValue]="validProp ? validProp.valid : null"
                [messageError]="validProp ? validProp.message : null" [usingMessageParent]="true" [enterToSubmit]="true"
                [escapeToCancel]="true" [readonlyInline]="disabled ?? false" [isAccess]="isAccess ?? false"
                [pipe]="pipe" [prefix]="prefix" [searchArrayAPI]="observable" [searchProperty]="searchProp"
                [searchArray]="searchArray" [isAscii]="isAscii || false">
            </app-auto-complete-inline-edit>
        </div>
    </div>
</ng-template>

<ng-template #searchEditInline let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-func="func" let-apiSearching="apiSearching">
    <div fxFlex="24" fxFlex.lt-md="32" fxFlex.lt-sm="49" fxLayout="column" class="mb-2 pr-2" [matTooltip]="toolTip"
        matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <app-search-edit-inline
              [ngStyle]="(property == 'Relationship') && {'font-weight': '700', 'font-size': '17px', 'color': '#307abd'}"
              fxFlex="1 1 calc(100%-30px)" [inputData]="value" [widthDisplay]="'100%'"  [showRemoveButton]="true"
              (handleSave)="func($event, property)" [apiSearching]="apiSearching"
              (onEventDeleteHandle)="savePrimaryResellerOrRelationship(null, property)">
            </app-search-edit-inline>
        </div>
    </div>
</ng-template>

<ng-template #loadingInline let-toolTip="toolTip" let-icon="icon" let-flexArea="flexArea">
    <div [fxFlex]="flexArea ?? 24" fxFlex.lt-md="32" fxLayout="column" class="mb-2 pr-2"
        [fxHide.lt-md]="flexArea && flexArea == 46 ? true : false"
        [fxHide.gt-sm]="flexArea && flexArea == 24 ? true : false" [matTooltip]="toolTip" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <ngx-skeleton-loader fxFlex="1 1 calc(100%-30px)" fxLayoutAlign="flex-start end" count="1" appearance="line"
                animation="progress" [theme]="{ 'margin-bottom': '0px' }">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-template>

<ng-template #contactSelectPop>
    <app-pop-over-select-contacts [id]="id" [type]="'SALEACCOUNT'" [(contactList)]="contacts"
        [(primaryContactId)]="saleAccountModel.accountContactId" [action]="'email'"
        (onSelectedContact)="getSelectedContactMail($event)" [popover]="nbMailPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #contactSelectPopCall>
    <app-pop-over-select-contacts [id]="id" [type]="'SALEACCOUNT'" [(contactList)]="contacts"
        [(primaryContactId)]="saleAccountModel.accountContactId" [action]="'call'"
        (onSelectedContact)="getSelectedContactCall($event)" [popover]="makeCallPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #contactSelectPopSMS>
    <app-pop-over-select-contacts [id]="id" [type]="'SALEACCOUNT'" [(contactList)]="contacts"
        [primaryContactId]="saleAccountModel.accountContactId" [action]="'call'" [actionTitle]="'send SMS'"
        (onSelectedContact)="openSMS($event)" [popover]="sendSMSPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #externalTemplate>
    <ng-container [ngTemplateOutlet]="data.externalTemplate? data.externalTemplate : ''"
        [ngTemplateOutletContext]="{row: data.row}"></ng-container>
</ng-template>

<ng-template #addFilesMenu class="column d-flex flex-column align-items-start">
    <input #fileInput hidden="true" type="file" onclick="this.value =null"
    (change)="addSaleAccountFile($event)"/>
    <!-- [extension]='{"extensions":["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"]}'
    accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.html,.zip,.csv,.txt" -->

  <button mat-flat-button class="col float-start" debouncedClick
      [throttleTime]="500" matTooltipPosition="below" (throttleClick)="fileInput.click()">
      <mat-icon style="color: #007100;">add</mat-icon> Add New Files
  </button>

  <hr class="custom-divider">
  <button mat-flat-button class="col d-flex flex-row" debouncedClick
      [throttleTime]="500" matTooltipPosition="below" (click)="openURLDialogRef()">
      <mat-icon style="color: #007100;">add</mat-icon> Add URL
  </button>
</ng-template>
