<div mat-dialog-title>
    <h1 style="text-align:center" *ngIf="!data.isHelper">Import completed!</h1>
    <div *ngIf="data.isHelper">What should we do with the duplicated item?</div>

</div>
<div mat-dialog-content>
    <div *ngIf="!data.isHelper">
        <div style="text-align:center">
            <p><span class="badge badge-pill badge-info import-status-number">{{data.totalRow}}</span> item(s) are
                processed successfully</p>
            <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
                <p>
                    <span class="badge badge-pill badge-success import-status-number">{{data.totalNew}} created </span>
                </p>
                <p>
                    <span class="badge badge-pill badge-success import-status-number"> {{data.totalUpdate}}
                        modified</span>
                </p>
                <p>
                    <span class="badge badge-pill badge-warning import-status-number">{{data.totalReview}}
                        reviews</span>
                </p>
                <p>
                    <span class="badge badge-pill badge-danger import-status-number"> {{data.totalError}} errors </span>
                </p>
                <span *ngIf="data.fileError"> Please <a href="javascript:void(0)" (click)="downloadFileError()"> click
                        here</a> to download the error records,</span>
            </div>
        </div>
    </div>
    <div class="alert alert-warning" role="alert" *ngIf="!data.isHelper && data.totalReview">
        We found {{data.totalReview}} item(s) that duplicate the email with the Sale Process Entry
        ({{environment.titleLead}}s, {{environment.titleAccount}}s, {{environment.titleOpportunity}})
    </div>
    <div *ngIf="data.isHelper">
        <p>The review list is the duplicate the email with the Sale Process Entry ({{environment.titleLead}}s,
            {{environment.titleAccount}}s, {{environment.titleOpportunity}})</p>
    </div>
    <!-- <app-primas-toolbar #primasToolbar [selectedCout]="primasTable?.selected?.length"
    (onAfterAddFinish)="refreshData(true)" [disableAddNewButton]="true" [columns]="columns" [table]="primasTable" [resource]="data.toolbarResource">
  </app-primas-toolbar> -->

    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="space-between">
        <nb-card class="import-instruction-card" fxFlex="33">
            <nb-card-header class="import-instruction-header">
                <div class="text-muted">What should we do with the duplicated item is a
                    <b>{{environment.titleLead}}</b>?
                </div>
            </nb-card-header>
            <nb-card-body class="import-instruction-header">
                <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start start">
                    <div>
                        <mat-icon class='instruction-icon' style="color:#28a745">
                            swap_horiz
                        </mat-icon>
                    </div>
                    <div>
                        <p>
                            <b>Convert</b>
                        </p>
                        We can <b>convert a {{environment.titleLead}} to {{environment.titleAccount}}</b>
                        with option: <b>Existing {{environment.titleAccount}}</b> and
                        <b>New Contact</b>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
        <nb-card class="import-instruction-card" fxFlex="33">
            <nb-card-header class="import-instruction-header">
                <div class="text-muted">What should we do with the duplicated item is a
                    <b>{{environment.titleAccount}}</b>?
                </div>
            </nb-card-header>
            <nb-card-body class="import-instruction-header">
                <div fxLayout="row" fxLayoutGap="12px">
                    <div>
                        <mat-icon class='instruction-icon' style="color:#28a745">
                            group_add
                        </mat-icon>
                    </div>
                    <div>
                        <p>
                            <b>Add to relationship</b>
                        </p>
                        <div>
                            We can add <b>this duplicate
                                {{environment.titleAccount}} entry</b> to <b>this {{environment.titleAccount}}'s
                                relationship</b>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
        <nb-card class="import-instruction-card" fxFlex="33">
            <nb-card-header class="import-instruction-header">
                <div class="text-muted">What should we do with the duplicated item is a
                    <b>{{environment.titleOpportunity}}</b>?
                </div>
            </nb-card-header>
            <nb-card-body class="import-instruction-header">
                <div fxLayout="row" fxLayoutGap="12px">
                    <div>
                        <mat-icon class='instruction-icon' style="color:#28a745">
                            add_link
                        </mat-icon>
                    </div>
                    <div>
                        <p>
                            <b>Add {{environment.titleOpportunity}}'s {{environment.titleAccount}} to relationship</b>
                        </p>
                        <div>
                            We can add <b>the {{environment.titleOpportunity}}'s {{environment.titleAccount}}</b> to
                            <b>this {{environment.titleAccount}}'s
                                relationship</b>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Close</button>
    <button mat-button color="primary" class="float-right" [mat-dialog-close]="true">
        I understood
    </button>
</mat-dialog-actions>