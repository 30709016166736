import { ProfileModel } from "src/app/modules/admin/profile-management/profile-model";
import { Page } from "src/app/shared/models/paging/page";

export class NextOrPrevious {
    index: number = -1;
    currentId: any = null;
    pageFilter: Page = new Page();
    isLoadingNext = false;
    isLoadingPre = false;
}
