import { prop, required, minLength, email, compare, numeric } from "@rxweb/reactive-form-validators";
import { TblActionType } from "src/app/shared/enums/tbl-action-type.enum";
import { UserRoleModel } from "./user-role-model";

export class UserModel {
    @prop()
    id: string;
    @required()
    userName: string;
    // @required({
    //     conditionalExpression: (x: UserModel) => {
    //         return x.action == TblActionType.Add;
    //     }
    // })
    @minLength({ value: 6 })
    password: string;
    // @required({
    //     conditionalExpression: (x: UserModel) => {
    //         return x.action == TblActionType.Add;
    //     }
    // })
    @compare({ fieldName: 'password', message: 'Confirm Password are not matched' })
    @minLength({ value: 6 })
    confirmPassword!: string;
    @required()
    @email()
    @required()
    email: string;
    @prop()
    @required()
    roles: string[];
    @prop()
    @required()
    lastName: string;
    @prop()
    @required()
    firstName: string;
    @prop()
    phoneNumber: string;
    @prop()
    action: TblActionType
    @prop()
    base64IMG: string;
    @prop()
    pictureURL: any;
    @prop()
    outboundCallerId: string;
    roleNames: string[]
    @prop()
    jobTitle: string;

    //property support to display
    fullName: string;
    @prop()
    isActive:boolean | null = true;
    cxAutoLoginURL: string;
    cxUser: string;
}
