<div mat-dialog-title *ngIf="id" fxLayout="row" fxLayoutAlign="space-between start">
  <mat-button-toggle-group class="group-mode" style="font-size: 12px; font-weight: unset;" [(ngModel)]="revisionVersionStatus">
    <mat-button-toggle [value]="0" (click)="groupingData()">
      {{"Waiting For Review" | uppercase}}
    </mat-button-toggle>
    <mat-button-toggle [value]="3" (click)="groupingData()">
      {{"Approved" | uppercase}}
    </mat-button-toggle>
    <mat-button-toggle [value]="2" (click)="groupingData()">
      {{"Rejected" | uppercase}}
    </mat-button-toggle>
    <mat-button-toggle [value]="4" (click)="groupingData()">
      {{"Archived" | uppercase}}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div>
  <app-primas-toolbar #primasToolbar [selectedCout]="primasTable?.selected?.length" [customView]="true" [customView]="false" [table]="primasTable" [columns]="columns" [resource]="resource.toolbar" [isShowEntitiesLimit]="false" [hiddenDeleteButton]="false">
  </app-primas-toolbar>

  <app-primas-table #primasTable (onRefresh)="refreshData($event)" [columnsTable]="columns" [resource]="resource.table" [allowSelectRow]="false" [tableAction]="true" [usingCustomEdit]="true" [customAction]="customAction" [isHiddenEditButton]="true" (onDelete)="removeRevision($event)"></app-primas-table>
</div>
<ng-template #publishedContentTemplate let-row="row">
  <div *ngIf="row?.isPublish === true; else publishFalse">
    <mat-icon aria-hidden="false" aria-label="check_circle" class="success large">check_circle</mat-icon>
  </div>
  <ng-template #publishFalse>
  </ng-template>
</ng-template>

<ng-template #submitter let-row="row" let-value="value">
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="row?.submitter as dataUser">
    <nb-user [name]="dataUser?.userName" size="small" [onlyPicture]="true" class="custom-avatar-outline"></nb-user>
    <label class="mb-0"><b>{{dataUser?.userName}}</b></label>
  </div>
</ng-template>

<ng-template #reviewer let-row="row" let-value="value">
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="row?.reviewer as dataUser">
    <nb-user [name]="dataUser?.userName" size="small" [onlyPicture]="true" class="custom-avatar-outline"></nb-user>
    <label class="mb-0"><b>{{dataUser?.userName}}</b></label>
  </div>
</ng-template>

<ng-template #customAction let-row="row">
  <button mat-menu-item *nbIsGranted="['view','reviewer-revision-version']" (click)="approveRevision(row)" [ngClass]="row.status | revisionVersion : buttonTypeEnum.Approve : row">
    <mat-icon style="color: #42d719">check_circle</mat-icon>
    <span>Approve</span>
  </button>

  <button mat-menu-item *nbIsGranted="['view','reviewer-revision-version']" (click)="rejectRevision(row)" [ngClass]="row.status | revisionVersion : buttonTypeEnum.Reject : row">
    <mat-icon style="color: #ee2b2b">cancel</mat-icon>
    <span>Reject</span>
  </button>

  <button mat-menu-item *nbIsGranted="['view','reviewer-revision-version']" (click)="publishRevision(row)" [ngClass]="row.status | revisionVersion : buttonTypeEnum.Publish : row">
    <mat-icon style="color: #f4cf4b">visibility</mat-icon>
    <span>Publish</span>
  </button>

  <button mat-menu-item *nbIsGranted="['view','reviewer-revision-version']" (click)="unpublishRevision(row)" [ngClass]="row.status | revisionVersion : buttonTypeEnum.Unpublish : row">
    <mat-icon>visibility_off</mat-icon>
    <span>Unpublish</span>
  </button>

  <button mat-menu-item *nbIsGranted="['view','reviewer-revision-version']" [ngClass]="row.status | revisionVersion : buttonTypeEnum.RequestIndexing : row">
    <mat-icon color="primary">thumb_up_alt</mat-icon>
    <span>Request indexing</span>
  </button>

  <button mat-menu-item class="ml-auto float-start" (click)="clickSEOAudit(row)">
    <mat-icon svgIcon="seo-audit"></mat-icon>
    <span>Run SEO Audit</span>
  </button>

  <button mat-menu-item *nbIsGranted="['view','request-AI-checker']" class="ml-auto float-start" (click)="clickAIChecker(row)">
    <mat-icon>smart_toy</mat-icon>
    <span>Run AI Checker</span>
  </button>

  <button mat-menu-item *nbIsGranted="['view','reviewer-revision-version']" (click)="restoreRevision(row)" [ngClass]="row.status | revisionVersion : buttonTypeEnum.Restore : row">
    <mat-icon color="info">restore</mat-icon>
    <span>Restore</span>
  </button>

  <!-- <button mat-menu-item (click)="openRestoreNote(row)" [ngClass]="row.status | revisionVersion : buttonTypeEnum.RestoreNote : row">
    <mat-icon color="info">notes</mat-icon>
    <span>Edit backup description</span>
  </button> -->
  
  <button mat-menu-item *ngIf="!id" (click)="openDetailDialog(row)">
    <mat-icon style="color: green">arrow_right_alt</mat-icon>
    <span>Open detail</span>
  </button>

  <button mat-menu-item *nbIsGranted="['view','reviewer-revision-version']" (click)="removeRevision(row)">
    <mat-icon style='color: #f44336'>
      delete
    </mat-icon>
    <span>Delete item</span>
  </button>
</ng-template>

<ng-template #publishCheckbox>
  <mat-checkbox class="mt-3" color="primary" [(ngModel)]="isPublishNow">Publish Immediately</mat-checkbox>
</ng-template>


<ng-template #profileOpenInline let-row="row">
  <div class="entity-inline-name" (click)="openDialog(row)">
    {{row?.rvProfile?.displayName}}
  </div>
</ng-template>

<ng-template #categoryOpenInline let-row="row">
  <div class="entity-inline-name" (click)="openDialog(row)">
    {{row?.rvCategory?.categoryName}}
  </div>
</ng-template>

<ng-template #friendlyUrlOpenInline let-row="row">
  <a (click)="goToLink(row?.friendlyUrl, row)" href="javascript:void(0);">
    {{row?.friendlyUrl}}
  </a>
</ng-template>
<ng-template #SEOAuditTemp let-row>
  <div style="max-height: 40vh; max-width: 560px;">
    <app-seo-audit [id]="row.revisionId" [type]="'RVRevisionMeta'">
    </app-seo-audit>
    <hr>
  </div>
</ng-template>
<ng-template #status let-row="row">
  <span [ngClass]="row.status | revisionColorFormat">{{row.status | enumPipe : RevisionStatus}}</span>
</ng-template>
<ng-template #rejectReasonOpenInline let-row="row">
  <div class="entity-inline-name" (click)="openRejectReason(row)">
    {{row?.description}}
  </div>
</ng-template>


<ng-template #rejectReasonTemplate>
  <mat-form-field appearance="outline" class="mt-3" style="width: 400px;">
    <mat-label>Rejected Reason</mat-label>
    <textarea matInput cdkTextareaAutosize [(ngModel)]="rejectReasonData" placeholder="Rejected Reason" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
  </mat-form-field>
</ng-template>

<ng-template #rejectReasonReadonlyTemplate let-row="row">
  <mat-form-field appearance="outline" class="mt-3" style="width: 400px;">
    <mat-label>Rejected Reason</mat-label>
    <textarea matInput cdkTextareaAutosize [value]="row.description" placeholder="Rejected Reason" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6" readonly></textarea>
  </mat-form-field>
</ng-template>

<ng-template #restoreRevisionNoteTemplate let-row="row">
  <div class="entity-inline-name" (click)="openRestoreNote(row)">
    {{row?.extendData | formatRevisionExtendDataPipe : "note"}}
  </div>
</ng-template>

<ng-template #restoreNoteTemplate let-row="row">
  <mat-form-field appearance="outline" class="mt-3" style="width: 400px;">
    <mat-label>Archived Description</mat-label>
    <textarea matInput cdkTextareaAutosize [value]="row?.restoreNote" (change)="restoreNoteChange($event)" placeholder="Archived Description" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
  </mat-form-field>
</ng-template>

<ng-template #restoreRevisionTemplate let-formDetail="formDetail">
  <form [formGroup]="formDetail" class="d-flex flex-column" style="gap: 12px;">
    <div>
      Do you wish to restore this revision to the {{revisionEntity | lowercase}} detail?
    </div>
    <div>
      <mat-checkbox class="checkbox-snapshot" color="primary" formControlName="isSnapshot">Archive the current {{revisionEntity | lowercase}} detail (Recommended) </mat-checkbox>
    </div>
    <ng-container *ngIf="formRestore?.get('isSnapshot')?.value">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Archived Description</mat-label>
        <textarea matInput formControlName="note" cdkTextareaAutosize placeholder="Archived Description" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
      </mat-form-field>
    </ng-container>
  </form>
</ng-template>
<ng-template #seoScore let-row="row">
  <i class="fs-8 mr-1 fa fa-solid fa-circle" [ngClass]="row?.OverallScore | seoColorScore : '100'" 
  [matTooltip]="!row?.OverallScore ? 'No SEO Audit' : 
  row?.OverallScore > 74 ? 'Good' : 
  row?.OverallScore < 40 ? 'Bad' : 'Optimize'"></i>
</ng-template>

<ng-template #readabilityScore let-row="row">
  <i class="fs-8 mr-1 fa fa-solid fa-circle" [ngClass]="row?.ReadabilityScore | readabilityScore : '100'" 
  [matTooltip]="!row?.ReadabilityScore ? 'Never Check' :
  row?.ReadabilityScore >= 71 ? 'Good' :
  row?.ReadabilityScore < 60 ? 'Bad' : 'Optimize'"></i>
</ng-template>

<ng-template #AICheckerTemplate let-row>
  <div style="max-height: 40vh; max-width: 560px;">
      <app-ai-checker [id]="row?.revisionId" [type]="'RVRevisionMeta'" (isFinishDetection)="onAIDetectionChanged($event)"></app-ai-checker>
      <hr>
  </div>
</ng-template>

<ng-template #aiScoreTemplate let-row="row">
  <i class="fs-8 mr-1 text-center fa fa-solid fa-circle"
    [ngClass]="row?.extendData | aiDetectionPipe: 'color' : true"
    [matTooltip]="row?.extendData | aiDetectionPipe: 'tooltip' : true">
  </i>
</ng-template>