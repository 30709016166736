export enum PrimasDatetimeFilterOperator {
    IsBefore = "Is Before",
    IsBeforeOrEqual = "Is Before Or Equal",
    IsAfter = "Is After",
    IsAfterOrEqual = "Is After Or Equal",
    IsEqualTo = "Is Equal To",
    IsNotEqualTo = "Is Not Equal To",
    Between = "Between",
    Quarter = "Quarter"
}
