import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subtractDate'
})
export class SubtractDatePipe implements PipeTransform {

  transform(date: Date | string): string {
    var secondsResult = 0;
    var minutesResult = 0;
    var hoursResult = 0;
    var daysResult = 0;
    if (date) {
      let currentDate = new Date();
      date = new Date(date);
      secondsResult = Math.floor((currentDate.getTime() - date.getTime()) / 1000);
      minutesResult = Math.floor(secondsResult / 60);
      hoursResult = Math.floor(minutesResult / 60);
      daysResult = Math.floor(hoursResult / 24);
    }

    return daysResult != 0 ? daysResult + ` Day${daysResult > 1 ? 's' : ''} ago`
      : (hoursResult != 0) ? hoursResult + ` Hour${hoursResult > 1 ? 's' : ''} ago`
        : (minutesResult != 0) ? minutesResult + ` Minute${minutesResult > 1 ? 's' : ''} ago`
          : secondsResult + ` Second${secondsResult > 1 ? 's' : ''} ago`;
  }

}
