import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatastateManagementService } from 'src/app/modules/admin/datastate-management/datastate-management.service';
import { SeoInfoService } from 'src/app/modules/admin/seo-info-management/seo-info.service';
// import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { PrimasTableComponent } from 'src/app/shared/components/template-management/primas-table/primas-table.component';
import { permissionSeoInfo } from 'src/app/shared/contances/permission';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { KeywordHistoryModel } from './keyword-hisory.model';

@Component({
  selector: 'app-keyword-history',
  templateUrl: './keyword-history.component.html',
  styleUrls: ['./keyword-history.component.scss']
})
export class KeywordHistoryComponent implements OnInit {
  @ViewChild('primasTable', { static: true }) primasTable: PrimasTableComponent;
  @ViewChild('name', { static: true }) name: TemplateRef<any>;

  columns = []
  resource = permissionSeoInfo;
  id: number;
  keywordName: string = '';
  keywordIndex: number = 0;
  keywordHistories: KeywordHistoryModel[] = [];

  // addComponent = AddEditKeywordMappingToolComponent
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private seoInfoService: SeoInfoService,
    private toastr: NbToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateFormat: DateTimeFormatPipe,
    public automatService: DatastateManagementService
  ) {
    if (this.data) {
      this.id = this.data.id;
      this.keywordName = this.data.keywordName,
        this.keywordIndex = this.data.keywordIndex
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.columns = [
      {
        name: 'Date Modified',
        prop: 'dateModified',
        filter: false,
        sortable: false,
        pipe: this.dateFormat
      },
      {
        name: 'User Modified',
        prop: 'user.username',
        cellTemplate: this.name,
        filter: false,
        sortable: false
      },
      {
        name: 'Modified Value',
        prop: 'modifiedValue',
        width: 300,
        // cellTemplate: this.name,
        filter: false,
        sortable: false
      },
    ]
    this.primasTable.page.pageNumber = 1;
  }

  onClickDelete(data) {
    this.seoInfoService.deleteKeywordMapping([data.row.keywordMappingId]).pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
        if (resp.result) {

          this.primasTable.page.pageNumber = Math.floor(data.rowIndex / this.primasTable.page.size);
          this.toastr.success(`Successfully deleted`, 'Success');
          this.refreshData();
        }
      })
  }
  async refreshData(reset: boolean = false, data?: any) {
    let offSetX;
    this.primasTable.isLoading = 1;
    if (reset) {
      this.primasTable.page.pageNumber = 0;
      this.primasTable.cache = {};
      this.primasTable.rows = [];
      this.primasTable.selected = [];
      this.primasTable.tableAction = false;
      this.primasTable.table.offset = 0;
      this.primasTable.table.bodyComponent._offset = 0;
      offSetX = 0;
    }
    this.primasTable.isLoading = 1;
    let pageRes = await this.seoInfoService.getKeywordMappingHistory(this.primasTable.page, this.id).toPromise();
    if (pageRes?.result && pageRes.result.data) {
      for (var idx = 0; idx < pageRes.result.data.length; idx++) {
        if (pageRes.result.data[idx].content) {
          const historyExtendData = JSON.parse(pageRes.result.data[idx].content);
          const rawExtendData = historyExtendData.rawExtendData ? JSON.parse(historyExtendData.rawExtendData) : null;
          if (rawExtendData) {
            pageRes.result.data[idx].name = historyExtendData.name;
            var keywordHistoryModel = new KeywordHistoryModel();
            keywordHistoryModel.user = pageRes.result.data[idx]?.user || {};
            keywordHistoryModel.dateModified = historyExtendData?.dateModified;
            if(rawExtendData?.KeywordLst) {
              keywordHistoryModel.modifiedValue = rawExtendData?.KeywordLst[this.keywordIndex]?.Keyword || "";
            }
            this.keywordHistories.push(keywordHistoryModel);
          }
        }
      }
 
      // Group histories and get the oldest data on each group
      if (this.keywordHistories && this.keywordHistories.length > 0) {
        var result = this.splitDuplicateKeywordHistory(this.keywordHistories);
        if(result && result.length>0) {
          this.keywordHistories = [];
          result.forEach(group => {
            this.keywordHistories.push(group[group.length - 1]);
          });
        }
      }

      pageRes.result.data = this.keywordHistories;
      pageRes.result.page.totalElements = this.keywordHistories.length || 0;
    }

    this.primasTable.setData(pageRes.result);
    setTimeout(() => {
      this.primasTable.table.recalculate();
      this.primasTable.table['cd'].markForCheck();
      document.body.style.width = 'auto';
      this.primasTable.table._offsetX.next(offSetX);
    }, 200);
    this.primasTable.isLoading = 0;
  }

  splitDuplicateKeywordHistory(keywordHistories: KeywordHistoryModel[] = []): KeywordHistoryModel[][] {
    let resultArray = [];
    let currentSubarray = [];
    for (var idx = 0; idx < keywordHistories.length; idx++) {
      if (idx === 0 || keywordHistories[idx].modifiedValue.trim() === keywordHistories[idx - 1].modifiedValue.trim()) {
        currentSubarray.push(keywordHistories[idx]);
      } else {
        resultArray.push(currentSubarray);
        currentSubarray = [keywordHistories[idx]];
      }
    }

    if (currentSubarray.length > 0) {
      resultArray.push(currentSubarray);
    }
    return resultArray;
  }
}
