<div style="text-align: center;">
  <h1>DAILY SUMMARY REPORT</h1>
</div>

<div class="col-12">
  <div fxLayoutAlign="center center">
    <mat-form-field appearance="legacy" style="width:300px">
      <mat-select [formControl]="bankCtrl" placeholder="Choose a date" #singleSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Find date..."
            noEntriesFoundLabel="'no matching bank found'"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
          {{bank.text | date : 'medium'}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span style="margin-left: 10px;"> <button mat-raised-button color="primary" (click)="applyChange()">
        Apply
      </button>
      <button mat-raised-button (click)="refactorData()" style="margin-left: 10px;" color="accent"
        [nbSpinner]="loadingRefactor" *nbIsGranted="['view', 'refactor-summary-report']">
        Refactor Data
      </button>
      <button mat-button (click)="refreshGetReport()" class="float-right">
        <mat-icon>refresh</mat-icon>
      </button>
    </span>
  </div>
  <div>
  </div>
  <!-- <div class="row">
  <div class="col align-self-start">
    <button [nbSpinner]="isLoadingExport" [disabled]="true" type="button" mat-button>
      <mat-icon class="mr">file_download</mat-icon>Export
    </button>
  </div>
  <div class="col align-self-center">
    
    <div class="col align-self-end float-right">
     
    </div>
  </div>

  <div class="col align-self-end float-right">
    <button mat-button (click)="refreshGetReport()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div> -->
  <br>
  <mat-divider></mat-divider>
  <br>
  <div class="example-container mat-elevation-z8">
    <div class="example-table-container">
      <table mat-table [dataSource]="dataSource" [nbSpinner]="loading">
        <!-- Country Column -->
        <ng-container matColumnDef="Country">
          <th mat-header-cell *matHeaderCellDef style="width: 170px" class="header-color"> <b
              class="custom-font">Country</b></th>
          <td mat-cell *matCellDef="let element"> {{element.country || 'Unknown'}} </td>
        </ng-container>

        <!-- Total Column -->
        <ng-container matColumnDef="Total">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b
              class="custom-font">Total</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.total}}</td>
        </ng-container>

        <!-- Hascontactinfo -->
        <ng-container matColumnDef="Hascontactinfo">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b class="custom-font">Has
              contact phone</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.hasContactInfo}}
            <span>({{showPercent(element.hasContactInfo,
              element.total) || 'Unknown'}}%)</span>
          </td>
        </ng-container>

        <!-- Hascontactinfo -->
        <ng-container matColumnDef="HasContactEmail">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b class="custom-font">Has
              contact email</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.hasContactEmail}}
            <span>({{showPercent(element.hasContactEmail,
              element.total) || 'Unknown'}}%)</span>
          </td>
        </ng-container>

        <!-- TotalEmailed -->
        <!-- <ng-container matColumnDef="TotalEmailed">
        <th mat-header-cell *matHeaderCellDef> <b class="custom-font">Total</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.totalEmail}} <span>({{showPercent(element.totalEmail,
            element.total) || 'Unknown'}}%)</span></td>
      </ng-container> -->

        <!-- emailSuccess -->
        <ng-container matColumnDef="emailSuccess">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b
              class="custom-font">Success</b> </th>
          <td mat-cell *matCellDef="let element" class="yellow-color"> {{element.successEmail}} </td>
        </ng-container>

        <!-- emailFailed -->
        <ng-container matColumnDef="emailFailed">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b
              class="custom-font">Failed</b> </th>
          <td mat-cell *matCellDef="let element" class="yellow-color"> {{element.failedEmail}} </td>
        </ng-container>

        <!-- Hascontactinfo -->
        <!-- <ng-container matColumnDef="ReadEmail">
        <th mat-header-cell *matHeaderCellDef> <b class="custom-font">Read</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.readEmail}}</td>
      </ng-container> -->

        <!-- Replied -->
        <ng-container matColumnDef="Replied">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b
              class="custom-font">Replied</b> </th>
          <td mat-cell *matCellDef="let element" class="yellow-color"> {{element.repliedEmail}} </td>
        </ng-container>

        <!-- Unsub -->
        <!-- <ng-container matColumnDef="Unsub">
        <th mat-header-cell *matHeaderCellDef> <b class="custom-font">Unsub</b> </th>
        <td mat-cell *matCellDef="let element" class="yellow-color"> {{element.unsubEmail}} </td>
      </ng-container> -->


        <!-- Total call -->
        <!-- <ng-container matColumnDef="totalCall">
        <th mat-header-cell *matHeaderCellDef> <b class="custom-font">Total</b> </th>
        <td mat-cell *matCellDef="let element" class="brown-blue-color"> {{element.totalCalled}} </td>
      </ng-container> -->


        <!-- Total Success -->
        <ng-container matColumnDef="totalSuccess">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b
              class="custom-font">Success</b> </th>
          <td mat-cell *matCellDef="let element" class="brown-blue-color"> {{element.successCalled}} </td>
        </ng-container>


        <!-- Total Failed -->
        <ng-container matColumnDef="totalFailed">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b
              class="custom-font">Failed</b> </th>
          <td mat-cell *matCellDef="let element" class="brown-blue-color"> {{element.failedCalled}} </td>
        </ng-container>

        <!-- SubmittedForms -->
        <ng-container matColumnDef="SubmittedForms">
          <th mat-header-cell *matHeaderCellDef style="width:130px" class="header-color"> <b
              class="custom-font">Submitted Forms</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.submittedForms}} </td>
        </ng-container>


        <!-- Accepted -->
        <!-- <ng-container matColumnDef="Accepted">
        <th mat-header-cell *matHeaderCellDef> <b class="custom-font">Accepted</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.accepted}} </td>
      </ng-container> -->


        <!-- Rejected -->
        <!-- <ng-container matColumnDef="Rejected">
        <th mat-header-cell *matHeaderCellDef> <b class="custom-font">Rejected</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.rejected}} </td>
      </ng-container> -->


        <!-- Ready To Publish -->
        <!-- <ng-container matColumnDef="ReadyToPublish">
        <th mat-header-cell *matHeaderCellDef> <b class="custom-font">Ready To Publish</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.readyToPublish}} </td>
      </ng-container> -->


        <!-- Ready To Publish -->
        <ng-container matColumnDef="Published">
          <th mat-header-cell *matHeaderCellDef class="header-color"> <b class="custom-font">Published</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.published}} </td>
        </ng-container>
        <!-- Consent -->
        <ng-container matColumnDef="Consent">
          <th mat-header-cell *matHeaderCellDef class="header-color"> <b class="custom-font">Consent</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.consent}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="emailed">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="4">
          Emailed
        </th>
      </ng-container> -->


        <!-- firstEmpty group -->
        <ng-container matColumnDef="firstEmpty">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="4" style="text-align: center"
            class="first-header-color">
          </th>
        </ng-container>


        <!-- emailed group -->
        <ng-container matColumnDef="emailedGroup">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" style="text-align: center"
            class="first-header-color">
            <b class="custom-font">Email</b>
          </th>
        </ng-container>

        <!-- Called group -->
        <ng-container matColumnDef="calledGroup">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="text-align: center"
            class="first-header-color">
            <b class="custom-font">
              Call
            </b>
          </th>
        </ng-container>

        <!-- secondEmpty group -->
        <ng-container matColumnDef="secondEmpty">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" style="text-align: center"
            class="first-header-color">
          </th>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="parentDisplayColumns; sticky:true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky : true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

      </table>
      <mat-paginator [length]="countryLength" [pageSizeOptions]="[10, 50, 100, 250]" (page)="pageChanged($event)"
        [length]="250" class="mat-paginator-sticky"></mat-paginator>

    </div>

  </div>
  <br>
  <mat-divider></mat-divider>
  <div style="text-align: center; padding-top: 2.25rem;">
    <h1>ACTIVITY STATISTICS CHARTS</h1>
  </div>
  <app-graph-report [dropdownList]="dateTimeChosen" isPublished="true"></app-graph-report>