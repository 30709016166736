import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private numPipe: NumberFormatPipe) {

  }
  transform(value, iconOnly: boolean = false) {
    const getCurrency = localStorage.getItem('currency');

    if (iconOnly) {
      return `<div class="currency-color">${getCurrency ?? ''}<span class="primary-currency-color" ></span></div>`;
    }

    return `<div class="currency-color d-flex"">${getCurrency ?? ''}<span class="primary-currency-color text-truncate schedule-currency-value-style">${value ? this.numPipe.transform(value) : 0}</span></div>`;
  }

}
