import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventDataCalendar } from 'src/app/shared/components/custom-ngx-event-calendar/interface/event-data-calendar';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { TaskTypeModel } from 'src/app/shared/models/task-type.model';
import { environment } from 'src/environments/environment';
import { CRMDashBoardModel, CRMDashBoardProfileModel } from '../dashboard-crm/dashboard-crm.model';
import { TaskStatus } from '../task-management/task-board/task-board-lane/task-status.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseUrl = environment.apiDashboard;
  taskUrl = environment.apiTaskManagement;
  constructor(private http: HttpClient) { }

  getDataDashboard(): Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${this.baseUrl}/GetDashboard`);
  }

  getTaskSummary(isMyTask: boolean = false): Observable<ReturnResult<TaskTypeModel[]>> {
    return this.http.get<ReturnResult<TaskTypeModel[]>>(`${this.baseUrl}/GetTaskSummary?isMyTask=${isMyTask}`);
  }
  getBlocksDashBoard(): Observable<ReturnResult<CRMDashBoardModel>> {
    return this.http.get<ReturnResult<CRMDashBoardModel>>(`${this.baseUrl}/GetBlocksDashBoard`);
  }

  getTaskStatusSummary(isMyTask: boolean = false): Observable<ReturnResult<TaskStatus[]>> {
    return this.http.get<ReturnResult<TaskStatus[]>>(`${this.baseUrl}/GetTaskStatusSummary?isMyTask=${isMyTask}`);
  }

  getTaskSummaryLineTwo(isMyTask: boolean = false): Observable<ReturnResult<TaskTypeModel[]>> {
    return this.http.get<ReturnResult<TaskTypeModel[]>>(`${this.baseUrl}/GetTaskSummaryLineTwo?isMyTask=${isMyTask}`);
  }

  getTaskSummaryLineThree(isMyTask: boolean = false): Observable<ReturnResult<TaskTypeModel[]>> {
    return this.http.get<ReturnResult<TaskTypeModel[]>>(`${this.baseUrl}/GetTaskSummaryLineThree?isMyTask=${isMyTask}`)};
  getContactBlocks(): Observable<ReturnResult<CRMDashBoardModel>> {
    return this.http.get<ReturnResult<CRMDashBoardModel>>(`${this.baseUrl}/GetContactBlock`);
  }
  getCampaignBlocks(): Observable<ReturnResult<CRMDashBoardModel>> {
    return this.http.get<ReturnResult<CRMDashBoardModel>>(`${this.baseUrl}/GetCampaignBlock`);
  }
  getProfileBlock(): Observable<ReturnResult<CRMDashBoardProfileModel>> {
    return this.http.get<ReturnResult<CRMDashBoardProfileModel>>(`${this.baseUrl}/GetProfileBlock`);
  }

  getTaskEventCalendar(startDate: Date, endDate: Date,relationshipId:string = null): Observable<ReturnResult<EventDataCalendar[]>> {
    return this.http.post<ReturnResult<EventDataCalendar[]>>(`${this.taskUrl}/GetTaskEventCalendar`, {startDate, endDate, relationshipId});
  }

}
