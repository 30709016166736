<ng-template #noteDetails>
    <div class="show-note">
        <div class="note-stack" *ngIf="listNoteOnQueue.length > 0">
            <mat-icon>layers</mat-icon>
            <span>{{ listNoteOnQueue.length }}</span>
            <div class="drop-up-stack-list">
                <ul>
                    <li *ngFor="let note of listNoteOnQueue">
                        <div style="width: 100%; text-align: start ; align-items: center;" fxLayout="row" class="">
                            <button mat-button style="flex:1" (click)="showNoteItemDetailsFromQueue(note)">
                                <div style="width: 100%; text-align: start ; align-items: center;" fxLayout="row">
                                    <mat-icon style="color: #307abd">description</mat-icon>
                                    <span class="button-span">{{ note.title }}</span>
                                </div>
                            </button>
                            <button mat-icon-button (click)="removeNoteFromQueue(note)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngFor="let item of listNote; let i = index">
            <app-note-detail-card #noteCardTemplates [nbSpinner]="item?.loading" [note]="item"
                (removeNote)="closeNoteDetails($event, i)" (saveNote)="saveNote($event,'save', i, item)"
                (deleteNote)="deleteNote($event, i)" (autosaveNote)="saveNote($event,'autosave', i, item)"
                [isDisableEditorArea]="true">
            </app-note-detail-card>
        </div>
    </div>
</ng-template>