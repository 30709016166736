<div mat-dialog-title>Share Owner</div>
<div mat-dialog-content>
  <form [formGroup]="ownerFrm">
    <app-multi-select-drop-down #saleAccountType class="border-select-type" [readonly]="false"
      [inputValues]="getOwnerApi" [(selectedValues)]="selectOwners" [placeholder]="'Please select owners...'"
      [isMatChip]="true" [maxHeight]="'6.25rem'" matTooltip="Owners" matTooltipPosition="left"
      (selected)="onSelectedOwner($event)">
    </app-multi-select-drop-down>
  </form>
  <mat-error *ngIf="ownerFrm.controls.owners.value?.length == 0">
    This field is required!</mat-error>

</div>

<div *ngIf="showActionDialog" mat-dialog-actions align="end">
  <button mat-button  mat-dialog-close>
    Cancel
  </button>
  <button mat-raised-button color="primary" [disabled]="ownerFrm.controls.owners.value?.length == 0 || loading"
    debouncedClick [nbSpinner]="loading" (throttleClick)="saveOwners()">
    Save
  </button>
</div>