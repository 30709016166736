import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { BioRewrite, ReasonBioModel, RelationCategoryViewModel, RemoveActionList } from './bio-reason-category.model';

@Injectable({
  providedIn: 'root'
})
export class ReasonBioService {
  profileUrl = environment.apiProfileManagement;
  categoryUrl = environment.apiCategory;
  reasonUrl = environment.reasonManagement;
  private _reasonBio$: ReplaySubject<ReturnResult<ReasonBioModel>> = new ReplaySubject<ReturnResult<ReasonBioModel>>(1);


  constructor(private http: HttpClient) { }

  getReasonBio(): Observable<ReturnResult<ReasonBioModel>> {
    return this._reasonBio$.asObservable();
  }

  refreshData(id: string): Observable<ReturnResult<any>> {  //call api
    return this.http.get<ReturnResult<any>>(`${this.profileUrl}/ReasonBio/${id}`).pipe(tap(response => {
      this._reasonBio$.next(response);
    }));
  }

  addRelationCategory(relationCategory: RelationCategoryViewModel, isReason: boolean = true) {
    if (isReason === true)
      return this.http.post<ReturnResult<boolean>>(`${this.categoryUrl}/AddReasonCategory`, relationCategory);
    else return this.http.post<ReturnResult<boolean>>(`${this.categoryUrl}/AddBioCategory`, relationCategory);

  }
  removeRelationCategory(relationCategory: RelationCategoryViewModel, isReason: boolean = true) {
    if (isReason === true)
      return this.http.post<ReturnResult<boolean>>(`${this.categoryUrl}/DeleteReasonCategory`, relationCategory);
    else return this.http.post<ReturnResult<boolean>>(`${this.categoryUrl}/DeleteBioCategory`, relationCategory);
  }
  removeReason(reasonList: RemoveActionList) {
    return this.http.post<ReturnResult<boolean>>(`${this.reasonUrl}/DeleteReason`, reasonList);
  }
  removeBio(bioList: RemoveActionList) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/DeleteBio`, bioList);
  }
  getProfileSubProperty(profileId: string, property: string): Observable<ReturnResult<string>> {
    return this.http.post<ReturnResult<string>>(`${this.profileUrl}/GetProfileSubProperty`, { profileId, property });
  }
  setProfileSubProperty(profileId: string, property: string, propertyValue: string): Observable<ReturnResult<string>> {
    return this.http.post<ReturnResult<string>>(`${this.profileUrl}/SaveProfileSubProperty`, { profileId, property, propertyValue });
  }

  updateIsPublicBio(item: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdateIsPublicBioRewrite/${item}`, {});
  }
  selfGetBio(id: string): Observable<ReturnResult<ReasonBioModel>> {
    return this.http.get<ReturnResult<ReasonBioModel>>(`${this.profileUrl}/ReasonBio/${id}`);
  }
  updatePriorityBioRewrite(bio: BioRewrite): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdatePriorityBioRewrite`, bio);
  }

  getSetListActive(pid: string): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.reasonUrl}/GetSetListActive?pid=${pid}`);
  }

  isSetListActive(pid: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.reasonUrl}/IsSetListActive?pid=${pid}`, {});
  }
}
