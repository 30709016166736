export enum PrimasMailActionFilterOperator {
    Contains = "Contains",
    DoesNotContains = "Does Not Contains",
    EndsWith = "Ends With",
    IsEqualTo = "Is Equal To",
    IsNotEqualTo = "Is Not Equal To",
    StartsWith = "Starts With",
    EmailActions = "Email Actions",
    NoEmailActions = "No Email Actions"
}
