import { FormControl } from '@angular/forms';
import { maxLength, prop, required } from '@rxweb/reactive-form-validators';
import { Observable, Subject } from 'rxjs';
import { CountryObject } from 'src/app/shared/models/country-object.model';

export class AltusLocation {
    @prop()
    locationId: number;
    @prop()
    @maxLength({ value: 255 })
    city: string;
    @required()
    @maxLength({ value: 255 })
    country: string = "United States of America";
    @prop()
    @maxLength({ value: 100 })
    zipcode: string;
    @prop()
    address: string;
    @prop()
    address2: string;
    @prop()
    region: string;
    @prop()
    @maxLength({ value: 255 })
    state: string;
    @prop()
    primaryLocation: boolean;
    @prop()
    nearestBigCity: string;
    @prop()
    willTravel: boolean;
    @prop()
    howFar: string;
    @prop()
    deleted: boolean = false;
    countryObservable: Observable<CountryObject[]>;
    @prop()
    @maxLength({ value: 255 })
    majorCity: string;
    @prop()
    @maxLength({ value: 255 })
    majorCountry: string;
    @prop()
    @maxLength({ value: 100 })
    majorZipcode: string;
    @prop()
    @maxLength({ value: 255 })
    majorState: string;
}
