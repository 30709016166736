import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneExtension'
})
export class PhoneExtensionPipe implements PipeTransform {

  transform(value: string, isUpFirstCase: boolean = true): string {
    let result: string = value;

    try{
      result = isUpFirstCase ? `Ext. ${value || ''}` : `ext. ${value || ''}`;
    }
    catch(ex){
      console.error(ex);
    }

    return result;
  }

}
