import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AddEditTaskComponent } from "../../task-management/add-edit-task/add-edit-task.component";

@Injectable({
  providedIn: 'root'
})
export class CRMTaskService {
  isOpenTask: boolean = false;
  dialogTaskRef: MatDialogRef<AddEditTaskComponent>;
}
