<ng-container *ngIf="isEditing && !readonlyInline; else readonlyModeTmpl">
  <mat-form-field class="mat-focused" [ngClass]="[
        removePaddingBottom ? 'remove-padding-form' : '',
        accessFullWidth ? 'access-full-width': 'full-width',
        ]" [ngStyle]="{'width': widthDisplay ? widthDisplay : ''}" appearance="legacy" floatLabel="never">
    <input id="myInput" #myInput class="custom-mat-input parent-font" matInput type="text" [formControl]="inputControl"
      [errorStateMatcher]="matcher" (change)="inputChange()" (keydown.enter)="enterToSubmit? onConfirm() : null"
      (keydown.escape)="escapeToCancel? onCancel() : null">
    <mat-error *ngIf="inputControl.hasError('required') && !usingMessageParent" trim="blur">
      The field is not <strong>empty</strong>
    </mat-error>
    <mat-error *ngIf="inputControl.errors" trim="blur">
      {{messageError}}
    </mat-error>
    <button *ngIf="isAccess" mat-icon-button matSuffix style="color:blue" (click)="goToLink()">
      <mat-icon class="custom-icon">open_in_new</mat-icon>
    </button>
    <button mat-icon-button matSuffix color="primary" (click)="onConfirm()">
      <mat-icon class="custom-icon">save</mat-icon>
    </button>
    <button mat-icon-button matSuffix color="warn" (click)="onCancel(); eventBlur = null">
      <mat-icon class="custom-icon">close</mat-icon>
    </button>
  </mat-form-field>
</ng-container>
<ng-template #readonlyModeTmpl>
  <div class="w-100 d-flex justify-content-between">
    <div class="w-100" style="min-height: 100%">
      <b> {{label ? label + ": " : ''}}</b>
      <div [ngClass]="inputData != null ? 'text-limit' : ''" *ngIf="!prefix; else prefixDisplay"
        [ngStyle]="{'max-width': width , 'display': display, 'width': widthDisplay ? widthDisplay : '', 'text-align': textCenter ? 'center': ''}"
        id="shownInputLabel" class="textarea-inline-control parent-font h-100" (click)="setEditMode(true)">
        {{ isCurrency? (showInputData | currency) : (showInputData) || defaultNullLabel }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #prefixDisplay>
  <div [ngClass]="inputData != null ? 'text-limit' : ''" [ngStyle]="{'max-width': width , 'display': 'flex', 'width': widthDisplay ? widthDisplay : '',
    'color': colorText ? colorText : ''}" id="shownInputLabel" class="textarea-inline-control parent-font"
    (click)="setEditMode(true)">
    <span [innerHtml]="prefix"></span>
    {{isCurrency?
    (showInputData | currency) : (showInputData)
    || defaultNullLabel}}
  </div>
</ng-template>
