<!-- <div fxLayout="row-reverse">
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider> -->
<div mat-dialog-title [ngStyle]="{'min-width': '40vw'}">
  {{data?.header || 'Send Email'}}
  <button *ngIf="isSendNew; else btnNewEmail" mat-button color="primary" (click)="updateFlagCreateEmail()">Using
    template</button>
  <ng-template #btnNewEmail>
    <button *ngIf="!isEditEmail" mat-button color="primary" (click)="updateFlagCreateEmail()">
      Create email
    </button>
    <button *ngIf="isEditEmail" mat-button color="primary" (click)="editEmailTemplate()">
      Back
    </button>
  </ng-template>
</div>
<mat-dialog-content *ngIf="!isSendNew && !isEditEmail" style="padding-bottom: 10px;">
  <div *ngIf="(data && data.removeLatestProfile) ? false : true">
    <h4>
      Last Email Sent Information:
    </h4>
    <div>
      <div class="alert alert-warning" role="alert" *ngIf="!lastSentEmail; else sentInformation">
        No Mail has been sent to this {{lastSentEmailEntity}} yet.
      </div>
      <ng-template #sentInformation>
        <mat-card>
          <div class="row">
            <div class="col-lg-6">
              <b>Subject: </b> {{lastSentEmail ? lastSentEmail['titleLastSend'] : 'Unknown'}}
            </div>
            <div class="col-lg-6">
              <b>Date Sent:</b> {{lastSentEmail.dateCreated | date: 'medium'}}
            </div>
          </div>
        </mat-card>
      </ng-template>
    </div>
    <mat-divider></mat-divider>
  </div>

  <h4>
    Email Templates Available
  </h4>
  <form class="example-form">
    <mat-form-field appearance="standard">
      <mat-label>Search Template</mat-label>
      <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSearchSelected($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          <div>
            <b>Name: </b> {{option.name}} |
            <b>Title: </b> {{option.title}}
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <div class="container" *ngIf="!isLoading;else skeleton;">
    <mat-radio-group>
      <mat-radio-button *ngFor="let emailTemplate of linkscopeTemplateEmail?.data; index as index" [value]="index"
        [checked]="selectedEmailTemplate.id == linkscopeTemplateEmail?.data[index].id ? true : false"
        (change)="onChangeEmailTemplate($event)">
        <mat-expansion-panel>
          <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
            <mat-panel-title matTooltip="Subject" fxLayoutAlign="start center">
              {{emailTemplate.title | truncateText: [30,'...']}}
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end center">
              <div fxLayout="column" fxLayoutAlign="end end">
                <button *ngIf="selectedEmailTemplate.id == linkscopeTemplateEmail?.data[index].id" mat-button
                  color="primary" (click)="editEmailTemplate()"
                  style="padding: 0; min-width: 0; line-height: normal; margin-top: 0.5rem;">
                  Edit
                </button>
                <div matTooltip="Name">
                  <b>{{emailTemplate.name}}</b>
                </div>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <h4>
            Note:
          </h4>
          <div>
            {{emailTemplate.note}}
          </div>
          <mat-divider></mat-divider>
          <h4>
            Body:
          </h4>
          <div class="iframe-holder container" bind-innerHTML=" emailTemplate.body | safeHtml"></div>
          <!-- <p>I'm visible because I am open</p> -->
        </mat-expansion-panel>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>

<app-add-new-email #addNewEmail [hidden]="!isSendNew" [profileModel]="profileModel" [emailSubject]="emailSubject"
  [emailTemplate]="data.selectedTemplate" [isNormalEditor]="isNormalEditor" [entity]="entity" [buyerModel]="buyerModel"
  [bodyEmbedded]="bodyEmbedded" [isClassicEditor]="data?.isClassicEditor ?? true" [userModel]="userModel"
  [targetEmail]="targetEmail" [emailSend]="emailSend" [(senderId)]="senderId" [isShowSenderId]="isShowSenderId">
</app-add-new-email>
<app-edit-email-linkscope #editEmailLinkScope *ngIf="isEditEmail" [profileModel]="profileModel"
  [selectedTemplate]="selectedEmailTemplate" [isNormalEditor]="isNormalEditor" [entity]="entity"
  [buyerModel]="buyerModel" [bodyEmbedded]="bodyEmbedded" [userModel]="userModel" [targetEmail]="targetEmail"
  [isClassicEditor]="data?.isClassicEditor ?? true" [emailSend]="emailSend" [(senderId)]="senderId"
  [isShowSenderId]="isShowSenderId">
</app-edit-email-linkscope>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="closeDialog()">Cancel</button>
  <button mat-raised-button color="primary" class="float-right" *ngIf="!externalHandleSubmit"
    [disabled]="isLoading || (isSendNew && !sendNewEmail.frmTitle.valid)" [nbSpinner]="submitLoading" debouncedClick
    (throttleClick)="confirmSendCampaign()">
    <mat-icon fontSet=" material-icons-outlined">send</mat-icon>Send
  </button>
  <button mat-button color="primary" class="float-right" *ngIf="externalHandleSubmit"
    [disabled]="isLoading || (isSendNew && !sendNewEmail.frmTitle.valid)" [nbSpinner]="submitLoading" debouncedClick
    (throttleClick)="clickToNext()">
    {{data.externalButtonLabel || 'Next'}}
  </button>
</mat-dialog-actions>
<ng-template #skeleton>
  <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
</ng-template>