import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { perksName } from 'src/app/modules/admin/customer/default-perk';
import { CustomerPlanManagementComponent } from 'src/app/modules/admin/customer/customer-plan-management/customer-plan-management.component';
import { CustomerService } from 'src/app/modules/admin/customer/customer.service';

@Component({
  selector: 'app-ask-to-upgrade',
  templateUrl: './ask-to-upgrade.component.html',
  styleUrls: ['./ask-to-upgrade.component.scss']
})
export class AskToUpgradeComponent implements OnInit {

  @ViewChild('addBtn') addBtn: TemplateRef<any>;
  @ViewChild('themeBtn') themeBtn: TemplateRef<any>;
  @ViewChild('loadingAPI') loadingAPI: TemplateRef<any>;
  @ViewChild('seoAuditBtn') seoAuditBtn: TemplateRef<any>;
  @ViewChild('readAbilityBtn') readAbilityBtn: TemplateRef<any>;
  @ViewChild('cardUpgradePlan') cardUpgradePlan: TemplateRef<any>;
  @ViewChild('requestToPublishBtn') requestToPublishBtn: TemplateRef<any>;

  currentPlan;
  perk: string;
  perksName = perksName;
  planValue: [string, any];
  lastPaymentStatus: string;
  isMobile: boolean = false;
  isMobileAndTablet: boolean = false;
  valueVerify: [string, any];

  constructor(
    private dialog: MatDialog,
    private customerService: CustomerService
  ) {
    let width = window.innerWidth;
    this.isMobile = width > 599 ? false : true;
    this.isMobileAndTablet = width > 1279 ? false : true;
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = window.innerWidth <= 599;
    this.isMobileAndTablet = window.innerWidth <= 1279;
  }

  async getCurrentPlan() {
    try {
      const data = await this.customerService.getCurrentPlan().toPromise();
      if (data.result) {
        this.currentPlan = data.result;
      }
      else {
        this.currentPlan = null;
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  async getLastInvoice() {
    const data = await this.customerService.getInvoicePaging().toPromise();
    if (data.result && data.result.data.length > 0) {
      this.lastPaymentStatus = (data.result.data[0].status === 'voided') ? data.result.data[0].status : data.result.data[0].payment_status;
    }
  }

  async clickToBtn() {
    const dialogUpgrade = this.dialog.open(ConfirmModalComponent, {
      maxWidth: (this.isMobile) ? '90vw' : '45vw',
      maxHeight: (this.isMobile) ? '90vh' : '80vh',
      data: {
        externalTemplate: this.cardUpgradePlan,
        yesTitle: "Upgrade Plan",
        falseTitle: "Continue",
      }
    })

    dialogUpgrade.afterClosed().subscribe(async (respDialog) => {
      if (respDialog) {
        const waitingDialog = this.dialog.open(this.loadingAPI, {
          maxHeight: '50vh',
          width: '50vw',
        });
        await this.getCurrentPlan();
        await this.getLastInvoice();
        waitingDialog.close();

        const dialogRef = this.dialog.open(CustomerPlanManagementComponent, {
          minWidth: (this.isMobile) ? '90vw' : '45vw',
          maxHeight: (this.isMobile) ? '90vh' : '80vh',
          disableClose: true,
          autoFocus: false,
          data: {
            plan: this.currentPlan,
            completePayment: (!this.currentPlan?.next_plan_code && (this.lastPaymentStatus !== 'failed' || !(this.currentPlan?.plan?.name)))
          }
        });

        dialogRef.afterClosed().subscribe(resp => {
          if (resp) this.customerService.clearPerks();
        });
      }
    })
  }
}
