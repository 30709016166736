import { date, digit, numeric, NumericValueType, prop, required } from "@rxweb/reactive-form-validators";
import { Contact, ProfileDetailModel } from "../../profile-management/profile-detail.model";

export class ConvertSaleLeadInputModel {
    constructor(

    ) {
        this.isNewAccount = true;
        this.isNewContact = true;
        this.isNewOpportunity = true;
        this.amount = 0;
    }
    @prop()
    isNewAccount: boolean;
    @prop()
    isNewContact: boolean;
    @prop()
    isNewOpportunity: boolean;
    @required({
        conditionalExpression: function () {
            return this.isNewAccount === true ? true : false;
        }
    })
    newAccountName: string;
    @required({
        conditionalExpression: function () {
            return this.isNewContact === false ? true : false;
        }
    })
    contact: Contact;
    @required({
        conditionalExpression: function () {
            return this.isNewOpportunity === true ? true : false;
        }
    })
    opportunityName: string;
    @required({
        conditionalExpression: function () {
            return this.isNewAccount === false ? true : false;
        }
    })
    saleAccountProfileModel: ProfileDetailModel;
    // extension properties
    @prop()
    existedContactId: number;
    @prop()
    existedSaleAccountId: string;
    @prop() orgLeadId: string;
    @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
    amount: number;
    @prop()
    @date({ message: 'Please enter valid format: MM/DD/YYYY.Ex: 20/11/2022' })
    closeDate: Date | string | null;
    @prop()
    @date({ message: 'Please enter valid format: MM/DD/YYYY.Ex: 20/11/2022' })
    secondaryCloseDate: Date | string | null;
}


export class RecommentSaleLeadConvertInput {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    company: string | null;
}