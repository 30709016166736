import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactActivityPage, ContactActionLog } from './contact-activity.model';
import { Observable } from 'rxjs';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ActivityLog } from '../../campaign-management/campaign-tracking.model';

@Injectable({
  providedIn: 'root'
})
export class ContactActivityService {
  baseUrl: string = environment.apiContactActivityLog;

  constructor(private http: HttpClient) { }

  getContactActivityLog(paging: ContactActivityPage): Observable<ReturnResult<PagedData<ContactActionLog>>> {
    return this.http.post<ReturnResult<PagedData<ContactActionLog>>>(`${this.baseUrl}/GetContactActivitityLog`, paging);
  }

  deleteContactActivityLog(activityLogId: number): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteContactActivityLog`, {
      ActivityLogId: activityLogId
    })
  }

  leaveACommment(user: any, profileId: string, body: string): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/LeaveAComment`, {
      action: `Comment`,
      body: body,
      userId: user?.nameid || "",
      userName: user?.userName || "Unknown",
      profileId: profileId
    })
  }

  getContactActivityDetail(referenceLogId: string): Observable<ReturnResult<ContactActionLog>> {
    return this.http.get<ReturnResult<ContactActionLog>>(`${this.baseUrl}/GetContactActionDetail?referenceLogId=${referenceLogId}`);
  }

  updateComment(activityLogId: number, newComment: string): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/UpdateComment`, {
      ActivityLogId: activityLogId,
      NewComment: newComment
    });
  }
}
