import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'src/app/shared/utility/Helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-import-helper',
  templateUrl: './import-helper.component.html',
  styleUrls: ['./import-helper.component.scss']
})
export class ImportHelperComponent implements OnInit {

  environment = environment;
  constructor(public dialModalRef: MatDialogRef<ImportHelperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }
  downloadFileError() {
    Helper.downloadFile(this.data.fileError);
  }
}
