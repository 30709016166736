import { ActivityLog } from './../../campaign-management/campaign-tracking.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Observable, ReplaySubject, Subject } from 'rxjs';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { TaskStatus, TaskType, Priority } from './task-board-lane/task-status.model';
import { GMTModel, MultipleAssignTaskModel, MultipleChangeStatusTask, ResolutionCode, ReturnSuccessIdModel, Task, TaskFilterProp, TaskTemplate } from './task-board-lane/task/task.model';
import { TaskActivityLog } from '../task-activity-log/task-activity-log.model';
import { ProfileModel } from '../../profile-management/profile-model';
import { Contact, ProfileDetailModel } from '../../profile-management/profile-detail.model';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { TaskPlan } from '../../task-plan-management/task-plan.model';
import { tap } from 'rxjs/operators';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
var phoneDetect = require('../../../../shared/utility/PhoneDetectTimeZone');
@Injectable({
  providedIn: 'root'
})
export class TaskBoardService {
  baseUrl = environment.apiTaskManagement;
  settingUrl = environment.apiSetting;
  onBoardingUrl = environment.apiOnboarding;
  taskBoardLst = new BehaviorSubject<TaskStatus[]>(undefined);
  private _taskTypeList$: ReplaySubject<TaskType[]> = new ReplaySubject<TaskType[]>(1);
  private _taskStatusList$: ReplaySubject<TaskStatus[]> = new ReplaySubject<TaskStatus[]>(1);
  private _priorityList$: ReplaySubject<Priority[]> = new ReplaySubject<Priority[]>(1);
  _taskReplay$: ReplaySubject<Task> = new ReplaySubject<Task>(1);
  private tastLists$: ReplaySubject<Task[]> = new ReplaySubject<Task[]>(1);
  private taskDone$: ReplaySubject<number> = new ReplaySubject<number>(1);

  private resolutionCodeLst$: ReplaySubject<ResolutionCode[]> = new ReplaySubject<ResolutionCode[]>(1);
  //2022-03-02 vuonglqn add start
  private filterTask$: ReplaySubject<TaskFilterProp[]> = new ReplaySubject<TaskFilterProp[]>(1);
  private viewModelTask$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private filterPageTask$: ReplaySubject<any> = new ReplaySubject<any>(1);
  //2022-03-02 vuonglqn add end
  //2022-03-08 vuonglqn add start
  private listTaskSeletedCalendar$: ReplaySubject<Task[]> = new ReplaySubject<Task[]>(1);
  private isRefreshEditInline$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  //2022-03-08 vuonglqn add end
  private pageNumber$ = new BehaviorSubject<number>(0);

  private _userListObservable = new Subject<Task[]>();
  private isOnlyMyIssues = new Subject<boolean>();

  // flag initial for task:
  isInitTaskStatus: boolean = false;
  isInitTaskType: boolean = false;
  isInitTaskPriority: boolean = false;

  pageNumber = this.pageNumber$.asObservable();

  constructor(private http: HttpClient, private settingPoolService: SettingPoolService) { }


  getTaskDone(): Observable<number> {
    return this.taskDone$.asObservable();
  }

  setPageNumber(pageNumber: number) {
    this.pageNumber$.next(pageNumber);
  }
  setTaskToReplay(task: Task) {
    this._taskReplay$.next(task);
  }
  getTaskReplay(): Observable<Task> {
    return this._taskReplay$.asObservable();
  }
  refreshTaskById(id: number) {
    return this.http.get<ReturnResult<Task>>(`${this.baseUrl}/GetTaskById/${id}`).pipe(tap(resp => {
      if (resp.result) this._taskReplay$.next(resp.result);
    }));
  }

  setTaskBoardLst(data: TaskStatus[]) {
    if (data) this.taskBoardLst.next(data);
  }

  getTaskBoardLst(): Observable<TaskStatus[]> {
    return this.taskBoardLst.asObservable();
  }

  refreshTaskBoardLst(userId: string = null, filters: TaskFilterProp[] = [], currentPage: number) {
    this.setPageNumber(currentPage);
    const page = this.pageNumber$.getValue();

    let timezoneFilter: number[] = window.localStorage.getItem('timezones') ? JSON.parse(window.localStorage.getItem('timezones')) : [];
    this.http.post<ReturnResult<TaskStatus[]>>(`${this.baseUrl}?pageNumber=${page}`, { userId, filters }).subscribe(resp => {
      if (resp.result) {
        if (page === 0) {
          let initData = resp.result
          // handler time zone
          if (timezoneFilter.length > 0) {
            initData.forEach(taskStatus => {
              // filter by timezone:
              if (taskStatus.tasks) {
                // check each task:
                taskStatus.tasks = taskStatus.tasks.filter(task => {
                  // check timezone
                  if (task.primaryPhoneNumber) {
                    let taskTimeZone = phoneDetect.getLocalInfo(task.primaryPhoneNumber, { zone_display: 'offset' })?.time.zone;
                    if (taskTimeZone) {
                      let value = timezoneFilter.findIndex(x => taskTimeZone.includes(x));
                      if (value != -1) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                });
                // Re-calculate task length
                taskStatus.taskLength = taskStatus.tasks.length;
              }
            })
          }
          // end handler time zone
          this.taskBoardLst.next(initData);
        }
        else {
          var newData = this.taskBoardLst.getValue();
          newData.forEach(e => {
            var taskData = resp.result.find(x => x.taskStatusId === e.taskStatusId);
            e.tasks = e.tasks.concat(taskData.tasks);
          });

          // handler time zone
          if (timezoneFilter.length > 0) {
            newData.forEach(taskStatus => {
              // filter by timezone:
              if (taskStatus.tasks) {
                // check each task:
                taskStatus.tasks = taskStatus.tasks.filter(task => {
                  // check timezone
                  if (task.primaryPhoneNumber) {
                    let taskTimeZone = phoneDetect.getLocalInfo(task.primaryPhoneNumber, { zone_display: 'offset' })?.time.zone;
                    if (taskTimeZone) {
                      let value = timezoneFilter.findIndex(x => taskTimeZone.includes(x));
                      if (value != -1) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                });

                // Re-calculate task length
                taskStatus.taskLength = taskStatus.tasks.length;

              }
            })
          }

          // end handler time zone
          this.taskBoardLst.next(newData);
        }
      }
    });
  }
  taskTypeList(): Observable<TaskType[]> {
    return this._taskTypeList$.asObservable();
  }
  getAllTaskType() {
    this.http.get<ReturnResult<TaskType[]>>(`${this.baseUrl}/TaskType`).subscribe(resp => {
      if (resp.result) {
        this._taskTypeList$.next(resp.result);
        this.isInitTaskType = true;
      }
    });
  }
  //2021-31-12 HMTien add start
  getTaskType() {
    return this.http.get<ReturnResult<TaskType[]>>(`${this.baseUrl}/TaskType`);
  }
  //2022-31-12 HMTien add end
  taskStatusList(): Observable<TaskStatus[]> {
    return this._taskStatusList$.asObservable();
  }
  getAllTaskStatus() {
    this.http.get<ReturnResult<TaskStatus[]>>(`${this.baseUrl}/TaskStatus`).subscribe(resp => {
      if (resp.result) {
        this._taskStatusList$.next(resp.result);
        this.isInitTaskStatus = true;
      }
    });
  }
  taskPriorityList(): Observable<Priority[]> {
    return this._priorityList$.asObservable();
  }
  getAllPriority() {
    this.http.get<ReturnResult<Priority[]>>(`${this.baseUrl}/Priority`).subscribe(resp => {
      if (resp.result) {
        this._priorityList$.next(resp.result);
        this.isInitTaskPriority = true;
      }
    });
  }
  saveTask(task: Task): Observable<ReturnResult<ReturnSuccessIdModel>> {
    return this.http.post<ReturnResult<ReturnSuccessIdModel>>(`${this.baseUrl}/SaveTask`, task)
      .pipe(tap(x => window.sessionStorage.setItem('modifyTask', 'true')));
  }
  // tienlm add start
  updateTaskStatusOutSideBoard(taskId: number, statusId: number): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/EditStatus`, {
      taskId, statusId
    });
  }
  deleteTask(taskId: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteTask/${taskId}`);
  }
  // tienlm add start
  updateTaskStatus(tasks: Task[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdateTaskStatus`, tasks);
  }
  getTaskList(): Observable<Task[]> {
    return this.tastLists$.asObservable();
  }
  refreshTaskList(userId: string = null, filters: TaskFilterProp[] = []): void {
    this.http.post<ReturnResult<Task[]>>(`${this.baseUrl}/GetAllListOfTask`, { userId, filters }).subscribe(resp => {
      if (resp.result) {
        this.tastLists$.next(resp.result);
      }
    });
  }
  updateListTasks(tasks: Task[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdateListTasks`, tasks);
  }
  refreshHistoryTask(id: number): Observable<ReturnResult<TaskActivityLog[]>> {
    return this.http.get<ReturnResult<TaskActivityLog[]>>(`${this.baseUrl}/TaskActivityLog/${id}`);
  }
  SelfGetStatusList(): Observable<ReturnResult<TaskStatus[]>> {
    return this.http.get<ReturnResult<TaskStatus[]>>(`${this.baseUrl}/TaskStatus`)
      .pipe(tap(resp => this._taskStatusList$.next(resp.result)));
  }
  selfGetAllPriority(): Observable<ReturnResult<Priority[]>> {
    return this.http.get<ReturnResult<Priority[]>>(`${this.baseUrl}/Priority`)
      .pipe(tap(resp => this._priorityList$.next(resp.result)));
  }
  selfGetTaskById(taskId: number): Observable<ReturnResult<Task>> {
    return this.http.get<ReturnResult<Task>>(`${this.baseUrl}/GetTaskById/${taskId}`);
  }
  selfGetListResolutionCode(): Observable<ResolutionCode[]> {
    return this.http.post<ResolutionCode[]>(`${this.baseUrl}/GetListResolutionCodeAsync`, {});
  }
  getProfileData(taskId: number, relationshipName: string): Observable<ReturnResult<ProfileDetailModel>> {
    const params = { taskId, relationshipName };
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.baseUrl}/ProfileRelationship`, { params: params });
  }
  getContactData(taskId: number, relationshipName: string): Observable<ReturnResult<Contact>> {
    const params = { taskId, relationshipName };
    return this.http.get<ReturnResult<Contact>>(`${this.baseUrl}/ContactRelationship`, { params: params });
  }
  getListResolutionCode(): Observable<ResolutionCode[]> {
    return this.resolutionCodeLst$.asObservable();
  }
  refreshGetListResolutionCode() {
    this.http.get<ResolutionCode[]>(`${this.baseUrl}/GetListResolutionCodeAsync`).subscribe(resp => {
      if (resp) {
        this.resolutionCodeLst$.next(resp);
      }
    });
  }

  //2022-01-19 vuonglqn add start
  getTaskByTaskOrderId(
    indexTask: number,
    selectStatus: number,
    isNext: boolean,
    filterList: TaskFilterProp[] = [],
    pageFilter: Page = null,
    viewModel: string = 'kaban',
    currentTaskId: number = -1,
    indexPushUrgent: number = null,
  ): Observable<ReturnResult<any>> {
    var nextPreviousTask = {
      filters: filterList,
      indexTask,
      isNext,
      taskStatusSelected: selectStatus,
      pageFilter,
      viewModel,
      currentTaskId,
      indexPushUrgent,
    }
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/GetTaskByTaskOrderId`, nextPreviousTask);
  }
  //2022-01-19 vuonglqn add end
  getTaskTemplate(): Observable<ReturnResult<TaskTemplate[]>> {
    return this.http.get<ReturnResult<TaskTemplate[]>>(`${this.baseUrl}/GetTaskTemplate`);
  }
  executeAutoMateTask(taskId: number) {
    return this.http.post(`${this.baseUrl}/ExecuteAutoMateTask`, {
      "taskId": taskId,
      "taskScriptGroupId": 1
    })
  }
  getTaskPaging(page: Page, dashboard: boolean = false): Observable<ReturnResult<PagedData<Task>>> {
    return this.http.post<ReturnResult<PagedData<Task>>>(`${this.baseUrl}/GetTaskPaging?dashboard=${dashboard}`, page);
  }
  saveTaskInline(task: Task) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveTaskInline`, task);
  }

  getFilterTaskProp(): Observable<TaskFilterProp[]> {
    return this.filterTask$.asObservable();
  }

  updateFilterTaskProp(filters: TaskFilterProp[]) {
    this.filterTask$.next(filters);
  }

  getTaskTypeDone() {
    // 2023-01-02 ducqm start add
    return this.http.get<ReturnResult<number>>(`${this.settingUrl}/GetTaskDoneId`).subscribe(e => {
      this.taskDone$.next(e.result);
    });
    // this.settingPoolService.getSettingByKeyAndGroup( "DONE_TASK_ID", "TASK").subscribe(resp => {
    //   this.taskDone$.next((resp && resp.resutt) ? resp.result.value : null);
    // })
    // 2023-01-02 ducqm end add
  }
  getTaskStatusDone(): Observable<ReturnResult<number>> {
    return this.http.get<ReturnResult<number>>(`${this.settingUrl}/GetTaskDoneId`);
  }
  updateViewModelObser(viewModel: string) {
    this.viewModelTask$.next(viewModel);
  }

  getViewModelObser(): Observable<string> {
    return this.viewModelTask$.asObservable();
  }

  updateFilterPagingObser(page: Page, pageData: PagedData<Task>) {
    return this.filterPageTask$.next({ page, pageData });
  }

  getFilterPagingObser(): Observable<any> {
    return this.filterPageTask$.asObservable();
  }
  multipleAssignTasks(multipleAssignTaskModel: MultipleAssignTaskModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MultipleAssignTasks`, multipleAssignTaskModel);
  }

  updateListTaskSeletedCalendar(tasks: Task[]) {
    this.listTaskSeletedCalendar$.next(tasks);
  }

  getListTaskSeletedCalendar(): Observable<Task[]> {
    return this.listTaskSeletedCalendar$.asObservable();
  }

  updatIsRefreshEditInline(isRefresh: boolean) {
    this.isRefreshEditInline$.next(isRefresh);
  }

  getIsRefreshEditInline(): Observable<boolean> {
    return this.isRefreshEditInline$.asObservable();
  }
  getTaskPlanPaging(page: Page): Observable<ReturnResult<PagedData<TaskPlan>>> {
    return this.http.post<ReturnResult<PagedData<TaskPlan>>>(`${this.baseUrl}/GetTaskPlanPaging`, page);
  }
  SaveTaskPlan(model: TaskPlan): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveTaskPlan`, model);
  }
  deleteTaskPlan(id: number): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/DeleteTaskPlan/${id}`);
  }
  deleteTaskPlanRange(ids: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteTaskPlanRange`, ids);
  }
  autoCreateTaskOnBoardingData(pid: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AutoCreateTaskOnBoardingData/${pid}`, {});
  }

  saveSubmitOnBoardingForm(pid: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.onBoardingUrl}/SaveSubmitOnBoardingForm/${pid}`, {});
  }

  duplicateTask(id: number): Observable<ReturnResult<Task>> {
    return this.http.get<ReturnResult<Task>>(`${this.baseUrl}/DuplicateTask/${id}`);
  }
  getTaskPagingByRelationshipId(page: Page, relationshipId: string): Observable<ReturnResult<PagedData<Task>>> {
    return this.http.post<ReturnResult<PagedData<Task>>>(`${this.baseUrl}/GetTaskPagingByRelationshipId/${relationshipId}`, page);
  }

  bulkAddTask(page: Page, task: Task, type: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkAddTask`, { page, task, type });
  }
  getAllTaskTypeModel(): Observable<ReturnResult<TaskType[]>> {
    return this.http.get<ReturnResult<TaskType[]>>(`${this.baseUrl}/TaskType`).pipe(tap(resp => {
      if (resp.result) {
        this._taskTypeList$.next(resp.result);
        this.isInitTaskType = true;
      }
    }));
  }
  getAllPriorityModel(): Observable<ReturnResult<Priority[]>> {
    return this.http.get<ReturnResult<Priority[]>>(`${this.baseUrl}/Priority`).pipe(tap(resp => {
      if (resp.result) {
        this._priorityList$.next(resp.result);
        this.isInitTaskPriority = true;
      }
    }));
  }

  multipleChangesStatusOfTasks(model: MultipleChangeStatusTask) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ChangesStatusOfTasks`, model);
  }

  syncClickUpTask(): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SyncClickUp`, null);
  }

  deleteByFilter(model: { idArr: number[], page: Page }): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteByFilter`, model);
  }

  setIsOnlyMyIssues(val: boolean) {
    return this.isOnlyMyIssues.next(val);
  }

  getIsOnlyMyIssues() {
    return this.isOnlyMyIssues.asObservable();
  }
}
