<h1 mat-dialog-title>Friendly Url History</h1>
<div mat-dialog-content>
    <app-primas-table #primasTable (onRefresh)="refreshData($event)" [columnsTable]="columns"
        [resource]="resource.table" [tableAction]="false" [optionHeight]="'45vh'" [allowSelectRow]="false">
    </app-primas-table>
</div>
<div mat-dialog-actions align="end">
    <button  mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
</div>

<ng-template #status let-row="row" let-column="column">
    <div style="border-radius: 10px;" class="primary-tag" [style.background]="ffcUrlColor['Redirect']">
        <mat-chip class="primary-tag" selected [style.background]="ffcUrlColor['Redirect']">Redirect</mat-chip>
    </div>
</ng-template>

