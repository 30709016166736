import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonParse'
})
export class JsonParsePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let result = {};
    try {
      result = JSON.parse(value);
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }

}
