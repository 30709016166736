import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ShadowProfileEnum } from '../../enums/shadow-profile.enum';
import { FormControl } from '@angular/forms';
import { KeyPairsValueObjectAPI, KeyPairsValueTypeObjectAPI } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { environment } from 'src/environments/environment';
import { fromEvent, Observable, Subject } from 'rxjs';
import { ReturnResult } from '../../models/return-result';
import { SettingCompanyViewModel } from 'src/app/modules/admin/setting-management/setting-company/setting-company';
import { SaleLeadService } from 'src/app/modules/admin/sale-lead-management/sale-lead.service';
import { SaleAccountService } from 'src/app/modules/admin/sale-account-management/sale-account.service';
import { OpportunityManagementService } from 'src/app/modules/admin/opportunity-management/opportunity-management.service';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { Router } from '@angular/router';
import { SettingService } from '../../services/setting.service';
import { UserExperienceTrackingService } from '../user-experience-tracking/user-experience-tracking.service';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { EntityNameEnums } from '../../enums/entity-color.enums';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-move-object-dialog',
  templateUrl: './move-object-dialog.component.html',
  styleUrls: ['./move-object-dialog.component.scss']
})
export class MoveObjectDialogComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() selectedForm: FormControl = new FormControl(ShadowProfileEnum[ShadowProfileEnum.OPPORTUNITY]);
  @Input() typeObjects: KeyPairsValueTypeObjectAPI[] = [
    { key: ShadowProfileEnum[ShadowProfileEnum.OPPORTUNITY], displayType: environment.titleOpportunity, iconType: 'account_circle', value: (data) => this.opportunityService.searchOpportunity(data), color: '#004d4d', propId: 'profileId', propName: 'displayName' } as KeyPairsValueTypeObjectAPI,
    { key: ShadowProfileEnum[ShadowProfileEnum.SALEACCOUNT], displayType: environment.titleAccount, iconType: 'account_circle', value: (data) => this.saleAccountService.searchSaleAccount(data), color: '#808000', propId: 'profileId', propName: 'displayName' } as KeyPairsValueTypeObjectAPI,
    { key: ShadowProfileEnum[ShadowProfileEnum.LEADS], displayType: environment.titleLead, iconType: 'account_circle', value: (data) => this.saleLeadService.searchSaleLead(data), color: '#5c5c8a', propId: 'profileId', propName: 'displayName' } as KeyPairsValueTypeObjectAPI,
  ];

  @Input() apiGetSelectedObjects: Observable<ReturnResult<KeyPairsValueObjectAPI[]>> = null;
  @Input() selectedObjects: KeyPairsValueObjectAPI[] = [];
  @Input() customClassIndex: string;
  @Input() isShowMatChip: boolean = true;

  moveObject: string = "";
  objectAction: string = "Move";
  selectedValue: any = null;
  // inputSearch: ElementRef;
  // @ViewChild('inputSearch') set content(content: ElementRef) {
  //   if (this.inputSearch == null && content) {
  //     this.inputSearch = content;
  //     this.triggerSearchProfile();
  //   }

  // }

  isLoading: boolean = false;
  isLoadingSearch: boolean = false;

  selected: KeyPairsValueTypeObjectAPI = this.typeObjects ? this.typeObjects.find((x, index) => index == 0) : null;
  searchForm: FormControl = new FormControl('');
  objects: any[] = [];


  defaultAvatar = SettingCompanyViewModel.golbalDefaultAvatar;
  protected _onDestroy = new Subject<void>();
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<MoveObjectDialogComponent>,
    private saleLeadService: SaleLeadService,
    private saleAccountService: SaleAccountService,
    private opportunityService: OpportunityManagementService,
    private profileService: ProfileService,
    public element: ElementRef,
  ) {
    if (data) {
      if (data.object) this.moveObject = data.object;
      if (data.objectAction) this.objectAction = data.objectAction ?? this.objectAction
    }
  }

  ngOnInit(): void {
    if (this.apiGetSelectedObjects) {
      this.isLoading = true;
      this.apiGetSelectedObjects.pipe(take(1))
        .subscribe({
          next: resp => {
            if (resp.result) {
              this.selectedObjects = resp.result;
              this.configInputSelectedObjects();
            }
          },
          complete: () => this.isLoading = false
        });
    }
  }

  ngAfterViewInit() {
    this.triggerSearchProfile();
  }

  triggerSearchProfile() {
    //fromEvent(this.inputSearch.nativeElement, 'keyup')
    this.searchForm.valueChanges.pipe(
      filter(res => res.length > 2 || res.length === 0),
      debounceTime(1000),
    ).subscribe((keyword: string) => {
      this.searchObjects(keyword);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeSelectedForm = changes.selectedForm;
    const changeSelectedObjects = changes.selectedObjects;

    if (changeSelectedForm && JSON.stringify(changeSelectedForm.currentValue) != JSON.stringify(changeSelectedForm.previousValue))
      if (this.typeObjects && this.typeObjects.length > 0)
        this.selected = this.typeObjects.find(x => x.key == changeSelectedForm.currentValue.value);

    if (changeSelectedObjects && JSON.stringify(changeSelectedObjects.currentValue) != JSON.stringify(changeSelectedObjects.previousValue))
      this.configInputSelectedObjects();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  configInputSelectedObjects() {
    if (this.typeObjects && this.typeObjects.length > 0)
      this.selectedObjects = this.selectedObjects.map(x => {
        var item = this.typeObjects.find(y => y.key == x.key);

        if (!item && x.key == ShadowProfileEnum[ShadowProfileEnum.LEADS]) {
          item = {
            key: ShadowProfileEnum[ShadowProfileEnum.LEADS],
            displayType: environment.titleLead,
            color: '#5c5c8a',
            propId: 'profileId',
            propName: 'displayName',
          } as KeyPairsValueTypeObjectAPI;
        }

        return Object.assign(x, {
          color: item?.color ?? '',
          displayType: item?.displayType ?? '',
        });
      });
  }

  searchObjects(data: string) {
    this.objects = [];
    if (data === '') {
      return;
    }

    this.isLoadingSearch = true;
    if (this.selected && this.selected.value) {
      this.selected.value(data).pipe(takeUntil(this._onDestroy))
        .subscribe({
          next: resp => {
            if (resp.result != null && resp.result.length !== 0)
              this.objects = resp.result.map(x => Object.assign(x, {
                disabled: this.selectedObjects.find(y => y.id == x[this.selected.propId]) ? true : false,
              }));
          },
          complete: () => this.isLoadingSearch = false,
        });
      return;
    }
  }

  changeSelected() {
    if (this.selectedForm) {
      if (this.customClassIndex) {
        var overlayTypeObject = window.document.querySelector<any>(`.${this.customClassIndex}`);
        if (overlayTypeObject) {
          overlayTypeObject.closest('.cdk-overlay-connected-position-bounding-box').style.zIndex = 1005;
          var backdropMatSelect = window.document.querySelector<any>(`.cdk-overlay-backdrop.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing`);
          if (backdropMatSelect) backdropMatSelect.style.zIndex = 1005;
        }
      }
      this.selected = this.typeObjects.find(x => x.key == this.selectedForm.value);
    }
  }

  selectedObject(data: any) {
    if (data) {
      if (this.selected && this.selectedObjects) {
        var newObject: KeyPairsValueObjectAPI = Object.assign({}, this.selected, {
          id: data[this.selected.propId],
          value: data[this.selected.propName],
          isRemove: true,
        });

        this.selectedObjects.unshift(newObject);
        this.selectedValue = newObject;
      }
    }
  }

  customIndexAutocomplete() {
    if (this.customClassIndex) {
      var overlayTypeObject = window.document.querySelector<any>(`.${this.customClassIndex}`);
      if (overlayTypeObject) overlayTypeObject.closest('.cdk-overlay-connected-position-bounding-box').style.zIndex = 1005;
    }
  }

  goToProfile(profile: KeyPairsValueObjectAPI) {
    let currentPage = window.location;

    let path = "";
    switch (profile.key) {
      case ShadowProfileEnum[ShadowProfileEnum.SALEPROFILE]:
        path = `configuration/profile`
        break;
      case ShadowProfileEnum[ShadowProfileEnum.SALEACCOUNT]:
        path = `configuration/sale-account-crm`
        break;
      case ShadowProfileEnum[ShadowProfileEnum.OPPORTUNITY]:
        path = `configuration/opportunity-crm`
        break;
      case ShadowProfileEnum[ShadowProfileEnum.LEADS]:
        path = `configuration/sale-lead`
        break;
      default:
        break;
    }

    window.open(`${currentPage.origin}/${path}/${profile.id}`, "_blank");
  }

  onRemoveSelectedProfile() {
    this.objects = [];
    this.selectedValue = null;
    this.selectedObjects = [];
    this.searchForm.setValue('');
  }

  closePopup() {
    this.dialModalRef.close();
  }

  onConfirm() {
    let dialogMessage = "";

    switch (this.objectAction.toLowerCase()) {
      case 'create':
        dialogMessage = `Do you wish to create a ${(this.moveObject || 'Data').toLowerCase()} and link it to ${this.selectedValue?.displayType || ''} <b>${this.selectedValue?.value}</b>?`;
        break;
      default:
        dialogMessage = `Do you wish to move the selected ${(this.moveObject || 'Data').toLowerCase()} to ${this.selectedValue?.displayType || ''} <b>${this.selectedValue?.value}</b>?`;
        break;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: dialogMessage
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.dialModalRef.close({ profile: this.selectedValue })
      }
    });
  }

  clearSearchForm() {
    this.searchForm.setValue("");
  }
}
