<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container"
    style="cursor: move;" class="w-100" fxLayout="row">
    <ng-container *ngIf="ngTemplateHeaderOutlet" [ngTemplateOutlet]="ngTemplateHeaderOutlet"
        [ngTemplateOutletContext]="ngTemplateHeaderOutletContext"></ng-container>
    <div class="ml-auto">
        <button mat-icon-button style="cursor: move;">
            <mat-icon>open_with</mat-icon>
        </button>
        <button mat-icon-button (click)="confirmClose()" matTooltip="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div #resizeBox class="container-drag" style="position: relative;">
    <span #dragHandleCorner class="dragHandle corner" cdkDrag
        (cdkDragMoved)="dragMove(dragHandleCorner, $event)"></span>
    <span #dragHandleRight cdkDragLockAxis="x" class="dragHandle right" cdkDrag
        (cdkDragMoved)="dragMove(dragHandleRight, $event)"></span>
    <span #dragHandleBottom cdkDragLockAxis="y" class="dragHandle bottom" cdkDrag
        (cdkDragMoved)="dragMove(dragHandleBottom, $event)"></span>
    <ng-container [ngTemplateOutlet]="ngTemplateOutlet"
        [ngTemplateOutletContext]="ngTemplateOutletContext"></ng-container>
</div>