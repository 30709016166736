<div style="min-height: 500px;">
    <div class="p-2" mat-dialog-title>
        Add Existing {{label}}
    </div>
    <div mat-dialog-content class="mat-typography" style="min-height: 364px;">
        <form>
            <mat-form-field [formGroup]="profileFormGroup" matTooltip="Enter 3 words to find profile">
                <mat-label>{{label}}</mat-label>
                <input matInput [placeholder]="placeholder ? placeholder : ((findItemByKey(tooltipProp, 'displayName') ?? 'Display Name')
                    + ' or '
                    + (findItemByKey(tooltipProp, 'realName') ?? 'Real Name'))" #myInput
                    formControlName="profileFormInput" [matAutocomplete]="autocomplete" [disabled]="loading"
                    [nbSpinner]="loading">
                <mat-autocomplete #autocomplete="matAutocomplete"
                    (optionSelected)="showSelectedProfile($event.option.value)">
                    <mat-option *ngIf="loading" [nbSpinner]="loading" [disabled]="true"></mat-option>
                    <ng-container *ngIf="firstSuggest && profileFormGroup.controls.profileFormInput.value < 3">
                        <mat-option style="height: 35px !important;" [disabled]="true">We found {{searchArray.length}}
                            results
                            recently <b style="color:green">updated</b>.</mat-option>
                        <mat-option *ngFor="let item of searchArray" [value]="item.displayName"
                            class="multiline-mat-option">
                            <div class="limit-line" style="--line: 1">
                                {{item.displayName}} {{item?.realName ? ' - ' + item?.realName : ''}}
                            </div>
                            <div *ngIf="item?.producer">Producer: {{item?.producer?.producerName|| "No producer data"}}
                            </div>
                            <div *ngIf="item.actGroupId"><i class="text-muted">Has relationship</i></div>
                        </mat-option>
                    </ng-container>
                    <!-- Search Show bar -->
                    <mat-option *ngFor="let profile of profileList" [value]="profile.displayName"
                        class="multiline-mat-option">
                        <div class="limit-line" style="--line: 1">
                            {{profile.displayName}} {{profile?.realName ? ' - ' + profile?.realName : ''}}
                        </div>
                        <div *ngIf="profile?.producer">Producer: {{profile?.producer?.producerName|| "No producer
                            data"}}
                        </div>
                        <div *ngIf="profile.actGroupId"><i class="text-muted">Has relationship</i></div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
        <div class="h6">
            {{label}} Information
        </div>
        <div *ngIf="!isProfile; else profileRelationship">
            <div *ngIf="selectedProfile != undefined && selectedProfile != null">
                <app-producer-profile-info [profile]="selectedProfile" [tooltipProp]="tooltipProp">
                </app-producer-profile-info>
            </div>
        </div>

    </div>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="warn" (click)="closeDialog(false)">
            Cancel
        </button>
        <button mat-raised-button color="primary" [nbSpinner]="saveLoading" (click)="saveProfile()"
            [disabled]="id == selectedProfile?.profileId">
            Add
        </button>
    </mat-dialog-actions>
</div>
<ng-template #profileRelationship>
    <div *ngIf="selectedProfile != undefined && selectedProfile != null">
        <ng-container [ngSwitch]="selectedProfile.typeName">
            <ng-container *ngSwitchCase="'SALEACCOUNT'">
                <app-sale-account-relationship-card [accountLabel]="label" [model]="selectedProfile" [id]="id" [isRemovable]="true"
                    (removeAccount)="clearSaleAccount()">
                </app-sale-account-relationship-card>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <app-profile-relationship-info [profile]="selectedProfile" [tooltipProp]="tooltipProp">
                </app-profile-relationship-info>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
