import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { NbDateService, NbPopoverDirective } from '@nebular/theme';
import { DropDownValue } from 'src/app/modules/admin/summary-report/summary-report.component';
import { environment } from 'src/environments/environment';
import { Helper } from '../../utility/Helper';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit, OnChanges {

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  quarter: MatSelect;
  @ViewChild('quarterDateFilter', { static: false }) set content(content: MatSelect) {
    if (content && this.popupFilterType) {
      this.quarter = content;
      if (this.isExternal.toLowerCase() == "showtable") this.chooseFilterTable.emit(this.quarter);
      else if(this.isExternal.toLowerCase() == "showexternal") this.chooseFilterExternal.emit(this.quarter)
    }
  }

  between: MatSelect;
  @ViewChild('picker', { static: false }) set contentBetween(contentBetween: MatSelect) {
    if (contentBetween && this.popupFilterType) {
      this.between = contentBetween;
      if (this.isExternal.toLowerCase() == "showtable") this.chooseFilterTable.emit(this.between);
      else if(this.isExternal.toLowerCase() == "showexternal") this.chooseFilterExternal.emit(this.between)
    }
  }
  popupFilterType = false;
  @Input() columnName;
  @Input() operationMode;
  // used to change open popup of external or table
  @Input() isExternal: string = null;
  @Input() appearance: string = null;
  @Input() set value(tempValue) {
    if (tempValue) {
      if (Helper.verifyJson(tempValue)) {
        const dateRangeData = JSON.parse(tempValue);
        this.range.controls.start.setValue(this.dateService.parse(dateRangeData.startDate, environment.formatDateTimeZone));
        this.range.controls.end.setValue(this.dateService.parse(dateRangeData.endDate, environment.formatDateTimeZone));
        this.datetime.setValue(null);

      }
      else {
        if (tempValue.constructor.name == 'Array') {
          this.datetime.setValue(tempValue);
        }
        else {
          this.datetime.setValue(this.dateService.parse(tempValue, environment.formatDateTimeZone));
        }
        this.range.controls.start.setValue(null);
        this.range.controls.end.setValue(null);
      }
    } else {
      this.datetime.setValue(null);
      this.range.controls.start.setValue(null);
      this.range.controls.end.setValue(null);
    }
  };
  @Output() chooseFilterExternal = new EventEmitter<any>();
  @Output() chooseFilterTable = new EventEmitter<any>();

  @Output() onFilter = new EventEmitter<any>();
  datetime = new FormControl();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  quarterList: DropDownValue[] = [
    { text: 'Q1', value: '1' },
    { text: 'Q2', value: '2' },
    { text: 'Q3', value: '3' },
    { text: 'Q4', value: '4' }
  ]
  constructor(protected dateService: NbDateService<Date>,
    private cdRef: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
    const modeChange = changes.operationMode;
    if (modeChange && modeChange.previousValue && modeChange.currentValue && modeChange.previousValue == 'Quarter' && modeChange.previousValue != modeChange.currentValue) {
      this.datetime.setValue(null);
    }
    if (modeChange && modeChange.previousValue && modeChange.currentValue && modeChange.currentValue == 'Quarter' && modeChange.previousValue != modeChange.currentValue) {
      this.datetime.setValue(null);
    }
    if (modeChange?.currentValue != modeChange?.previousValue && !changes?.value?.currentValue) {
      this.popupFilterType = true;
    }
    else {
      this.popupFilterType = false;
    }
    this.cdRef.detectChanges();
  }

  ngOnInit() {
  }

  // toggleDatetimePop() {
  //   if (this.popover.isShown) {
  //     this.popover.hide();
  //   } else {
  //     this.popover.show();
  //   }
  // }

  onClickSubmit() {
    this.onFilter.emit({
      datetime: this.dateService.format(this.datetime.value, environment.formatDateTimeZone),
      // from: this.dateService.format(this.from.value, environment.formatDateTimeZone),
      // to: this.dateService.format(this.to.value, environment.formatDateTimeZone)
    })
    //this.toggleDatetimePop();
  }

  onClickDropdown(event) {
    if (!event) {
      const value = this.datetime.value ?? [];
      this.onFilter.emit({ datetime: value });
    }
  }

  onClickDateRangeSubmit() {
    if (this.range.controls.start.value && this.range.controls.end.value) {
      const startDate = this.dateService.format(this.range.controls.start.value, environment.formatDateTimeZone);
      const endDate = this.dateService.format(this.range.controls.end.value, environment.formatDateTimeZone);
      this.onFilter.emit({
        datetime: JSON.stringify({ startDate, endDate })
      })
    } else {
      this.refreshValue();
    }

  }
  refreshValue() {
    if (!this.range?.controls?.start?.value && !this.range?.controls?.end?.value) {
      this.onFilter.emit({
        datetime: "",
        noFilter: true
      })
    }
  }
}
