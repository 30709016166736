import { TaskBoardService } from './../../task-board.service';
import { AddEditTaskComponent } from './../../../add-edit-task/add-edit-task.component';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TaskPriority } from 'src/app/shared/contances/task-priority';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { EditInlineTaskComponent } from '../../../edit-inline-task/edit-inline-task.component';
import { Task, TaskFilterProp } from './task.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { NbToastrService } from '@nebular/theme';
import { Priority, TaskStatus } from '../task-status.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import dateFormat, { masks } from "dateformat";
import { KeyPairsValue } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { Helper } from 'src/app/shared/utility/Helper';
import { UserExperienceTrackingService } from 'src/app/shared/components/user-experience-tracking/user-experience-tracking.service';
// import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
// import { getLocalInfo } from "phone-number-to-timezone";
var phoneDetect = require('../../../../../../shared/utility/PhoneDetectTimeZone');
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit, OnDestroy {

  constructor(
    private dialog: MatDialog,
    private taskService: TaskBoardService,
    private toast: NbToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    // private tabMode: AdminTabModeService,
    private userExperienceTrackingService: UserExperienceTrackingService,
  ) {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.taskId) {
        this.urlTaskId = Number(params.taskId);
      }
    });

    this.taskService.getFilterTaskProp().pipe(takeUntil(this.destroy$)).subscribe(e => this.filterList = [...e]);
  }
  @Input() taskPriority: Priority[] = [];
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @Input() task: Task;
  @Input() userId: string;
  @Input() isOnlyMyIssues: boolean;
  @Input() isDisplayAsList = false;
  @Input() taskStatusList: TaskStatus[] = [];
  @Input() isDragable = true;
  @Input() isIndependable = false;
  @Input() truncateTaskName: number = 0;
  // filter Array:
  @Input() filterList: TaskFilterProp[] = [];
  //@Input() listTask: Task[] = [];
  @Input() removeAssigneeTaskList: boolean = false;
  @Input() tooltipProp: KeyPairsValue[] = [];
  @Input() funcClickDetail: (data: string) => void = null;

  @Output() closeDialog = new EventEmitter();
  @Output() clickDetail: EventEmitter<any> = new EventEmitter<any>();

  urlTaskId = 0;
  //2022-03-24 vuonglqn add start
  userImage = null;
  tootipUser = '';
  userNameImage = '';
  //2022-03-24 vuonglqn add end
  taskTypeImg = 'assets/images/story.svg';
  taskPriorityImg = 'assets/images/high.svg';
  taskPriorityName = '';
  addEditComponent = EditInlineTaskComponent;
  menuTopLeftPosition = { x: '0', y: '0' };
  doneTask: number;
  dialogRef: MatDialogRef<EditInlineTaskComponent, any>;
  findItemByKey = Helper.findItemByKey;
  isTabMode = Helper.checkTabMode();

  private destroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.taskService.taskPriorityList().pipe(takeUntil(this.destroy$)).subscribe(e => {

      this.taskPriority = e;
      const priority = this.taskPriority.find(priority => priority.priorityId === this.task.taskPriority);
      if (priority) {
        if (this.task) {
          this.taskPriorityImg = `assets/images/priority/${priority.priorityId.toString()}.svg`;
          this.taskPriorityName = `Priority: ${priority.priorityName.toLowerCase()}`;
          this.userImage = this.task?.user?.pictureURL?.replace(/\\/g, '/');
          this.userNameImage = (`${this.task.user?.firstName || ''} ${this.task.user?.lastName || ''}`).trim();
          this.tootipUser = (this.task.user?.userName || '') + (this.userNameImage.length > 0 ? ` - ${this.userNameImage}` : '');
        }
      }

    });
    // this.showTimeZonePrimaryPhone(this.task?.primaryPhoneNumber);

    this.taskService.getTaskDone().pipe(takeUntil(this.destroy$)).subscribe(e => {
      this.doneTask = e;
    })

  }
  redirectToOtherComponent() {

  }
  openTask() {
    if (!this.task?.relationshipId) {
      this.editDialog();
      return;
    }

    if (this.funcClickDetail != null) {
      this.funcClickDetail(this.task.taskId.toString());
      return;
    }

    Helper.pushBackURL();
    Helper.setItemSessionFilterTask(this.filterList);
    Helper.circleDetail(this.task.taskId.toString(), 'task', this.filterList, this.router, this.userExperienceTrackingService);
  }

  onRightClick(event: MouseEvent, item) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item };

    // we open the menu
    this.matMenuTrigger.openMenu();

  }

  deleteTheTask(task: Task) {
    this.taskService.deleteTask(task.taskId).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp.result) {
        this.toast.success(`Delete ${task.taskName} successfully`, 'Success', { limit: 1 });
        if (this.isOnlyMyIssues) {
          if (this.isDisplayAsList) {
            this.taskService.refreshTaskList(this.userId, this.filterList);
          } else {
            this.taskService.refreshTaskBoardLst(this.userId, this.filterList, 0);
          }
        } else {
          if (this.isDisplayAsList) {
            this.taskService.refreshTaskList(null, this.filterList);
          } else {
            this.taskService.refreshTaskBoardLst(null, this.filterList, 0);
          }
        }
      } else {
        this.toast.danger(`Delete ${task.taskName} failed, please try again...`, 'Error');
      }
    });
  }
  showTaskStatus(taskStatusId: number) {
    return this.taskStatusList.find(ts => ts.taskStatusId === taskStatusId).taskStatusName;
  }
  showTaskStatusColor(taskStatusId: number) {
    if (taskStatusId == this.doneTask && this.isDisplayAsList) {
      return '#adadad';
    }
    return this.taskStatusList.find(ts => ts.taskStatusId === taskStatusId).colorCode;
  }
  redirectToTaskDetail() {
    // this.router.navigate([`/configuration/task`], { queryParams: { action: TblActionType.Edit, taskId: this.task.taskId } });
    this.userExperienceTrackingService.navigateNext("/configuration/task", { queryParams: { action: TblActionType.Edit, taskId: this.task.taskId } }, null, true);
  }
  isExpirationExpired() {
    //var moment = require('moment');
    // your date logic here, recommend moment.js;
    //return moment(product.experationDate).isBefore(moment(currentdate));
    // or without using moment.js:
    //return product.experationDate.getTime() < currentdate.getTime();
    // or using Date
    if (!this.task.taskStartDate) return false;
    var currentDate = dateFormat(new Date(), 'mm-dd-yyyy 23:59:59');
    return new Date(this.task.taskStartDate).getTime().valueOf() < new Date(currentDate).getTime().valueOf() + 1000;
  }
  removeHtmlTag(content: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = content;
    return tmp.textContent || tmp.innerText || "";
  }
  getHardCodePhoneCall(type: string) {
    let value = type?.trim()?.toLowerCase()?.replace(' ', '');
    return value;
  }
  // Function to show primary phone number of the profile
  showTimeZonePrimaryPhone(primaryPhoneNumber: string) {
    try {
      if (primaryPhoneNumber) {
        // debugger;
        const data = phoneDetect.getLocalInfo(primaryPhoneNumber, { zone_display: 'offset' });
        return data.time.zone.replace("GMT", "UTC") ?? 'Unknown';
      }
    } catch (error) {
      // console.log('error at :' + this.task.primaryPhoneNumber);
      return 'Unknown';
    }
  }

  editDialog() {
    const dialogRef = this.dialog.open(AddEditTaskComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: TblActionType.Edit,
        model: { ...this.task },
        profile: this.task.profile,
        tooltipProp: this.tooltipProp
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      this.closeDialog.emit();
    });
  }
}
