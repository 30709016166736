export enum EntityColorEnums {
    // color of entity
    Profile = "#808080",
    SaleLead = "#5c5c8a",
    SaleAccount = "#808000",
    Opportunity = "#004d4d",
    Buyer = "#0078A8",

    // Text Display when entity is colored.
    ProfileText = "#ffffff",
    SaleLeadText = "#ffffff",
    SaleAccountText = "#ffffff",
    OpportunityText = "#ffffff",
    BuyerText = "#ffffff",

}

export enum EntityIconEnums {
    Profile = "account_circle",
    SaleLead = "account_circle",
    SaleAccount = "account_circle",
    Opportunity = "account_circle",
    Buyer = "local_mall",
}

export enum EntityNameEnums {
    Profile = "SALEPROFILE",
    SaleLead = "LEADS",
    SaleAccount = "SALEACCOUNT",
    Opportunity = "OPPORTUNITY",
    Buyer = "BUYER",
}