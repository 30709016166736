import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-one-column-employee-layout',
  styleUrls: ['./one-column-employee.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header [isEmployee]="true"></ngx-header>
      </nb-layout-header>

      <!-- <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar> -->

      <nb-layout-column class="content-body">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!-- <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer> -->
    </nb-layout>
  `,
})
export class OneColumnEmployeeLayoutComponent { }