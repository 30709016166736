import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { SubmitIndexingDialogComponent } from '../submit-indexing-dialog/submit-indexing-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FFCUrl, GoogleSearchQueryRequestViewModel, SeoActivityLogRespond, SeoActivityLogViewModel } from 'src/app/modules/admin/indexing/indexing.model';
import { Subject } from 'rxjs';
import { IndexingService } from 'src/app/modules/admin/indexing/indexing.service';
import { takeUntil } from 'rxjs/operators';
import { ActivityLogService } from 'src/app/shared/services/activity-log.service';
import { SeoInfo } from '../../../profile-detail.model';
import { SettingService } from 'src/app/shared/services/setting.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-impression-chart-dialog',
  templateUrl: './impression-chart-dialog.component.html',
  styleUrls: ['./impression-chart-dialog.component.scss']
})
export class ImpressionChartDialogComponent implements OnInit, OnDestroy {
  @ViewChild('eChart') chartElement: ElementRef;
  @ViewChild('popoverContainer') popoverContainer: ElementRef;

  popoverVisible: boolean = false;
  popoverDate: string = '';
  popoverContent: SeoActivityLogRespond[] = [];
  seoActivityList: SeoActivityLogRespond[] = [];
  activitySeoRequest: SeoActivityLogViewModel = new SeoActivityLogViewModel();
  impressionLoad: boolean = false;
  lineChartOption: EChartsOption;
  impressionLineChartRequestModel: GoogleSearchQueryRequestViewModel = new GoogleSearchQueryRequestViewModel();
  // Track the state of truncated strings
  isTruncated: { [index: number]: { logInfo1: boolean; logInfo2: boolean } } = {};
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialModalRef: MatDialogRef<SubmitIndexingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private indexService: IndexingService,
    private activityLogService: ActivityLogService,
    private settingService: SettingService,
    private toastService: NbToastrService,
    ) {
      this.setupLineData();
    }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setupLineData() {    
    // indexing line chart by date
    this.lineChartOption = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['IMPRESSIONS']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLabel: {
          
        },
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: [
      {
        name: "IMPRESSIONS",
        type: "line",
        stack: "total",
        itemStyle: {
          color: this.data.impressionsColor ?? 'black'
        },
        emphasis: {
          focus: "series"
        },
      },    
      {
        name: "IMPRESSIONS",
        type: 'scatter',
        data: [],
        symbol: 'diamond',
        symbolSize: 20,
        itemStyle: {
          color: '#dc3545'
        },        
        emphasis: {
          focus: "series"
        },
        zlevel: 2, // Higher z-level to be rendered on top
        z: 2, // Higher z value to be on top within the same z-level
        tooltip: {
          show: false // Disable tooltip for this series
        }
      }]
    };

    // init seo activity request
    this.activitySeoRequest.type = this.data.type;
    this.activitySeoRequest.objId = this.data.profileId;
    this.activitySeoRequest.startDate = new Date(this.data.impressionStartDate).toISOString().split('T')[0] ?? new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    this.activitySeoRequest.endDate = new Date(this.data.impressionEndDate).toISOString().split('T')[0] ?? new Date(Date.now()).toISOString().split('T')[0];

    if (this.data.type === "CATEGORY") {
      this.activitySeoRequest.objId = this.data.categoryId;
      this.activitySeoRequest.seoInfoId = this.data.seoInfoId?.toString();
      this.activitySeoRequest.hyperDataIdList = this.data.hyperDataList?.map(item => item?.hyperDataId?.toString()) ?? [];
    }

    // init first request data
    this.impressionLineChartRequestModel.startDate = new Date(this.data.impressionStartDate).toISOString().split('T')[0] ?? new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    this.impressionLineChartRequestModel.endDate = new Date(this.data.impressionEndDate).toISOString().split('T')[0] ?? new Date(Date.now()).toISOString().split('T')[0];
    this.impressionLineChartRequestModel.dimension = "DATE";
    this.impressionLineChartRequestModel.url = this.data?.seoInfo?.ffcUrl?.url;
    // get date between
    this.impressionLineChartRequestModel.limitRow = 1 + Math.ceil(Math.abs(new Date(this.impressionLineChartRequestModel.startDate).getTime() - new Date(this.impressionLineChartRequestModel.endDate).getTime()) / (1000 * 3600 * 24));
    this.refreshData();
  }
  
  getSummaryDate(data: { start: string, end: string }) {
    if (!this.impressionLineChartRequestModel) this.impressionLineChartRequestModel = new GoogleSearchQueryRequestViewModel();
    this.impressionLineChartRequestModel.startDate = new Date(new Date(data.start).getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    this.impressionLineChartRequestModel.endDate = new Date(new Date(data.end).getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    // get date between
    this.impressionLineChartRequestModel.limitRow = 1 + Math.ceil(Math.abs(new Date(this.impressionLineChartRequestModel.startDate).getTime() - new Date(this.impressionLineChartRequestModel.endDate).getTime()) / (1000 * 3600 * 24));
    
    this.activitySeoRequest.startDate = new Date(new Date(data.start).getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    this.activitySeoRequest.endDate = new Date(new Date(data.end).getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    this.refreshData();
  }
  
  async refreshData() {
    this.popoverVisible = false; // close popup
    this.impressionLoad = true;
    try {
      // added new ffc url when dont have
      if (!this.impressionLineChartRequestModel.url) {
        const addedFFC = await this.createNewFFC(this.data.seoInfo);
        if (addedFFC) {
          this.impressionLineChartRequestModel.url = addedFFC.url;
        }
      }
  
      if (this.impressionLineChartRequestModel.url) {
        const impressionData = await this.indexService.getOverViewIndexBlock(this.impressionLineChartRequestModel).pipe(takeUntil(this.destroy$)).toPromise();
        if (impressionData?.result?.rows?.length > 0) {
          this.lineChartOption.xAxis.data = impressionData.result.rows.map(item => item.keys[0]);
          this.lineChartOption.series[0].data = impressionData.result.rows.map(item => item.impressions);
        }
        else {
          this.setupDumpData(); // fake data when google return nothing
        }
      }
      else {
        this.setupDumpData();
      }
        
      const activityData = await this.activityLogService.GetSeoActivityLog(this.activitySeoRequest).pipe(takeUntil(this.destroy$)).toPromise();
      if (activityData?.result) {
        this.seoActivityList = activityData.result;
      }
      else {
        this.seoActivityList = [];
      }
  
      if (this.seoActivityList?.length > 0) {
        // setup the pointer (the red diamond icon) -> click to open activity logs
        this.lineChartOption.series[1].data = this.lineChartOption.series[0].data?.map((element, index) => {
          if (this.seoActivityList.findIndex(item => item.dateCreated?.toString().split('T')[0] == this.lineChartOption.xAxis.data[index]) != -1) {
            return element;
          }
          else {
            return NaN;
          }
        });
      }
      else {
        this.lineChartOption.series[1].data = Array(this.impressionLineChartRequestModel.limitRow).fill(NaN);
      }
    }
    catch (ex) {
      this.setupDumpData();
      this.lineChartOption.series[1].data = Array(this.impressionLineChartRequestModel.limitRow).fill(NaN);
    }
    finally {
      this.impressionLoad = false;
    }
  }

  onChartEvent(event: any) {
    if (event?.seriesType === 'scatter') {      
      // Get the position of the clicked point relative to the chart container
      const chartRect = this.chartElement.nativeElement.getBoundingClientRect();
      let clickedPointX = chartRect.left + event.event.offsetX;
      let clickedPointY = chartRect.top + event.event.offsetY;
 
      // Set the position of the popover container
      const popoverContainer = this.popoverContainer.nativeElement;
      popoverContainer.scrollTop = 0;

      popoverContainer.style.left = clickedPointX + 'px';
      if (clickedPointX * 100 / window.innerWidth > 60) {
        popoverContainer.style.left = 60 + '%';
      }

      popoverContainer.style.top = clickedPointY - 180 + 'px';
 
      // get date
      this.popoverDate = event.name;
      this.popoverContent = this.formatActivityContent(
        this.seoActivityList.filter(x => x.dateCreated?.toString().split('T')[0] === event.name)
      );

      if (this.popoverContent?.length > 0) {
        this.popoverContent.forEach((_, index) => {
          this.isTruncated[index] = { logInfo1: false, logInfo2: false };
        })
      }

      // Show the popover
      if (!this.popoverVisible) {
        this.popoverVisible = true;
      }
    }
  }
  
  closePopover() {
    this.popoverVisible = false
  }

  formatActivityContent(array: SeoActivityLogRespond[]) {
    if (array?.length > 0) {
      return array.map(item => {
        // if propertyName is Reason1 -> remove the last character ('1')
        if (item.propertyName === "Reason1") {
          item.propertyName = "Reason"
        }
        // if SEO property name -> add SEO's {{item.propertyname}}
        else if (item.propertyName === "Description" || item.propertyName === "Keywords" 
        || item.propertyName === "Title" || item.propertyName === "FriendlyUrl") {
          item.propertyName = `SEO's ${item.propertyName.toLowerCase()}`
        }
        else if (item.propertyName === "Testimonials") {
          item.logInfo1 = this.formatTestimonialString(item.logInfo1);
          item.logInfo2 = this.formatTestimonialString(item.logInfo2);
        }
        return item;
      })
    }
    return [];
  }
  
  checkDifferentActivity(prop: string) {
    if (prop !== 'SeoInfoId' && prop !== 'PrimaryImage' && prop !== "AutomateDataStateId") {
      return true;
    }
    else return false;
  }

  async createNewFFC(seoInfo: SeoInfo) {
    if (seoInfo.friendlyUrl) {
      let domain = this.data.domainUrl;
      // if not exist get it from setting
      if (!domain) {
        const domainFromSetting = await this.settingService.getSettingByKeyAndGroup('FONT-FACING', 'DOMAIN').toPromise();
        if (domainFromSetting?.result?.value) {
          domain = domainFromSetting.result.value;
        }
      }
      // add '/' at the end if not have
      if (!domain.endsWith('/')) {
        domain = domain.concat('/');
      }

      // can't get domain -> return null
      if (!domain) {
        return null;
      }

      let url = `${domain}act/${seoInfo.friendlyUrl}/`;
      if (this.data.type === "CATEGORY") {
        `${domain}categories/${seoInfo.friendlyUrl}/`
      }
      // add new ffc url if don't have
      const data = await this.indexService.saveIndexingUrl({
        url: url,
        seoId: seoInfo.seoInfoId
      } as FFCUrl).toPromise();
        
      if (data?.result) {
        return data.result;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  setupDumpData() {    
    // setup xAxis
    let dateRange: string[] = [];
    for (let date = new Date(this.impressionLineChartRequestModel.startDate); date <= new Date(this.impressionLineChartRequestModel.endDate); date.setDate(date.getDate() + 1)) {
        dateRange.push(new Date(date).toISOString().split('T')[0]);
    }
    this.lineChartOption.xAxis.data = [...dateRange];
    this.lineChartOption.series[0].data = Array(this.impressionLineChartRequestModel.limitRow).fill(0);
  }
  
  toggleTruncate(index: number, field: string): void {
    this.isTruncated[index][field] = !this.isTruncated[index]?.[field] ?? true;
  }

  formatTestimonialString(testimonial) {
    let resp = testimonial;
    if (resp) {
      if (resp.includes(`"`)) resp = resp.replaceAll(`"`, '');
      if (resp.includes(`[`)) resp = resp.replaceAll(`[`, '');
      if (resp.includes(`]`)) resp = resp.replaceAll(`]`, '');
      if (resp.includes(`}`)) resp = resp.replaceAll(`}`, '');
      if (resp.includes(`,{`)) resp = resp.replaceAll(`,{`, '; ');
      if (resp.includes(`{`)) resp = resp.replaceAll(`{`, '');
      resp = resp.replaceAll(`:`, ': ');
      resp = resp.replaceAll(`,`, ', ');
    }
    return resp;
  }
}
