import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SettingService } from 'src/app/shared/services/setting.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header [(tabMode)]="tabMode">
        <ng-content  select="[headerNav]" ngProjectAs="[headerNav]"></ng-content>
        </ngx-header>
      </nb-layout-header>

      <nb-sidebar *ngIf="tabMode == false" class="menu-sidebar" tag="menu-sidebar"  state="expanded">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column *ngIf="!refresh" class="main-content" [ngStyle]="{'padding-top':loginAsMode || testMode ?'30xp':'0px'}" tag="main-content" >
        <!-- <app-admin-tab-mode>
          <div outlet>
          <ng-content select="router-outlet"></ng-content>
          </div>
        </app-admin-tab-mode> -->
        <ng-content select="[tab-bar]"></ng-content>
        <ng-content select="[router-outlet]"></ng-content>

      </nb-layout-column>

    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnChanges {
  /**
   *
   */
  loginAsMode = false;
  refresh = false;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private localStorage: LocalStorageService,
    private settingService: SettingService,
    private cdr: ChangeDetectorRef

  ) {
    let loginAs = window.localStorage.getItem('org_accessToken') ?? null;
    if (loginAs && loginAs != '') {
      this.loginAsMode = true;
    }

  }
  ngOnChanges(changes: SimpleChanges): void {
    let testChange = changes['testMode'];
    if (testChange && !testChange.firstChange) {
      if (this.testMode) {
        this.refresh = true;
        this.cdr.detectChanges();
        setTimeout(() => { this.refresh = false; this.cdr.detectChanges(); }, 20)
      }
    }
  }
  @Input() tabMode: boolean = false;
  @Input() testMode = false;
}
