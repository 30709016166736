import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { HeadshotDialog } from '../headshot-dialog/headshot.dialog';

@Component({
    selector: 'app-headshot',
    templateUrl: './headshot.component.html',
    styleUrls: ['./headshot.component.css']
})
export class HeadshotComponent implements OnInit, AfterViewInit {
    @Input() userModel: UserModel;
    @ViewChild('uploader') myInputVariable: ElementRef;
    fileHeadShot: any;
    pictureHeadShot: string;
    currentPicture: string
    constructor(private dialog: MatDialog,
        private toast: NbToastrService) {


    }
    ngAfterViewInit(): void {
       
        this.currentPicture = this.userModel.pictureURL?.replaceAll("\\","/");
    }
    ngOnInit() {

    }
    fileChangeEvent(event: any): void {

        var filesize = ((event.target.files[0].size / 1024) / 1024);
        if (filesize > 2) {
            this.toast.danger("The file size can not exceed 2MB.", 'Error');
            return;
        }
        const dialogRef = this.dialog.open(HeadshotDialog, {
            disableClose: true,
            height: '100vh',
            width: '600px',
            panelClass: 'dialog-detail',
            autoFocus: false,
            data: {
                event: event
            }

        });
        dialogRef.afterClosed().subscribe(response => {
            if (response != null) {
                this.fileHeadShot = response.file;
                this.pictureHeadShot = response.base64;
            }
            this.myInputVariable.nativeElement.value = "";
        });
    }


}
