<mat-dialog-content>
  <div class="row">
    <image-cropper class="col-6" [imageChangedEvent]="type == 'event'? imageChangedEvent : null"
      [imageURL]="type == 'url'? imageURL: null" [maintainAspectRatio]="false" [imageFile]="type == 'file'? file: null"
      [containWithinAspectRatio]="false" [onlyScaleDown]="true"
      [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'" format="jpeg" [imageQuality]="100"
      (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
    <div class="col-6">
      <div class="h6">
        Tools:
      </div>
      <div>
        <button mat-flat-button (click)="rotateLeft()">Rotate left</button>
        <button mat-flat-button (click)="rotateRight()">Rotate right</button>
        <button mat-flat-button (click)="flipHorizontal()">Flip horizontal</button>
        <button mat-flat-button (click)="flipVertical()">Flip vertical</button>
        <button mat-flat-button (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill
          AspectRatio':'Contain Within Aspect Ratio'}}</button>
        <button mat-flat-button (click)="resetImage()">Reset image</button>
        <button mat-flat-button (click)="zoomOut()">Zoom -</button>
        <button mat-flat-button (click)="zoomIn()">Zoom +</button>
        <div class="col-lg-12">
          <!-- <form [formGroup]="frm">
            <mat-form-field appearance="standard">
              <mat-label>Crop Ratio</mat-label>
              <input matInput autocomplete="off" required formControlName="ratio" trim="blur" (focusout)="calRatio()">
              <mat-error *ngIf="frm.controls.ratio.errors && (frm.controls.ratio.dirty || frm.controls.ratio.touched)">
                {{frm.controls.ratio['errorMessage']}}</mat-error>
            </mat-form-field>
          </form> -->
        </div>
      </div>
      <div class="h6">
        Cropped Image:
      </div>
      <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" [nbSpinner]="loading"
        style="width: 500px;" />

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="d-flex flex-row justify-content-between">
    <button mat-button color="warn" (click)="closeDialog(false)"> Cancel</button>
    <button mat-button color="primary" (click)="closeDialog(true)"> OK</button>
  </div>
</mat-dialog-actions>