import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ReplaySubject, Subject } from 'rxjs';
import { OpportunityManagementService } from 'src/app/modules/admin/opportunity-management/opportunity-management.service';
import { SaleOpportunityViewModel } from 'src/app/modules/admin/opportunity-management/opportunity.model';
import { AutomateDataState } from 'src/app/modules/admin/profile-management/profile-detail/automate-datastate-log/automate-datastate-model';
import { AutomateDatastateService } from 'src/app/modules/admin/profile-management/profile-detail/automate-datastate-log/automate-datastate.service';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { take, takeUntil } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
import { Helper } from 'src/app/shared/utility/Helper';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { environment } from 'src/environments/environment';
import { MicrosoftOffice365Service } from 'src/app/shared/services/microsoft-office-365.service';

@Component({
  selector: 'app-opportunity-modal',
  templateUrl: './opportunity-modal.component.html',
  styleUrls: ['./opportunity-modal.component.scss']
})
export class OpportunityModalComponent implements OnInit, AfterViewInit {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @Input() saleAccountId: string;
  @Input() actionType: TblActionType = TblActionType.Add;
  @Input() saleOpportunityViewModel: SaleOpportunityViewModel;

  protected _onDestroy = new Subject<void>();

  environment = environment;
  opportunityForm: FormGroup;
  // loading for entire modal
  modelLoading: boolean = false;
  loading = false;
  automateDataStateList: AutomateDataState[] = [];
  automateCtrl: FormControl = new FormControl();
  automateFilterCtrl: FormControl = new FormControl();
  filteredAutomate: ReplaySubject<AutomateDataState[]> = new ReplaySubject<AutomateDataState[]>(1);
  isChange: boolean = false;
  leadSourceDynamicAPI;
  typeDynamicAPI;
  companyDynamicAPI;
  leadSourceLoading = false;
  typeLoading = false;
  companyLoading = false;

  stateWon: string;
  stateClose: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private frmBuilder: RxFormBuilder,
    private service: OpportunityManagementService,
    private automateDataStateService: AutomateDatastateService,
    private dialModalRef: MatDialogRef<OpportunityModalComponent>,
    private toastService: NbToastrService,
    private dialog: MatDialog,
    private dynamicContentService: DynamicContentService,
    private cdref: ChangeDetectorRef,
    private settingService: SettingService,
    private microsoftOffice365Service: MicrosoftOffice365Service
  ) {
    this.leadSourceDynamicAPI = this.dynamicContentService.getDynamicContentByType('opportunity-leadsource');
    this.typeDynamicAPI = this.dynamicContentService.getDynamicContentByType('opportunity-type');
    this.companyDynamicAPI = this.dynamicContentService.getDynamicContentByType('general_company');
    this.actionType = data.actionType ?? this.actionType;
    this.saleAccountId = data.saleAccountId ?? this.saleAccountId;
    this.modelLoading = true;

    if (data.model) {
      this.saleOpportunityViewModel = data.model;
    } else {
      this.saleOpportunityViewModel = new SaleOpportunityViewModel();
    }
    this.saleOpportunityViewModel.saleAccountId = this.saleAccountId;

    if (this.actionType == TblActionType.Add) {
      this.saleOpportunityViewModel.saleAccountId = this.saleAccountId;

      //THIS IS A LOGIC FROM SALE_ACCOUNT DETAIL
      //Auto populate Account name as a Company Name
      if (this.data && this.data.companyName) {
        this.saleOpportunityViewModel.company = this.data.companyName
      }

    } else if (this.actionType == TblActionType.Edit) {
      // parse date from backend
      if (this.saleOpportunityViewModel.closeDate && Helper.isDateString(this.saleOpportunityViewModel.closeDate.toString()))
        this.saleOpportunityViewModel.closeDate = new Date(this.saleOpportunityViewModel.closeDate);
      if (this.saleOpportunityViewModel.secondaryCloseDate && Helper.isDateString(this.saleOpportunityViewModel.secondaryCloseDate.toString()))
        this.saleOpportunityViewModel.secondaryCloseDate = new Date(this.saleOpportunityViewModel.secondaryCloseDate);
    }

  }

  ngOnInit() {
    this.automateDataStateService.getDataStateList().subscribe(data => {
      this.automateDataStateList = data;
      if (this.automateDataStateList && this.automateDataStateList.length > 0) {
        this.initSearchAutomateDataState();
        this.changeValue(false);
      }
    });
    this.opportunityForm = this.frmBuilder.formGroup(SaleOpportunityViewModel, this.saleOpportunityViewModel);
    this.setupState();

    // get required for stageId:
    this.opportunityForm.controls.stageId.setValidators([RxwebValidators.required()]);
    // this.opportunityForm.controls.closeDate.setValidators([RxwebValidators.pattern({ expression: { 'pattern': /^\d{2}\/\d{2}\/\d{4}$/ }, message: 'Please enter valid format: DD/MM/YYYY ' })]);
  }

  ngAfterViewInit(): void {
    if (this.opportunityForm) {
      this.opportunityForm.valueChanges.pipe(take(1))
        .subscribe(resp => this.isChange = true);
    }
    this.cdref.detectChanges();
  }

  async setupState() {
    var res = await this.settingService.getSettingByGroupName("SALE_CONFIG").toPromise();
    if (res.result) {
      this.stateWon = res.result.find(x => x.key == "WON_TYPE_ID")?.value || '';
      this.stateClose = res.result.find(x => x.key == "LOST_TYPE_ID")?.value || '';
    }

    this.automateDataStateService.refreshAllAutomateDataState((data) => this.modelLoading = data);
  }

  closeDialog(res: boolean = false) {
    if (this.isChange) {
      this.isChange = false;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: "Do you wish to close this popup? You will lose your unsaved data."
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) this.dialModalRef.close();
        else this.isChange = true;
      });
      return;
    }

    this.dialModalRef.close(res);
  }

  initSearchAutomateDataState() {
    // if in edit mode:
    if (this.actionType === TblActionType.Edit && this.saleOpportunityViewModel.stageId) {
      // map stageId to select dropdown:
      var stageId = this.saleOpportunityViewModel.stageId;
      var currentStage = this.automateDataStateList.find(v => v.automateDataStateId === stageId);
      if (currentStage) {
        this.automateCtrl.setValue(currentStage);
      }
    } else if (this.actionType === TblActionType.Add && this.automateDataStateList.length > 0) {
      let firstAutoData = this.automateDataStateList[0];
      this.saleOpportunityViewModel.stageId = firstAutoData.automateDataStateId;
      this.automateCtrl.setValue(firstAutoData);
    }
    // load the initial bank list
    this.filteredAutomate.next(this.automateDataStateList.slice());
    // listen for search field value changes
    this.automateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAutomateDataState();
      });
  }
  protected filterAutomateDataState() {
    if (!this.automateDataStateList) {
      return;
    }
    // get the search keyword
    let search = this.automateFilterCtrl.value;
    if (!search) {
      this.filteredAutomate.next(this.automateDataStateList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredAutomate.next(
      this.automateDataStateList.filter(x => x.dataStateName.toLowerCase().indexOf(search) > -1)
    );
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  changeValue(data) {
    if (!data) {
      // this.selectionChange.emit(this.bankCtrl.value);
      if (this.opportunityForm?.controls && this.automateCtrl?.value && this.automateCtrl?.value?.automateDataStateId) {
        // console.log(this.automateCtrl.value);
        this.opportunityForm.controls.stageId.setValue(this.automateCtrl.value.automateDataStateId);

        this.opportunityForm.controls.probability.disable();
        switch (this.automateCtrl.value.automateDataStateId.toString()) {
          case this.stateWon:
            this.opportunityForm.controls.probability.setValue(100);
            break;
          case this.stateClose:
            this.opportunityForm.controls.probability.setValue(0);
            break;
          default:
            this.opportunityForm.controls.probability.enable();
            break;
        }
      }
    }
  }

  onClickSave() {
    if (!this.opportunityForm.valid) {
      this.toastService.warning('Please enter all required fields', 'Warning');
    }
    this.loading = true;
    this.opportunityForm.enable();
    var newOp: SaleOpportunityViewModel = this.opportunityForm.value;
    var oldOp: SaleOpportunityViewModel = this.saleOpportunityViewModel;
    newOp.saleAccountId = this.saleAccountId;
    newOp.referenceId = this.saleAccountId;
    this.service.SaveAccountOpportunity(newOp).subscribe({
      next: (result) => {
        if (newOp && oldOp && newOp.opportunityName != oldOp.opportunityName && oldOp.opportunityProfile)
          this.microsoftOffice365Service.updateNameFolder(
            oldOp.opportunityProfile.profileId,
            oldOp.opportunityProfile.typeName,
            oldOp.opportunityName, newOp.opportunityName).subscribe();

        if (result.result) {
          this.toastService.success(`${this.actionType == TblActionType.Add ? 'Create' : 'Edit'} new ${this.environment.titleOpportunity} successfully`, 'Success');
        }
      },
      complete: () => {
        this.loading = false;
        this.isChange = false;
        this.closeDialog(true);
      }
    });
  }
  onNumberFocus(controlName: string) {
    let value = this.opportunityForm.controls[controlName].value;
    if (!isNaN(value) && value == 0) {
      this.opportunityForm.controls[controlName].setValue(undefined);
      this.opportunityForm.controls[controlName].markAsTouched();
    }
  }
  onNumberUnFocus(controlName: string) {
    let value = this.opportunityForm.controls[controlName].value;
    if (!value || value == "") {
      this.opportunityForm.controls[controlName].setValue(0);
    }
  }
  getLoadingValueLeadSource(value: boolean) {
    this.leadSourceLoading = value;
  }
  getLoadingValueType(value: boolean) {
    this.typeLoading = value;
  }
  getLoadingValueCompany(value: boolean) {
    this.companyLoading = value;
  }
  resetSecondaryValue() {
    event.stopPropagation();
    this.opportunityForm.controls['secondaryCloseDate'].setValue(null);
  }

  resetCloseDate() {
    event.stopPropagation();
    this.opportunityForm.controls['closeDate'].setValue(null);
  }
}
