import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readabilityScore'
})
export class ReadabilityScorePipe implements PipeTransform {

  transform(value: string, maxScore: string): string {
    let result: string = 'text-unknown';
    try {
      if ((value != null && value != undefined) && +value >= 0 && +maxScore) {
        let checkValue = ((+value) / (+maxScore)) * 100;
        if (+checkValue > 70) result = 'text-success';
        if (+checkValue <= 70 && +checkValue >= 60) result = 'text-warning';
        if (+checkValue < 60) result = 'text-danger';
      }
    }
    catch (ex) {
      console.log(ex);
    }

    return result;
  }


}
