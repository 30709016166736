<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;" [nbSpinner]="isLoading"
  style="margin: unset;padding: 1px 10px 1px 63px;">
  <button mat-button color="primary" (click)="stepper.previous()" *ngIf="stepper.selectedIndex==1">
    Back
  </button>
  <button mat-button color="primary" (click)="stepper.next();" [disabled]="!formDoc.valid"
    *ngIf="stepper.selectedIndex==0">
    Next
  </button>
  <button mat-button color="primary" (click)="importFile()" [disabled]="!formDoc.valid"
    *ngIf="stepper.selectedIndex==1">
    Import
  </button>
  <button mat-button color="primary" (click)="closeTab()" *ngIf="stepper.selectedIndex==2">
    Finish
  </button>
  <!-- <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button> -->
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography" style="max-height:80vh">
  <form [formGroup]="formDoc" style="padding-right: 50px;padding-left: 50px;">
    <mat-horizontal-stepper labelPosition="bottom" linear #stepper (selectionChange)="onStepChange($event)">
      <mat-step label=" UPLOAD" [completed]="formDoc.valid" state="upload">
        <mat-form-field>
          <ngx-mat-file-input (click)="$event.target.value=null" formControlName="requiredfile" placeholder="Upload your file"
            valuePlaceholder="No file selected" required [accept]="'.xlsx'"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formDoc.get('requiredfile').hasError('required')">
            Please select a file
          </mat-error>
          <mat-error *ngIf="formDoc.get('requiredfile').hasError('maxContentSize')">
            The total size must not exceed {{formDoc.get('requiredfile')?.getError('maxContentSize').maxSize |
            byteFormat}} ({{formDoc.get('requiredfile')?.getError('maxContentSize').actualSize
            | byteFormat}}).
          </mat-error>
        </mat-form-field>
        <small class="text-muted row" *ngIf="templateFile">
          <em class="col-12"><a class="float-left" href="{{templateFile}}">Click here to download the
              excel
              template.</a></em>
        </small>
      </mat-step>
      <mat-step label="MAP" state="map" [completed]="isComplete">
        <p class="text-center">Map columns in your file to item properties.</p>
        <ngx-datatable style="height: inherit;" #table class="material" [rows]="rows" [columns]="columns"
          [columnMode]="ColumnMode.force" [footerHeight]="0" [headerHeight]="50" [rowHeight]="50" [scrollbarV]="true"
          [scrollbarH]="true" [reorderable]="false" [swapColumns]="false">
        </ngx-datatable>
      </mat-step>
      <mat-step label="RESULT" state="result">
        <nb-alert status="danger" *ngIf="systemError"> Sorry, the system has an unexpected technical issue.</nb-alert>
        <div *ngIf="!systemError">
          <h1 mat-dialog-title style="text-align:center">Import completed!</h1>
          <div style="text-align:center">
            <p><span class="badge badge-pill badge-info import-status-number">{{totalRow}}</span> item(s) are
              processed successfully</p>
            <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
              <p>
                <span class="badge badge-pill badge-success import-status-number">{{totalNew}} created </span>
              </p>
              <p>
                <span class="badge badge-pill badge-success import-status-number"> {{totalUpdate}} modified</span>
              </p>
              <p>
                <span class="badge badge-pill badge-warning import-status-number">{{totalReview}} reviews</span>
              </p>
              <p>
                <span class="badge badge-pill badge-danger import-status-number"> {{totalError}} errors </span>
              </p>
              <span *ngIf="fileError"> Please <a href="javascript:void(0)" (click)="downloadFileError()"> click
                  here</a> to download the error records,</span>
            </div>
          </div>
        </div>

        <!-- <app-primas-toolbar #primasToolbar [selectedCout]="primasTable?.selected?.length"
          (onAfterAddFinish)="refreshData(true)" [disableAddNewButton]="true" [columns]="columns" [table]="primasTable" [resource]="data.toolbarResource">
        </app-primas-toolbar> -->
        <!-- <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="space-between">
          <nb-card class="import-instruction-card" fxFlex="33">
            <nb-card-header class="import-instruction-header">
              <div class="text-muted">What should we do with the duplicated item is a <b>Lead</b>?</div>
            </nb-card-header>
            <nb-card-body class="import-instruction-header">
              <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start start">
                <div>
                  <mat-icon class='instruction-icon' style="color:#28a745">
                    swap_horiz
                  </mat-icon>
                </div>
                <div>
                  <p>
                    <b>Convert</b>
                  </p>
                  We can <b>convert a Lead to Account</b>
                  with option: <b>Existing Account</b> and
                  <b>New Contact</b>
                </div>
              </div>
            </nb-card-body>
          </nb-card>
          <nb-card class="import-instruction-card" fxFlex="33">
            <nb-card-header class="import-instruction-header">
              <div class="text-muted">What should we do with the duplicated item is a <b>Account</b>?</div>
            </nb-card-header>
            <nb-card-body class="import-instruction-header">
              <div fxLayout="row" fxLayoutGap="12px">
                <div>
                  <mat-icon class='instruction-icon' style="color:#28a745">
                    group_add
                  </mat-icon>
                </div>
                <div>
                  <p>
                    <b>Add to relationship</b>
                  </p>
                  <div>
                    We can add <b>this duplicate
                      account entry</b> to <b>this account's relationship</b>
                  </div>
                </div>
              </div>
            </nb-card-body>
          </nb-card>
          <nb-card class="import-instruction-card" fxFlex="33">
            <nb-card-header class="import-instruction-header">
              <div class="text-muted">What should we do with the duplicated item is a <b>Opportunity</b>?</div>
            </nb-card-header>
            <nb-card-body class="import-instruction-header">
              <div fxLayout="row" fxLayoutGap="12px">
                <div>
                  <mat-icon class='instruction-icon' style="color:#28a745">
                    add_link
                  </mat-icon>
                </div>
                <div>
                  <p>
                    <b>Add opportunity to account</b>
                  </p>
                  <div>
                    We can add <b>the opportunity entry</b> to <b>this account's opportunity</b>
                  </div>
                </div>
              </div>
            </nb-card-body>
          </nb-card>
        </div> -->
        <div fxLayout="row" fxLayout="row" [hidden]="totalReview == 0">
          <h2 mat-dialog-title>Review List</h2>
          <button mat-icon-button id="blink" (click)="openHelperDialog(true)">
            <mat-icon style="margin-top: -10px !important;">help_outline</mat-icon>
          </button>
        </div>
        <div [hidden]="totalReview == 0">
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" *ngIf="stepper.selectedIndex==2">
              <button mat-raised-button style="background-color:#28a745; color:white" (click)="bulkAction('Add')"
                [disabled]="primasTable.table.rows.length <= 0">
                <mat-icon>playlist_add</mat-icon>
                Bulk Create New
              </button>
              <button mat-raised-button style="background-color:#916f0b;  color:white" (click)="bulkAction('Update')"
                [disabled]="primasTable.table.rows.length <= 0">
                <mat-icon>edit_note</mat-icon>
                Bulk Update For Existing Entry
              </button>
              <button mat-button (click)="bulkSkipRecord()" [disabled]="primasTable.table.rows.length <= 0"
                style="background-color: red;color:white">
                <mat-icon>playlist_remove</mat-icon>
                Bulk Skip Entry
              </button>
              <button mat-raised-button style="background-color:#28a745; color:white" (click)="bulkConvertLead()"
                [disabled]="primasTable.table.rows.length <= 0" *ngIf="tabMode == 'LEADS'">
                <mat-icon>swap_horiz</mat-icon>
                <span>Bulk Convert</span>
              </button>
              <button mat-raised-button style="background-color:#28a745; color:white" (click)="bulkAddToRelationship()"
                [disabled]="primasTable.table.rows.length <= 0" *ngIf="tabMode == 'SALEACCOUNT'">
                <mat-icon>group_add</mat-icon>
                <span>Bulk Add To Relationship</span>
              </button>

              <button mat-raised-button style="background-color:#28a745; color:white" (click)="bulkAddMoreOpportunity()"
                [disabled]="primasTable.table.rows.length <= 0" *ngIf="tabMode == 'OPPORTUNITY'">
                <mat-icon>add_link</mat-icon>
                <span>Bulk Add {{environment.titleAccount}}'s {{environment.titleOpportunity}} To Relationship</span>
              </button>
            </div>

            <mat-button-toggle-group [(value)]="tabMode" class="import-review-tab" *ngIf="totalReview > 0">
              <mat-button-toggle *ngFor="let item of tabModeLst" [value]="item.value"
                (change)="changeModeConfig($event)">{{item.text}}</mat-button-toggle>

            </mat-button-toggle-group>
          </div>

          <app-primas-table #primasTable class="content-table" [columnsTable]="reviewColumns" [resource]="resource"
            (onRefresh)="refreshData($event)" [tableAction]="true" [customAction]="customAction" [optionHeight]="'45vh'"
            [externalSorting]="false">
          </app-primas-table>
        </div>

        <br>

      </mat-step>
    </mat-horizontal-stepper>
  </form>
</mat-dialog-content>

<ng-template #importRequired let-row="row" let-value="value">
  <p [ngClass]="{'asterix-after':row.importRequired}">{{value}}</p>
</ng-template>

<ng-template #headerFromFile let-row="row" let-value="value" let-rowIndex="rowIndex">
  <mat-select [value]="value" (selectionChange)="onChangeColumn($event,value,rowIndex)">
    <mat-option [value]="-1">Ignore</mat-option> <!--Not map-->
    <mat-option [value]="-2">Type input</mat-option> <!--Type input-->
    <mat-option *ngFor="let column of  columnsFile; let i = index" [value]="column.value" [disabled]="column.selected">
      {{column.name}}
    </mat-option>
  </mat-select>
</ng-template>

<ng-template #reviewInfo let-row="row" let-value="value" let-rowIndex="rowIndex">
  <p *ngIf="row.columnFile > -1">{{value}}</p>
  <input matInput *ngIf="row.columnFile == -2" style="border-bottom: 1px solid #307abd;" type="text"
    placeholder="Typing value" (input)="inputProperty(rowIndex, $event.target.value)">
</ng-template>

<ng-template #customAction let-row="row" let-rowIndex="rowIndex">
  <button mat-menu-item (click)="onClickToOpenDialog(row)">
    <mat-icon color="primary">visibility</mat-icon>
    <span>View entry</span>
  </button>
  <button mat-menu-item (click)="convertLead(row)" *ngIf="row.typeName == 'LEADS'">
    <mat-icon style="color:#28a745">swap_horiz</mat-icon>
    <span>Convert</span>
  </button>
  <button mat-menu-item (click)="addToRelationship(row)" *ngIf="row.typeName == 'SALEACCOUNT'"
    [disabled]="row.profileId == paramsQuery.refId">
    <mat-icon style="color:#28a745">group_add</mat-icon>
    <span>Add to relationship</span>
  </button>

  <button mat-menu-item (click)="addMoreOpportunity(row)" *ngIf="row.typeName == 'OPPORTUNITY'"
    [disabled]="row.profileId == paramsQuery.refId">
    <mat-icon style="color:#28a745">add_link</mat-icon>
    <span>Add {{environment.titleAccount}}'s {{environment.titleOpportunity}} to relationship</span>
  </button>

  <button mat-menu-item (click)="createNewContact(row)">
    <mat-icon style="color:#28a745">add</mat-icon>
    <span>Create new entry</span>
  </button>
  <button mat-menu-item (click)="onClickRow(row, 'Update')">
    <mat-icon color="primary">edit</mat-icon>
    <span>Update existing entry</span>
  </button>
  <button mat-menu-item (click)="onClickToSkipRecord({row, rowIndex})">
    <mat-icon style="color: red;">close</mat-icon>
    <span>Skip Entry</span>
  </button>
</ng-template>


<ng-template #valueTemplate let-value="value" let-row="row">
  <div fxFlex="100" (click)="onClickToOpenDialog(row)" class="pointer">
    {{value}}
  </div>
</ng-template>


<ng-template #externalTemplate>
  <div>
    The {{environment.titleOpportunity}} has already relationship with {{environment.titleAccount}} <a
      href="javascript: void(0);" (click)="openAccount()">{{objectReference.displayName || 'None'}}</a>. Do you wish to
    add <a href="javascript: void(0);" (click)="openAccount()">{{objectReference.displayName || 'None'}} </a> to this
    {{environment.titleAccount}}'s relationship?
  </div>
</ng-template>


<ng-template #reviewContactAction let-row="row">
  <div fxLayout="row" fxLayoutAlign="space-between" style="width:200px;padding-right: 24px;">
    <button mat-icon-button (click)="convertLead(row)" *ngIf="row.typeName == 'LEADS'"
      [matTooltip]="'Convert ' + environment.titleLead + ' to ' + environment.titleAccount"
      [disabled]="row.profileId == paramsQuery.refId">
      <mat-icon style="color:#28a745">swap_horiz</mat-icon>
    </button>
    <button mat-icon-button (click)="addToRelationship(row)" *ngIf="row.typeName == 'SALEACCOUNT'"
      [disabled]="row.profileId == paramsQuery.refId" matTooltip="Add to relationship">
      <mat-icon style="color:#28a745">group_add</mat-icon>
    </button>

    <button mat-icon-button (click)="addMoreOpportunity(row)" *ngIf="row.typeName == 'OPPORTUNITY'"
      [disabled]="row.profileId == paramsQuery.refId"
      [matTooltip]="'Add ' + environment.titleAccount + '\'s ' + environment.titleOpportunity + ' to relationship'">
      <mat-icon style="color:#28a745">add_link</mat-icon>
    </button>

    <button mat-icon-button (click)="createNewContact(row)" matTooltip="Create new entry">
      <mat-icon style="color:#28a745">add</mat-icon>
    </button>
    <button mat-icon-button (click)="onClickRow(row, 'Update')" matTooltip="Update existing entry">
      <mat-icon color="primary">edit</mat-icon>
    </button>
    <button mat-icon-button (click)="onClickToSkipRecord({row, rowIndex : -1})" matTooltip="Skip Entry">
      <mat-icon style="color: red;">close</mat-icon>
    </button>
  </div>
</ng-template>