import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { map, tap, timeout } from 'rxjs/operators';
import { UserModel } from './user-model';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { UserRoleModel } from './user-role-model';
// import { TabModeModel } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { ResetPassword } from '../../home/master-layout/reset-password/reset-password.model';
import { ForgotPassword } from '../../home/master-layout/forgot-password/forgot-password.model';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl = environment.apiUser;
  tokenUrl = environment.userApiToken;
  authUrl = environment.apiAuth;
  userListObservable = new Subject<UserModel[]>();
  pictureURL: any;
  pictureURLChange: Subject<any> = new Subject<any>();
  modelChange: Subject<any> = new Subject<any>();
  private _RoleList$: ReplaySubject<UserRoleModel[]> = new ReplaySubject<UserRoleModel[]>(1);
  private _userList$: ReplaySubject<UserModel[]> = new ReplaySubject<UserModel[]>(1);
  private _unassignTaskPlanUser: ReplaySubject<UserModel[]> = new ReplaySubject<UserModel[]>(1);
  public isInitUserList: boolean = false;
  constructor(private http: HttpClient) {
    this.pictureURLChange.subscribe((value) => {
      this.pictureURL = value;
    })

  }

  allUserList() {
    return this._userList$.asObservable();
  }
  getUserPaging(page: Page): Observable<ReturnResult<PagedData<UserModel>>> {
    return this.http.post<ReturnResult<PagedData<UserModel>>>(`${this.baseUrl}/get`, page);
  }
  getUserById(id: any): Observable<ReturnResult<UserModel>> {

    return this.http.get<ReturnResult<UserModel>>(`${this.baseUrl}/id?id=${id}`);
  }
  saveUser(model: UserModel): Observable<ReturnResult<UserModel>> {
    return this.http.post<ReturnResult<UserModel>>(this.baseUrl, model);
  }
  exportUser(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/export`, page);
  }
  deleteUser(id: string): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/${id}`);
  }
  deleteUsers(id: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/delete`, id);
  }

  importUser(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/import`, formData).pipe(
      timeout(1200000)
    );
  }

  updateInActiveUser(id: string, inActive: boolean) {
    return this.http.put(`${this.baseUrl}/inactive`, { id: id, inActive: inActive });
  }
  updateUser(model: UserModel) {
    return this.http.put(this.baseUrl, model);
  }
  updateCustomerName(id: string, customerName: string) {
    return this.http.put(`${this.baseUrl}/customername`, { id: id, customerName: customerName });
  }
  updateCustomerEnable(id: string, isActive: boolean) {
    return this.http.put(`${this.baseUrl}/enable`, { id: id, isActive: isActive });
  }
  updateRole(model: UserModel) {
    return this.http.put(`${this.baseUrl}/role`, model);
  }
  sendEmail(userID: string[]) {
    return this.http.post(`${this.baseUrl}/email`, userID);
  }

  requestRoles() {
    return this.http.get<ReturnResult<UserRoleModel[]>>(`${this.baseUrl}/GetRole`).subscribe(res => {
      if (res.result != null) {
        this._RoleList$.next(res.result);
      }
    });
  }

  getRoles(): Observable<UserRoleModel[]> {
    return this._RoleList$.asObservable();
  }

  getUserList(): Observable<UserModel[]> {
    return this.userListObservable.asObservable();
  }

  getUserFromInputAsync(query: string) {
    return this.http.get<ReturnResult<UserModel[]>>(`${this.baseUrl}/QueryUser?query=${query}`).subscribe(result => {
      if (result.result != null) {
        this.userListObservable.next(result.result);
      }
    });
  }

  searchUser(query: string) {
    return this.http.get<ReturnResult<UserModel[]>>(`${this.baseUrl}/QueryUser?query=${query}`).pipe(
      map(x => {
        x.result = x.result.map(y => {
          y.fullName = (`${y.firstName} ${y.lastName}`).trim();
          return y;
        });
        return x;
      }),
      tap(result => this.userListObservable.next(result.result))
    );
  }

  //2022-13-01 hmtien add start
  getUserRolesId() {
    return this.http.get<ReturnResult<String>>(`${this.baseUrl}/GetUserRolesId`);
  }
  //2022-13-01 hmtien add end
  //2022-01-20 vuonglqn add start
  getUserCurrentLogin(): Observable<ReturnResult<UserModel>> {
    return this.http.get<ReturnResult<UserModel>>(`${this.baseUrl}/GetUserCurrentAuthorize`);
  }
  getAllUser() {
    return this.http.get<ReturnResult<UserModel[]>>(`${this.baseUrl}/GetAllUser`).subscribe(e => {
      if (e.result) {
        this._userList$.next(e.result);
        this.isInitUserList = true;
      }
    });
  }
  switchUser(userName: string): Observable<{ token, defaultScreen, accessToken, message }> {
    return this.http.get<{ token, defaultScreen, accessToken, message }>(`${this.authUrl}/SwitchUser?userName=${userName}`);
  }
  getUnassignTaskPlan() {
    this.http.get<ReturnResult<UserModel[]>>(`${this.baseUrl}/GetUnassignTaskPlan`).subscribe(res => {
      if (res.result) {
        this._unassignTaskPlanUser.next(res.result);
      }
    })
  }
  unassignTaskPlanUsers(): Observable<UserModel[]> {
    return this._unassignTaskPlanUser.asObservable();
  }

  getAllUserModel(): Observable<ReturnResult<UserModel[]>> {
    return this.http.get<ReturnResult<UserModel[]>>(`${this.baseUrl}/GetAllUser`)
      .pipe(tap(resp => {
        this._userList$.next(resp.result);
        this.isInitUserList = true;
      }));
  }

  getAccessToken(): Observable<{ token }> {
    return this.http.get<{ token }>(`${this.authUrl}/GetAccessToken`);

  }
  // getUserTabs(userId: string): Observable<ReturnResult<TabModeModel[]>> {
  //   return this.http.get<ReturnResult<TabModeModel[]>>(`${this.baseUrl}/GetUserTabs/7fc995de-e8a6-4413-83f0-fd6b5b019279`);

  // }

  sendEmailResetPassword(userId: string): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/SendEmailResetPassword?userId=${userId}`);
  }
  resetPassword(resetSecret: string, model: ResetPassword): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ResetPassword/${resetSecret}`, model)
  }

  forgotPassword(model: ForgotPassword, fromCustomer: boolean = false): Observable<ReturnResult<boolean>> {
    if (fromCustomer) return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/CustomerForgotPassword`, model);
    else return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ForgotPassword`, model);
  }

  getAllRoles() {
    return this.http.get<ReturnResult<UserRoleModel[]>>(`${this.baseUrl}/GetRole`)
      .pipe(tap(res => this._RoleList$.next(res.result)))
  }

  getAPIToken(userId: string): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.tokenUrl}/GetToken/${userId}`)
  }

  getFullAPIToken(userId: string): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.tokenUrl}/GetFullToken/${userId}`)
  }

  generateAPIToken(userId: string): Observable<ReturnResult<string>> {
    return this.http.post<ReturnResult<string>>(`${this.tokenUrl}/Generate/${userId}`, null)
  }

  getUserByRole(roleId: string): Observable<ReturnResult<UserModel[]>> {
    return this.http.get<ReturnResult<UserModel[]>>(`${this.baseUrl}/GetUserByRole?roleId=${roleId}`)
      .pipe(map(x => {
        x.result = x.result.map(y => {
          y.fullName = (`${y.firstName || ''} ${y.lastName || ''}`).trim();
          y.pictureURL = y?.pictureURL?.replaceAll('\\', '/');
          return y;
        });
        return x;
      }));
  }

  createCustomer(data: any): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.authUrl}/CustomerRegister`, data);
  }

  confirmAccount(userId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.authUrl}/ConfirmAccount?userId=${userId}`, null);
  }

  grantPermissionOpenFileOrFolder(userId: string, itemId: string): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/grantPermissionOpenFileOrFolder?userId=${userId}&itemId=${itemId}`);
  }

  checkUserExistOrganization(userId: string): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/CheckUserExistOrganization?userId=${userId}`);
  }
}
