import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { ActivityLog } from '../campaign-management/campaign-tracking.model';
import { Contact, Location, ProfileDetailModel } from '../profile-management/profile-detail.model';
import { ProfileModel } from '../profile-management/profile-model';
import { RecommendSaleLeadConvert, SaleConvertHistory, SaleLeadsViewModel } from './add-sale-leads.model';
import { ConvertSaleLeadInputModel, RecommentSaleLeadConvertInput } from './convert-sale-lead/convert-sale-lead.model';

@Injectable({
  providedIn: 'root'
})
export class SaleLeadService {
  baseUrl = environment.apiSaleLead;
  saleLeadDetail$: ReplaySubject<ReturnResult<ProfileDetailModel>> = new ReplaySubject<ReturnResult<ProfileDetailModel>>(1);
  isRefreshSaleGrid$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  searchSaleLead$: ReplaySubject<ReturnResult<ProfileDetailModel[]>> = new ReplaySubject<ReturnResult<ProfileDetailModel[]>>(1);

  constructor(
    private http: HttpClient
  ) { }

  refreshSaleLeadById(id: string): Observable<ReturnResult<ProfileDetailModel>> {
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.baseUrl}/SaleLead?id=${id}`)
      .pipe(tap(resp => this.saleLeadDetail$.next(resp)));
  }

  getSaleLeadById(): Observable<ReturnResult<ProfileDetailModel>> {
    return this.saleLeadDetail$.asObservable();
  }

  getSaleLeadPaging(page: Page): Observable<ReturnResult<PagedData<ProfileModel>>> {
    // console.log(page);
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.baseUrl}/get`,
      page);
  }
  countingLeadPaging(page: Page): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/GetTotalSaleLead`,
      page);
  }

  saveSaleLead(model: SaleLeadsViewModel) {
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/SaveSaleLeads`, model);
  }

  editSaleLead(model: { id: string, saleLeadModel?: ProfileDetailModel, contactModel?: Contact }): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaleLead/Update?id=${model.id}`, model)
      .pipe(tap(resp => this.isRefreshSaleGrid$.next(resp.result)));
  }

  editSaleLeadLocationPrimary(id: number, model: Location): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaleLead/Update/Location?id=${id}`, model)
      .pipe(tap(resp => this.isRefreshSaleGrid$.next(resp.result)));
  }
  importProfile(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/import`, formData).pipe(
      timeout(1200000)
    );
  }

  importLeadBackground(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ImportBackground`, formData);
  }

  exportProfile(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/export`, page);
  }


  editSaleLeadProperties(saleLeadId: string, contactId: number, properties: {}) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaleLead/Update/Properties?saleLeadId=${saleLeadId}&contactId=${contactId}`, properties)
      .pipe(tap(resp => this.isRefreshSaleGrid$.next(resp.result)));
  }

  getSearchSaleLead() {
    return this.searchSaleLead$.asObservable();
  }

  searchSaleLead(keyword: string): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.baseUrl}/Search?keyword=${keyword}`)
      .pipe(tap(resp => this.searchSaleLead$.next(resp)));
  }
  getArchivedSaleLeadPaging(page: Page): Observable<ReturnResult<PagedData<ProfileModel>>> {
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.baseUrl}/GetArchivedSaleLeadPaging`,
      page);
  }
  saveArchivedSaleLead(model: SaleLeadsViewModel) {
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.baseUrl}/SaveArchivedSaleLeads`, model);
  }
  exportArchivedSaleLeads(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ExportArchiveSaleLeads`, page);
  }
  archivesMany(page: Page): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ArchivesMany`, page);
  }
  unArchivesMany(page: Page): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UnArchiveMany`, page);
  }
  convertSaleLead(model: ConvertSaleLeadInputModel): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/ConvertSaleLeadProccess`, model);
  }
  convertToAccount(saleLeadId: string): Observable<ReturnResult<number>> {
    return this.http.get<ReturnResult<number>>(`${this.baseUrl}/ConvertToAccount?leadId=${saleLeadId}`);
  }
  getSaleConvertById(id: number): Observable<ReturnResult<SaleConvertHistory>> {
    return this.http.get<ReturnResult<SaleConvertHistory>>(`${this.baseUrl}/GetSaleConvertById/${id}`);
  }

  // recommendSaleLeadConvert(keywords: string[]): Observable<ReturnResult<RecommendSaleLeadConvert>> {
  //   return this.http.post<ReturnResult<RecommendSaleLeadConvert>>(`${this.baseUrl}/RecommendSaleLeadConvert`, keywords);
  // }
  recommendSaleLeadConvert(keywords: RecommentSaleLeadConvertInput): Observable<ReturnResult<RecommendSaleLeadConvert>> {
    return this.http.post<ReturnResult<RecommendSaleLeadConvert>>(`${this.baseUrl}/RecommendSaleLeadConvert`, keywords);
  }
  deleteProfileByFilter(model) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteAllAsync`, model);
  }

  bulkAssigneeOwner(ids: string[], ownerId: string, referenceType: string, pageFilter?: Page, bulkAction: boolean = true): Observable<ReturnResult<number>> {
    let model = { ids, pageFilter, referenceType, bulkAction };
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkAssigneeOwner?ownerId=${ownerId}`, model);
  }


  bulkAssigneeOwnerArchived(ids: string[], ownerId: string, referenceType: string, pageFilter?: Page): Observable<ReturnResult<number>> {
    let model = { ids, pageFilter, referenceType };
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkAssigneeOwnerArchived?ownerId=${ownerId}`, model);
  }

  bulkAddTag(page: Page, tags: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkAddTags?tags=${tags}`, page);
  }
  bulkActionImportReviewLst(model: [], action: string) {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/ReviewLeadBulkImport?action=${action}`, model);

  }
  bulkAddProperties(page: Page, value: string, property: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkAddProperties`, { pagingFilter: page, value, property });
  }

  getLeadVenuePaging(page: Page): Observable<ReturnResult<PagedData<ProfileModel>>> {
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.baseUrl}/venues`, page);
  }
}
