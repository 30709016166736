import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomViewEntity } from 'src/app/modules/admin/custom-view-management/custom-view-entity.model';
import { environment } from 'src/environments/environment';
import { CustomView } from '../models/custom-view';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ReturnResult } from '../models/return-result';

@Injectable({
  providedIn: 'root'
})
export class CustomViewService {
  baseUrl = environment.apiCustomView;
  constructor(private http: HttpClient) { }


  getCustomView(page: Page): Observable<ReturnResult<CustomView[]>> {
    return this.http.post<ReturnResult<CustomView[]>>(`${this.baseUrl}/getcustomviewentity`, page);
  }
  saveCustomView(customView: CustomView): Observable<ReturnResult<CustomView>> {
    return this.http.post<ReturnResult<CustomView>>(`${this.baseUrl}`, customView);
  }
  delCustomView(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/${id}`,);
  }
  getAllCustomView(page: Page): Observable<ReturnResult<PagedData<CustomView>>> {
    return this.http.post<ReturnResult<PagedData<CustomView>>>(`${this.baseUrl}/getallcustomviewentity`, page);
  }
  saveCustomViewManagement(customViewEntity: CustomViewEntity): Observable<ReturnResult<CustomViewEntity>> {
    return this.http.post<ReturnResult<CustomViewEntity>>(`${this.baseUrl}/savecustomviewmanagement`, customViewEntity);
  }
  deletesCustomView(id: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/deletescustomview`,id);
  }
}
