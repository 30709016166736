import { BuyerType, BuyerTypeModel } from './../models/buyer.model';
import { Buyer } from './../../modules/admin/enquiry-management/booking-binding.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { first, shareReplay, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BuyerModel } from '../models/buyer.model';
import { ReturnResult } from '../models/return-result';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { T } from '@angular/cdk/keycodes';
import { ActivityLogFilters } from 'src/app/modules/admin/profile-management/profile-model';
import { BuyerLogDetails } from '../models/buyer-log-detail.model';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { NextOrPrevious } from '../components/stand-alone-component/next-or-previous-detail/next-previous.model';

@Injectable({
  providedIn: 'root'
})
export class BuyerManagementService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.apiBuyer;
  private buyerSearchs$: BehaviorSubject<BuyerModel[]> = new BehaviorSubject<BuyerModel[]>(undefined);
  public buyerDetails$: ReplaySubject<ReturnResult<BuyerModel>> = new ReplaySubject<ReturnResult<BuyerModel>>(1);
  public buyerLogDetails$ = new BehaviorSubject<BuyerLogDetails>(undefined);
  public buyerType$: ReplaySubject<ReturnResult<BuyerType[]>> = new ReplaySubject<ReturnResult<BuyerType[]>>(1);
  private _pageFilterBuyer$: ReplaySubject<PagedData<BuyerModel>> = new ReplaySubject<PagedData<BuyerModel>>(1);

  // 2022-04-05 tienlm add start
  getSearchBuyer(): Observable<BuyerModel[]> {
    return this.buyerSearchs$.asObservable();
  }
  addBuyer(buyer: BuyerModel): Observable<ReturnResult<boolean>> {
    return this.http.put<ReturnResult<boolean>>(`${this.baseUrl}/AddBuyer`, buyer);
  }
  getBuyerPaging(page: Page): Observable<PagedData<BuyerModel>> {
    return this.http.post<PagedData<BuyerModel>>(`${this.baseUrl}/get`,
      page);
  }
  searchBuyerAsync(searchValue: string): void {
    this.http.get<ReturnResult<BuyerModel[]>>(`${this.baseUrl}/SearchBuyerAsync/?search=${searchValue}`).subscribe(res => {
      this.buyerSearchs$.next(res.result)
    });
  }

  searchBuyer(searchValue: string): Observable<ReturnResult<BuyerModel[]>> {
    return this.http.get<ReturnResult<BuyerModel[]>>(`${this.baseUrl}/SearchBuyerAsync/?search=${searchValue}`)
      .pipe(tap(res => this.buyerSearchs$.next(res.result)));
  }

  GetGeneralDataBuyer(buyerId: string): Observable<ReturnResult<BuyerModel>> {
    return this.http.get<ReturnResult<BuyerModel>>(`${this.baseUrl}/GetGeneralDataBuyer/${buyerId}`);
  }
  // 2022-04-05 tienlm add end
  getAllBuyerType(): Observable<ReturnResult<BuyerType[]>> {
    return this.http.get<ReturnResult<BuyerType[]>>(`${this.baseUrl}/GetAllBuyerType`).pipe(first(), tap(resp => {
      this.buyerType$.next(resp);
    }));
  }

  getAllBuyerTypeObs(): Observable<ReturnResult<BuyerType[]>> {
    return this.buyerType$.asObservable();
  }

  deletedBuyer(id: string) {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteBuyer?id=${id}`);
  }
  deletedBuyers(ids: string[]) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteBuyers`, { ids });
  }

  requestGetBuyerById(id: string) {
    return this.http.get<ReturnResult<BuyerModel>>(`${this.baseUrl}/GetBuyerById/${id}`).pipe(first(), tap(resp => {
      this.buyerDetails$.next(resp);
    }));
  }

  getBuyerById(): Observable<ReturnResult<BuyerModel>> {
    return this.buyerDetails$.asObservable();
  }

  dataChange(data, prop: string) {
    // var profileSaveChange = this.profileDataChange$.getValue();
    // if (!data.isRemove) {
    //   profileSaveChange[prop] = data.data;
    //   this.isSaveAllChange$.next(true);
    // }
    // else {
    //   profileSaveChange[prop] = null;
    // }
    // this.profileDataChange$.next(profileSaveChange);
    // this.updateSaveChangeState();
  }

  editBuyer(id: string, buyerModel: BuyerModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/EditBuyer/${id}`, buyerModel);
  }
  refreshGetBuyerLogs(buyerId: string, pageNumber: number, filters: ActivityLogFilters) {
    this.http.post<ReturnResult<BuyerLogDetails>>(`${this.baseUrl}/GetBuyerLogs/`, {
      buyerId, pageNumber, filters
    }).pipe(shareReplay()).subscribe(res => {
      if (res.result) {
        this.buyerLogDetails$.next(res.result);
      }
    });
  }
  initProfileLogDetails(): void {
    this.buyerLogDetails$.next(null);
  }
  getBuyerLogDetails(): Observable<BuyerLogDetails> {
    return this.buyerLogDetails$.asObservable();
  }
  destroyBuyerLogDetail() {
    this.buyerLogDetails$.unsubscribe();
  }
  //2022-12-05 thoai add start 
  getBuyerTypePaging(page: Page): Observable<ReturnResult<PagedData<BuyerTypeModel>>> {
    return this.http.post<ReturnResult<PagedData<BuyerTypeModel>>>(`${this.baseUrl}/GetBuyerTypePaging`, page);
  }
  saveBuyerType(model: BuyerTypeModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveBuyerType`, model);
  }
  deleteBuyerType(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteBuyerType/${id}`);
  }
  deleteListBuyerType(listId: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListBuyerType`, listId);
  }
  getAllBuyerTypeModel(): Observable<ReturnResult<BuyerTypeModel[]>> {
    return this.http.get<ReturnResult<BuyerTypeModel[]>>(`${this.baseUrl}/GetAllBuyerType`);
  }
  getBuyerRelationship(id: string): Observable<ReturnResult<BuyerModel[]>> {
    return this.http.get<ReturnResult<BuyerModel[]>>(`${this.baseUrl}/RelationshipBuyer/${id}`);
  }
  buyerMakeACall(url, buyer, type, userName, taskId) {
    return this.http.post<any>(`${this.baseUrl}/MakeACall`, { url, buyer, type, userName, taskId });
  }

  addBuyerRelationship(buyerId: string, relationshipId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AddBuyerRelationship?buyerId=${buyerId}&relationshipId=${relationshipId}`, {});
  }

  resetMasterForGroup(actGroupId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ResetMasterForGroup?actGroupId=${actGroupId}`, {});
  }

  relationshipBuyerUnlink(buyerId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/RelationshipBuyerUnlink?buyerId=${buyerId}`, {});
  }
  getBuyerAgent(buyerId: string): Observable<ReturnResult<UserModel[]>> {
    return this.http.get<ReturnResult<UserModel[]>>(`${this.baseUrl}/GetBuyerAgent?buyerId=${buyerId}`);
  }
  saveBuyerAgent(buyerId: string, ids: string[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveBuyerAgent?buyerId=${buyerId}`, { ids });
  }
  deleteBuyerAgent(buyerId: string, userId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteBuyerAgent`, { buyerId, userId });
  }
  exportBuyer(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/export`, page);
  }
  importBuyer(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/import`, formData).pipe(
      timeout(1200000)
    );
  }

  nextOrPreBuyer(model: NextOrPrevious, isNext: boolean): Observable<ReturnResult<any>> {
    model['isNext'] = isNext;
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/NextOrPreBuyer`, model);
  }

  savePageFilterToObs(pageFilter: PagedData<BuyerModel>) {
    if (pageFilter)
      this._pageFilterBuyer$.next(JSON.parse(JSON.stringify(pageFilter)));
  }

  getPageFilterObs(): Observable<PagedData<BuyerModel>> {
    return this._pageFilterBuyer$.asObservable();
  }
}
