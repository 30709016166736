import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReturnResult } from '../../models/return-result';
import { ExtendDataKeyEnums, TabTypeEnums, UserCustomizableTabModel, childrenIgnoreCases, customTabRelationshipUrls, IndexRelationship } from './user-customizable-tab-bar.model';
import { Helper } from '../../utility/Helper';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserCustomizableTabBarService implements OnDestroy {
  baseUrl: string = environment.apiUserTab;
  public tabDataSubject: BehaviorSubject<UserCustomizableTabModel[]> = new BehaviorSubject<UserCustomizableTabModel[]>([]);
  public tabData: UserCustomizableTabModel[];
  private destroy$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private location: Location,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTabData(): Observable<UserCustomizableTabModel[]> {
    return this.tabDataSubject.asObservable();
  }

  getUserCustomizableTabs(): Observable<ReturnResult<UserCustomizableTabModel[]>> {
    return this.http.get<ReturnResult<UserCustomizableTabModel[]>>(`${this.baseUrl}/GetUserCustomizableTabs`);
  }

  saveUserCustomizableTabs(model: UserCustomizableTabModel, currentUrl?: string): Observable<ReturnResult<UserCustomizableTabModel[]>> {
    //Check if the currentUrl is CHILDREN or not
    if (
      !Helper.isNullOrEmpty(currentUrl) &&
      this.detectParentChildrenRelationship(model.tabUrl, currentUrl)
    ) {
      let checkChildren: boolean = this.detectRelationshipTypeUrl(currentUrl, IndexRelationship.CHILDREN);
      model.currentUrl = checkChildren ? currentUrl : '';
    }

    return this.http.post<ReturnResult<UserCustomizableTabModel[]>>(`${this.baseUrl}/SaveUserCustomizableTabs`, model);
  }

  deleteUserCustomizableTabs(tabIds: number[] = []): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteUserCustomizableTabs`, tabIds);
  }

  //Update isActive of the tabId into "True", if it -1, turn all the isActive into "False" 
  updateUserCustomizableTabsActiveIndex(tabId: number): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/UpdateUserCustomizableTabsActiveIndex/${tabId}`);
  }

  createDefaultTabs(): Observable<ReturnResult<UserCustomizableTabModel[]>> {
    return this.http.get<ReturnResult<UserCustomizableTabModel[]>>(`${this.baseUrl}/CreateDefaultTabs`);
  }

  /* ------------ Custom Function ------------ */

  //This function is used for: when we use router.navigation() -> at the event NavigationEnd, this function will detect and change the activeIndex in the tabData.
  validateURLNavigation() {
    //if the URL destination wasn't in the User Customizable Tab data, disable the tabIndex;
    try {
      let currentUrlPath: string = this.location.path();
      if (this.tabData && this.tabData.length > 0) {
        //Lookup on PARENT tab to find "parentTabIndex"
        let parentIndexFound: number = this.findTabIndexBaseOnURL(this.tabData, currentUrlPath, TabTypeEnums.PARENT);
        this.tabData.forEach(x => x.isActive = false);
        if (parentIndexFound == -1) {
          this.updateUserCustomizableTabsActiveIndex(-1).pipe(takeUntil(this.destroy$)).subscribe();
        }
        else {
          let tabId: number = this.tabData[parentIndexFound]?.tabId;
          const childrenTab = this.tabData[parentIndexFound]?.children?.find(x=>x.tabUrl == currentUrlPath);
          if(childrenTab){
            this.updateUserCustomizableTabsActiveIndex(childrenTab.tabId).pipe(takeUntil(this.destroy$)).subscribe();
            childrenTab.dateModified = new Date().toUTCString();
          }
          else {
            this.updateUserCustomizableTabsActiveIndex(tabId).pipe(takeUntil(this.destroy$)).subscribe();
            this.tabData[parentIndexFound].isActive = true;
          }
        }

        //Synchronize with tabData in component file
        this.tabDataSubject.next(this.tabData);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  //Convert from tabData into PARENT & CHILDREN relationship 
  handleConvertToChildrenTab(tabs: UserCustomizableTabModel[]) {
    let result: UserCustomizableTabModel[] = [];
    try {
      if (tabs && tabs.length > 0) {
        //get parent
        let parentTabs: UserCustomizableTabModel[] = tabs.filter(x => x.tabType == TabTypeEnums.PARENT).map(x => x as UserCustomizableTabModel);

        //get child 
        let childrenTabs: UserCustomizableTabModel[] = tabs.filter(x => x.tabType == TabTypeEnums.CHILDREN);

        //Mapping children into parent
        for (let tab of childrenTabs) {
          if (tab && tab?.extendData && !Helper.isNullOrEmpty(tab.extendData)) {
            try {
              let extendData = JSON.parse(tab.extendData);
              if (extendData && extendData?.[ExtendDataKeyEnums.PARENT_KEY]) {
                let parentId: number = Number.parseInt(extendData?.[ExtendDataKeyEnums.PARENT_KEY]);
                let parentIndex: number = parentTabs.findIndex(x => x.tabId == parentId);
                if (parentIndex != -1) {
                  if (!parentTabs[parentIndex]?.children) {
                    parentTabs[parentIndex].children = [];
                  }

                  parentTabs[parentIndex].children.push(tab);
                  parentTabs[parentIndex].children.sort((a, b) => { return a.tabOrders - b.tabOrders })
                }
              }

            } catch (ex) {
              console.error(ex);
              continue;
            }
          }
        }

        //Sort parents tab
        result = parentTabs.sort((a, b) => { return a.tabOrders - b.tabOrders });
      }
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  //Detect if the parentUrl have relationship with childrenUrl.
  detectParentChildrenRelationship(parentUrlInput: string, childrenUrlInput: string): boolean {
    let result: boolean = false;
    try {
      if (!Helper.isNullOrEmpty(parentUrlInput) && !Helper.isNullOrEmpty(childrenUrlInput)) {
        if (!parentUrlInput.startsWith("/")) {
          parentUrlInput = `/${parentUrlInput}`;
        }
        if (!childrenUrlInput.startsWith("/")) {
          childrenUrlInput = `/${childrenUrlInput}`;
        }

        //Loop through 3D
        for (let relationshipArr of customTabRelationshipUrls) {
          //relationshipArr -> an 2D array with parentArray & childrenArray
          let parentArray: string[] = relationshipArr[IndexRelationship.PARENT];
          let childrenArray: string[] = relationshipArr[IndexRelationship.CHILDREN];

          //Detect exist parentUrl in the ParentArray (exist: != -1)
          let detectParentUrlValid: boolean = parentArray.findIndex(parentUrl => parentUrl == parentUrlInput) != -1;

          if (detectParentUrlValid) {
            //Detect exist children in the ChildrenArray (exist: != -1)
            for (let childrenUrl of childrenArray) {
              result = this.compareTwoChildrenUrl(childrenUrl, childrenUrlInput);
              if (result) {
                break;
              }
            }

            if (result) {
              //End the loop because there is a result
              break;
            }
          }
        }
      }
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  //Validate the destinationUrl is match CHILDREN/PARENT relationship tab or not 
  detectRelationshipTypeUrl(destinationUrl: string, relationship: IndexRelationship): boolean {
    //children format: /abc/xyz/{id}

    var result: boolean = false;
    try {
      if (!Helper.isNullOrEmpty(destinationUrl)) {
        if (!destinationUrl.startsWith("/")) {
          destinationUrl = `/${destinationUrl}`;
        }

        //If ChildrenUrl
        if (relationship == IndexRelationship.CHILDREN) {
          //check if the destination URL is ignore ore not
          if (childrenIgnoreCases.findIndex(x => x == destinationUrl) != -1) {
            return;
          }

          for (let relationshipArr of customTabRelationshipUrls) {
            let childrenArray: string[] = relationshipArr[IndexRelationship.CHILDREN];

            for (let childrenUrl of childrenArray) {
              result = this.compareTwoChildrenUrl(childrenUrl, destinationUrl);
              if (result) { break; }
            }

            if (result) { break; }
          }
        }
        //If ParentUrl
        else if (relationship == IndexRelationship.PARENT) {
          let parentUrlPath: string = destinationUrl;
          // Remove /view/:id in url berfore checking. E.g: /configuration/sale-lead/view/0 => /configuration/sale-lead
          parentUrlPath = this.formatToDefaultURL(parentUrlPath);

          for (let relationshipArr of customTabRelationshipUrls) {
            let parentArray: string[] = relationshipArr[IndexRelationship.PARENT];

            for (let parentUrl of parentArray) {
              if (parentUrl == parentUrlPath) {
                result = true;
                break;
              }
            }

            if (result) { break; }
          }
        }
      }
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  compareTwoChildrenUrl(childrenInput1: string, childrenInput2: string) {
    let result: boolean = false;
    try {
      if (!Helper.isNullOrEmpty(childrenInput1) && !Helper.isNullOrEmpty(childrenInput2)) {
        let childrenUrl1Split: string[] = childrenInput1.split("/");
        let childrenUrl2Split: string[] = childrenInput2.split("/");

        if (
          (childrenUrl1Split && childrenUrl2Split) &&
          (childrenUrl1Split?.length == childrenUrl2Split?.length)
        ) {
          childrenUrl1Split.pop();
          childrenUrl2Split.pop();

          let baseChildrenUrl1: string = childrenUrl1Split.join("/");
          let baseChildrenUrl2: string = childrenUrl2Split.join("/");

          if (baseChildrenUrl1 == baseChildrenUrl2) {
            result = true;
          }
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }

  //Generate CHILDREN tab Model base on destinationUrl (REQUIREMENT: the parentTab must be inside the tabData already!)
  generateChildrenTabModel(destinationUrl: string) {
    let result: UserCustomizableTabModel = null;
    let model: UserCustomizableTabModel = new UserCustomizableTabModel();

    try {
      let extractChildrenURL = this.convertParentUrlToChildUrl(destinationUrl);
      let detailId: string = extractChildrenURL.detailId;

      //Use the childrenUrlPath to lookup in the current tabData to find the parentTab
      //Ex: (sale-account-crm).includes("sale-account")
      // let selectedParentTab: UserCustomizableTabModel = this.tabData.find(x => childrenUrlPath.includes(x.tabUrl));
      // if (constantParentUrls.findIndex(innerArray => innerArray.some(path => childrenUrlPath.includes(path))) != -1) {

      let lookupParentId: number = this.findTabIndexBaseOnURL(this.tabData, destinationUrl, TabTypeEnums.PARENT);
      if (lookupParentId != -1) {
        let extendData = { "parentId": this.tabData[lookupParentId]?.tabId };
        model.tabUrl = destinationUrl;
        model.tabName = detailId;
        model.tabType = TabTypeEnums.CHILDREN;
        model.isActive = true;
        model.extendData = JSON.stringify(extendData);

        result = model;
      }
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  //Handle click into details screen (turn into the Children on tab bar)
  detailClickedIntoTab(destinationUrl: string) {
    try {
      //Detail Tab exist || Parent Tab doesn't exist -> return;
      if (
        (this.checkExistTabByUrl(this.tabData, destinationUrl) != -1) ||
        (this.findTabIndexBaseOnURL(this.tabData, destinationUrl, TabTypeEnums.PARENT) == -1)
      ) {
        return;
      }

      let flattenTabData: UserCustomizableTabModel[] = this.flattenTabDataArray(this.tabData);
      if (flattenTabData.findIndex(x => x.tabUrl == destinationUrl) == -1) {
        if (this.detectRelationshipTypeUrl(destinationUrl, IndexRelationship.CHILDREN)) {
          let model: UserCustomizableTabModel = this.generateChildrenTabModel(destinationUrl);

          return this.saveUserCustomizableTabs(model).subscribe({
            next: (response) => {
              if (response?.result) {
                this.tabData = this.handleConvertToChildrenTab(response.result);
                this.tabDataSubject.next(this.tabData);
              }
            },
            complete: () => { },
          });
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  //Find TabIndex base on URL input 
  //Input (tabData, currentURL, tabType lookup)
  //1. CurrentURL (PARENT), tabType (PARENT) => find TabIndex of ParentTab by ParentURL
  //2. CurrentURL (CHILDREN), tabType (PARENT) => find TabIndex of ParentTab by ChildrenURL
  //3. CurrentURL (CHILDREN), tabType (CHILDREN) => find TabIndex of ChildrenTab by ChildrenURL
  //Do not support! CurrentURL (PARENT), tabType (CHILDREN).
  //Support find alternative URL (constantParentTabs) 
  findTabIndexBaseOnURL(tabData: UserCustomizableTabModel[], url: string, tabType?: TabTypeEnums): number {
    //Case 1: ParentURL -> ParentTabs
    //Case 2: ChildrenURL -> ParentTabs
    //Case 3: ChildrenURL -> ChildrenTabs

    let result: number = -1;
    let flattenTabData: UserCustomizableTabModel[] = [];

    try {
      //Check if the currentUrl is in Relationship
      let isCurrentUrlIsChildren: boolean = this.detectRelationshipTypeUrl(url, IndexRelationship.CHILDREN);
      let isCurrentUrlIsParent: boolean = this.detectRelationshipTypeUrl(url, IndexRelationship.PARENT);

      let relationshipIndex: number = -1;

      //If the currentURL isn't in Relationship
      if (!isCurrentUrlIsChildren && !isCurrentUrlIsParent) {
        var formattedURL = this.formatToDefaultURL(url);
        flattenTabData = this.flattenTabDataArray(tabData, TabTypeEnums.PARENT);
        result = flattenTabData.findIndex(tab => tab.tabUrl == formattedURL);
      }
      //If the currentURL is in Relationship
      else {
        if (tabType == TabTypeEnums.PARENT) {
          flattenTabData = this.flattenTabDataArray(tabData, TabTypeEnums.PARENT);

          //Case 2: ChildrenURL lookup ParentTabs
          if (isCurrentUrlIsChildren) {
            //Find RelationshipIndex by lookup on ChildrenUrl
            for (const [index, relationshipBlock] of customTabRelationshipUrls.entries()) {
              if (relationshipBlock[IndexRelationship.CHILDREN].findIndex(childrenUrl =>
                this.convertParentUrlToChildUrl(childrenUrl).childrenUrlPath ==
                this.convertParentUrlToChildUrl(url).childrenUrlPath)
                != -1
              ) {
                relationshipIndex = index;
                break;
              }
            }

            //There are relationship found
            if (relationshipIndex != -1) {
              for (let parentUrlInRelationship of customTabRelationshipUrls[relationshipIndex][IndexRelationship.PARENT]) {
                //Lookup ParentUrlArray in TabData to find index of tabData.
                let indexFound: number = flattenTabData.findIndex(tab => tab.tabUrl == parentUrlInRelationship);
                if (indexFound != -1) {
                  result = indexFound;
                }
              }
            }
          }
          //Case 1: ParentURL lookup ParentTabs 
          else {
            //Find RelationshipIndex by lookup on ParentUrl
            for (const [index, relationshipBlock] of customTabRelationshipUrls.entries()) {
              var formatURL = this.formatToDefaultURL(url);
              if (relationshipBlock[IndexRelationship.PARENT].findIndex(parentUrl => parentUrl == formatURL) != -1
              ) {
                relationshipIndex = index;
                break;
              }
            }

            //There are relationship found
            if (relationshipIndex != -1) {
              for (let parentUrlInRelationship of customTabRelationshipUrls[relationshipIndex][IndexRelationship.PARENT]) {
                //Lookup ParentUrlArray in TabData to find index of tabData.
                let indexFound: number = flattenTabData.findIndex(tab => tab.tabUrl == parentUrlInRelationship);
                if (indexFound != -1) {
                  result = indexFound;
                }
              }
            }
          }
        }
        else if (tabType == TabTypeEnums.CHILDREN) {
          flattenTabData = this.flattenTabDataArray(tabData, TabTypeEnums.CHILDREN);
          //Find RelationshipIndex by lookup on ChildrenUrl
          for (const [index, relationshipBlock] of customTabRelationshipUrls.entries()) {
            if (relationshipBlock[IndexRelationship.CHILDREN].findIndex(childrenUrl =>
              this.convertParentUrlToChildUrl(childrenUrl).childrenUrlPath ==
              this.convertParentUrlToChildUrl(url).childrenUrlPath)
              != -1
            ) {
              relationshipIndex = index;
              break;
            }
          }

          //There are relationship found
          if (relationshipIndex != -1) {
            for (let childrenUrlInRelationship of customTabRelationshipUrls[relationshipIndex][IndexRelationship.CHILDREN]) {
              //Lookup ParentUrlArray in TabData to find index of tabData.
              let indexFound: number = flattenTabData.findIndex(tab =>
                this.convertParentUrlToChildUrl(tab.tabUrl).childrenUrlPath ==
                this.convertParentUrlToChildUrl(childrenUrlInRelationship).childrenUrlPath
              );
              if (indexFound != -1) {
                result = indexFound;
              }
            }
          }
        }
      }
    } catch (ex) {
      console.error(ex)
    }

    return result;
  }

  //Bring Array with PARENT - CHILD into 1 layers array only.
  flattenTabDataArray(tabData: UserCustomizableTabModel[], tabType?: TabTypeEnums): UserCustomizableTabModel[] {
    let result: UserCustomizableTabModel[] = [];
    let parentOnly: boolean = false;
    let childrenOnly: boolean = false;

    try {
      if (!Helper.isNullOrEmpty(tabType)) {
        //Only get PARENT tabs
        if (tabType == TabTypeEnums.PARENT) {
          parentOnly = true;
        }
        //Only get CHILDREN tabs
        else if (tabType == TabTypeEnums.CHILDREN) {
          childrenOnly = true;
        }
      }

      let cloneTabData: UserCustomizableTabModel[] = [];
      tabData.forEach(tab => cloneTabData.push(Object.assign({}, tab)));

      for (let tab of cloneTabData) {
        if (!parentOnly) {
          if (tab?.children && tab?.children?.length > 0) {
            result.push(...tab?.children);
          }
        }
        if (!childrenOnly) {
          tab.children = [];
          result.push(tab);
        }
      }

    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  //Check if there is any tab with the EXACT url match with the input URL
  //Ex: /abc/axys === /abc/axys => TRUE
  //Ex: /abx/axys === /abc/axys => FALSE
  checkExistTabByUrl(tabData: UserCustomizableTabModel[], url: string) {
    let result: number = 0;
    try {
      let flattenTabData: UserCustomizableTabModel[] = this.flattenTabDataArray(tabData);
      return flattenTabData.findIndex(x => x.tabUrl == url);
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }

  //Split ChildrenUrl
  // config/profile/123asd -> config/profile && 123asd
  convertParentUrlToChildUrl(childrenUrl: string): { childrenUrlPath: string, detailId: string } {
    let result = {
      // URL path ignore Detail ID
      childrenUrlPath: "",
      // Detail ID
      detailId: ""
    };

    try {
      if (!Helper.isNullOrEmpty(childrenUrl)) {
        //Remove ID out of the URL: /abc/xyz/123 -> /abc/xyz
        let tempDestinationArr: string[] = childrenUrl.split("/");
        //Save the Detail ID into tabName -> go to Server lookup for the Display Name
        let detailId: string = tempDestinationArr.pop();
        let childrenUrlPath: string = tempDestinationArr.join("/");

        result.childrenUrlPath = childrenUrlPath;
        result.detailId = detailId;
      }
    }
    catch (ex) {
      console.error(ex);
    }

    return result;
  }
  
  // Remove /view/:id in url. E.g: /configuration/sale-lead/view/0 => /configuration/sale-lead
  // Remove query params in url. E.g: /configuration/wp-location?query=state => /configuration/wp-location
  formatToDefaultURL(url: string = "") {
    try {
      var isChecked = false;
      if (url.includes("/view/")) {
        let tempArr: string[] = url.split("/view/");
        if (tempArr && tempArr?.length > 0) {
          url = tempArr[0];
          isChecked = true;
        }
      } 
      if(url.includes("?") && !isChecked) {
        let tempArr: string[] = url.split("?");
        if (tempArr && tempArr?.length > 0) {
          url = tempArr[0];
        }
      }
    } catch(error) {
      console.error(error);
    }
    return url;
  }
}