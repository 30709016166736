import { SupportTicket } from './../support-ticket.model';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-support-ticket-detail',
  templateUrl: './support-ticket-detail.component.html',
  styleUrls: ['./support-ticket-detail.component.scss']
})
export class SupportTicketDetailComponent implements OnInit {

  supportTicket: SupportTicket;
  constructor(  public dialModalRef: MatDialogRef<SupportTicketDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,) { 

      this.supportTicket = data.model;
    }

  ngOnInit(): void {
    this.dialModalRef.updatePosition({ right: '0', });
    this.dialModalRef.updateSize("100%", "100%")
  }
  closeDialog() {
    this.dialModalRef.close();
  }
}
