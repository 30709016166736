import { ActivityByUserViewModel, ActivityEntityViewModel, ContactActivityLogViewModel, DetailsActivityByUserViewModel, LogError, ActivityLogEmailActionDetail } from './../../modules/admin/campaign-management/campaign-tracking.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingRangeDateFilter } from 'src/app/modules/admin/activity-log-management/page-filter-range-date';
import { ActivityLog } from 'src/app/modules/admin/campaign-management/campaign-tracking.model';
import { environment } from 'src/environments/environment';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ReturnResult } from '../models/return-result';
import { ProfileModel } from 'src/app/modules/admin/profile-management/profile-model';
import { Category, Contact, Location } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { EmailHistoryViewModel } from 'src/app/modules/admin/email-history/email-history-model';
import { timeout } from 'rxjs/operators';
import { EmailAttachment } from '../models/email-attachment.model';
import { ProfileEmailLookup } from 'src/app/modules/admin/task-management/task-activity-log/task-activity-log.model';
import { ExternalActivityLogModel } from '../components/add-external-activity-log/add-external-activity-log.component';
import { ActivityReportClient } from 'src/app/modules/admin/activity-log-management/activity-profile/activity-report-client.model';
import { SeoActivityLogRespond, SeoActivityLogViewModel } from 'src/app/modules/admin/indexing/indexing.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {
  baseUrl = environment.apiActivityLog;
  constructor(private http: HttpClient) { }

  getAllActivityLogProfile(id: string): Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${this.baseUrl}/ActivityLogProfile/${id}`);
  }

  getActivityLog(page: PagingRangeDateFilter): Observable<ReturnResult<PagedData<ActivityLog>>> {
    return this.http.post<ReturnResult<PagedData<ActivityLog>>>(`${this.baseUrl}/GetActivityLogPaging`, page);
  }
  getContactActivityLog(profileId: string, data: ContactActivityLogViewModel): Observable<ReturnResult<PagedData<ActivityLog>>> {
    return this.http.post<ReturnResult<PagedData<ActivityLog>>>(`${this.baseUrl}/GetContactActivityLog?profileId=${profileId}`, data);
  }

  getActivityByUserPaging(page: PagingRangeDateFilter): Observable<ReturnResult<PagedData<ActivityByUserViewModel>>> {
    return this.http.post<ReturnResult<PagedData<ActivityByUserViewModel>>>(`${this.baseUrl}/GetActivityByUserPaging`, page);
  }

  getActivityProfiles(page: PagingRangeDateFilter, username: string, option: ActivityReportClient): Observable<ReturnResult<PagedData<ProfileModel>>> {
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.baseUrl}/GetActivityProfiles?username=${username}`, {
      page,
      option
    });
  }

  getActivityContacts(page: PagingRangeDateFilter, username: string): Observable<ReturnResult<PagedData<Contact>>> {
    return this.http.post<ReturnResult<PagedData<Contact>>>(`${this.baseUrl}/GetActivityContacts?username=${username}`, page);
  }

  getActivityLocations(page: PagingRangeDateFilter, username: string): Observable<ReturnResult<PagedData<Location>>> {
    return this.http.post<ReturnResult<PagedData<Location>>>(`${this.baseUrl}/GetActivityLocations?username=${username}`, page);
  }

  getActivityCategoris(page: PagingRangeDateFilter, username: string): Observable<ReturnResult<PagedData<Category>>> {
    return this.http.post<ReturnResult<PagedData<Category>>>(`${this.baseUrl}/GetActivityCategories?username=${username}`, page);
  }

  getDetailsActivityByUserPaging(page: PagingRangeDateFilter, username: string): Observable<ReturnResult<PagedData<DetailsActivityByUserViewModel>>> {
    return this.http.post<ReturnResult<PagedData<DetailsActivityByUserViewModel>>>(`${this.baseUrl}/GetDetailsActivityByUserPaging?username=${username}`, page);
  }

  getPagingEmailHistory(page: Page): Observable<ReturnResult<PagedData<EmailHistoryViewModel>>> {
    return this.http.post<ReturnResult<PagedData<EmailHistoryViewModel>>>(`${this.baseUrl}/GetPagingEmailHistory`, page);
  }
  //2022-04-28 gnguyen start add
  importActivity(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/import`, formData).pipe(
      timeout(1200000)
    );
  }

  //2022-04-28 gnguyen end add
  addActivityLog(activityLog: any) {
    return this.http.post(`${this.baseUrl}/add`, activityLog);
  }
  getPagingIncomingEmail(page: Page): Observable<ReturnResult<PagedData<EmailHistoryViewModel>>> {
    return this.http.post<ReturnResult<PagedData<EmailHistoryViewModel>>>(`${this.baseUrl}/GetPagingIncomingEmail`, page);
  }
  updateIsReadEmail(id: number, isRead: boolean): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdateIsReadEmail/`, { id, isRead });
  }
  markMyEmail(id: number): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MarkMyEmail/${id}`, {});
  }
  unMarkMyEmail(id: number): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UnMarkMyEmail/${id}`, {});
  }

  getEmailAttachment(activityLogId: number): Observable<ReturnResult<EmailAttachment[]>> {
    return this.http.get<ReturnResult<EmailAttachment[]>>(`${this.baseUrl}/EmailAttachment/${activityLogId}`);
  }
  lookupProfileByEmail(email: string) {
    return this.http.get<ReturnResult<string[]>>(`${this.baseUrl}/LookupMultiProfileByEmail?email=${email}`);
  }

  checkConnect0365Auth(): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/CheckConnect365Auth`, {});
  }

  removeConnect365Auth(): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/RemoveConnect365Auth`, {});
  }

  addExternalActivityLog(model: ExternalActivityLogModel): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/AddExternalActivityLog`, model);
  }
  deleteExternalLog(activityLogId: number) {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteExternalLog/${activityLogId}`)
  }
  SaveLogError(logError: LogError) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveLogError`, logError);
  }
  GetSeoActivityLog(model: SeoActivityLogViewModel) {
    return this.http.post<ReturnResult<SeoActivityLogRespond[]>>(`${this.baseUrl}/GetSeoActivityLog`, model);
  }
  GetProfileDataWithEmailCampaign(campaignId: number, profileId: string, action: string = 'Click') {
    return this.http.post<ReturnResult<ActivityLogEmailActionDetail[]>>(`${this.baseUrl}/GetProfileDataWithEmailCampaign?profileId=${profileId}&campaignId=${campaignId}&action=${action}`, null)
  }
  updateLastContact(activityLogId: number): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdateLastContact`, { activityLogId: activityLogId });
  }
}
