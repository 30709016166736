<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;">
  <div style="padding-left: 18px;">    <b>Support ticket {{supportTicket.ticketId}}: &nbsp;</b> <i style="font-weight:500">{{supportTicket.title || 'No data display'}}</i></div>
  <button mat-icon-button (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
    <!-- <div class="row">
      <div class="col-md-12 padding-block-profile">
        <h4><strong>Title</strong></h4>
        <div class="row">
          <div class="col-12">
         
          </div>
        </div>
      </div>
    </div> -->
    <div style="background-color: #e7f0f6; padding: 12px; margin-bottom: 10px; border-radius: 5px;">
      <div class="row">
        <div class="col-md-12 padding-block-profile">
          <h4><strong>Resolution code</strong></h4>  <div [ngClass]="supportTicket.resolutionCode ?  'badge badge-pill badge-success' : 'badge badge-pill badge-secondary'" style="font-size:16px">
            {{supportTicket.resolutionCode || 'WAITING FOR SUPPORT'}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 padding-block-profile">
          <h4><strong>System comment</strong></h4>
          <div class="row">
            <div class="col-12">
                  {{supportTicket.systemComment || 'No data display'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #f4eff8; padding: 12px; margin-bottom: 10px; border-radius: 5px;">
      <div class="row" >
        <div class="col-md-12 padding-block-profile">
          <h4><strong>Date Created</strong></h4>
          <div class="row">
            <div class="col-12">
              {{supportTicket.dateCreated || 'Unknown'| date : 'M/dd/y, h:mm:ss aaa'}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 padding-block-profile">
          <h4><strong>Description</strong></h4>
          <div class="row">
            <div class="col-12" >
              <nb-card>
                <nb-card-body>
                  <div [innerHtml]="supportTicket.bodyHtml || 'No data display' | safeHtml ">
                  </div>
                </nb-card-body>
              </nb-card>
            </div> 
          </div>
        </div>
      </div>
    </div>
    </div>
   
</mat-dialog-content>