import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { KeywordField, KeywordMappingForm, KeywordMappingViewModel, SeoInfo } from '../../../profile-management/profile-detail.model';
import { AddSeoInfoComponent } from '../../../seo-info-management/add-seo-info/add-seo-info.component';
import { seoInfoInputModel } from '../../../seo-info-management/seo-info-model';
import { SeoInfoService } from '../../../seo-info-management/seo-info.service';
import { SeoInfoFormControl } from 'src/app/shared/models/seo-form-control.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutomateDatastateService } from '../../../profile-management/profile-detail/automate-datastate-log/automate-datastate.service';
import { DatastateManagementService } from '../../../datastate-management/datastate-management.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { SettingService } from 'src/app/shared/services/setting.service';
import { KeywordHistoryComponent } from './keyword-history/keyword-history.component';
import { countWordValidators } from './countWordValidator';

@Component({
  selector: 'app-add-edit-keyword-mapping-tool',
  templateUrl: './add-edit-keyword-mapping-tool.component.html',
  styleUrls: ['./add-edit-keyword-mapping-tool.component.scss']
})
export class AddEditKeywordMappingToolComponent implements OnInit {
  action: TblActionType;
  frm: FormGroup;
  formDoc: FormGroup;
  selectedSeoInfoId = '';
  isLoading = false;
  isStatusLoading = false;
  readonly = true;
  keywordMapping: KeywordMappingForm;
  isChange: boolean = false;
  statusKeywordMapping = [];
  duplicateModels: KeywordMappingViewModel[];
  duplicateAlert: object[] = [];
  duplicateInputMessage: object[] = [];
  mode = TblActionType;
  _destroy: Subject<void> = new Subject<void>();
  disableField: boolean = false;
  totalVolume = 0;
  matcher = new KeywordErrorStateMatcher();
  maxWord: number = 6;
  mentionConfig = {
    items: [
      { text: "CategoryName", value: "CategoryName]" },
      { text: "CategoryMetaTitle", value: "CategoryMetaTitle]" },
      { text: "Country", value: "Country]" },
      { text: "State", value: "State]" },
      { text: "City", value: "City]" },
      { text: "CurrentDay", value: "CurrentDay]" },
      { text: "CurrentMonth", value: "CurrentMonth]" },
      { text: "CurrentYear", value: "CurrentYear]" },
    ],
    triggerChar: "[",
    labelKey: 'value',
    disableSort: true,
    //dropUp: true,
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdref: ChangeDetectorRef,
    public dialModalRef: MatDialogRef<AddEditKeywordMappingToolComponent>,
    private frmBuilder: RxFormBuilder,
    private seoInfoService: SeoInfoService,
    private toast: NbToastrService,
    public dialog: MatDialog,
    public dataStateService: DatastateManagementService,
    private settingService: SettingService
  ) {
    this.action = data.action;
  }

  ngOnInit(): void {
    this.dialModalRef.updatePosition({ right: '0', });
    this.convertExtendDataToGroup();
    this.getStatusKeywordMapping();
  }
  async closeDialog(value: boolean = false) {
    if (this.isChange) {
      const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
        data: { message: 'Do you wish to close this popup? You will lose your unsaved data.' }
      });

      var result = await dialogConfirm.afterClosed().toPromise();
      if (!result) return;
    }

    this.dialModalRef.close();
  }
  async saveKeywordMapping() {
    if (this.frm.valid) {
      const model: KeywordMappingForm = this.frm.value;
      var res = await this.seoInfoService.checkDuplicateKeyword(model).toPromise();
      this.duplicateModels = res.result;
      this.duplicateAlert = [];
      this.duplicateInputMessage = [];
      this.onCheckContainsExist();
      if (res.result != null) {
        model.KeywordLst.forEach(key => {
          if(!key.Keyword) return;
          this.duplicateModels.forEach(m => {
            const duplicateModel = m?.keywordLst.find(x => x?.keyword?.toLowerCase().trim().includes(key?.Keyword.toLowerCase().trim()));
            if (duplicateModel) {
              const alert = { key: key.Keyword, url: m?.targetUrl };
              this.duplicateAlert.push(alert);
            }
          })
        });
        setTimeout(() => document.getElementById('duplicateNotification')
          .scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }), 100);
      }
      else if(res.result == null && this.duplicateInputMessage && this.duplicateInputMessage.length > 0) {
        setTimeout(() => document.getElementById('duplicateNotification')
          .scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }), 100);
      }
      else {
        const rsSave = await this.seoInfoService.saveKeywordMapping(model).toPromise();
        if (rsSave && !rsSave.message) {
          this.toast.success("Saved");
          this.dialModalRef.close((this.action == TblActionType.Add) ? true : false);
        }
      }
    }
  }
  removeDetail(index: number) {
    try {
      if (index >= 0) {
        const KeywordControls = this.frm?.controls['KeywordLst']['controls'][index].get('Keyword');
        KeywordControls.setValue('_@#_');
        KeywordControls.setValue('');
        let details = this.frm?.controls?.KeywordLst as FormArray
        if (details) {
          details.removeAt(index);
        }
      }
    }
    catch (ex) { }
  }

  convertExtendDataToGroup() {
    if (this.action == TblActionType.Custom) {
      this.disableField = true;
    }
    if (this.action == TblActionType.Add) {
      this.keywordMapping = new KeywordMappingForm();
      this.keywordMapping.ReferenceType = this.data?.referenceType;
      this.frm = this.frmBuilder.formGroup(KeywordMappingForm, this.keywordMapping);
      

      this.addDetail(true);
    }
    else {
      this.keywordMapping = JSON.parse(this.data.model.rawExtendData ?? this.data.model.RawExtendData);
      this.keywordMapping.KeywordMappingId = this.data.model.keywordMappingId ?? this.data.model.KeywordMappingId;
      this.frm = new FormGroup({})
      this.frm = this.frmBuilder.formGroup(KeywordMappingForm, this.keywordMapping);
      if(this.keywordMapping.ReferenceType == "Location" || this.keywordMapping.ReferenceType == "CategoryLocation") {
        this.frm.controls.Name.disable();
        //this.frm.controls.TargetUrl.disable();
      }
    }
    if(this.keywordMapping.ReferenceType == "CategoryLocation") {
      this.frm.controls.TargetUrl.clearValidators();
      this.frm.controls.TargetUrl.updateValueAndValidity();
    }
    this.setUpKeywordValidator();
    this.initValueChangeEvent();
  }

  setUpKeywordValidator() {
    try {
      var formArrayKeywordList: FormArray = this.frm?.get('KeywordLst') as FormArray;
      formArrayKeywordList.controls.forEach((formGroup, index) => {
        let formControl: FormControl = formGroup.get('Keyword') as FormControl;
        formControl.clearValidators();
        formControl.setValidators([
          countWordValidators(this.maxWord),
          RxwebValidators.unique({ message: "The keyword must be unique." })
        ]);
        formControl.updateValueAndValidity();
        formGroup.updateValueAndValidity();
      })
      formArrayKeywordList.updateValueAndValidity();
      this.frm.updateValueAndValidity();
    } catch(error) {}
  }

  initValueChangeEvent() {
    this.frm?.controls?.KeywordLst.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(resp => {
        this.keywordMapping.TotalVolume = 0;
        this.frm.controls.KeywordLst.value.forEach((e: KeywordField) => {
          try {
            this.keywordMapping.TotalVolume += +(e.SearchVolume)
          }
          catch (ex) {
          }
        })
        this.isChange = true
      });
  }
  addDetail(detect: boolean = false) {
    try {
      let details = this.frm?.controls?.KeywordLst as FormArray
      if (details) {
        let keywordStuff = new KeywordField();
        if (details.length == 0) keywordStuff.IsPrimary = true;
        let frmGroup = this.frmBuilder.formGroup(keywordStuff);
        details.push(frmGroup);
        this.setUpKeywordValidator();
        // if (detect && frmGroup) {
        //   frmGroup.valueChanges
        //     .pipe(takeUntil(this._destroy))
        //     .subscribe(resp => {
        //       this.keywordMapping.TotalVolume = 0;
        //       this.frm.controls.KeywordLst.value.forEach((e: KeywordField) => {
        //         try {
        //           this.keywordMapping.TotalVolume += +(e.SearchVolume)
        //         }
        //         catch (ex) {
        //         }
        //       })
        //       this.isChange = true
        //     });
        // }
      }
    }
    catch (ex) { console.log(ex); }
  }
  async getStatusKeywordMapping() {
    this.isStatusLoading = true;
    const data = await this.dataStateService.getAutomateDataState("KEYWORD_MAPPING").toPromise();
    if(this.action == TblActionType.Add) {
      const defaultStatusSetting = await this.settingService.getSettingByKeyAndGroup("DEFAULT_KW_STATUS", "SEO").toPromise();
      if(defaultStatusSetting.result?.value ) {
        this.keywordMapping.Status = Number(defaultStatusSetting.result.value);
        this.frm.controls['Status'].setValue(this.keywordMapping.Status);
      }
    }
    if (!data.message) {
      this.statusKeywordMapping = data.result;
    }
    this.isStatusLoading = false;
  }
  onCheckContainsExist() {
    const model: KeywordMappingForm = this.frm.value;
    model.KeywordLst.forEach(value => {
      const existedItems = model.KeywordLst.filter(x => x?.Keyword?.toLowerCase().trim().includes(value?.Keyword?.toLowerCase().trim())
                              && x?.Keyword?.toLowerCase().trim() !== value?.Keyword?.toLowerCase().trim());
      var item = model.KeywordLst.findIndex(x => x?.Keyword?.toLowerCase().trim() === value?.Keyword?.toLowerCase().trim());
      if(existedItems && existedItems.length > 0 && value.Keyword && value) {
        this.duplicateInputMessage[item] = {key: "",keywords: ""};
        this.duplicateInputMessage[item]['key'] = `${value.Keyword}`;
        var listKeyword = [];
        existedItems.forEach(element => {
          var index = model.KeywordLst.findIndex(x => x.Keyword.toLowerCase().trim() === element.Keyword.toLowerCase().trim());
          if(index === 0) listKeyword.push(`Primary Keyword`);
          else listKeyword.push(`Secondary Keyword ${index}`);
        });
        this.duplicateInputMessage[item]['keywords'] = listKeyword.join(', ');
      }
    });
  }

  changeInput(value: string, index: number) {
    if(value === "" && index >= 0) {
      const KeywordControls = this.frm?.controls['KeywordLst']['controls'][index].get('Keyword');
      KeywordControls.setValue('_@#_');
      KeywordControls.setValue('');
    }
  }

  openKeywordHistory(keywordType: string, index: number) {
    let historyRef = this.dialog.open(KeywordHistoryComponent, {
      disableClose: true,
      autoFocus: false,
      width: '45vw',
      data: {
        id: this.keywordMapping.KeywordMappingId,
        clusterName: this.keywordMapping.Name,
        keywordName: `${keywordType} Keyword`,
        keywordIndex: index
      }
    });
    historyRef.afterClosed().subscribe(res => {
      if (res) {
        // this.refreshData(true);
      }
    });
  }
}

export class KeywordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid);
  }
}
