import { SaleAccountViewModel } from './../add-sale-acount.model';
import { SaleAccountService } from './../sale-account.service';
import { SaleAccountDetailsComponent } from './../sale-account-details/sale-account-details.component';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { DataFieldsManagementService } from 'src/app/shared/services/data-fields-management.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { KeyPairsValue } from '../../profile-management/profile-detail.model';
import { AddEditSaleLeadComponent } from '../../sale-lead-management/add-edit-sale-lead/add-edit-sale-lead.component';
import { SaleLeadsViewModel } from '../../sale-lead-management/add-sale-leads.model';
import { SaleLeadService } from '../../sale-lead-management/sale-lead.service';
import { ContactService } from 'src/app/shared/services/contact.service';
import { map, skip, startWith, take, takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { MatSelectChange } from '@angular/material/select';
import { ContactTypeFilter } from 'src/app/shared/components/stand-alone-component/contact/contact-type';
import { environment } from 'src/environments/environment';
import { DynamicContentModel } from 'src/app/shared/models/dynamic-content.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-add-sale-account',
  templateUrl: './add-sale-account.component.html',
  styleUrls: ['./add-sale-account.component.scss']
})
export class AddSaleAccountComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('websiteInput') websiteInput: ElementRef<HTMLInputElement>;

  @Input() frmProfile: FormGroup;
  @Input() openFromCustomPopup: boolean = false;
  @Input() stateFromContactMap: {id: string, name: string} = null;
  @Input() countryFromContactMap: any = null;

  environment = environment;
  action: TblActionType;
  profileModel: SaleLeadsViewModel;
  isLoading = false;
  selectedProfileId = '';
  onsaved = false;
  editorOptions = QuillConfiguration;
  //2021-09-10 hieuvm start add
  parentId: string;
  arrayContactType: ContactTypeFilter[] = [];
  parentContactType: ContactTypeFilter[] = [];
  childContactType: ContactTypeFilter[] = [];

  //2021-09-10 hieuvm end add
  // tslint:disable-next-line:use-lifecycle-interface
  isChange: boolean = false;
  dynamicContentCountryAPI = this.dynamicContentService.getDynamicContentByType('country');
  dynamicContentStateAPI = this.dynamicContentService.getDynamicContentByType('location_state');
  // dynamicContentWebsiteAPI = this.dynamicContentService.getDynamicContentByType('general_webpage');
  dynamicContentEmailAPI = this.dynamicContentService.getDynamicContentByType('general_email');
  dynamicContentPhoneAPI = this.dynamicContentService.getDynamicContentByType('general_phone');

  listDynamicContentWebsite: DynamicContentModel[] = [];
  separatorKeysCodes: number[] = [ENTER];
  websiteObservable = new Observable<DynamicContentModel[]>();
  private _destroy: Subject<void> = new Subject<void>();
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  findItemByKey = Helper.findItemByKey;
  tooltipProp: KeyPairsValue[] = [
    { key: 'displayName', value: 'Display Name' },
    { key: 'realName', value: 'Real Name' },
    { key: 'webPage', value: 'Website' },
  ];

  listWebsite: string[] = []
  loadingWebsite: boolean = false;
  constructor(
    public dialModalRef: MatDialogRef<AddSaleAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SaleAccountService,
    private toast: NbToastrService,
    private frmBuilder: RxFormBuilder,
    private cdref: ChangeDetectorRef,
    private dataFieldsService: DataFieldsManagementService,
    private dialog: MatDialog,
    private serviceContact: ContactService,
    private dynamicContentService: DynamicContentService,

  ) {
    this.action = data.action;
    this.profileModel = data.model ?? new SaleAccountViewModel();

    // Only update the state when creating a new SaleAccount
    if (data.state && !data.model) {
      this.profileModel.state = data.state;
    }
    if (data.country && !data.model) {
      this.profileModel.country = data.country.countryName;
    }
  }

  ngOnInit() {
    if (!this.frmProfile) {
      this.frmProfile = this.frmBuilder.formGroup(SaleAccountViewModel, this.profileModel);
    }

    // Set the State from Input Parameters if existing and in the create a new Account mode
    if (this.stateFromContactMap && !(this.frmProfile.controls.profileId?.value)) {
      this.profileModel.state = this.stateFromContactMap.name;
    }
    if (this.countryFromContactMap && !(this.frmProfile.controls.profileId?.value)) {
      this.profileModel.country = this.countryFromContactMap.countryName;
    }

    // If the status is Create New Account, update state if exsist
    if (!this.frmProfile.controls.profileId.value) {
      if (this.profileModel.state) {
        this.frmProfile.controls.state.setValue(this.profileModel.state);
      }
      if (this.profileModel.country) {
        this.frmProfile.controls.country.setValue(this.profileModel.country);
      }
    }

    this.getListWebsiteDynamic();
    this.dialModalRef.updatePosition({ right: '0' });
    this.getContactType();
  }

  ngAfterViewInit(): void {
    if (this.frmProfile)
      this.frmProfile.valueChanges.pipe(skip(1), take(1))
        .subscribe(resp => this.isChange = true);
  }
  closeDialog() {
    if (this.isChange) {
      this.isChange = false;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: "Do you wish to close this popup? You will lose your unsaved data."
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) this.dialModalRef.close();
        else this.isChange = true;
      });
      return;
    }

    this.dialModalRef.close();
  }
  getCategoriesLst(categoryLst: { key: string, value: string }[]): void {
    console.log(categoryLst);

    if (categoryLst.length > 0) {
      this.frmProfile.setValue({ ...this.frmProfile.value, categories: categoryLst });
    } else {
      this.frmProfile.setValue({ ...this.frmProfile.value, categories: [] });
    }
  }

  saveData(): void {
    if (this.frmProfile.valid) {
      // validated
      this.isLoading = !this.isLoading;
      const websiteData = this.listWebsite?.join(",");
      const model: SaleAccountViewModel = Object.assign({}, this.frmProfile.value);
      if (model.contactTypeLst) {
        let currentContactTypeLst = [];
        currentContactTypeLst.push(model.contactTypeLst);
        if (model.accountSubContactType) {
          currentContactTypeLst.push(model.accountSubContactType);
        }
        model.contactTypeLst = currentContactTypeLst;
      }
      model.website = websiteData;
      //model.parentId = this.parentId ?? null;
      // call api
      let createOb = this.service.saveSaleAccount(model);
      createOb.subscribe(reps => {
        if (reps.result) {
          this.dialModalRef.close(reps.result);
          //this.onClickEdit(reps.result);
          this.toast.success(`Create ${this.environment.titleAccount} successfully`, 'Success');
        }
      }).add(() => {
        this.isLoading = !this.isLoading;
      });

    }
  }
  ngOnDestroy(): void {
    if (this._destroy) {
      this._destroy.next();
      this._destroy.complete();
    }
  }
  getTagsFromComponent(values: string[]): void {
    console.log(values);
    if (values.length > 0) {
      this.frmProfile.setValue({ ...this.frmProfile.value, tags: values.join(',') });
    } else {
      this.frmProfile.setValue({ ...this.frmProfile.value, tags: '' });
    }
  }
  onClickEdit(profileId: string) {

    const dialogRef = this.dialog.open(SaleAccountDetailsComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        model: { profileId },
        action: TblActionType.Edit
      }
    });
  }
  getContactType() {
    this.serviceContact.GetAllContactTypeAsync("ACCOUNT").subscribe(res => {
      if (res.result != null) {
        this.arrayContactType = res.result;
        this.parentContactType = res.result.slice().filter(x => x.parentId == 0);
      }
    });
  }
  parentChange(data: MatSelectChange) {
    if (data) {
      this.childContactType = this.arrayContactType.slice().filter(x => x.parentId == data.value);
      if (this.childContactType.length == 0) {
        this.frmProfile.controls.accountSubContactType.setValue(0);
      }
    } else {
      this.childContactType = [];
      this.frmProfile.controls.accountSubContactType.setValue(0);
    }
  }

  addWebsite(event: any): void {
    if (event.value && this.checkValueIsUrl(event.value)) {
      let value = (event.value || '').trim().toLowerCase();
      value = value.replace(/^(https?:\/\/)?/, '').split('/')[0];

      if (!this.listWebsite.includes(value) && !Helper.isNullOrEmpty(value)) {
        this.listWebsite.push(value);
      }

      this.frmProfile.controls.website.setValue("");
      this.websiteInput.nativeElement.value = '';
    } else {
      this.websiteInput.nativeElement.value = '';
      this.frmProfile.controls.website.setValue("");
      this.frmProfile.controls.website.markAsTouched();
    }
  }

  removeWebsite(url: string) {
    const index = this.listWebsite.indexOf(url);
    if (index >= 0) {
      this.listWebsite.splice(index, 1);
    }
  }

  onClearInput(value) {
    value = '';
    this.websiteInput.nativeElement.value = '';
  }

  selectedWebsite(event: MatAutocompleteSelectedEvent): void {
    if (event.option.viewValue && this.checkValueIsUrl(event.option.value)) {
      let value = event.option.viewValue;
      value = value.replace(/^(https?:\/\/)?/, '').split('/')[0];

      if (!this.listWebsite.includes(event.option.viewValue)) {
        this.listWebsite.push(value);
        this.frmProfile.controls.website.setValue("");
        this.websiteInput.nativeElement.value = '';
      }
    } else {
      this.websiteInput.nativeElement.value = '';
      this.frmProfile.controls.website.setValue("");
      this.frmProfile.controls.website.markAsTouched();
    }
  }

  checkValueIsUrl(url: string): boolean {
    var isUrlValid = false;
    const regex = /^\s*(https?:\/\/)?(localhost|(?!.*\.\.)([\w-]+\.)+[\w-]{1,})(:\d+)?(\/[^\s]*)?\s*$/;
    if (url && regex.test(url)) {
      isUrlValid = true;
    }

    return isUrlValid;
  }

  private _filter(value: string): DynamicContentModel[] {
    const filterValue = value.toLowerCase();
    return this.listDynamicContentWebsite.filter(x => x.content.toLowerCase().includes(filterValue));
  }

  async getListWebsiteDynamic(addValueChangeTagSelected: boolean = true) {
    this.loadingWebsite = true
    const tagValue: string = this.frmProfile?.controls['website']?.value;
    if (tagValue) {
      this.listWebsite = tagValue.split(",").filter(x => x).map(x => x.trim());
    }
    const result = await this.dynamicContentService.getDynamicContentByType('general_webpage').toPromise();
    if (result.result) this.listDynamicContentWebsite = result.result;
    if (addValueChangeTagSelected) {
      this.websiteObservable = this.frmProfile.controls['website'].valueChanges.pipe(
        startWith(''), takeUntil(this._destroy),
        map((value: string | null) => value !== '' ? this._filter(value) : this.listDynamicContentWebsite.slice()));
    }
    this.loadingWebsite = false;
  }
}
