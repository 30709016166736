<div class="table-responsive material" *nbIsGranted="['view', resource.table]">
  <ngx-datatable #table [ngClass]="{'material': true,
  'server-scrolling': true,
  'has-group-rows': !!groupRowsBy,
  classAddMore: true}" [ngStyle]="{'height': optionHeight ? optionHeight : customView?'calc(100vh - 230px)':'calc(100vh - 230px)'}" [rows]="rows" [(columns)]="columns" [columnMode]="ColumnMode.force" [loadingIndicator]="isLoading > 0" [headerHeight]="headerHeight" [footerHeight]="50" [rowHeight]="rowHeight ? rowHeight : dynamicRowHeight" [externalPaging]="externalPaging" [reorderable]="false"
    [swapColumns]="false" [selected]="selected" [selectionType]="SelectionType.checkbox" [externalSorting]="externalSorting" [selectAllRowsOnPage]="false" [scrollbarH]="true" [scrollbarV]="isVirtualScrollV" [virtualization]="false" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (page)="setPage($event)" (select)="onSelect($event)" (sort)="onSortColumn($event)"
    (activate)="onActivate($event)" sortType="multi" [rowClass]="rowClass" [groupRowsBy]="groupRowsBy || null" [groupExpansionDefault]="!!groupRowsBy && groupExpansionDefault" (mouseleave)="onMouseLeave($event)" appNgxDataTableEmpty>

    <ngx-datatable-row-detail *ngIf="hasDetails" [rowHeight]="rowDetailHeight" #myDetailRow [template]="rowDetails" cdkDrag>
    </ngx-datatable-row-detail>

    <!-- Group Header Template -->
    <ng-container *ngIf="groupRowsBy">
      <ngx-datatable-group-header [rowHeight]="50" #primasTableHeaderGroup>
        <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
          <div class="primas-table-header-group" [attr.primas-table-data-group-id]="group?.key" (click)="toggleExpandGroup(group)">
            <div class="primas-table-header-group-content" [class.datatable-icon-right]="!groupToggleState(group)" [class.datatable-icon-down]="groupToggleState(group)">
              <ng-container *ngIf="groupHeaderTemplate; else defaultGroupHeader">
                <ng-container *ngTemplateOutlet="groupHeaderTemplate; context: {
                $implicit: group, expanded: expanded
              }">
                </ng-container>
              </ng-container>
              <ng-template #defaultGroupHeader>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-group-header>
    </ng-container>

    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-selectAllRowsOnPage="selectAllRowsOnPage" let-offset="offset" let-isVisible="isVisible">
        <div class="page-count">
          <span>
            {{ selectedCount?.toLocaleString() }} selected /
          </span>
          {{!isLoadingTotal ? rowCount?.toLocaleString() || 0 : '...'}} total
        </div>
        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>

  </ngx-datatable>
</div>



<ng-template #columnAction let-row="row" let-value="value" let-rowIndex="rowIndex">
  <button [ngStyle]="{'width.px':actionWidth}" type="button" mat-icon-button title="Action" [matMenuTriggerFor]="menuAction" [disabled]="disableTableAction">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menuAction="matMenu">
    <ng-container *ngIf="!isHiddenEditButton">
      <button *nbIsGranted="['view', resource.edit]" mat-menu-item (click)="onClickEdit(row,rowIndex)">
        <mat-icon color="primary">edit</mat-icon>
        <span>Edit item</span>
      </button>
    </ng-container>

    <!-- Additional Action -->
    <div>
      <ng-container *ngIf="customAction" [ngTemplateOutlet]="customAction" [ngTemplateOutletContext]="{row:row, rowIndex:rowIndex}">
      </ng-container>
    </div>
    <div>
      <ng-container *ngIf="customAction2" [ngTemplateOutlet]="customAction2" [ngTemplateOutletContext]="{row:row, rowIndex:rowIndex}">
      </ng-container>
    </div>
    <button *nbIsGranted="['view', resource.remove]" mat-menu-item (click)="onClickDelete(row,rowIndex)">
      <mat-icon style="color: red;">delete</mat-icon>
      <span>Delete item</span>
    </button>
    <div>
      <ng-container *ngIf="customAction3" [ngTemplateOutlet]="customAction3" [ngTemplateOutletContext]="{row:row, rowIndex:rowIndex}">
      </ng-container>
    </div>
  </mat-menu>
  <!-- <div style="margin-top: -8px;">
    <button mat-icon-button class="mr-2" color="primary" (click)="onClickEdit(row,rowIndex)">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button style="color: red;" (click)="onClickDelete(row,rowIndex)">
      <mat-icon>delete</mat-icon>
    </button>
  </div> -->
</ng-template>

<ng-template #filterHeader let-sort="sortFn" let-column="column" ngx-datatable-header-template let-filterType="column.filter.filterType" let-filterOperator="column.filterOprator">
  <!--Filter Operator-->
  <app-dropdown-filter-operator *ngIf="column.filter || filterType" [column]="column" (onFilter)="onChangeFilterOperator($event, column)" [selectedFilter]="column.filterOperator">
  </app-dropdown-filter-operator>

  <!--Filter Text-->
  <mat-form-field fxFlex="85" *ngIf="filterType== null || filterType==0">
    <mat-label>{{column?.name}}</mat-label>
    <input matInput type="text" (change)="onChangeFilterHeader($event.target.value, column)" [value]="column.filterValue??''" trim="blur" />
  </mat-form-field>

  <!--Filter Number-->
  <mat-form-field fxFlex="85" *ngIf="filterType && (filterType==1 || filterType==10)">
    <mat-label>{{column?.name}}</mat-label>
    <input matInput type="number" (change)="onChangeFilterHeader($event.target.value, column, filterType)" [value]="column.filterValue??''" />
  </mat-form-field>

  <!--Filter Datetime-->
  <app-datetime-filter fxFlex="85" *ngIf="filterType && filterType==2" [columnName]="column?.name" [value]="column.filterValue??null" (onFilter)="onFilterDateTime($event, column)" [operationMode]="column.filterOperator">
  </app-datetime-filter>

  <!--Filter Dropdown-->
  <app-dropdown-filter fxFlex="85" *ngIf="filterType && filterType==3" [columnName]="column?.name" [filter]="column.filter" [value]="column.filterValue??null" (onFilter)="onFilterDropDown($event,column)">
  </app-dropdown-filter>

  <!--Filter Dropdown-->
  <app-boolean-filter fxFlex="85" *ngIf="filterType && filterType==4" [columnName]="column?.name" [filter]="column.filter" [value]="column.filterValue??null" (onFilter)="onFilterBoolean($event,column)">
  </app-boolean-filter>

  <!--Filter Date-->
  <app-date-filter #dateFilerValue fxFlex="85" *ngIf="filterType && filterType==5" [columnName]="column?.name" [value]="column.filterValue??null" (onFilter)="onFilterDate($event, column)" [isExternal]="column?.isExternal || ''" (chooseFilterTable)="$event ? $event.open() : ''" [operationMode]="column.filterOperator">
  </app-date-filter>
  <app-dropdown-filter fxFlex="85" *ngIf="filterType && filterType==6" [columnName]="column?.name" [(notifyRefresh)]="column.filter.notifyRefresh== undefined ? false : column.filter.notifyRefresh" [filter]="column.filter" [value]="column.filterValue??null" (onFilter)="onFilterDropDownList($event,column)">
  </app-dropdown-filter>

  <!--Filter Dynamic content-->
  <app-dropdown-filter fxFlex="85" *ngIf="filterType && filterType==7" [columnName]="column?.name" [filter]="column.filter" [value]="column.filterValue??null" (onFilter)="onFilterDynamicContent($event,column)">
  </app-dropdown-filter>

  <!--Filter Mail Action-->
  <app-mail-log-filter fxFlex="85" *ngIf="filterType && filterType==8" [columnName]="column?.name" [value]="column.filterValue??null" (onFilterText)="onChangeFilterHeader($event, column)" [(operationMode)]="column.filterOperator" (onEmailActionFilter)="onEmailActionFilter($event,column)">
  </app-mail-log-filter>

  <!--Filter JSON STRING Text-->
  <mat-form-field fxFlex="85" *ngIf="filterType==9">
    <mat-label>{{column?.name}}</mat-label>
    <input matInput type="text" (change)="onChangeFilterHeader($event.target.value, column,filterType)" [value]="column.filterValue??''" trim="blur" />
  </mat-form-field>

  <!--Filter START WITH TEXT Text-->
  <mat-form-field fxFlex="85" *ngIf="filterType==11">
    <mat-label>{{column?.name}}</mat-label>
    <input matInput type="text" (change)="onChangeFilterHeader($event.target.value, column,filterType)" [value]="column.filterValue??''" trim="blur" />
  </mat-form-field>
</ng-template>


<ng-template #disabledFilterHeader let-sort="sortFn" let-column="column" ngx-datatable-header-template>
  <mat-form-field fxFlex="80">
    <mat-label>{{column?.name}}</mat-label>
    <input matInput type="text" readonly />
  </mat-form-field>
</ng-template>

<mat-menu #columnMenu="matMenu">
  <div (click)="$event.stopPropagation()">
    <ng-container *ngFor="let column of columnsTable; let i = index">
      <span mat-menu-item>
        <mat-checkbox color="primary" [checked]="isShow(column.prop)" (change)="displayColumnChange($event,column.prop,i)">
          {{column.name??column.prop}}
        </mat-checkbox>
      </span>
    </ng-container>
  </div>

</mat-menu>

<ng-template #spinnerColumnHeader let-column="column">
  <span [nbSpinner]="true">
    {{column.name}}
  </span>
</ng-template>
