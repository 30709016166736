<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;"
  [nbSpinner]="isLoading">
  <button mat-button color="primary" (click)="saveProposalNonArtistFee()"
    [disabled]="!frmProposalNonArtist.valid || isLoading">
    <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
  </button>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="frmProposalNonArtist" class="container" (ngSubmit)="saveProposalNonArtistFee()"
    style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Type Name</mat-label>
          <input matInput type="text" required formControlName="typeName" autocomplete="off" trim="blur">
          <mat-error
            *ngIf="frmProposalNonArtist.controls.typeName.errors && (frmProposalNonArtist.controls.typeName.dirty || frmProposalNonArtist.controls.typeName.touched)">
            {{frmProposalNonArtist.controls.typeName['errorMessage']}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Amount</mat-label>
          <input matInput required formControlName="amount" autocomplete="off" trim="blur">
          <mat-error
            *ngIf="frmProposalNonArtist.controls.amount.errors && (frmProposalNonArtist.controls.amount.dirty || frmProposalNonArtist.controls.amount.touched)">
            {{frmProposalNonArtist.controls.amount['errorMessage']}}</mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="col-12">
        <mat-form-field appearance="standard" [ngClass]="isEditor ? 'remove-unline' : ''">
          <mat-label>Description</mat-label>
          <quill-editor *ngIf="isEditor" class="content-editor" [modules]="editorOptions"
            formControlName="description" [placeholder]="'Add content...'">
          </quill-editor>
          <div *ngIf="frmProposalNonArtist.value.description != null" [innerHTML]="frmProposalNonArtist.value.description | safeHtml"
          (click)="changeStatusEditor()" [hidden]="isEditor" placeholder="'Add content...'"></div>

          <input matInput formControlName="description"
            [hidden]="isEditor || (!isEditor && frmProposalNonArtist.value.description != null)" autocomplete="off"
            (click)="changeStatusEditor()">

        </mat-form-field>
        
      </div> -->
      <quill-editor class="content-editor" [modules]="editorOptions" placeholder="Click to add content"
      formControlName="description" style="max-height: 300px;">
      </quill-editor>
      <button type="submit" style="display: none;"></button>
    </div>
  </form>
</mat-dialog-content>