import { AddWpHomepageModel, PagingGroupFilter, WPHomePage, WPTypeDetail } from './wp-home-page.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { Observable } from 'rxjs';
import { ReturnResult } from 'src/app/shared/models/return-result';

@Injectable({
  providedIn: 'root'
})
export class WpHomepageManagementService {
  baseUrl = environment.apiFontFacing;
  constructor(private http: HttpClient) { }

  getFrontFacingPaging(page: Page): Observable<PagedData<WPHomePage>> {
    return this.http.post<PagedData<WPHomePage>>(`${this.baseUrl}/FrontFacingPaging`, page);
  }

  removeHomePages(ids: number[]) {
    return this.http.put<ReturnResult<boolean>>(`${this.baseUrl}/DeleteWPHomePages`, { ids: ids });
  }
  getListTypeDetailsAsync(): Observable<ReturnResult<WPTypeDetail[]>> {
    return this.http.get<ReturnResult<WPTypeDetail[]>>(`${this.baseUrl}/GetListTypeDetailsAsync`);
  }
  saveWPHomePageAsync(model: AddWpHomepageModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveWPHomePageAsync`, model);
  }
  getSearchingDataSource(keyWord: string, type: string) {
    return this.http.get<ReturnResult<[]>>(`${this.baseUrl}/SearchEveryThing?keyword=${keyWord}&type=${type}`);
  }
  getGroupWPHomePagePaging(page: PagingGroupFilter): Observable<PagedData<WPHomePage>> {
    return this.http.post<PagedData<WPHomePage>>(`${this.baseUrl}/GetGroupWPHomePagePaging`, page);
  }

  saveFeatureAndSeasonalAct(model: WPHomePage): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveFeatureAndSeasonalAct`, model);
  }

  getFeatureAndSeasonalAct(profileId: string): Observable<ReturnResult<WPHomePage[]>> {
    return this.http.get<ReturnResult<WPHomePage[]>>(`${this.baseUrl}/GetFeatureAndSeasonalAct?profileId=${profileId}`);
  }
}
