<mat-form-field [nbSpinner]="isLoading" nbSpinnerStatus="danger" [ngClass]="readonly ? 'form-field-disabled' : ''"
    [ngClass]="hiddenUnderLine ? 'hidden-line' : ''">
    <mat-select [style.max-height]="maxHeight ? maxHeight : ''" [ngClass]="isMatChip ? 'body-form-select' : ''"
        [multiple]="isMultiple" [(ngModel)]="selectedValues" panelClass="multi-select-dropdown"
        [placeholder]="selectedValues.length > 0 ? '' : placeholder" [disabled]="readonly" (closed)="onBlurSelect()">
        <mat-select-trigger *ngIf="isMatChip">
            <mat-chip-list>
                <mat-chip *ngFor="let item of getListSelectMatChip(selectedValues); let id = index"
                    [style.background-color]="item.color ?? '#307abd'" selected [removable]="true"
                    (removed)="removeChip(item)">
                    {{item.value}}
                    <mat-icon matChipRemove *ngIf="(!disabledFirstObject || id !== 0) && !readonly">cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-select-trigger>
        <mat-option>
            <ngx-mat-select-search [showToggleAllCheckbox]="true" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleSelectAll($event)"
                [formControl]="bankMultiFilterCtrl" noEntriesFoundLabel="No matching item found"
                [placeholderLabel]="placeholder">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="isLoading; else newOptionPer" [nbSpinner]="isLoading"></mat-option>
        <ng-template #newOptionPer>
            <mat-option class="col" *ngFor="let item of filteredBanksMulti | async ; let id = index" [value]="item.key"
                [disabled]="(!disabledFirstObject || id !== 0) ? item.selected : true" [matTooltip]="isTooltip ? (item.tooltip ?? '') : ''"
                matTooltipPosition="left">
                <span *ngIf="!normalMode; else normalTemplate"  class="badge badge-pill" [ngClass]="isMatChip ? 'badge-primary' : 'badge-light'"
                    style="font-size: 14px;" [style.background-color]="item.color ?? '#307abd'">
                    {{item.value}}
                </span>
                <ng-template #normalTemplate>
                    {{item.value}}
                </ng-template>
            </mat-option>
        </ng-template>
    </mat-select>
</mat-form-field>

