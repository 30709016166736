import { Component, Inject, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { EmailCampaignTemplate, SingleMailBodyTemplate } from '../campaign-linkscope-email.model';
import { CampaignLinkscopeService } from '../campaign-linkscope.service';
import { iframely } from '@iframely/embed.js';
import { ProfileDetailModel } from '../../../profile-detail.model';
import { MatRadioChange } from '@angular/material/radio';
import { NbToastrService } from '@nebular/theme';
import { ProfileService } from '../../../profile.service';
import { ActivityLog } from 'src/app/modules/admin/campaign-management/campaign-tracking.model';
import { first, map, startWith, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AddNewEmailComponent } from '../add-new-email/add-new-email.component';
import { EmailTemplate } from 'src/app/modules/admin/setting-management/setting-campaign/email-template';
import { EditEmailLinkscopeComponent } from '../edit-email-linkscope/edit-email-linkscope.component';
import { SettingService } from 'src/app/shared/services/setting.service';
import { BuyerModel } from 'src/app/shared/models/buyer.model';
import { TableEntity } from 'src/app/shared/enums/table-entities.enum';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { BodyEmbedded } from 'src/app/shared/models/body-embedded.model';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { DropDownValue, DropDownValueEmailCampaign } from 'src/app/modules/admin/summary-report/summary-report.component';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
import { Helper } from 'src/app/shared/utility/Helper';

@Component({
  selector: 'app-linkscope-email',
  templateUrl: './linkscope-email.component.html',
  styleUrls: ['./linkscope-email.component.scss']
})
export class LinkscopeEmailComponent implements OnInit, OnDestroy, AfterViewInit {
  //2022-02-21 vuonglqn add start
  @ViewChild('addNewEmail', { static: false }) sendNewEmail: AddNewEmailComponent;
  private editEmailLinkScope: EditEmailLinkscopeComponent;
  @ViewChild('editEmailLinkScope', { static: false }) set contentEditEmail(content: EditEmailLinkscopeComponent) {
    if (content)
      this.editEmailLinkScope = content;
  };
  //2022-02-21 vuonglqn add end
  //2022-05-12 tienlm add start
  @Input() buyerModel: BuyerModel;
  @Input() entity = 'profile';
  @Input() targetEmail = '';
  @Input() lastSentEmailEntity = 'profile';
  //2022-05-12 tienlm add end
  @Input() externalHandleSubmit = false;
  @Input() isEmbedJSON: boolean = true;
  @Input() isShowSenderId: boolean = false;

  constructor(
    private service: CampaignLinkscopeService,
    private profileService: ProfileService,
    public dialModalRef: MatDialogRef<LinkscopeEmailComponent>,
    public toast: NbToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingService: SettingService,
    private authService: NbAuthService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    //2022-03-29 vuonglqn add start
    this.emailSubject = this.data.emailSubject ?? this.emailSubject;
    //2022-03-29 vuonglqn add end
    this.externalHandleSubmit = this.data.externalHandleSubmit ?? this.externalHandleSubmit;
    this.profileModel = this.data.profileModel ?? this.profileModel;
    this.buyerModel = this.data.buyerModel ?? this.buyerModel;
    this.entity = this.data.entity ?? this.entity;
    this.lastSentEmailEntity = this.data.lastSentEmailEntity ?? this.lastSentEmailEntity;
    this.optionTaskId = this.data.optionTaskId ?? this.optionTaskId;
    this.targetEmail = this.data.targetEmail ?? this.targetEmail;
    this.isEmbedJSON = this.data.isEmbedJSON ?? this.isEmbedJSON;
    this.isShowSenderId = this.data.isShowSenderId ?? this.isShowSenderId;
    this.emailTemplateSubscription = this.service.getLinkscopeEmailTemplate().subscribe(item => {
      if (!item) {
        return;
      }
      this.linkscopeTemplateEmail = item;
      this.searchEmailTemplate = JSON.parse(JSON.stringify(this.linkscopeTemplateEmail.data));
      this.backupSearchTemplate = JSON.parse(JSON.stringify(this.linkscopeTemplateEmail.data));
      if (this.lastSentEmail) {
        //this.getEmailTemplateFromId(this.lastSentEmail.subObjId);
      }
      if (this.linkscopeTemplateEmail?.data.length > 0) {
        this.selectedEmailTemplate = this.linkscopeTemplateEmail.data[0];
      }
      // console.log(item);
      this.isLoading = false;
    });

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          const user = token.getPayload();
          this.userId = user['nameid'];
        };
      });

    this.formatBodyEmbedded();
  }

  profileModel: ProfileDetailModel;
  selectedEmailTemplate: SingleMailBodyTemplate;
  linkscopeTemplateEmail: EmailCampaignTemplate;
  isLoading: boolean;
  emailTemplateSubscription: Subscription;
  submitLoading = false;
  lastSentEmail: ActivityLog;
  searchEmailTemplate: SingleMailBodyTemplate[] = [];
  backupSearchTemplate: SingleMailBodyTemplate[] = [];
  filteredOptions: Observable<SingleMailBodyTemplate[]>;
  searchControl = new FormControl();
  //2022-02-21 vuonglqn add start
  isSendNew = true;
  //2022-02-21 vuonglqn add end
  //2022-03-18 vuonglqn add start
  isEditEmail = false;
  selectEditEmail: EmailTemplate = null;
  //2022-03-18 vuonglqn add end
  private ngUnsubscribe = new Subject();

  listEmailEdited = [];
  emailSubject: string = "";
  isNormalEditor: boolean = false;
  optionTaskId: number = null;
  bodyEmbedded: BodyEmbedded = new BodyEmbedded();
  userId: string;
  userModel: UserModel;
  isUsingSendGrid: boolean = false;
  sendGridDomain: string;
  defaultEmailList: [];
  defaultDomain: string;
  isChangeSender: boolean = true;
  emailSend: DropDownValueEmailCampaign[] = null;
  senderId: string;
  user;
  userLoginModel: UserModel;
  ngOnInit() {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    this.isLoading = true;
    // config to get last email
    this.getLastEmail();
    // get template from campaign
    this.service.refreshGetLinkscopeEmailTemplate();
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.searchEmailTemplate.slice())),
    );

    this.settingService.getSettingByKeyAndGroup("IS_NORMAL_EDITOR", "CAMPAIGN").pipe(first())
      .subscribe(resp => {
        if (resp.result) this.isNormalEditor = parseInt(resp.result.value) > 0;
      });


    if (this.userId)
      this.userService.getUserById(this.userId).pipe(first()).subscribe(resp => {
        if (resp.result) {
          this.userModel = resp.result
          if (this.bodyEmbedded && this.isEmbedJSON) {
            this.bodyEmbedded['username'] = this.userModel.userName;
            this.bodyEmbedded['useremail'] = this.userModel.email;
          }
        };
      })
    else this.toast.danger("Error get current user!", "Error");
    this.setupUserData();
  }

  ngAfterViewInit(): void {
    if (this.sendNewEmail) this.sendNewEmail.setTitleFrm(this.data?.title);
  }
  async setupUserData() {
    //get list campaign setting
    var respSetting = await this.settingService.getSettingByGroupName("CAMPAIGN").toPromise();
    if (respSetting.result && respSetting.result?.length > 0) {
      this.defaultDomain = respSetting.result.find(x => x.key == 'DEFAULT_EMAIL_LIST')?.value || '';
      if (!this.emailSend) this.emailSend = [];
      if (this.defaultDomain) {
        var checkSpaceString = Helper.splitSpaceString(this.defaultDomain);
        var defaultEmailList = checkSpaceString.split(",");
        defaultEmailList.forEach(i => {
          this.emailSend.push({ text: i, value: i, icon: 'emailDefault' });
        })

        this.senderId = this.emailSend[0].value;
      }
      this.isUsingSendGrid = (respSetting.result.find(x => x.key == 'IS_USING_SENDGRID')?.value == '1') || false;
      this.sendGridDomain = respSetting.result.find(x => x.key == 'SENDGRID_DOMAIN')?.value || '';
      //if is_using_grid = 1 && sendGridDomain have value
      if (this.isUsingSendGrid && this.sendGridDomain && this.sendGridDomain.length > 0 && this.isShowSenderId) {
        // get info user login
        this.userService.getUserById(this.user?.nameid).subscribe(resp => {
          if (resp.result) {
            this.userLoginModel = resp.result;
            if (this.userLoginModel) {
              var getEmailDomain = this.userLoginModel?.email?.split('@')[1];
              // Accept the user's edit if the email domain of the user is equal to the email domain of SENDGRID_DOMAIN
              if (getEmailDomain.toLowerCase() == this.sendGridDomain?.toLowerCase()) {
                // this.isChangeSender = false;
                this.emailSend.push({ text: this.userLoginModel.email, value: this.userLoginModel.email, icon: 'emailUsers' })
              }
            }
          }
        });
      }

      this.emailSend = [...this.emailSend];
    }
  }
  formatBodyEmbedded() {
    if (this.isEmbedJSON) {
      this.bodyEmbedded = {
        taskid: this.optionTaskId?.toString() ?? '',
        entityid: this.entity?.toLowerCase() == TableEntity.NoEntity.toLowerCase() ? ''
          : this.entity?.toLowerCase() == TableEntity.Profile.toLowerCase()
            ? (this.profileModel?.profileId ?? '')
            : (this.buyerModel?.buyerId ?? ''),
        entitytype: this.entity?.toLowerCase() == TableEntity.NoEntity.toLowerCase()
          ? '' : this.entity?.toLowerCase(),
        username: "", useremail: "", timestamp: "", ignore: 0
      } as BodyEmbedded;
    } else this.bodyEmbedded = null;
  }

  closeDialog() {
    this.dialModalRef.close();
  }

  ngOnDestroy(): void {
    this.emailTemplateSubscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  FormatSenderId(message: string) {
    return `<b>${message}</b>`;
  }
  confirmSendCampaign() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to send this email to <b>${this.profileModel?.contact?.contactEmail}</b> ${this.isShowSenderId ? ('by ' + this.FormatSenderId(this.senderId)) : ''}`
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.onClickSendCampaign();
      }
      else {
        this.submitLoading = false;
      }
    });
  }
  onClickSendCampaign() {
    this.submitLoading = true;
    if (!this.isSendNew) {
      if (this.isEditEmail) {
        this.editEmailLinkScope.sendEmail();
        this.submitLoading = false;
        this.closeDialog();
      } else {
        if (this.listEmailEdited.length > 0) {
          this.listEmailEdited = [...new Set(this.listEmailEdited)];
          var findExistedEdit = this.listEmailEdited.find(x => x == this.selectedEmailTemplate.id);
          if (findExistedEdit > -1) {
            if (this.entity.toLowerCase() == TableEntity.Profile.toLowerCase()) {
              this.service.sendWithEditorEmail(this.profileModel, this.selectedEmailTemplate.title, this.selectedEmailTemplate.body, this.senderId).pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp => {
                  if (resp.result) this.toast.success(`Send Email for ${this.profileModel.displayName} successfully`, 'Success');
                  else this.toast.danger(`Send Email for ${this.profileModel.displayName} fail`, 'Error');
                  this.submitLoading = false;
                  this.closeDialog();
                });
            } else if (this.entity.toLowerCase() == TableEntity.Buyer.toLowerCase()) {
              this.service.sendWithEditorEmailByBuyerId(this.buyerModel, this.selectedEmailTemplate.title, this.selectedEmailTemplate.body).pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp => {
                  if (resp.result) this.toast.success(`Send Email for ${this.buyerModel.companyName} successfully`, 'Success');
                  else this.toast.danger(`Send Email for ${this.buyerModel.companyName} fail`, 'Error');
                  this.submitLoading = false;
                  this.closeDialog();
                });
            }
            return;
          }
        }
        // case profile and buyer
        if (this.entity.toLowerCase() == TableEntity.Profile.toLowerCase()) {
          this.service.sendCampaign(this.profileModel, this.selectedEmailTemplate.id, this.selectedEmailTemplate.title, this.embeddedTimeStampBodyEmail(this.selectedEmailTemplate.body), this.senderId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
            if (resp.result) this.toast.success(`Send Email for ${this.profileModel.displayName} successfully`, 'Success');
            else this.toast.danger(`Send Email for ${this.profileModel.displayName} fail`, 'Error');
            this.submitLoading = false;
            this.closeDialog();
          });
        } else if (this.entity.toLowerCase() == TableEntity.Buyer.toLowerCase()) {
          this.service.sendCampaignByBuyerId(this.buyerModel, this.selectedEmailTemplate.id, this.selectedEmailTemplate.title, this.embeddedTimeStampBodyEmail(this.selectedEmailTemplate.body)).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
            if (resp.result) this.toast.success(`Send Email for ${this.buyerModel.companyName} successfully`, 'Success');
            else this.toast.danger(`Send Email for ${this.buyerModel.companyName} fail`, 'Error');
            this.submitLoading = false;
            this.closeDialog();
          });
        }
        // send email for specific email.
        else if (this.entity.toLowerCase() == TableEntity.NoEntity.toLowerCase()) {
          this.service.sendEmailIndividual(this.selectedEmailTemplate.title, this.embeddedTimeStampBodyEmail(this.selectedEmailTemplate.body), this.targetEmail).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
            if (resp.result) this.toast.success(`Send Email for ${this.targetEmail} successfully`, 'Success');
            else this.toast.danger(`Send Email for ${this.targetEmail} fail`, 'Error');
            this.submitLoading = false;
            this.closeDialog();
          });
        }
      }
    }
    //2022-02-21 vuonglqn add start
    else {
      this.sendNewEmail.sendEmail();
      this.submitLoading = false;
      this.closeDialog();
    }
    //2022-02-21 vuonglqn add end
  }
  onChangeEmailTemplate(event: MatRadioChange) {
    this.selectedEmailTemplate = this.linkscopeTemplateEmail?.data[event.value];
  }

  // getEmailTemplateFromId(emailTemplateId: string) {
  //   if (this.lastSentEmail && this.linkscopeTemplateEmail) {
  //     // case: SendMailImmediately from altus to outside
  //     if (this.lastSentEmail.subAction && this.lastSentEmail.subAction.toLowerCase() == 'SendMailImmediately'.toLowerCase()) {
  //       // case 1: Send email with template
  //       if (this.lastSentEmail.subObjName && this.lastSentEmail.subObjId && this.lastSentEmail.subObjName.toLowerCase() === 'EmailTemplate'.toLowerCase()) {
  //         this.lastSentEmailTemplate = this.linkscopeTemplateEmail.data.find(em => em.id === parseInt(emailTemplateId));
  //       }
  //       //case 2: send email with Raw Email on altus
  //       else {
  //         this.lastSentEmailTemplate = new SingleMailBodyTemplate();
  //         this.lastSentEmailTemplate.title = this.getPropertyFromTags('title', this.lastSentEmail.logInfo1) == "" ? 'Unknown' : this.getPropertyFromTags('title', this.lastSentEmail.logInfo1);
  //         this.lastSentEmailTemplate.name = 'send from Altus';
  //       }
  //     }
  //     // send email from campaign
  //     else {
  //       // tslint:disable-next-line:radix
  //       if (this.lastSentEmail.subObjName && this.lastSentEmail.subObjName.toLowerCase() === 'EmailTemplate'.toLowerCase()) {
  //         this.lastSentEmailTemplate = this.linkscopeTemplateEmail.data.find(em => em.id === parseInt(emailTemplateId));
  //       } else {
  //         this.lastSentEmailTemplate = new SingleMailBodyTemplate();
  //         this.lastSentEmailTemplate.title = 'Unknown';
  //         this.lastSentEmailTemplate.name = this.lastSentEmail.campaignName;
  //       }
  //     }
  //   }
  // }

  private _filter(name: string): SingleMailBodyTemplate[] {
    const filterValue = name.toLowerCase();
    // tslint:disable-next-line:max-line-length
    const filterLst = this.searchEmailTemplate.filter(option => option.name.toLowerCase().includes(filterValue) || option.title.toLocaleLowerCase().includes(filterValue));
    this.linkscopeTemplateEmail.data = filterLst;
    return;
  }
  displayFn(template: SingleMailBodyTemplate): string {
    return template && template.name ? template.name : '';
  }
  onSearchSelected(select: MatAutocompleteSelectedEvent) {
    this.linkscopeTemplateEmail.data = [select.option.value];
  }

  updateFlagCreateEmail() {
    this.isSendNew = !this.isSendNew;
  }
  getPropertyFromTags(propName: string, logInfor: string) {
    let splitArr = logInfor.split(';');
    const keyIndex = splitArr.indexOf(propName);
    return splitArr[keyIndex + 1];
  }

  editEmailTemplate() {
    if (this.isEditEmail && this.editEmailLinkScope) {
      if (this.isNormalEditor) {
        var data = this.editEmailLinkScope.editorQuill.getEmailHtmlQuill();
        this.updateTemplateAfterEdit(data);
      } else {
        this.editEmailLinkScope.editorEmail.exportHtml();
        this.editEmailLinkScope.editorEmail.getEmailHtml().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.updateTemplateAfterEdit(data);
        });
      }

      setTimeout(() => this.isEditEmail = !this.isEditEmail, 500);
    } else this.isEditEmail = !this.isEditEmail;
  }

  updateTemplateAfterEdit(data: any) {
    if (data) {
      var findTemplate = this.linkscopeTemplateEmail.data.find(x => x.id === this.selectedEmailTemplate.id);
      var newTitle = this.editEmailLinkScope.frmTitle.controls['title'].value;
      var newBody = data.html ?? findTemplate.body;
      var newBodyJson = data.design ?? findTemplate.bodyJson;
      if (findTemplate.title != newTitle || findTemplate.body != newBody || findTemplate.bodyJson != newBodyJson) {
        findTemplate.title = newTitle;
        findTemplate.body = newBody;
        findTemplate.bodyJson = newBodyJson;
        this.listEmailEdited.push(findTemplate.id);
      }
    }
  }

  async getLastEmail() {
    var resp;
    switch (this.entity.toLowerCase()) {
      case TableEntity.Profile.toLowerCase():
        resp = await this.service.GetLastSendEmail(this.profileModel.profileId).toPromise();
        break;
      case TableEntity.Buyer.toLowerCase():
        resp = await this.service.GetLastSendEmailByBuyerId(this.buyerModel.buyerId).toPromise();
        break;
    }

    if (resp.result && resp.result.length > 0) {
      this.lastSentEmail = resp.result[0];
      this.formatLastEmailSend();
    }
  }

  formatLastEmailSend() {
    var jsonLog = Helper.convertStringToJson(";", this.lastSentEmail.logInfo1);
    if (jsonLog) {
      this.lastSentEmail = Object.assign(this.lastSentEmail, {
        titleLastSend: jsonLog['title'],
        emailLastSend: jsonLog['email']
      });
    }
  }

  log(data) {
    console.log(data);
  }

  embeddedTimeStampBodyEmail(body: string) {
    var resultData = body.slice();
    if (this.bodyEmbedded && this.isEmbedJSON) {
      this.bodyEmbedded.timestamp = Date.now().toString();
      const str = resultData.substring(
        resultData.indexOf(`<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;`),
        resultData.indexOf(`;EMBEDDED_END</span>`) + 20
      ).slice();
      if (str.length > 0 && str.startsWith(`<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;`) && str.endsWith(`;EMBEDDED_END</span>`)) {
        resultData = resultData.replace(str, '');
      }

      resultData += `<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;${btoa(JSON.stringify(this.bodyEmbedded))};EMBEDDED_END</span>`;
    }

    return resultData;
  }
  async clickToNext() {
    let subject;
    let body;
    let emailTemplateId;
    if (this.isSendNew) {
      subject = this.sendNewEmail.frmTitle.value;
      this.sendNewEmail.editorEmail.exportHtml();
      body = await this.sendNewEmail.editorEmail.getEmailHtml().toPromise();
    }
    else {
      if (this.isEditEmail) {
        subject = this.editEmailLinkScope.frmTitle.value;
        this.editEmailLinkScope.editorEmail.exportHtml();
        body = await this.editEmailLinkScope.editorEmail.getEmailHtml().toPromise();
      }
      else {
        subject = { title: this.selectedEmailTemplate.title };
        body = { html: this.selectedEmailTemplate.body, design: this.selectedEmailTemplate.bodyJson };
      }
      emailTemplateId = this.selectedEmailTemplate.id
    }
    this.dialModalRef.close({ body: body, subject: subject, btnState: true, emailTemplateId: emailTemplateId })
  }
}
