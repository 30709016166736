import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../utility/Helper';

@Pipe({
  name: 'removeSpaceString'
})
export class RemoveSpaceStringPipe implements PipeTransform {

  transform(value: string): unknown {
    var result = value;
    try {
      if (result) result = Helper.splitSpaceString(result);
    }
    catch (ex) {
      console.error(ex);
    }

    return result;
  }
}
