import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { model } from '@rxweb/reactive-form-validators';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AddEditUserComponent } from 'src/app/modules/admin/user-management/add-edit-user/add-edit-user.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { Helper } from 'src/app/shared/utility/Helper';
import { AdvancedImportDataComponent } from '../../advanced-import-data/advanced-import-data.component';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { ImportModalComponent } from '../../import-modal/import-modal.component';
import { PrimasTableComponent } from '../primas-table/primas-table.component';

@Component({
  selector: 'app-primas-toolbar',
  templateUrl: './primas-toolbar.component.html',
  styleUrls: ['./primas-toolbar.component.scss']
})
export class PrimasToolbarComponent implements OnInit, OnChanges {
  @Output() onExport = new EventEmitter<any>();
  @Output() onImport = new EventEmitter<any>();
  @Output() onDeletes = new EventEmitter<any>();
  @Output() onDeleteAllByFilter = new EventEmitter<any>();
  @Output() onAfterAddFinish = new EventEmitter<any>();
  @Output() onAfterImportFinish = new EventEmitter<any>();
  @Output() customClearAllFiltersFunction: EventEmitter<any> = new EventEmitter<any>();
  @Output() advancedImportBulkAction: EventEmitter<any> = new EventEmitter<any>(); //emit function for Advanced Import feature
  // run any sub action after clicked clear filter.
  @Output() afterClearFilter: EventEmitter<any> = new EventEmitter<any>();

  @Input() primaryKey: string;
  // 2021-29-11 tienlm add start
  @Input() customSelectsFunction: TemplateRef<any>;
  // 2021-29-11 tienlm add end
  // 2022-03-15 tienlm add start
  @Input() customSelectsFunction2: TemplateRef<any>;
  @Input() customSelectsFunction3: TemplateRef<any>;

  //2022-04-28 gnguyen start add
  @Input() customSelectsFunction4: TemplateRef<any>;
  //2022-08-25 thoainna add start
  @Input() customSelectsFunction5: TemplateRef<any>;
  //2022-08-25 thoainna add end
  @Input() templateFile: string;
  //2022-04-28 gnguyen end add

  // 2022-03-15 tienlm add end
  // 2022-01-27 tienlm add start
  @Input() customSearch: TemplateRef<any>;
  @Input() isCustomSearch = false;
  @Input() algoliaSearch: TemplateRef<any>;
  @Input() isAlgoliaSearch = false;
  // 2022-01-27 tienlm add end
  @Input() addEditComponet;
  @Input() columnsImport;
  @Input() selectedCout;
  @Input() table: PrimasTableComponent;
  @Input() columns;
  @Input() customView = false;
  @Input() resource;
  // 2022-09-21 tienlm add start
  @Input() tableResource;
  // 2022-09-21 tienlm add end
  // 2021-11-29 tienlm add start
  @Input() isEnablecustomButtonFunction = false;
  @Input() isShowEntitiesLimit = false;
  @Input() centerTemplate: TemplateRef<any>;

  ///
  //*
  //*
  //** This flag ONLY Support to hidden Delete button */
  @Input() hiddenDeleteButton = true;
  ///

  @Input() hiddenDeleteAllButton = true;
  // 2022-09-19 tienlm add start
  // Additional data when creating logic
  @Input() addNewAdditionalData = null;
  // 2022-09-19 tienlm add end

  // 2022-12-28 ducqm add start
  // Add new logic to disable add new button
  @Input() disableAddNewButton = false;


  ///Turn on/off the advanced import feature
  @Input() useAdvancedImport = false;

  //Type name of profile framework
  @Input() typeName: string;

  @Input() cusPerkTable: [string, string] = ['', ''];

  @Input() enableCustomViewButton: boolean = true;

  pageLimitOptions = [
    25, 50, 100, -1
  ];
  currentPageLimit = 25;
  // 2021-11-29 tienlm add start
  btnExportState = false;
  dialogRef: MatDialogRef<ConfirmModalComponent, any>
  @ViewChild('confirmDeleteMode', { static: true }) confirmDeleteMode: TemplateRef<any>;
  constructor(private dialog: MatDialog, public numPipe: NumberFormatPipe) { }
  dialogImport: MatDialogRef<any, any>
  ngOnInit() {
    this.table.customView = this.customView;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let changeColumns = changes.columns;
    if (changeColumns) this.columns = [...Helper.getColumnsPermission(changeColumns.currentValue || [])];
  }

  onClickAddNew() {
    if (this.addEditComponet) {
      const dialogRef = this.dialog.open(this.addEditComponet, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          action: TblActionType.Add,
          ...this.addNewAdditionalData
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response != null) {
          this.onAfterAddFinish.emit(response);
        }
      });
    }
  }
  onClickImport() {
    if (this.columnsImport) {
      const columnImport = Helper.getColumnsPermission(this.columnsImport);
      if (!this.useAdvancedImport) {
        this.dialogImport = this.dialog.open(ImportModalComponent, {
          disableClose: true,
          height: '100vh',
          width: '600px',
          panelClass: 'dialog-detail',
          autoFocus: false,
          data: {
            templateFile: this.templateFile,
            systemProperty: (columnImport?.length > 0 ? columnImport : this.columnsImport) || this.columnsImport
          }
        });
      }
      else {
        this.dialogImport = this.dialog.open(AdvancedImportDataComponent, {
          disableClose: true,
          height: '100vh',
          width: '600px',
          panelClass: 'dialog-detail',
          data: {
            templateFile: this.templateFile,
            systemProperty: (columnImport?.length > 0 ? columnImport : this.columnsImport) || this.columnsImport,
            resource: { table: this.tableResource.table },
            toolbarResource: { toolbar: this.resource.toolbar },
            primaryKey: this.primaryKey,
            importColumn: this.columnsImport,
            typeName: this.typeName
          }
        });
        this.dialogImport.afterClosed().subscribe(e => {
          this.table.onRefresh.emit(true);
        })
        this.dialogImport.componentInstance.bulkActionFunction.subscribe((data) => {
          if (data) {
            this.advancedImportBulkAction.emit(data);
          }
        });

      }


      this.dialogImport.componentInstance.onImport.subscribe((dataImport) => {
        if (dataImport) {
          this.onImport.emit(dataImport);
        } else {
          this.dialogImport.componentInstance.finishImport(null);
        }
      })

    }
  }
  onClickExport() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to export ${this.numPipe.transform((this.table.selected && this.table.selected.length != 0) ? this.table.selected.length : this.table.table.rowCount)} item(s)?`
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.btnExportState = !this.btnExportState;
        this.onExport.emit()
      }
    });
  }
  finishImport(result) {
    if (this.dialogImport) {
      this.dialogImport.componentInstance.finishImport(result.result);
    }
  }
  onClickDeletes() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to delete ${this.numPipe.transform(this.selectedCout)} item(s)?`
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.onDeletes.emit();
      }
    });
  }
  onClickAllFilter() {
    this.dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to delete ${this.numPipe.transform(this.table.table.rowCount)} item(s)?`,
        externalTemplate: this.confirmDeleteMode,
        isDisabled: true
      }
    });
    this.dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.onDeleteAllByFilter.emit();
      }
    });
  }


  changeLimitEntities(event) {
    this.table.page.size = this.currentPageLimit;
    //if (this.onAfterAddFinish) {
      this.table.refreshTable(true);
    //}
  }
  configClearFilter() {
    if (this.customClearAllFiltersFunction.observers.length > 0) {
      this.customClearAllFiltersFunction.emit();
    } else {
      this.table.clearAllFilter();
    }
  }
  onDeleteFormChange(confirmText) {
    if (confirmText === 'DELETE ALL') this.dialogRef.componentInstance.isDisabled = false;
    else this.dialogRef.componentInstance.isDisabled = true;
  }
  invokeClearFilter() {
    if (this.afterClearFilter.observers.length > 0) {
      this.afterClearFilter.emit();
    }
  }
}
