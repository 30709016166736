import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'contactGroupParentPipe'
})
export class ContactGroupParentPipe implements PipeTransform {

  transform(group: any, listRelationshipEntity: any[], currentEntityId?: string): any {
    let result = "";
    try {
      let relationship = listRelationshipEntity?.find(x => x?.profileId === group?.key);
      if (relationship?.profileId === currentEntityId) {
        result = `<b>Current ${environment?.titleAccount}</b>: ${relationship?.displayName} <b>(${group?.value?.length || 0})</b>`;
      }
      else if (relationship?.profileEntity === "SALEACCOUNT") {
        result = `<b>Relationship</b>: ${relationship?.displayName} <b>(${group?.value?.length || 0})</b>`;
      }
      else if (relationship?.profileEntity === "OPPORTUNITY") {
        result = `<b>${environment?.titleOpportunity}</b>: ${relationship?.displayName} <b>(${group?.value?.length || 0})</b>`;
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }
}
