import { NumberFormatPipe } from './../../../../shared/pipes/number-format.pipe';
import { SettingService } from './../../../../shared/services/setting.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbPopoverComponent, NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { NumericValueType, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { QuillEditorComponent } from 'ngx-quill';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { MultiSelectDropDownComponent } from 'src/app/shared/components/stand-alone-component/multi-select-drop-down/multi-select-drop-down.component';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { CallService } from 'src/app/shared/services/call.service';
import { ContactService } from 'src/app/shared/services/contact.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { Contact, ContactContactType, ContactType, Location, ProfileAdditionDetail, ProfileDetailModel } from '../../profile-management/profile-detail.model';
import { LinkscopeEmailComponent } from '../../profile-management/profile-detail/action-block/linkscope-email/linkscope-email.component';
import { ProfileService } from '../../profile-management/profile.service';
import { AddEditTaskComponent } from '../../task-management/add-edit-task/add-edit-task.component';
import { TaskBoardService } from '../../task-management/task-board/task-board.service';
import { UserModel } from '../../user-management/user-model';
import { UserService } from '../../user-management/user.service';
import { SaleAccountService } from '../sale-account.service';
import { EntityColorEnums } from 'src/app/shared/enums/entity-color.enums';
import { Note, NoteDetails } from 'src/app/shared/components/note-management/noteManagement.model';
import { NoteTabComponent } from 'src/app/shared/components/note-tab/note-tab.component';
import { ContactComponent } from 'src/app/shared/components/stand-alone-component/contact/contact.component';
import { TableVariable } from 'src/app/shared/contances/data-table-contance';
import { AddExistingContactComponent } from '../../profile-management/profile-detail/contact-tab/add-existing-contact/add-existing-contact.component';
import { ContactRelationshipGridComponent } from 'src/app/shared/components/contact-relationship-grid/contact-relationship-grid.component';
import { OpportunityModalComponent } from 'src/app/shared/components/stand-alone-component/opportunity-modal/opportunity-modal.component';
import { SaleAccountOpportunityTabComponent } from './sale-account-opportunity-tab/sale-account-opportunity-tab.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { OverlayNoteDetailsComponent } from 'src/app/shared/components/note-management/overlay-note-details/overlay-note-details.component';
import { permissionGridNoteSaleAccount, permissionSaleAccountFile } from 'src/app/shared/contances/permission';
import { NoteManagementService } from 'src/app/shared/components/note-management/note-management.service';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { DynamicContentModel } from 'src/app/shared/models/dynamic-content.model';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { ProfileAdditionDetailService } from 'src/app/shared/services/profile-addition-detail.service';
import { AddExistingProfileComponent } from '../../producer-management/add-edit-producer/producer-profile-tab/add-existing-profile/add-existing-profile.component';
import { SaleAccountResellerTabComponent } from './sale-account-reseller-tab/sale-account-reseller-tab.component';
import { SaleAccountRelationshipTabV2Component } from './sale-account-relationship-tab-v2/sale-account-relationship-tab-v2.component';
import { LocationComponent } from 'src/app/shared/components/stand-alone-component/location/location.component';
import { LocationGridComponent } from './location-grid/location-grid.component';
import { AltusLocation } from 'src/app/shared/components/stand-alone-component/location/location';
import { ContactTypeFilter } from 'src/app/shared/components/stand-alone-component/contact/contact-type';
import { DropDownValue } from '../../summary-report/summary-report.component';
import { DropdownSearchingComponent } from 'src/app/shared/components/dropdown-searching/dropdown-searching.component';
import { AddEditUserUploadComponent } from '../../user-upload-management/add-edit-user-upload/add-edit-user-upload.component';
import { FormControl } from '@angular/forms';
import { UserUploadManagementService } from '../../user-upload-management/user-upload-management.service';
import { UserUpload } from '../../user-upload-management/user-upload.model';
import { ProfileFileTabComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/profile-file-tab.component';
import { HotToastService } from '@ngneat/hot-toast';
import { TabOpportunityGridComponent } from './sale-account-opportunity-tab/tab-opportunity-grid/tab-opportunity-grid.component';
import { TaskGridByProfileIdComponent } from '../../sale-lead-management/sale-lead-details/task-grid-by-profile-id/task-grid-by-profile-id.component';
import { ReviewContactCampaignComponent } from '../../email-campaign-management/email-campaign-detail/create-new-campaign/review-contact-campaign/review-contact-campaign.component';
import { CreateCampaignModelComponent } from '../../profile-management/create-campaign-model/create-campaign-model.component';
import { ContactPage } from 'src/app/shared/models/paging/page';
import { AddContactButtonComponent } from 'src/app/shared/components/add-button-in-tab/add-contact-button/add-contact-button.component';
// import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
// import { TabTagIndexModel } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { SaleAccountContactTabComponent } from './sale-account-contact-tab/sale-account-contact-tab.component';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
import { environment } from 'src/environments/environment';
import { PrimasSendSMSComponent } from 'src/app/shared/components/template-management/primas-send-sms/primas-send-sms.component';
import { UploadUrlComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/upload-url/upload-url.component';
import { UserExperienceTrackingService } from '../../../../shared/components/user-experience-tracking/user-experience-tracking.service';
import { MicrosoftOffice365Service } from '../../../../shared/services/microsoft-office-365.service';
import { SaleAccountDetailModel } from '../sale-account.model';

@Component({
  selector: 'app-sale-account-details',
  templateUrl: './sale-account-details.component.html',
  styleUrls: ['./sale-account-details.component.scss']
})
export class SaleAccountDetailsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() id: string = null;
  @Input() openByDialog = true;
  @Input() optionalTaskId: string;
  @Input() creatableTask = true;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Input() isShowButton: boolean = false;


  @Output() onAdvancedImport: EventEmitter<any> = new EventEmitter();
  @Output() refreshTask: EventEmitter<void> = new EventEmitter();

  @ViewChild('popupEditor') popupEditor: TemplateRef<any>;

  quillEditor: QuillEditorComponent;
  @ViewChild('quillEditor', { static: false }) set setQuillEditor(content: QuillEditorComponent) { if (content) this.quillEditor = content };

  // saleAccountTypeComponent: MultiSelectDropDownComponent
  // @ViewChild('saleAccountType', { static: false }) set setSaleAccountTypeComponent(content: MultiSelectDropDownComponent) { if (content) this.saleAccountTypeComponent = content };

  //Note: When using 1 component in tab by view child, please clean component when switch tab.
  //Name function: cleanComponentTab();
  noteTab: NoteTabComponent;
  @ViewChild('gridNoteTab', { static: false }) set setGridNoteTab(content: NoteTabComponent) { if (content) this.noteTab = content };

  contactTab: SaleAccountContactTabComponent;
  @ViewChild('contactGrid', { static: false }) set contactAccount(content: SaleAccountContactTabComponent) { if (content) this.contactTab = content };

  locationGrid: LocationGridComponent;
  @ViewChild('locationGrid', { static: false }) set setLocationGrid(content: LocationGridComponent) { if (content) this.locationGrid = content };

  opportunityTab: TabOpportunityGridComponent;
  @ViewChild('opportunityTab', { static: false }) set setOpportunityTab(content: TabOpportunityGridComponent) { if (content) this.opportunityTab = content };

  resellerTab: SaleAccountResellerTabComponent;
  @ViewChild('resellerTab', { static: false }) set setResellerTab(content: SaleAccountResellerTabComponent) { if (content) this.resellerTab = content };

  relationshipTab: SaleAccountRelationshipTabV2Component;
  @ViewChild('relationshipTab', { static: false }) set setRelationshipTab(content: SaleAccountRelationshipTabV2Component) { if (content) this.relationshipTab = content };

  fileTab: ProfileFileTabComponent;
  @ViewChild('profileFileTab', { static: false }) set setProfileFileTab(content: ProfileFileTabComponent) { if (content) this.fileTab = content }

  parentContactTypeSearch: DropdownSearchingComponent;
  childContactTypeSearch: DropdownSearchingComponent;
  @ViewChild('parentContactTypeSearch', { static: false }) set getParentContactTypeSearch(content: DropdownSearchingComponent) {
    if (content) this.parentContactTypeSearch = content;
  }
  @ViewChild('childContactTypeSearch', { static: false }) set getchildContactTypeSearch(content: DropdownSearchingComponent) {
    if (content) this.childContactTypeSearch = content;
  }
  nbMailPopover: NbPopoverDirective;
  @ViewChild('sendMailBtn', { static: false }) set setSendMailBtn(content: NbPopoverDirective) { if (content) this.nbMailPopover = content }

  makeCallPopover: NbPopoverDirective;
  @ViewChild('makeCallPopoverBtn', { static: false }) set setMakeCallBtn(content: NbPopoverDirective) { if (content) this.makeCallPopover = content }

  sendSMSPopover: NbPopoverDirective;
  @ViewChild('sendSMSBtn', { static: false }) set setSendSMSBtn(content: NbPopoverDirective) { if (content) this.sendSMSPopover = content }

  @ViewChild('contactSelectPop', { static: true }) contactSelectPop: TemplateRef<any>;
  taskGrid: TaskGridByProfileIdComponent;
  @ViewChild('taskGrid', { static: false }) set setTaskGrid(content: TaskGridByProfileIdComponent) { if (content) this.taskGrid = content };

  contactButton: AddContactButtonComponent
  @ViewChild('contactButton', { static: false }) set setContactButton(content: AddContactButtonComponent) { if (content) this.contactButton = content };

  nbFilesPopover: NbPopoverDirective;
  @ViewChild('nbFilesBtn', { static: false }) set setFilesPopoverBtn(content: NbPopoverDirective) { if (content) this.nbFilesPopover = content }

  environment = environment;
  saleAccountModel: SaleAccountDetailModel;
  ownerModel: UserModel;
  contactDialogRef: any;
  existingContactDialogRef: MatDialogRef<any, any>;
  isCopy = false;
  saleAccountColorCode = EntityColorEnums;
  isLoading: boolean = false;
  isRefreshing: boolean = false;
  isLoadingEdit: boolean = false;
  isLoadingContact: boolean = false;
  editorOptions = QuillConfiguration;
  public get TypeEditModelSaleAccount(): typeof TypeEditModelSaleAccount { return TypeEditModelSaleAccount; }
  dialogQuillEditor: MatDialogRef<any, any>;
  contentQuillEditor = "";
  isHideOpportunity: boolean = false;
  partnerType = [];
  formatValueQueryType = { key: 'contactTypeId', value: 'contactTypeName', color: 'contactTypeColorCode', parentId: 'parentId' };
  accountContactTypes: ContactTypeFilter[] = [];
  getContactType = this.contactService.GetAllContactTypeAsync("ACCOUNT")
    .pipe(tap(x => {
      if (x.result) {
        this.accountContactTypes = x.result;
        this.parentContactTypeAsDropdownModel = this.accountContactTypes.slice().filter(x => x.parentId == 0).map(x => {
          return new DropDownValue(x.contactTypeName, x.contactTypeId.toString(), x.contactTypeColorCode);
        });
      }
    }), map((vals: ReturnResult<any[]>) => Helper.mapArrayToFormat(this.formatValueQueryType, vals.result)));
  selectType: number[] = [];
  validProperties = {
    accountName: { valid: [RxwebValidators.required()], message: `${this.environment.titleAccount} name is required` },
    contactEmail: { valid: [RxwebValidators.email()], message: "Email is not valid" },
    //contactPhone: { valid: [RxwebValidators.pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ } })], message: "Phone is not valid" },
    annualRevenue: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "Annual revenue is not valid" },
    employees: {
      valid: [RxwebValidators.pattern({
        expression: { employees: /\b([0-9]{1,9}|1[0-9]{9}|2(0[0-9]{8}|1([0-3][0-9]{7}|4([0-6][0-9]{6}|7([0-3][0-9]{5}|4([0-7][0-9]{4}|8([0-2][0-9]{3}|3([0-5][0-9]{2}|6([0-3][0-9]|4[0-7])))))))))\b/ }
      }), RxwebValidators.digit()], message: "The Employees field is not valid or larger than 2147483647"
    }
  }
  isHasPrimaryContact = false;
  isCalling = false;
  isCallLoading = false;
  dynamicContentIndustryAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  dynamicContentCountryAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  dynamicContentStateAPI: Observable<ReturnResult<DynamicContentModel[]>>

  dynamicContentPhoneNumber: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_phone');

  dynamicContentWebsite: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_webpage');

  dynamicContentEmail: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_email');

  addNewTask = AddEditTaskComponent;
  selectTab: TabSaleAccount = TabSaleAccount.Contact;
  user;
  private routeSub: Subscription;
  apiGetSearchRelationship: (data) => void = null;
  resource = permissionGridNoteSaleAccount;
  overlayNoteDetails: OverlayNoteDetailsComponent;
  prefixCurrency: string;
  lastNote: NoteDetails;
  apiGetSearchOwner: (data) => void = (data) => this.userService.searchUser(data);
  isTabMode = Helper.checkTabMode();
  channelList: any[] = [{ key: "Direct", value: "Direct" }, { key: "Indirect", value: "Indirect" }]
  private destroy$: Subject<void> = new Subject<void>();
  // All contact type in backend:
  allContactTypesAsDropdownModel: DropDownValue[] = [];
  parentContactTypeAsDropdownModel: DropDownValue[] = [];
  subContactTypeAsDropdownModel: DropDownValue[] = [];
  selectedContactTypeParent: DropDownValue;
  selectedContactTypeChild: DropDownValue;
  uploadedFile: any;
  uploading: boolean = false;
  fileResource = permissionSaleAccountFile;
  contacts: Contact[] = null;
  resetContacts: () => void = () => this.contacts = null;

  //saleAccountContactId: number = 0;
  currentTab: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<SaleAccountDetailsComponent>,
    private saleAccountService: SaleAccountService,
    private taskService: TaskBoardService,
    private clipboard: Clipboard,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private userService: UserService,
    private contactService: ContactService,
    private profileService: ProfileService,
    private callService: CallService,
    private authService: NbAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private setting: SettingService,
    private settingPoolService: SettingPoolService,
    public numberPipe: NumberFormatPipe,
    public noteService: NoteManagementService,
    private dynamicContentService: DynamicContentService,
    private profileAdditionDetailService: ProfileAdditionDetailService,
    private builder: RxFormBuilder,
    private userUploadService: UserUploadManagementService,
    private hotToast: HotToastService,
    // private tabModeService: AdminTabModeService,
    private userExperienceTrackingService: UserExperienceTrackingService,
    private microsoftOffice365Service: MicrosoftOffice365Service
  ) {
    this.id = this.data?.model?.profileId ?? this.id;
    this.isLoading = true;
    this.route.params.subscribe(params => {
      if (params.id !== undefined) {
        this.id = params.id;
        //if (this.id && this.router.url.includes(`configuration/sale-account`)) Helper.cancelNavigate(`configuration/sale-account/${this.id}`);
      }
    });

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    if (this.router.url.includes('/configuration/sale-account') ||
      this.router.url.includes('/configuration/opportunity') ||
      this.router.url.includes('/configuration/email-history') ||
      this.router.url.includes('/configuration/email-inbox') ||
      this.router.url.includes('/configuration/history-data') ||
      this.router.url.includes('/configuration/email-campaign') ||
      this.router.url.includes('/configuration/dashboard-contact-map') ||
      this.router.url.includes('/configuration/sale-convert-history') ||
      this.router.url.includes('/configuration/import-contact') ||
      this.router.url.includes('/configuration/activity-log')
    ) {
      this.isShowButton = true;
    }
    // this.dynamicContentIndustryAPI = this.dynamicContentService.getDynamicContentByType('saleaccount-generalindustry');
    this.apiGetSearchRelationship = (data) => this.saleAccountService.searchSaleAccount(data).pipe(map(x => {
      let newFilter = x;
      if (newFilter.result) {
        newFilter.result = newFilter.result.filter(item => item.profileId !== this.id);
      }
      return newFilter;
    }));
    this.dynamicContentIndustryAPI = this.dynamicContentService.getDynamicContentByType('general_industry');
    this.dynamicContentCountryAPI = this.dynamicContentService.getDynamicContentByType('country');
    this.dynamicContentStateAPI = this.dynamicContentService.getDynamicContentByType('location_state');
    // Get Contact Type:
    this.getContactType.pipe(takeUntil(this.destroy$)).subscribe((returnData: AccountContactType[]) => {
      if (returnData) {
        this.allContactTypesAsDropdownModel = returnData.slice().map(x => {
          return new DropDownValue(x.value, x.key.toString(), x.color);
        });
        this.setupContactType();
      }
    });
  }

  ngOnInit(): void {
    this.userService.getAllUser();
    if (this.id) {
      this.refreshData();
    };
    if (this.openByDialog && this.dialModalRef?.componentInstance) {
      if (this.isTabMode) this.dialModalRef.afterOpened().subscribe(() => {
        var overlayBackdrops = window.document
          .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
        for (var i = 0; i < overlayBackdrops.length; i++)
          overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
      })

      this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
      if (this.data && this.data.advancedImport) {
        this.dialModalRef.updateSize(this.openByDialog ? '1600px' : '1600px', Helper.heightDialog());
      }
      else {
        this.dialModalRef.updateSize(this.openByDialog ? '1400px' : '1600px', Helper.heightDialog());
      }
    }
    // this.taskService.getAllTaskStatus();
    // this.taskService.getAllPriority();
    // this.taskService.getAllTaskType();
    this.getSettingToHideSaleOpportunity();
    this.formatCurrencyPrefix();
    this.getLastNote();
  }

  ngAfterViewInit(): void {

    this.overlayNoteDetails = this.noteService.overlayNoteDetailsComponent;
    if (this.router.url.includes('/configuration/sale-account')) {
      // let currentTabMode = this.tabModeService.getCurrentActiveTab();
      // let tag: string = null;
      // if (currentTabMode) {
      //   let tabTagIndex: TabTagIndexModel = this.tabModeService.getTabTagIndex(currentTabMode.tabId);
      //   if (tabTagIndex && tabTagIndex.uniqueId && typeof tabTagIndex.uniqueId == 'string' && tabTagIndex.uniqueId == this.id) {
      //     tag = tabTagIndex.tag;
      //   }
      // }

      let tag: string = this.userExperienceTrackingService.getTagIndexInUrl();

      let patternRegex = /#([\d]+)/;
      if (!Helper.isNullOrEmpty(tag)) {
        this.selectTab = parseInt(tag.replace('#', ''));
        Helper.handleTabChangedUrl(this.selectTab, '/configuration/sale-account');

      } else if (patternRegex.test(this.router.url)) {
        let matchTabIndex = this.router.url.match(patternRegex).length > 0 ? Number.parseInt(this.router.url.match(patternRegex)[0].replace('#', '')) : TabSaleAccount.Contact;
        this.selectTab = matchTabIndex;
        Helper.handleTabChangedUrl(this.selectTab, '/configuration/sale-account');
      }

      // if end with #tabIndex then move to that tab

    } else {
      this.selectTab = this.openByDialog ? TabSaleAccount.Contact : TabSaleAccount.ActivityLog;
    }
    this.currentTab = this.selectTab;
    this.saleAccountService.getSaleAccountById()
      .pipe(
        tap(resp => { 
          let profileDetailClone: ProfileDetailModel;
          let returnResult: ReturnResult<ProfileDetailModel>;
          profileDetailClone = Object.assign(profileDetailClone, resp);
          returnResult.result = profileDetailClone;

          return this.profileService._profileDetailData$.next(returnResult);
        }),
        takeUntil(this.destroy$)
      ).subscribe({
        next: resp => { if (resp.result) this.setupSaleAccount(resp.result); },
        complete: () => this.isLoading = false,
      });

    this.saleAccountService.isRefreshSaleGrid$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isRefresh: boolean) => this.isRefreshing = isRefresh);

    this.overlayNoteDetails.onRefreshed().pipe(
      takeUntil(this.destroy$),
      debounceTime(2000)
    ).subscribe(resp => {
      this.overlayNoteDetails.completedLoading();
      if (resp && this.noteTab)
        this.noteTab.refreshData();
      this.getLastNote(resp?.id, resp?.isDeleted);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeId = changes.id;
    if (changeId && !changeId.firstChange && changeId.currentValue != changeId.previousValue) {
      this.refreshData();
    }
  }

  ngOnDestroy(): void {
    if (this.routeSub != null)
    this.routeSub.unsubscribe();

    if (this.saleAccountService.saleAccountDetail$)
      this.saleAccountService.saleAccountDetail$.next(new ReturnResult<any>());

    this.destroy$.next();
    this.destroy$.complete();
  }

  refreshData() {
    this.saleAccountService.refreshSaleAccountById(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => { if (resp.result) this.setupSaleAccount(resp.result); },
        complete: () => {
          this.isLoading = false;
          this.isLoadingEdit = false;
          this.getOwnerSaleAccount();
        },
      });
  }

  setupSaleAccount(data: SaleAccountDetailModel) {
    if (data) {
      this.saleAccountModel = data;
      //this.saleAccountContactId = data.accountContactId;
      //this.contacts = this.saleAccountModel.profileContacts.map((contact) => contact.contact);
      this.selectType = this.saleAccountModel?.saleLeadsContact?.contactContactTypes?.map(x => x.contactTypeId) ?? [];
      // init selected contact type:
      if (this.allContactTypesAsDropdownModel.length > 0) this.setupContactType();
      this.saleAccountModel.tagList = this.saleAccountModel.tags !== '' && this.saleAccountModel.tags != null
        ? this.saleAccountModel.tags.split(',') : [];
      if (this.partnerType && this.partnerType.length > 0) {
        this.hideOpportunityConfig();
      }
    }
  }

  editAccountContact(value: any, prop: string, typeModel: TypeEditModelSaleAccount = TypeEditModelSaleAccount.SaleAccount) {
    if (!prop) {
      this.toast.danger('Failed', "Error");
      return;
    }

    if (prop == 'displayName') this.refreshTask.emit();
    if ((prop == 'annualRevenue' || prop == 'noOfEmployee') && !value)
      value = 0;
    let saleAccountRequest: SaleAccountDetailModel, contactRequest: Contact, locationRequest: Location

    if (prop === 'channel' || prop === 'primaryReseller') {
      if (prop === 'channel' && this.channelList.find(x => x.value === value) === undefined) {
        value = null;
      }
      if (this.saleAccountModel.profileAdditionDetail) {
        this.saleAccountModel.profileAdditionDetail[prop] = value;
      } else {
        let newExtendDetail: ProfileAdditionDetail = new ProfileAdditionDetail();
        newExtendDetail.profileId = this.saleAccountModel.profileId;
        newExtendDetail[prop] = value;
        this.saleAccountModel.profileAdditionDetail = newExtendDetail;
      }
      // if(this.saleAccountModel.profileId){
      //   this.saleAccountModel.saleLeadsContact.profileId = this.saleAccountModel.profileId;
      // }
      saleAccountRequest = Object.assign({}, this.saleAccountModel);
      contactRequest = Object.assign({}, this.saleAccountModel.saleLeadsContact);
      locationRequest = Object.assign({}, this.saleAccountModel.saleLeadsContact.location, { [prop]: value });


    } else {
      saleAccountRequest = Object.assign({}, this.saleAccountModel, { [prop]: value });
      contactRequest = Object.assign({}, this.saleAccountModel.saleLeadsContact, { [prop]: value });
      locationRequest = Object.assign({}, this.saleAccountModel.saleLeadsContact.location, { [prop]: value });

    }

    let id;
    switch (typeModel) {
      case TypeEditModelSaleAccount.SaleAccount: {
        id = this.saleAccountModel.profileId
        break;
      }
      case TypeEditModelSaleAccount.Contact: {
        id = this.saleAccountModel.saleLeadsContact.contactId.toString()
        break;
      }
      case TypeEditModelSaleAccount.Location: {
        id = this.saleAccountModel.saleLeadsContact.locationId.toString()
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    this.isLoadingEdit = true;
    this.saleAccountService.editSaleAccount({
      id: id,
      saleAccountModel: typeModel == TypeEditModelSaleAccount.SaleAccount
        ? saleAccountRequest : null,
      contactModel: typeModel == TypeEditModelSaleAccount.Contact
        ? contactRequest : null,
      locationModel: typeModel == TypeEditModelSaleAccount.Location
        ? locationRequest : null,
    }).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => {
          if (resp.result) {
            this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
            if (prop == "ownerId") this.ownerModel = null;
            this.refreshData();
            if (prop == "contactPhone" || prop == "contactEmail")
              this.contactTab?.refreshData();
          }
        },
        error: err => this.isLoadingEdit = false,
        complete: () => this.isLoadingEdit = false
      });
  }

  async closeDialog() {
    // remove tab cache:
    // this.tabModeService.removeCurrentTabTag();
    if (this.saleAccountService.cacheSaleAccounts && this.saleAccountService.cacheSaleAccounts.length > 0) {
      this.id = this.saleAccountService.getLastIdCacheSaleAccount();
      this.refreshData();
    }
    // if (this.router.url.includes(`configuration/sale-account`)) window.history.back();

    if (this.dialModalRef?.componentInstance)
      this.dialModalRef.close(this.isRefreshing);
    this.onClose.emit(this.isRefreshing);

    if (Helper.checkUrlDetailObj('sale-account')) {
      // var urlBack = Helper.popBackURL() || `/configuration/sale-account`;
      // this.router.navigate([urlBack]);

      var urlBack = Helper.popBackURL() || `/configuration/sale-account`;
      this.userExperienceTrackingService.navigatePrevious(`/configuration/sale-account`);
    }
  }

  getOwnerSaleAccount() {
    if (!this.saleAccountModel && Helper.isNullOrEmpty(this.saleAccountModel.ownerId) || Helper.isEmptyOrSpaces(this.saleAccountModel.ownerId))
      this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel);

    if (!this.ownerModel && this.saleAccountModel.ownerId)
      this.userService.getUserById(this.saleAccountModel.ownerId).pipe(takeUntil(this.destroy$))
        .subscribe(
          resp => {
            if (resp.result) {
              this.ownerModel = resp.result;
              let fullName = `${this.ownerModel.firstName} ${this.ownerModel.lastName}`;
              this.ownerModel.fullName = fullName.trim() ? fullName : "Unknown";
              this.ownerModel.pictureURL = this.userURL(this.ownerModel.pictureURL);
            };
          },
          err => this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel));
  }

  userURL(data): string {
    if (data)
      return data.replace(/\\/g, '/');
    else null;
  }

  convertHtmlToNormalStyle(data: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = data;
    return tmp.textContent || tmp.innerText || "";
  }

  openComponentEditor(model: string, prop: string) {
    this.dialogQuillEditor = this.dialog.open(this.popupEditor, {
      disableClose: true,
      autoFocus: false,
      width: '60vw',
      data: this.saleAccountModel[model][prop]
    });

    this.dialogQuillEditor.afterClosed().subscribe(resp => {
      if (resp) this.editAccountContact(resp, prop, TypeEditModelSaleAccount.Contact)
    });
  }

  setFocus(editor, data) {
    if (this.dialogQuillEditor) {
      const width = this.dialogQuillEditor._containerInstance._config.width;
      let toolbarEditor = this.quillEditor.elementRef.nativeElement.getElementsByClassName("ql-toolbar ql-snow")[0];
      let containerEditor = this.quillEditor.elementRef.nativeElement.getElementsByClassName("ql-container ql-snow")[0];
      toolbarEditor.style = `position: absolute; width: calc(${width} - 48px); background-color: snow; z-index: 100;`;
      containerEditor.style = `padding-top: 2.625rem; margin-bottom: 1px;`;
      this.contentQuillEditor = data;
      editor.focus();
    }
  }

  saveEditor() {
    if (this.dialogQuillEditor) {
      if (this.contentQuillEditor && this.contentQuillEditor !== "")
        this.closeDialogEditor(this.contentQuillEditor);
      else this.toast.danger("Content error or empty!", "Error");
    }
  }

  closeDialogEditor(data?: string) {
    if (this.dialogQuillEditor) this.dialogQuillEditor.close(data);
  }

  copyToClipboard() {
    const url = window.location.href.split('configuration')[0] + `configuration/sale-account/${this.id}`;
    this.clipboard.copy(url);
    this.toast.info(`Copied this ${this.environment.titleAccount} url to clipboard`, 'Success');
  }
  // Legacy contact:
  editSaleAccountType(event: number[]) {
    if (event) {
      if (JSON.stringify(this.selectType.sort()) !== JSON.stringify(event.sort())) {
        // if (this.saleAccountTypeComponent) this.saleAccountTypeComponent.isLoading = true;
        this.contactService.editContactContactType(this.saleAccountModel.saleLeadsContact.contactId, event)
          .pipe(tap(() => this.saleAccountService.isRefreshSaleGrid$.next(true)))
          .subscribe({
            next: resp => {
              if (resp.result) {
                this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
                this.refreshData();
              }
            },
            complete: () => {
              // if (this.saleAccountTypeComponent) this.saleAccountTypeComponent.isLoading = false;
            }
          });
      }
    }
  }

  sendMailClick(profile: SaleAccountDetailModel = null) {
    const dialogRef = this.dialog.open(LinkscopeEmailComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        profileModel: profile ?? this.saleAccountModel,
        entity: 'profile',
        optionTaskId: this.optionalTaskId,
        isShowSenderId: true,
        lastSentEmailEntity: this.environment.titleAccount
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response != null) {
        // return when closed
      }
    });
  }

  async onClickCall(alternativeContact: Contact = null) {
    this.isCallLoading = true;
    await this.callService.makeACall(
      alternativeContact ?? this.saleAccountModel.contact,
      this.saleAccountModel.profileContacts,
      this.user.nameid,
      this.saleAccountModel.profileId,
      this.creatableTask,
      this.optionalTaskId ? parseInt(this.optionalTaskId) : null,
      this.isCallLoading,
      this.toast,
      this.isCalling,
      'profile'
    );
    setTimeout(() => {
      this.isCallLoading = false;
    }, 3000);
  }

  createTask() {
    if (this.addNewTask) {
      const dialogRef = this.dialog.open(this.addNewTask, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          action: TblActionType.Add,
          profile: this.saleAccountModel,
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          // this.toast.success('Create task success!', 'Success');
          this.refreshData();
          if (this.taskGrid) this.taskGrid.refreshData();
        }
      });
    }
  }
  getSettingToHideSaleOpportunity() {
    // 2022-02-03 ducqm start add
    // this.setting.getSettingByKeyAndGroup("PARTNER_TYPE", "PROFILE").subscribe(resp => {
    //   if (resp.result && resp.result.value) {
    //     this.partnerType = resp.result.value.split(",").map(Number);
    //     this.hideOpportunityConfig();
    //   }
    // })
    this.settingPoolService.getSettingByKeyAndGroup("PARTNER_TYPE", "PROFILE").subscribe(resp => {
      if (resp.result && resp.result.value) {
        this.partnerType = resp.result.value.split(",").map(Number);
        this.hideOpportunityConfig();
      }
    })
    // 2022-02-03 ducqm end add
  }
  hideOpportunityConfig() {
    if (this.partnerType && this.partnerType.length > 0) {
      this.isHideOpportunity = false;
      this.partnerType.forEach(e => {
        const findExisted = this.selectType.find(x => x == e);
        if (findExisted) {
          this.isHideOpportunity = true;
        }
      });
      //this.isHideOpportunity = listPartnerLst && listPartnerLst.length > 0 ? true : false;
    }
  }
  copyGUIDToClipboard() {
    this.clipboard.copy(this.id);
    this.toast.info(`Copied this ${this.environment.titleAccount} ID to clipboard`, 'Success');
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }

  onAddOpportunityClick() {
    const dialog = this.dialog.open(OpportunityModalComponent, {
      hasBackdrop: true,
      autoFocus: false,
      disableClose: true,
      data: {
        actionType: TblActionType.Add,
        saleAccountId: this.id,
        companyName: this.saleAccountModel.displayName
      }
    });

    dialog.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          if (this.opportunityTab)
            this.opportunityTab.refreshData();
        }
      }
    });
  }

  updatePrimaryContact(contactLst: Contact[]) {
    if (this.saleAccountModel) {
      let primaryContact = contactLst.find(x => x.primaryContact == true);
      //this.contacts = contactLst ? contactLst.map((x: any) => x.contact) : this.contacts;
      if (!this.contacts.find(x => x.contactId == this.saleAccountModel.saleLeadsContact.contactId)) {
        this.contacts.push(this.saleAccountModel.saleLeadsContact);
      }
      if (primaryContact) {
        this.saleAccountModel.contact = primaryContact;
        this.saleAccountModel.contactId = primaryContact.contactId;
        this.saleAccountModel.accountContactId = primaryContact.contactId;
        this.isHasPrimaryContact = this.saleAccountModel.contact && !this.saleAccountModel.contact.deleted ? true : false;
      } else {
        this.saleAccountModel.contact = null;
        this.saleAccountModel.contactId = null;
        this.saleAccountModel.accountContactId = 0;
        this.isHasPrimaryContact = false;
      }
    }
  }

  tabIndexChanged(tabIndex: number) {
    this.currentTab = tabIndex;
    if (this.router.url.includes('configuration/sale-account')) {
      Helper.handleTabChangedUrl(tabIndex, '/configuration/sale-account');
      // this.tabModeService.setTagIndexToCurrentTab(tabIndex, this.id);
      this.userExperienceTrackingService.saveTagIndexChangeInUrl(tabIndex);
    }
  }

  cleanComponentTab() {
    this.noteTab = null;
    this.contactTab = null;
    this.locationGrid = null;
    this.opportunityTab = null;
    this.resellerTab = null;
    this.relationshipTab = null;
  }

  formatCurrencyPrefix() {
    this.prefixCurrency = `<div class="currency-color">${localStorage.getItem("currency") ?? ""}</div>`
  }

  getLastNote(resp?: number, isDelete: boolean = false) {
    if (this.lastNote && this.lastNote.id == resp && isDelete)
      this.lastNote = null;

    if ((this.lastNote && this.lastNote.id <= resp && this.lastNote.referenceId == this.id) || !this.lastNote)
      this.noteService.lastNoteByReferenceId(this.id, ShadowProfileEnum[ShadowProfileEnum.SALEACCOUNT])
        .subscribe({
          next: resp => {
            if (resp.result)
              this.lastNote = Object.assign(resp.result, {
                innerTextNote: Helper.removeStyleHtml(resp.result.note),
                ownerNote: (`${resp.result.user?.firstName || ''} ${resp.result.user?.lastName || ''}`).trim() || resp.result.user?.userName || 'Unknown',
                avatarNote: resp.result.user?.pictureURL?.replace(/\\/g, '/'),
                //referenceName: this.saleAccountModel.displayName
              })
          },
          //complete: () => this.overlayNoteDetails.completedLoading()
        });
  }

  savePrimaryResellerOrRelationship = (data: ProfileDetailModel, type: string) => {
    // if (data && type) {
    //   this.isLoadingEdit = true;
    //   this.saleAccountService.changePrimaryResellerOrRelationship(data.profileId, this.id, type).subscribe({
    //     next: res => {
    //       if (res.message) this.isLoadingEdit = false;
    //       if (res.result) {
    //         this.toast.success(`Save primary ${type?.toLowerCase()} success!`, "Success");
    //         if (this.resellerTab && type == "Reseller") this.resellerTab.refreshData();
    //         if (this.relationshipTab && type == "Relationship") this.relationshipTab.refreshData();
    //         this.refreshData();
    //       }
    //     },
    //     error: (err) => this.isLoadingEdit = false,
    //   })
    // }
    //if the data == null -> remove relationship / reseller
    let dataId = data?.profileId;

    if (typeof (data) === 'string') {
      if (Helper.isNullOrEmpty(data)) {
        dataId = null;
      } else {
        this.toast.danger("Please input a valid data", 'Invalid Input');
        return;
      }
    }

    this.isLoadingEdit = true;
    this.saleAccountService.changePrimaryResellerOrRelationship(dataId, this.id, type).subscribe({
      next: res => {
        if (res.result) {
          this.toast.success(`Save primary ${type?.toLowerCase()} success!`, "Success");
          if (this.resellerTab && type == "Reseller") this.resellerTab.refreshData();
          if (this.relationshipTab && type == "Relationship") this.relationshipTab.refreshData();
          this.refreshData();
        }
        else {
          this.isLoadingEdit = false;
        }
      },
      error: (err) => { this.isLoadingEdit = false; },
      complete: () => { }
    })
  }

  callerAddEditResellerOrRelationship(data: ProfileDetailModel, type: string) {
    this.toast.success(`Save add new ${type?.toLowerCase()} success!`, "Success");
    this.saleAccountService.addEditResellerOrRelationship(this.id, data.profileId, type).subscribe(res => {
      if (res.result) {
        if (this.resellerTab && type == "Reseller") this.resellerTab.refreshData();
        if (this.relationshipTab && type == "Relationship") this.relationshipTab.refreshData();
        if (this.opportunityTab) this.opportunityTab.refreshData();
      }
    })
  }

  openPopupFindingResellerOrRelationship(isReseller: boolean = true) {
    const dialogRef = this.dialog.open(AddExistingProfileComponent, {
      width: '40vw',
      height: '60vh',
      data: {
        id: this.id,
        isProfile: true,
        placeholder: `Search ${isReseller ? 'reseller' : 'relationship'}...`,
        label: isReseller ? 'Reseller' : 'Relationship',
        apiSearch: (data) => this.saleAccountService.searchSaleAccount(data),
        isListenEvent: true,
        firstSuggest: true,
        firstSuggestAPI: this.saleAccountService.suggestRecentlyAddedSaleAccount(this.id),
        idProp: 'profileId'
      }
    });

    dialogRef.afterClosed().subscribe((data: ProfileDetailModel) => {
      if (data && this.id)
        this.callerAddEditResellerOrRelationship(data, isReseller ? "Reseller" : "Relationship");
    });
  }



  openLocationDialog() {
    const locationDialogRef = this.dialog.open(LocationComponent, {
      disableClose: true,
      height: '80vh',
      width: '55vw',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        profileId: this.id,
        isUpdateAction: false,
        selfGetApi: false,
        isDialog: true,
        activeSetPrimary: false,
        closeDialogEvent: (result) => {
          if (locationDialogRef) {
            locationDialogRef.close(result);
          }
        }
      }
    });
    locationDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refreshData();
        this.locationGrid.refreshData(true);

      }
    });
  }
  setupContactType() {
    if (this.saleAccountModel?.profileId == this.id)
      this.selectType.forEach(item => {
        let itemInstance = this.accountContactTypes.find(x => x.contactTypeId == item);
        if (itemInstance && itemInstance.parentId > 0) {
          // if has parent: this is child:
          this.selectedContactTypeChild = new DropDownValue(itemInstance.contactTypeName, itemInstance.contactTypeId.toString(), itemInstance.contactTypeColorCode);
        }
        if (itemInstance && itemInstance.parentId == 0) {
          this.selectedContactTypeParent = new DropDownValue(itemInstance.contactTypeName, itemInstance.contactTypeId.toString(), itemInstance.contactTypeColorCode);
          // filter child dropdown:
          let childIds = this.accountContactTypes.slice().filter(x => x.parentId == itemInstance.contactTypeId).map(x => x.contactTypeId.toString());
          this.subContactTypeAsDropdownModel = this.allContactTypesAsDropdownModel.slice().filter(x => childIds.includes(x.value));
        }
      });
  }


  editNewSingleContactType(event: DropDownValue, type: string) {
    if (event) {
      let id: number = parseInt(event.value);
      let selectedTarget = this.accountContactTypes.find(x => x.contactTypeId === id);
      if (selectedTarget) {
        if (type === AccountSingleContactType.Parent) {
          // parent change
          if (!this.selectedContactTypeParent || (this.selectedContactTypeParent.value !== event.value)) {
            this.selectedContactTypeParent = event;
            this.selectedContactTypeChild = null;
            let newParent = this.accountContactTypes.find(x => x.contactTypeId === parseInt(this.selectedContactTypeParent.value));
            this._filterDropdownContactTypeChild(newParent);
          } else {
            // same result:
            return;
          }
        } else if (type === AccountSingleContactType.Child) {
          if (!this.selectedContactTypeChild || (this.selectedContactTypeChild.value !== event.value)) {
            // child change:
            this.selectedContactTypeChild = event;
          } else {
            // same result:
            return
          }
        }
        // filter the seleted Ids:
        let newIds = [];
        if (this.selectedContactTypeParent && !isNaN(parseInt(this.selectedContactTypeParent.value))) newIds.push(parseInt(this.selectedContactTypeParent.value));

        if (this.selectedContactTypeChild && !isNaN(parseInt(this.selectedContactTypeChild.value))) newIds.push(this.selectedContactTypeChild.value);
        // call api:
        this.contactService.editContactContactType(this.saleAccountModel.saleLeadsContact.contactId, newIds)
          .pipe(tap(() => this.saleAccountService.isRefreshSaleGrid$.next(true)))
          .subscribe({
            next: resp => {
              if (resp.result) {
                this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
                this.refreshData();
              }
            },
            complete: () => {
            }
          });
      }
    } else {
      if (this.selectedContactTypeParent && type === AccountSingleContactType.Parent) {
        this.selectedContactTypeParent = undefined;
        this.selectedContactTypeChild = undefined;
        this.subContactTypeAsDropdownModel = [];
        this.contactService.editContactContactType(this.saleAccountModel.saleLeadsContact.contactId, [])
          .pipe(tap(() => this.saleAccountService.isRefreshSaleGrid$.next(true)))
          .subscribe({
            next: resp => {
              if (resp.result) {
                this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
                this.refreshData();
              }
            },
            complete: () => {
            }
          });
      } else if (this.selectedContactTypeChild && type === AccountSingleContactType.Child) {
        this.selectedContactTypeChild = undefined;
        this.contactService.editContactContactType(this.saleAccountModel.saleLeadsContact.contactId, this.selectedContactTypeParent ? [parseInt(this.selectedContactTypeParent.value)] : [])
          .pipe(tap(() => this.saleAccountService.isRefreshSaleGrid$.next(true)))
          .subscribe({
            next: resp => {
              if (resp.result) {
                this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
                this.refreshData();
              }
            },
            complete: () => {
            }
          });
      }
    }
  }
  // filter the dropdown of sub contact type:
  private _filterDropdownContactTypeChild(itemInstance: ContactTypeFilter) {
    if (!itemInstance) return;
    let childIds = this.accountContactTypes.slice().filter(x => x.parentId == itemInstance.contactTypeId).map(x => x.contactTypeId.toString());
    this.subContactTypeAsDropdownModel = this.allContactTypesAsDropdownModel.slice().filter(x => childIds.includes(x.value));
  }
  async addSaleAccountFile(fileInputEvent: any) {
    this.uploading = true;
    this.uploadedFile = fileInputEvent.target.files[0];
    let acceptanceExtension = ["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"];
    let extension = Helper.getFileExtension(fileInputEvent.target.files[0].name) ? Helper.getFileExtension(fileInputEvent.target.files[0].name)[0] : null;
    // let IsAccept = extension ? (acceptanceExtension.indexOf(extension) == -1 ? false : true) : false;
    let IsAccept = true // Accept all extensions
    if (IsAccept) {
      let submitModel = new UserUpload();
      submitModel.fileName = this.uploadedFile.name;
      submitModel.referenceType = this.saleAccountModel.typeName;
      submitModel.referenceId = this.saleAccountModel.profileId;
      submitModel.owners = this.user.nameid;

      var toastRef = this.hotToast.loading('Uploading file...', { autoClose: false });
      try {
        const uploadFile = await this.userUploadService.uploadFile(this.uploadedFile, submitModel).toPromise();
        if (uploadFile?.result) {
          this.fileTab?.refreshData(true);
          toastRef.close();
        }
        else {
          toastRef.close();
        }
      }
      catch (ex) {
        console.log(ex);
        toastRef.close();
        this.hotToast.error('An error occurred while uploading...', { duration: 2000 });
      }

    } else {
      this.toast.warning('Please select file with these extensions: doc,docx,xls,xlsx,ppt,pdf,html,zip,csv,txt', "Warning");
    }
    this.nbFilesPopover.hide();
  }
  saveIndividualProfileAdditionDetail(data: string, type: string) {
    if (data) {
      // save:
      this.isLoadingEdit = true;
      let model: ProfileAdditionDetail = new ProfileAdditionDetail();
      model[type] = data;
      model.profileId = this.id;
      this.profileAdditionDetailService.saveSingleProfileAdditionByPropName(type, model).pipe(takeUntil(this.destroy$)).subscribe(res => {
        if (res.result) {
          this.toast.success(`Edit ${this.environment.titleAccount} success`, 'Successfully');
          this.refreshData();
          this.isLoadingEdit = false;

        }
      })
    }
  }

  getSelectedContactMail(contact: Contact) {
    if (contact) {
      let newProfileModel = Object.assign(this.saleAccountModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.sendMailClick(newProfileModel);
    } else {
      // skip mode (select primary contact)
      this.sendMailClick(null);
    }
  }
  getSelectedContactCall(contact: Contact) {
    if (contact) {
      let newProfileModel = Object.assign(this.saleAccountModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.onClickCall(newProfileModel.contact);
    } else {
      // skip mode (select primary contact)
      this.onClickCall(null);
    }
  }
  get checkNumberOfContact() {
    return this.saleAccountModel?.profileContacts?.length ?? 0;
  }
  confirmSendMailClick() {
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to send email to ${Helper.getEmailFromContact(this.saleAccountModel?.saleLeadsContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) {
        this.sendMailClick();
      }
    });
  }
  confirmMakeACall() {
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to make a call to number ${Helper.getPhoneFromContact(this.saleAccountModel?.saleLeadsContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) {
        this.onClickCall();
      }
    });
  }
  createContactCampaign() {
    if (!this.contactTab) {
      this.toast.warning('Please select to contact tab to start creating campaign');
      return;
    }
    const dialogRef1 = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to create campaign with ${this.numberPipe.transform((this.contactTab?.primasTable.selected && this.contactTab?.primasTable.selected.length != 0) ? this.contactTab?.primasTable.selected.length : this.contactTab?.primasTable.table.rowCount)} item(s)?`
      }
    });
    dialogRef1.afterClosed().subscribe(response => {
      if (response) {
        const campaignData: ContactPage = Object.assign({}, this.contactTab.primasTable.page, { relationshipId: this.saleAccountModel.profileId, type: "Profile" });
        const ComponentOpen: any = ReviewContactCampaignComponent


        const dialogRef = this.dialog.open(ComponentOpen, {
          width: '1300px',
          disableClose: true,
          autoFocus: false,
          data: {
            contactPage: campaignData,
            isSelectAll: true,
            externalComponent: true,
          }
        });
        dialogRef.afterClosed().subscribe(e => {
          this.contactTab.primasTable.selected = [];
        });
      }
    });
  }

  onClickImport(dataImport): void {
    this.profileService.importContactWithRelationship(dataImport, this.saleAccountModel.profileId).subscribe(resp => {
      this.contactButton.finishImport(resp);
      this.refreshData();
    });
  }

  async onClickAdvanceImport(action: string) {
    if (action == "Skip") {
      const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `Do you wish to skip 1 item(s)?`
        }, disableClose: true,
      })
      const result = await dialogConfirm.afterClosed().toPromise();
      if (result) {
        this.onAdvancedImport.emit(action);
        this.dialModalRef.close();
      }
    }
    else
      this.onAdvancedImport.emit(action);
  }

  openSMS(contact: Contact) {
    if (contact) {
      const dialogSMS = this.dialog.open(PrimasSendSMSComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'custom-dialog-SMS',
        data: {
          id: this.id,
          referenceType: 'SALEACCOUNT',
          outboundPhone: contact.contactPhone,
        },
      })
    }
  }
  nbPopoverCLick() {
    this.nbFilesPopover.show();
  }
  openURLDialogRef() {
    const dialogRef = this.dialog.open(UploadUrlComponent, {
      width: '30vw',
      maxWidth: '30vw',
      data:{
        profileId:this.saleAccountModel.profileId,
        nameId: this.user.nameid,
        referenceType : "SALEACCOUNT"
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if(resp){
        this.fileTab?.refreshData(true);
      }
    });
  }
  onRefresh(bool){
    this.refreshData();
  }
}

export enum TypeEditModelSaleAccount {
  SaleAccount,
  Contact,
  Location
}

export enum TabSaleAccount {
  Contact,
  Opportunity,
  Reseller,
  Location,
  MoreInfo, //ExtendData
  Relationship,
  Files,
  Note,
  Tasks,
  ActivityLog
}

class AccountContactType {
  key: string;
  value: string;
  color: string;
  parentId: string;
}
enum AccountSingleContactType {
  Parent = 'parent',
  Child = 'child'
}
