import { SupportTicketPage } from './../../../models/paging/page';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild, OnChanges, SimpleChanges, Renderer2, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NbSidebarService } from '@nebular/theme';
import { model } from '@rxweb/reactive-form-validators';
import { ColumnMode, DatatableComponent, DatatableGroupHeaderDirective, RowHeightCache, SelectionType } from '@swimlane/ngx-datatable';
import { latLngBounds } from 'leaflet';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { PrimasAllFilterOperator } from 'src/app/shared/enums/primas-all-filter-operator';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { PageInfo } from 'src/app/shared/interfaces/page-info';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { Helper } from 'src/app/shared/utility/Helper';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { isObservable, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';
import { ppid } from 'process';
import { PrimasDatetimeFilterOperator } from 'src/app/shared/enums/primas-datetime-filter-operator';
import { PrimasMailActionFilterOperator } from 'src/app/shared/enums/primas-email-action-filter-operator';
// import { AdminTabModeService } from '../../stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { DateFilterComponent } from '../../date-filter/date-filter.component';
import { PrimasToolbarComponent } from '../primas-toolbar/primas-toolbar.component';
import { SettingService } from 'src/app/shared/services/setting.service';
import { group } from 'console';

@Component({
  selector: 'app-primas-table',
  templateUrl: './primas-table.component.html',
  styleUrls: ['./primas-table.component.scss']
})
export class PrimasTableComponent implements OnInit {
  @ViewChild('columnAction', { static: true }) columnAction: TemplateRef<any>;
  @ViewChild('columnMenu', { static: true }) columnMenu: TemplateRef<any>;
  @ViewChild('filterHeader', { static: true }) filterHeader: TemplateRef<any>;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('spinnerColumnHeader', { static: true }) spinnerColumnHeader: TemplateRef<any>;
  @ViewChildren('dateFilerValue') dateFilterComponent: QueryList<DateFilterComponent>;
  // @ViewChild('table') tableComponent: ElementRef;
  // 2021-09-20 tienlm add start
  // for row detai only, this will be false default
  @Input() hasDetails = false;
  // ng-template for row detail
  @Input() rowDetails: TemplateRef<any>;
  // heigt of row detail
  @Input() rowDetailHeight;
  // 2021-09-20 tienlm add end
  @Input() tableAction = true;
  @Input() columnsTable: [];
  @Input() headerHeight = 60;
  @Input() rowHeight;
  @Input() customTemplate;
  //2022-03-22 gnguyen start add
  @Input() allowSelectRow = true;
  //2022-03-22 gnguyen end add
  @Input() rowClass;

  @Input() addEditComponet;
  @Input() resource;
  @Input() optionHeight: string = '';
  @Input() externalSorting: boolean = true;
  @Input() externalPaging: boolean = true;
  @Input() stickFirstColumn: boolean = false;
  @Input() classAddMore: string = ''
  @Output() onDelete = new EventEmitter<any>();
  @Output() onRefresh = new EventEmitter<any>();
  @Output() activate = new EventEmitter<any>();

  // 2022-03-16 tienlm ad start
  @Input()
  customAction: TemplateRef<any>;
  @Input()
  customAction2: TemplateRef<any>;

  // 2022-03-16 tien lm add end
  //2022-08-24 duythach add start
  @Input()
  customAction3: TemplateRef<any>;
  //2022-08-24 duythach add end
  // 2022-09-08 tienlm add start
  // Flag to use custom edit
  @Input() usingCustomEdit = false;
  @Input() actionWidth = 40;
  @Input() primasToolbar: PrimasToolbarComponent;

  @Output() customEditFunction = new EventEmitter<any>();
  @Output() customDeleteFunction = new EventEmitter<any>();
  @Output() editEvent: EventEmitter<void> = new EventEmitter<void>();

  //Additional data when update logic
  @Input() updateAdditionalData = null;
  @Input() disableTableAction = false;
  //Use to hidden "Edit item" button
  @Input() isHiddenEditButton: boolean = false;
  //The logic group the rows by the key of the column
  @Input() groupRowsBy: string = null;
  @Input() groupHeaderTemplate: TemplateRef<any>;

  @Input() isCatchEventHover: boolean = false;
  @Output() onHoverRow = new EventEmitter<any>();
  @Output() onLeaveRow = new EventEmitter<any>();

  // 2022-09-08 tienlm add end
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.table.recalculate();
      this.table['cd'].markForCheck();
      document.body.style.width = 'auto';
    }, 0);
  }
  cache: any = {};
  isLoading = 0;
  customView = false;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  page = new Page();
  columns = [];
  actionColumn = [];
  rows = [];
  selected: any[] = [];
  currentRouter: string = null;
  isVirtualScrollV: boolean = true;
  isLoadingTotal: boolean = false;

  groupExpansionDefault: boolean = true;
  private groupStates: { [key: string]: boolean } = {};
  private allGroupStates: { [key: string]: boolean } = {};

  private hoverTimer: any = null;
  private currentHoveredRow: any = null;
  private HOVER_DELAY = 1000; // 1 second

  constructor(private dialog: MatDialog, protected sidebarService: NbSidebarService, private activatedRoute: ActivatedRoute, private userService: UserService,
    private element: ElementRef, private settingService: SettingService
    // private tabMode: AdminTabModeService,
  ) {
    //2022-01-07 gnguyen start add
    this.userService.modelChange.subscribe(() => {
      this.refreshTable();
    })
    //2022-01-07 gnguyen end add
    this.sidebarService.onToggle().subscribe(() => {

      setTimeout(() => {
        this.table.recalculate();
        this.table['cd'].markForCheck();
        document.body.style.width = 'auto';
      }, 0);
    })

    let segmentLength = this.activatedRoute.snapshot.url.length;
    let path = '/';
    if (this.activatedRoute.snapshot.params['viewId']) {
      segmentLength -= 2;
    }

    for (let i = 0; i < segmentLength; i++) {
      path += this.activatedRoute.snapshot.url[i].path + '/';
    }
    this.currentRouter = path;
  }

  ngOnInit() {
    if (this.allowSelectRow) {
      this.actionColumn.push({
        width: 30,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        frozenLeft: true,
      });
    }
    if (this.tableAction) {
      this.actionColumn.push({
        maxWidth: this.actionWidth == 40 ? 45 : this.actionWidth,
        name: '',
        prop: 'actionNgxTable', // old name = 'id' => change to new name actionNgxTable
        sortable: false,
        cellTemplate: this.columnAction,
        headerClass: 'text-center remove-padding',
        cellClass: 'text-center remove-padding',
        frozenLeft: true,
      });
    }
    this.columns = this.columns.concat(this.actionColumn);
    if (this.columnsTable && this.columnsTable.length > 0) {
      this.columnsTable.forEach((column: any, index) => {

        //stick the first column
        if (index == 0 && this.stickFirstColumn) column.frozenLeft = true;
        if (column.filter && !column.headerTemplate) {
          column.headerTemplate = this.filterHeader;
        }
        // add Filter Operator default when start.
        if (column.filter && column.filterOperator == undefined) {
          column.filterOperator = null;
        }

        //case if the column is not visible on UI but still exist on the data
        if (column?.visible === false) {
          column.sortable = false;
          column.filter = false;
          column.resizeable = false;
          column.headerClass = 'hidden-ngx-datatable-header-cell';
          column.bodyClass = 'hidden-ngx-datatable-body-cell';
          column.cellClass = 'hidden-ngx-datatable-body-cell';
        }
      })
    }

    this.columnsTable = [...Helper.getColumnsPermission(this.columnsTable)] as [];
    this.columns = this.columns.concat(this.columnsTable);
    this.columns = [...Helper.getColumnsPermission(this.columns)];
    // this.page.viewId = this.activatedRoute.snapshot.params['viewId'] ?? parseInt(localStorage.getItem(this.currentRouter)) ?? null;
    // set local storage:
    if (this.activatedRoute.snapshot.params['viewId']) {
      this.page.viewId = this.activatedRoute.snapshot.params['viewId'];
    } else {
      // no param provided:
      // let currentActiveTab = this.tabMode.getCurrentActiveTab();
      // let viewId = this.tabMode.getPropExtendData('viewId', currentActiveTab);
      // if (!Helper.isNullOrEmpty(viewId)) {
      //   this.page.viewId = viewId;
      // } else {
      //   this.page.viewId = null;
      // }

      this.page.viewId = null;
    }

    // Add dummy listener to prevent unsubscribe error
    // When we use the groupRowsBy or rowDetail in the ngx-datatable, it will unsubscribe the listener without checking if the listener is exist or not
    setTimeout(() => {
      if (this.table?.bodyComponent) {
        if (this.table.bodyComponent['listener'] == null || this.table.bodyComponent['listener'] == undefined) {
          this.table.bodyComponent['listener'] = {
            unsubscribe: () => {
              // Empty function to prevent null error
              console.log('Dummy unsubscribe called');
            }
          };
        }
      }
    });

    if (this.isCatchEventHover) {
      this.setupHoverTimerEvent();
    }
  }

  async setupHoverTimerEvent() {
    var defaultValue = 1000;
    try {
      var setting = await this.settingService.getSettingByKeyAndGroup("DEFAULT_HOVER_TIME", "MONEY_MAKER").toPromise();
      if (setting.result) {
        var settingValue = setting.result.value;
        try {
          var value = parseInt(settingValue);
          if (value > 0) defaultValue = value;
        }
        catch (error) {
          console.log(error)
        }
      }

      this.HOVER_DELAY = defaultValue;
    }
    catch (error) {
      console.log(error)
    }
  }

  columnFilter() {
    this.columns.forEach(e => {
      if (this.page.filter) {
        const filterIndex = this.page.filter.find(x => x.prop == e.prop)
        if (filterIndex) {
          e.filterOperator = filterIndex.filterOperator;
        }
      }
    })
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    // this.page.selected = this.selected;
  }
  onSortColumn(event) {
    const prop = event.column.prop;
    const value = event.newValue;
    this.page.orders = Helper.formatOrder(this.page.orders, prop, value, event.column);
    this.refreshTable(true);

  }
  refreshTable(reset: boolean = false) {
    //this.columnFilter();
    if (reset) {
      setTimeout(() => {
        if (reset) {
          const hostEl = this.element.nativeElement;
          hostEl.getElementsByClassName("datatable-header")[0].scrollLeft = 0;
        }
        this.table._offsetX.next(1);
      }, 1);
      this.reloadTableHeader();
    }
    // check Reload Columns if has observable:
    this.onRefresh.emit(reset);

  }
  onChangeFilterHeader(filterText, column, type: PrimasFilterType = PrimasFilterType.Text) {
    // 2023_06_16 ducqm start add
    // Re-update the column before filtering data
    let columnNeedUpdateIndex = this.columns.findIndex(x => x.prop === column.prop);
    if (columnNeedUpdateIndex !== -1) {
      column = this.columns[columnNeedUpdateIndex];
    }
    // 2023_06_16 ducqm end add

    const prop = column.prop;
    column.filterValue = filterText;
    this.page.filter = Helper.formatFilter(this.page.filter, prop, filterText, type, column.filterOperator, column.filter);
    this.refreshTable(true);
  }

  onFilterDateTime(event, column) {
    const filterDatetime = event.datetime;
    const prop = column.prop;
    if (event) {
      if (filterDatetime.constructor.name == "Array") {
        const currentValue = column.filterValue ?? [];
        const isEqual = Helper.arrayEquals(currentValue, filterDatetime);
        if (isEqual) return;
      }
      column.filterValue = filterDatetime;
      this.page.filter = Helper.formatFilter(this.page.filter, prop, filterDatetime, PrimasFilterType.DateTime, column.filterOperator, column.filter);
      this.refreshTable(true);
    }
  }

  onFilterDate(event, column) {
    const filterDatetime = event.datetime;
    const prop = column.prop;
    if (event) {
      if (filterDatetime.constructor.name == "Array") {
        const currentValue = column.filterValue ?? [];
        const isEqual = Helper.arrayEquals(currentValue, filterDatetime);
        if (isEqual) return;
      }

      column.filterValue = filterDatetime;

      this.page.filter = Helper.formatFilter(this.page.filter, prop, filterDatetime, PrimasFilterType.Date, column.filterOperator, column.filter);
      this.refreshTable(true);
    }
  }
  onFilterDropDown(event, column) {
    const prop = column.prop;
    var currentValue = column.filterValue ?? [];
    if (!Helper.arrayEquals(currentValue, event)) {
      var columnIndex = this.columns?.findIndex(x => x?.prop === column?.prop);
      if (this.columns?.[columnIndex]?.filterValue) this.columns[columnIndex].filterValue = event;
      column.filterValue = event;
      this.page.filter = Helper.formatFilter(this.page.filter, prop, event, PrimasFilterType.DropDown, column.filterOperator, column.filter);
      this.refreshTable(true);
    }
  }

  onFilterBoolean(event, column) {
    const prop = column.prop;
    var currentValue = column.filterValue ?? [];
    if (!Helper.arrayEquals(currentValue, event)) {
      column.filterValue = event;
      this.page.filter = Helper.formatFilter(this.page.filter, prop, event, PrimasFilterType.Boolean, column.filterOperator);
      this.refreshTable(true);
    }
  }

  onFilterDropDownList(event, column) {
    const prop = column.prop;
    var currentValue = column.filterValue ?? [];
    if (!Helper.arrayEquals(currentValue, event)) {
      var columnIndex = this.columns?.findIndex(x => x?.prop === column?.prop);
      if (this.columns?.[columnIndex]?.filterValue) this.columns[columnIndex].filterValue = event;
      column.filterValue = event;
      this.page.filter = Helper.formatFilter(this.page.filter, prop, event, PrimasFilterType.DropDownList, column.filterOperator);
      this.refreshTable(true);
    }
  }

  onFilterDynamicContent(event, column) {
    const prop = column.prop;
    var currentValue = column.filterValue ?? [];
    if (!Helper.arrayEquals(currentValue, event)) {
      var columnIndex = this.columns?.findIndex(x => x?.prop === column?.prop);
      if (this.columns?.[columnIndex]?.filterValue) this.columns[columnIndex].filterValue = event;
      column.filterValue = event;
      this.page.filter = Helper.formatFilter(this.page.filter, prop, event, PrimasFilterType.DynamicContent, column.filterOperator);
      this.refreshTable(true);
    }
  }

  setPage(pageInfo) {
    // // Current page number is determined by last call to setPage
    // // This is the page the UI is currently displaying
    // // The current page is based on the UI pagesize and scroll position
    // // Pagesize can change depending on browser size
    // this.pageNumber = pageInfo.offset;

    // // Calculate row offset in the UI using pageInfo
    // // This is the scroll position in rows
    // const rowOffset = pageInfo.offset * this.page.size;

    // // When calling the server, we keep page size fixed
    // // This should be the max UI pagesize or larger
    // // This is not necessary but helps simplify caching since the UI page size can change

    // this.page.pageNumber = Math.floor(rowOffset / this.page.size);


    // // We keep a index of server loaded pages so we don't load same data twice
    // // This is based on the server page not the UI
    // if (this.cache[this.page.pageNumber]) return;
    // this.cache[this.page.pageNumber] = true;

    // // Counter of pending API calls
    this.isLoading++;
    this.page.pageNumber = pageInfo.offset;
    //if (!(window.location.pathname.includes('/view/') && this.primasToolbar && this.primasToolbar.customView && this.page.pageNumber == 0))
    this.refreshTable();
  }
  setData(result: PagedData<any>) {
    if (result) {
      this.isLoading--;
      this.page = result.page;
      this.page.viewId = null;
      this.rows = result.data;
      //  if(!result.data) this.table.offset = Number.POSITIVE_INFINITY;
      setTimeout(() => {
        // Reset cache bằng cách tạo mới instance
        this.table.bodyComponent['rowHeightsCache'] = new RowHeightCache();
        this.table.bodyComponent['rowIndexes'] = new Map();
        this.table.bodyComponent['rowExpansions'] = [];

        if (this.groupRowsBy) {
          // Re-group data
          const groups = this.rows.reduce((groups, row) => {
            const key = row[this.groupRowsBy] || '';
            if (!groups[key]) {
              groups[key] = [];
            }
            groups[key].push(row);
            return groups;
          }, {});

          // Force update internal grouped rows
          if (this.table.bodyComponent['groupedRows']) {
            this.table.bodyComponent['groupedRows'] = Object.keys(groups).map(key => {
              return {
                key: key,
                value: groups[key]
              };
            });
          }

          // Update states and refresh
          this.initGroupExpansionState();
          this.table.bodyComponent.updateRows();
        }

        // Update selected rows if needed
        if (this.selected?.length > 0) {
          const compareEqual = this.rows.filter(x =>
            this.selected.map(y => JSON.stringify(y)).includes(JSON.stringify(x))
          );
          if (compareEqual?.length > 0) {
            const compareDifferent = this.selected.filter(x =>
              !compareEqual.map(y => JSON.stringify(y)).includes(JSON.stringify(x))
            );
            this.selected = [...compareEqual, ...compareDifferent];
          }
        }

        this.updateGroupHeaderWidth();
        this.table.recalculate();
        this.table['cd'].markForCheck();
      }, 1);
    }

    setTimeout(() => {
      //this.table._offsetX.next(1);
    }, 1);
  }
  setTotalRecord(total: number) {
    setTimeout(() => {
      let page: Page = Object.assign(this.page);
      page.totalElements = total;
      this.page = page;
      this.isLoadingTotal = false;
    }, 1);
  }
  onClickDelete(row, rowIndex) {
    if (this.customDeleteFunction.observers.length > 0) {
      this.customDeleteFunction.emit({
        row: row,
        rowIndex: rowIndex
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: 'Do you wish to delete this item?'
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.onDelete.emit({
            row: row,
            rowIndex: rowIndex
          });
        }
      });
    }

  }
  // When using the usingCustomEdit = true then user must provide a customEdit method else set
  // it to false to run default setting (default of usingCustomEdit = false).
  onClickEdit(row, rowIndex) {
    // 2022-09-08 tienlm mod start
    if (this.usingCustomEdit) {
      if (this.customEditFunction.observers.length > 0) {
        this.customEditFunction.emit({ row: row, rowIndex: rowIndex });
      }
    } else {
      const dialogRef = this.dialog.open(this.addEditComponet, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          model: row,
          action: TblActionType.Edit,
          ...this.updateAdditionalData
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response != null) {
          // this.page.pageNumber = Math.floor(rowIndex / this.page.size);
          if (typeof response == "boolean") {
            this.refreshTable(response)
            this.editEvent.emit();
          } else this.refreshTable();
        }
      });
    }
    // 2022-09-08 tienlm mod end
  }
  clearAllFilter() {

    this.page.filter = [];
    this.columnsTable.forEach((col: any) => {
      col.filterValue = null;
      col.filterOperator = undefined;
    })

    this.columns.forEach(x => {
      x.filterValue = null;
    });
    //remove the filter/sort configuration at column header
    this.actionColumn = [];
    this.table.sorts = [];

    this.columns = [];
    //remove filter/sort configuration at cache data API
    this.page.filter = [];
    this.page.orders = [];

    // this.table.ngOnInit();
    this.ngOnInit();
    this.refreshTable(true);
  }

  dynamicRowHeight(row): number {
    if (row.customRowHeight != null)
      return row.customRowHeight;

    return 35;
  }

  isShow(prop) {
    return this.columns.find(x => x.prop == prop);
  }
  displayColumnChange(event, prop, index) {
    if (event.checked) {
      var tempIndex = this.actionColumn.length + (index);
      var currentColumn = this.columnsTable.find((x: any) => x.prop == prop);
      this.columns.splice(tempIndex, 0, currentColumn);
      this.columns = [...this.columns];
    } else {
      this.columns = this.columns.filter(x => x.prop != prop);
    }
  }

  // Update value option filter when select.
  onChangeFilterOperator(value, column) {
    let columnNeedUpdateIndex = this.columns.findIndex(x => x.prop === column.prop);
    this.columns[columnNeedUpdateIndex].filterOperator = value.filterOperator;
    if (this.columns[columnNeedUpdateIndex].filterOperator) {
      let findColumn = this.dateFilterComponent?.find(x => x.columnName == column.name);
      if (findColumn) {
        this.columns[columnNeedUpdateIndex]['isExternal'] = "showtable";
      }
    }

    //Get the new value for the column
    column = this.columns[columnNeedUpdateIndex];

    if (this.columns[columnNeedUpdateIndex].filterValue && this.columns[columnNeedUpdateIndex].filterValue.length > 0 ||
      (column.filterOperator == PrimasAllFilterOperator.IsEmpty || column.filterOperator == PrimasAllFilterOperator.IsNotEmpty
        || column.filterOperator == PrimasMailActionFilterOperator.NoEmailActions)) {
      column.filterOperator = this.columns[columnNeedUpdateIndex].filterOperator;
      // 2022-09-15 tienlm add end
      // this.columns.find(x => {
      //   if (x.prop === column.prop) {
      //     x.filterOperator = value.filterOperator;
      //   }
      // })
      // special case for email actions:

      if (column.filter.filterType === PrimasFilterType.MailActions) {
        if (column.filterOperator !== PrimasMailActionFilterOperator.EmailActions && (column.filterValue && Array.isArray(column.filterValue))) {
          column.filterValue = null;
          if (this.page.filter) {
            this.page.filter = this.page.filter.filter(x => x.prop != column.prop);
          }
        } else if (column.filterOperator === PrimasMailActionFilterOperator.EmailActions && (column.filterValue && !Array.isArray(column.filterValue))) {
          column.filterValue = null;
          if (this.page.filter) {
            this.page.filter = this.page.filter.filter(x => x.prop != column.prop);
          }
        }
      }
      const prop = column.prop;
      this.page.filter = Helper.formatFilter(
        this.page.filter,
        prop,
        value != PrimasAllFilterOperator.IsEmpty &&
          value != PrimasAllFilterOperator.IsNotEmpty ? column.filterValue : null,
        column.filter.filterType, column.filterOperator,
        column.filter
      );
      if (column.filterOperator == PrimasDatetimeFilterOperator.Between || column.filterOperator == PrimasDatetimeFilterOperator.Quarter ||
        column.filterOperator == PrimasMailActionFilterOperator.EmailActions) return;
      this.refreshTable(true);
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    console.log("drop now");
  }
  onActivate(event) {
    if (event.type == 'click') {
      this.activate.emit(event.row);
    }

    // hover the row
    if (event.type === 'mouseenter' && this.isCatchEventHover) {
      this.currentHoveredRow = event.row;

      if (this.hoverTimer) {
        clearTimeout(this.hoverTimer);
      }

      this.hoverTimer = setTimeout(() => {
        if (this.currentHoveredRow === event.row) {
          this.onHoverRow.emit(event.row);
        }
      }, this.HOVER_DELAY);
    }

    // leave the row
    if ((event.type === 'mouseleave' || event.type === 'mouseout') && this.isCatchEventHover) {
      // Clear the timer when mouse leaves
      if (this.hoverTimer) {
        clearTimeout(this.hoverTimer);
        this.hoverTimer = null;
      }
      this.currentHoveredRow = null;
    }
  }

  onMouseLeave(event) {
    // Clear the timer when mouse leaves
    if (this.hoverTimer) {
      clearTimeout(this.hoverTimer);
      this.hoverTimer = null;
    }
    this.currentHoveredRow = null;
  }

  // 2022-09-23 tienlm add start
  reloadTableHeader() {
    // reload table:
    this.columns = [...Helper.getColumnsPermission(this.columns)];
    if (this.columns && this.columns.length > 0) {
      for (let index = 0; index < this.columns.length; index++) {
        const column = this.columns[index];
        if (typeof column === 'object' && column.filter && column.filter.filterValue instanceof Observable) {
          this.columns[index].$$id = undefined;
          this.columns.splice(index, 1);
          this.columns.splice(index, 0, column);
        }
      }
      this.columns = [...this.columns];
    }
  }
  // 2022-09-23 tienlm add start
  onEmailActionFilter(event, column) {
    const prop = column.prop;
    var currentValue = column.filterValue ?? [];
    if (!Helper.arrayEquals(currentValue, event)) {
      column.filterValue = event;
      this.page.filter = Helper.formatFilter(this.page.filter, prop, event, PrimasFilterType.MailActions, column.filterOperator);
      this.refreshTable(true);
    }
  }

  setDataClientSide(result: PagedData<any>) {
    if (result) {
      this.isLoading--;
      //this.page = result.page;
      this.page.viewId = null;
      this.rows = result.data;
      //  if(!result.data) this.table.offset = Number.POSITIVE_INFINITY;
      setTimeout(() => {
        if (this.selected && this.selected.length > 0) {
          const compareEqual = this.rows.filter(x => this.selected.map(y => JSON.stringify(y)).includes(JSON.stringify(x)));
          if (compareEqual && compareEqual.length > 0) {
            const compareDifferent = this.selected.filter(x => !compareEqual.map(y => JSON.stringify(y)).includes(JSON.stringify(x)));
            this.selected = [...compareEqual, ...compareDifferent];
          }
        }
      }, 1);
    }

    setTimeout(() => {
      //this.table._offsetX.next(1);
    }, 1);
  }

  cleanTableSource() {
    this.rows = [];
    this.page.totalElements = 0;
  }

  mappingHeaderColumn() {
    var reloadHeader: boolean = false;

    try {
      if (this.page && this.page.filter && this.page.filter.length > 0) {
        reloadHeader = true;
        this.page.filter.map(x => {
          try {
            var columnProp = this.columns.find(y => y.prop == x.prop);
            if (columnProp) {
              columnProp.filterValue = x.value;
              columnProp.filterOperator = x.filterOperator;
            }
          } catch (ex) {
            console.warn(ex);
          }
        });
      }

      if (this.page && this.page?.orders?.length > 0) {
        reloadHeader = true;
        try {
          const sorts = this.page.orders.slice().map(x => ({
            dir: x.sortDir > 0 ? 'desc' : 'asc',
            prop: x.sort
          }));

          if (sorts && sorts.length > 0)
            this.table.sorts = [...sorts];
        } catch (ex) {
          console.warn(ex);
        }
      }
    } catch (ex) {
      reloadHeader = false;
      console.warn(ex);
    }

    if (reloadHeader) this.reloadTableHeader();
  }

  // ! function to attach ng-template to table header
  public attachFilterTemplate() {
    this.columns.forEach((column: any, index) => {

      if (column.filter && !column.headerTemplate) {
        column.headerTemplate = this.filterHeader;
      }
      // add Filter Operator default when start.
      if (column.filter && column.filterOperator == undefined) {
        column.filterOperator = null;
      }
    });
    this.mappingHeaderColumn();
  }

  initGroupExpansionState() {
    if (!Helper.isNullOrEmpty(this.groupRowsBy) && this.rows?.length) {
      // Reset all states
      this.allGroupStates = {};
      this.groupStates = {};

      const uniqueGroupIds = Array.from(
        new Set(
          this.rows
            .filter(row => row?.[this.groupRowsBy] != null)
            .map(row => row?.[this.groupRowsBy])
        )
      ) || [];

      // Set expanded states
      uniqueGroupIds.forEach(groupId => {
        this.allGroupStates[groupId] = true;
        this.groupStates[groupId] = true;
      });

      // Force render tất cả rows
      setTimeout(() => {
        // Force table update trước
        this.table.bodyComponent.updateRows();
        this.updateGroupHeaderWidth();
        
        // Force update virtual scroll offset
        if (this.table.bodyComponent['scroller']) {
          this.table.bodyComponent['scroller'].updateOffset();
          this.table.bodyComponent['scroller'].setOffset(0); // Reset scroll position
        }
        
        // Force recalculate và update view
        this.table.recalculate();
        this.table['cd'].markForCheck();

        // Sau đó mới show rows
        setTimeout(() => {
          const allRows = this.table?.element?.querySelectorAll('datatable-body-row');
          if (allRows?.length) {
            allRows.forEach((row: HTMLElement) => {
              row.style.removeProperty('display');
            });
          }
          // Force update lần nữa để đảm bảo
          this.table.bodyComponent.updateRows();
          this.table['cd'].markForCheck();
        }, 100);
      }, 100);
    }
  }

  toggleExpandGroup(group: any) {
    if (group) {
      // Cập nhật cả 2 objects
      this.groupStates[group.key] = !this.groupStates[group.key];
      this.allGroupStates[group.key] = this.groupStates[group.key];

      // Force table to refresh và recalculate
      setTimeout(() => {
        // Manually update DOM
        const element = this.table?.element;
        const groupRows = element?.querySelector(`[primas-table-data-group-id="${group?.key}"]`);
        const groupContainer = groupRows?.parentElement?.parentElement;

        if (groupContainer) {
          // Get all rows for this group
          const groupData = this.rows.filter(row => row[this.groupRowsBy] === group.key);
          const bodyRows = groupContainer.querySelectorAll('datatable-body-row');

          if (bodyRows?.length) {
            this.updateGroupHeaderWidth();

            bodyRows.forEach((row: HTMLElement, index: number) => {
              if (this.groupStates[group.key]) {
                // Show row and update its data
                row.style.removeProperty('display');
              } else {
                row.style.display = 'none';
              }
            });

            // Force update all rows
            this.table.bodyComponent.updateRows();
          }

          // Force table recalculate after DOM updates
          this.table.recalculate();
          this.table['cd'].markForCheck();
        }
      });
    }
  }

  // Add helper method to force group data refresh
  private refreshGroupData(groupKey: string) {
    const groupData = this.rows.filter(row => row[this.groupRowsBy] === groupKey);
    if (groupData.length) {
      // Force table to refresh these rows
      this.table.bodyComponent.updateRows();
    }
  }

  groupToggleState(group: any): boolean {
    let result = false;
    if (group) {
      result = this.groupStates[group.key];
    }
    return result;
  }

  private updateGroupHeaderWidth() {
    if (!this.groupRowsBy) return;

    const tableElement = this.table?.element;
    if (!tableElement) return;

    const tableWidth = (tableElement.querySelector('.datatable-header-inner') as HTMLElement)?.offsetWidth;

    const groupHeaders = tableElement.querySelectorAll('datatable-row-wrapper');
    if (groupHeaders?.length) {
      groupHeaders.forEach((element: HTMLElement) => {
        element.style.width = `${tableWidth}px`;
        element.style.maxWidth = `${tableWidth}px`;
      });
    }
  }
}
