import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactActionLog } from '../contact-activity.model';
import { Helper } from 'src/app/shared/utility/Helper';
import { CallEventService } from '../../../call-event-management/call-event-service.service';
import { CallEventModel } from '../../../call-event-management/call-event-model';

@Component({
  selector: 'app-callflow-event-detail',
  templateUrl: './callflow-event-detail.component.html',
  styleUrls: ['./callflow-event-detail.component.scss']
})
export class CallflowEventDetailComponent implements OnInit {

  isLoading: boolean = true;
  phoneCallDuration: string;
  callflowPhoneCallId: string;
  contactActionLog: ContactActionLog;
  listCallEvents: CallEventModel[] = [];

  constructor(
    private callEventService: CallEventService,
    public dialModalRef: MatDialogRef<CallflowEventDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.contactActionLog = data.contactActionLog || this.contactActionLog;
  }

  ngOnInit(): void {
    this.setupData();
  }

  setupData() {
    if (this.contactActionLog) {
      var logPhoneCallData = JSON.parse(this.contactActionLog.logInfo1);
      if (logPhoneCallData) {
        this.phoneCallDuration = Helper.getCallTime(logPhoneCallData.duration || 0);
        this.callflowPhoneCallId = logPhoneCallData.referenceLogId;
      }

      if (!Helper.isNullOrEmpty(this.callflowPhoneCallId)) {
        this.isLoading = true;
        this.callEventService.getCallflowPhoneCallLog(this.callflowPhoneCallId).subscribe({
          next: (res) => {
            if (res.result) this.listCallEvents = res.result;
          },
          complete: () => { this.isLoading = false; }
        })
      }
    }
  }

  closeDialog() {
    this.dialModalRef.close(false);
  }
}
