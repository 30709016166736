import { D } from '@angular/cdk/keycodes';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { ProfileDetailModel, RepliedEmailData } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { LinkscopeEmailComponent } from 'src/app/modules/admin/profile-management/profile-detail/action-block/linkscope-email/linkscope-email.component';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { TableEntity } from 'src/app/shared/enums/table-entities.enum';
import { BuyerModel } from 'src/app/shared/models/buyer.model';
import { EmailAttachment } from 'src/app/shared/models/email-attachment.model';
import { ActivityLogService } from 'src/app/shared/services/activity-log.service';
import { Helper } from 'src/app/shared/utility/Helper';

@Component({
  selector: 'app-show-replied-email',
  templateUrl: './show-replied-email.component.html',
  styleUrls: ['./show-replied-email.component.scss']
})
export class ShowRepliedEmailComponent implements OnInit, OnDestroy {

  @Input() activityLogId: number;
  // profile model
  @Input() profileModel: ProfileDetailModel;
  // flag to open email with no entity
  @Input() isNoEntity = false;
  // When NO ENTITY is set, MUST PASS targetEmail row to get incoming email
  @Input() targetEmail: string;
  constructor(
    private profileService: ProfileService,
    public dialModalRef: MatDialogRef<ShowRepliedEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private toast: NbToastrService,
    private activityLogService: ActivityLogService
  ) {
    this.activityLogId = data.activityLogId ?? this.activityLogId;
    this.profileModel = data.profileModel ?? this.profileModel;
    this.action = data.action ?? this.action;
    this.email = data.emailSend ?? this.email;
    this.buyerModel = data.buyerModel ?? this.buyerModel;
    this.entity = data.entity ?? this.entity;
    this.isNoEntity = data.isNoEntity ?? this.isNoEntity;
    this.isGetInboxed = data.isGetInboxed ?? this.isGetInboxed;
    this.targetEmail = data.targetEmail ?? this.targetEmail;
    if (this.email && this.entity.toLowerCase() == TableEntity.Profile.toLowerCase())
      this.email.body = this.replacePIDEmail(this.email.body);
  }

  ngOnDestroy(): void {
    this.subscribe?.unsubscribe();
  }

  /* isGetInboxed is a mode to change from getRepliedEmailData to GetInboxEmailData ( new api to get reply data in email inbox management screen) */
  isGetInboxed = false;
  loading = false;
  email: RepliedEmailData;
  error = false;
  subscribe: Subscription;
  selectedReplyEmail: number;
  action: string = "reply" // reply or send
  //2022-05-10 tienlm add start
  entity = 'profile';
  buyerModel: BuyerModel;
  attachments: EmailAttachment[] = null;
  typeImage: string[] = ['tif', 'tiff', 'bmp', 'jpg', 'jpeg', 'gif', 'png', 'eps', 'raw', 'cr2', 'nef', 'orf', 'sr2'];
  typeDoc: string[] = ['doc', 'docx'];
  typeExcel: string[] = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xml', 'xlam', 'xla', 'xlw', 'xlr'];

  //2022-05-10 tienlm add end
  ngOnInit() {
    if (this.action == "reply") {
      this.loading = true;
      this.subscribe = (!this.isGetInboxed ? this.profileService.getRepliedEmailData(this.activityLogId) : this.profileService.getInboxEmailData(this.activityLogId)).subscribe(res => {
        this.loading = false;
        if (res.result) {
          this.email = res.result;
          if (this.email && this.entity.toLowerCase() == TableEntity.Profile.toLowerCase())
            this.email.body = this.replacePIDEmail(this.email.body);
          if (this.email.message) {
            this.error = true;
            this.toast.danger(this.email.message, `Error Code: ${this.email.error}`);
          }
        }
      });
    }

    this.getEmailAttachment();
  }

  closeDialog() {
    this.dialModalRef.close();
  }

  onClickedReply() {
    if (this.entity == TableEntity.Profile.toLowerCase()) {
      if (this.profileModel.contact) {
        this.closeDialog();
        const dialogRef = this.dialog.open(LinkscopeEmailComponent, {
          disableClose: true,
          autoFocus: false,
          data: {
            profileModel: this.profileModel,
            emailSubject: this.email.title.startsWith('Re:') ? this.email.title : `Re: ${this.email.title}`,
            isShowSenderId:true
          }
        });
      }
      else this.toast.danger("Can't get contact for this profile.")
    } else if (this.entity == TableEntity.Buyer.toLowerCase()) {
      if (this.buyerModel.contact) {
        this.closeDialog();
        const dialogRef = this.dialog.open(LinkscopeEmailComponent, {
          disableClose: true,
          autoFocus: false,
          data: {
            buyerModel: this.buyerModel,
            emailSubject: this.email.title.startsWith('Re:') ? this.email.title : `Re: ${this.email.title}`,
            entity: this.entity,
            isShowSenderId:true
          }
        });
      }
      else this.toast.danger("Can't get contact for this buyer.")
    } else if (this.entity.toLowerCase() === TableEntity.NoEntity.toLowerCase()) {
      // new Reply goes here
      this.closeDialog();
      const dialogRef = this.dialog.open(LinkscopeEmailComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          targetEmail: this.targetEmail,
          emailSubject: this.email.title.startsWith('Re:') ? this.email.title : `Re: ${this.email.title}`,
          entity: TableEntity.NoEntity,
          isShowSenderId:true
        }
      });
    }
  }

  replacePIDEmail(body: string) {
    if (body && body.trim().length > 0) {
      body = body.replace(/\[PID\]/g, this.profileModel?.profileId ?? '[PID]');
    }

    return body;
  }

  getEmailAttachment() {
    if (this.activityLogId) {
      this.activityLogService.getEmailAttachment(this.activityLogId).subscribe(resp => {
        this.attachments = [];
        if (resp.result && resp.result.length > 0) {
          this.attachments = resp.result;
          this.attachments.forEach(x => {
            x.nameUrl = Helper.getLastNameUrl(x.attachmentUrl, 12, true)
            x.extensionUrl = Helper.getExtensionUrl(x.attachmentUrl);
          });
        }
      });
    }
  }

  openAttachment(attach: EmailAttachment) {
    var splitUrlExtension = attach.attachmentUrl.split(".");
    if (splitUrlExtension && splitUrlExtension.length > 1) {
      var checkExtension = splitUrlExtension[splitUrlExtension.length - 1];
      // if (this.typeImage.includes(checkExtension))
      //   window.open(`${attach.attachmentUrl}`, '_blank');
      // else window.open(`https://docs.google.com/gview?url=${attach.attachmentUrl}&embedded=true`, '_blank');
      window.open(`${attach.attachmentUrl}`, '_blank');
    }
  }
}
