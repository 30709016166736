<h2 mat-dialog-title>Upload Video</h2>
<div class="form-group">
    <form [formGroup]="frmVideo">
        <mat-form-field appearance="legacy">
            <mat-label>Video Name</mat-label>
            <input matInput type="text" formControlName="inputName">
            <mat-error
                *ngIf="frmVideo.controls.inputName.errors && (frmVideo.controls.inputName.dirty || frmVideo.controls.inputName.touched)">
                {{frmVideo.controls.inputName['errorMessage']}}
            </mat-error>
        </mat-form-field>
    </form>
    <div class="img-space">
        <ngx-dropzone (change)="handleFileInput($event)" accept="video/mp4,video/mkv,video/*" [multiple]="isMultiple">
            <ngx-dropzone-label>Drag your files here or click in this area.</ngx-dropzone-label>
            <ngx-dropzone-video-preview *ngFor="let video of listVideo" [file]="video" [removable]="true"
                (removed)="onRemove(video)">
            </ngx-dropzone-video-preview>
        </ngx-dropzone>
    </div>
    <mat-error *ngIf="messageError !== ''">
        {{messageError}}
    </mat-error>
</div>
<div class="d-flex flex-row justify-content-between">
    <button mat-button mat-dialog-close (click)="closeDialog()" color="warn"> Cancel</button>
    <button mat-button color="primary" (click)="uploadVideo()"
        [disabled]="(listVideo.length == 0 || spotlightrKey === '' || !frmVideo.valid) ? true : false">Upload</button>
</div>