import { Component, Inject, OnInit } from '@angular/core';
import { ProfileCompareDetailModel, ProfileCompareModel } from './profile-compare.model';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/shared/utility/Helper';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { CategoryManagementService } from '../../../category-management/category-management.service';

@Component({
  selector: 'app-profile-comparison-detail',
  templateUrl: './profile-comparison-detail.component.html',
  styleUrls: ['./profile-comparison-detail.component.scss']
})
export class ProfileComparisonDetailComponent implements OnInit {
  profileCompareData: ProfileCompareModel;
  isLoading: boolean = false;
  profileCompareDetailData: ProfileCompareDetailModel[] = [];
  childData: ProfileCompareDetailModel[] = [];
  editorOptions = {
    toolbar: false
  };
  screenName: string = '';
  editorBlogs = { toolbar: [...QuillConfiguration.toolbar.filter((x: any) => !x.includes('link'))] };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private categoryService: CategoryManagementService
  ) {
    this.profileCompareData = data.profileCompareData;
    this.screenName = data.screenName;
   }

  ngOnInit() {
    this.setUpData();
  }

  async setUpData() {
    try {
      if (!this.profileCompareData) {
        return;
      }
  
      this.profileCompareDetailData = [];
      
      const mainDifferences = Array.isArray(this.profileCompareData.mainDifferences) 
        ? this.profileCompareData.mainDifferences 
        : Object.entries(this.profileCompareData.mainDifferences || {}).map(([key, value]) => ({ key, value }));
  
      const diffDifferences = Array.isArray(this.profileCompareData.diffDifferences)
        ? this.profileCompareData.diffDifferences
        : Object.entries(this.profileCompareData.diffDifferences || {}).map(([key, value]) => ({ key, value }));
  
      // Use Promise.all to wait for all async operations
      await Promise.all(mainDifferences.map(async mainItem => {
        const diffItem = diffDifferences?.find(diff => diff.key === mainItem.key) || null;
  
        let childDataArray = [];
        let oldValue = mainItem.value as string;
        let newValue = diffItem?.value as string;
  
        // Handle special fields that need async processing
        if (mainItem.key === 'ParentId') {
          oldValue = await this.getCategoryName(mainItem.value as string);
          newValue = diffItem?.value ? await this.getCategoryName(diffItem.value as string) : '';
        }
  
        // Handle child data processing
        if (
          mainItem.key === 'Testimonials' ||
          mainItem.key === 'BioRewrite' ||
          mainItem.key === 'Reason' ||
          mainItem.key === 'Media' ||
          mainItem.key === 'ProfileCategory' ||
          mainItem.key === 'Faqs'
        ) {
          childDataArray = await this.handleDataChild(mainItem.value as string, diffItem?.value as string, mainItem.key);
        }
  
        this.profileCompareDetailData.push({
          fieldName: this.convertToDisplayName(mainItem.key),
          oldValue,
          newValue,
          isDisplayDownLine: mainItem.key === 'SetList' ||  
                            mainItem.key === 'Reason' ||
                            mainItem.key === 'Rider' || 
                            mainItem.key === 'Notes' || 
                            mainItem.key === 'Bio' || 
                            mainItem.key === 'BioRewrite' ||
                            mainItem.key === 'TopDescription' ||
                            mainItem.key === 'Description' ||
                            mainItem.key === 'HyperDescription1' ||
                            mainItem.key === 'HyperDescription2' ||
                            mainItem.key === 'HyperDescription3' ||
                            mainItem.key === 'Faqs' ||
                            mainItem.key === 'PreviousClients',
          childData: childDataArray
        });
      }));
    } catch (ex) {
      console.error(ex);
    }
  }
  async handleDataChild(mainData: string, diffData: string, typeName: string = '') {
    try {
      if(typeName === 'Media') {
        const mainDataArray = JSON.parse(mainData || '[]');
        const diffDataArray = JSON.parse(diffData || '[]');
        const combinedDifferences = [
          ...mainDataArray.filter(mainItem => 
            !diffDataArray.find(diffItem => 
              JSON.stringify(mainItem) === JSON.stringify(diffItem)
            )
          ).map(mainItem => {
            return Object.keys(mainItem)
              .filter(key => mainItem[key] !== null)
              .map(key => ({
                fieldName: this.convertToDisplayName(key),
                oldValue: mainItem[key] ? mainItem[key] : '',
                newValue: '',
                isDisplayDownLine: false
              }));
          }).flat(),
          
          ...diffDataArray.filter(diffItem => 
            !mainDataArray.find(mainItem => 
              JSON.stringify(diffItem) === JSON.stringify(mainItem)
            )
          ).map(diffItem => {
            return Object.keys(diffItem)
              .filter(key => diffItem[key] !== null)
              .map(key => ({
                fieldName: this.convertToDisplayName(key),
                oldValue: '',
                newValue: diffItem[key],
                isDisplayDownLine: false
              }));
          }).flat()
        ];
      
        return combinedDifferences;
      }
  
      const mainDataArray = JSON.parse(mainData || '[]').map((item, index)=>({
        ...item,
        id: index + 1
      }));
      const diffDataArray = JSON.parse(diffData || '[]').map((item, index)=>({
        ...item,
        id: index + 1
      }));
  
      const promises = diffDataArray.map(async diffItem => {
        const mainItem = mainDataArray?.find(main => main.id === diffItem.id) || null;
        
        const fieldPromises = Object.keys(diffItem)
          .filter(key => {
            if(key === 'id') return false;
            const hasChange = mainItem?.[key] !== diffItem[key];
            const hasValue = mainItem?.[key] || diffItem[key];
            return hasValue && hasChange;
          })
          .map(async key => {
            let oldValue = '';
            let newValue = '';
            
            if(key === "CategoryId") {
              oldValue = mainItem?.[key] ? await this.getCategoryName(mainItem[key]) : '';
              newValue = await this.getCategoryName(diffItem[key]);
            } else {
              oldValue = mainItem?.[key] || '';
              newValue = diffItem[key];
            }
            
            return {
              fieldName: this.convertToDisplayName(key),
              oldValue,
              newValue,
              isDisplayDownLine: key === 'Answer'
                                || key === 'Rewrite'
            };
          });
  
        return Promise.all(fieldPromises);
      });
  
      const results = await Promise.all(promises);
      return results.reduce<ProfileCompareDetailModel[]>((acc, curr: ProfileCompareDetailModel[]) => [...acc, ...curr], []);
      
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  setDesc(quill) {
    if (quill && quill.container) {
      quill.container.classList.add("custom-desc-blog");
    }
  }

  convertToDisplayName(value: string): string {
    switch (value) {
      case 'TopDescription':
        return 'First Description';
      case 'Description':
        return this.screenName === 'Category' ? 'Second Description' : Helper.createSpaceString(value);
      case 'MediaTitle':
        return 'Primary image alt text';
      case 'HyperDescription1':
        return 'Description Left Blog';
      case 'HyperDescription2':
        return 'Description Right Blog';
      case 'HyperDescription3':
        return 'Description';
      case 'AlternativeDisplayName':
        return 'H1';
      case 'CategoryId':
        return 'Category';
      case 'ParentId':
        return 'Parent Category';
      case 'Active1':
        return 'Active (Description Left Blog)';
      case 'Active2':
        return 'Active (Description Right Blog)';
      case 'Active3':
        return 'Active (Description)';
      case 'BioRewrite':
        return 'Display Bio';
      default:
        return Helper.createSpaceString(value);
    }
  }

  async getCategoryName(categoryId: string){
    if(categoryId === '' || categoryId === null || categoryId === undefined || categoryId === '[]'){
      return '';
    }
    const category = await this.categoryService.getCategoryById(categoryId).toPromise();
    if(category.result){
      return category.result.categoryName;
    }
    return '';
  }

}
