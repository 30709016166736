import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { NbDateService, NbPopoverDirective } from '@nebular/theme';
import moment from 'moment';
import { Moment } from 'moment';
import { DropDownValue } from 'src/app/modules/admin/summary-report/summary-report.component';
import { environment } from 'src/environments/environment';
import { Helper } from '../../utility/Helper';
import { DISPLAY_INPUT_DATE, GENERAL_DATE_TIME_FORMATS } from '../stand-alone-component/primas-custom-date-time-picker/date-format.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-datetime-filter',
  templateUrl: './datetime-filter.component.html',
  styleUrls: ['./datetime-filter.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide: MAT_DATE_FORMATS, useValue: GENERAL_DATE_TIME_FORMATS }]
})
export class DatetimeFilterComponent implements OnInit, OnChanges {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  @Input() columnName;
  @Input() operationMode;
  @Input() set value(tempValue) {
    if (tempValue) {
      if (Helper.verifyJson(tempValue)) {
        const dateRangeData = JSON.parse(tempValue);
        this.range.controls.start.setValue(this.dateService.parse(dateRangeData.startDate, environment.formatDateTimeZone));
        this.range.controls.end.setValue(this.dateService.parse(dateRangeData.endDate, environment.formatDateTimeZone));
        this.datetime.setValue(null);

      }
      else {
        if (tempValue.constructor.name == 'Array') {
          // 2023_06_14 ducqm start add setTimeout to load data
          setTimeout(() => {
            this.datetime.setValue(tempValue);
          }, 1);
          // 2023_06_14 ducqm end add setTimeout to load data
        }
        else {
          this.datetime.setValue(this.dateService.parse(tempValue, environment.formatDateTimeZone));
        }
        this.range.controls.start.setValue(null);
        this.range.controls.end.setValue(null);
      }
    } else {
      this.datetime.setValue(null);
      this.range.controls.start.setValue(null);
      this.range.controls.end.setValue(null);
    }

  };
  @Output() onFilter = new EventEmitter<any>();
  datetime = new FormControl();
  from = new FormControl()
  to = new FormControl()
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  quarterList: DropDownValue[] = [
    { text: 'Q1', value: '1' },
    { text: 'Q2', value: '2' },
    { text: 'Q3', value: '3' },
    { text: 'Q4', value: '4' }
  ]
  constructor(protected dateService: NbDateService<Date>,
    private datePipe: DatePipe) { }
  ngOnChanges(changes: SimpleChanges): void {
    const modeChange = changes.operationMode;
    if (modeChange && modeChange.previousValue && modeChange.currentValue && modeChange.previousValue == 'Quarter' && modeChange.previousValue != modeChange.currentValue) {
      this.datetime.setValue(null);
    }
    if (modeChange && modeChange.previousValue && modeChange.currentValue && modeChange.currentValue == 'Quarter' && modeChange.previousValue != modeChange.currentValue) {
      this.datetime.setValue(null);
    }
  }

  ngOnInit() {
  }

  // toggleDatetimePop() {
  //   if (this.popover.isShown) {
  //     this.popover.hide();
  //   } else {
  //     this.popover.show();
  //   }
  // }

  onClickSubmit(data: { value: Moment, isInitial: boolean } = null) {
    // set default date if not select
    let currentSelectedDateTime: any = data?.value;
    this.datetime.setValue(currentSelectedDateTime);

    if (moment.isMoment(currentSelectedDateTime)) {
      currentSelectedDateTime = (<Moment>this.datetime.value).utc().toDate();
    }

    this.onFilter.emit({
      datetime: this.dateService.format(currentSelectedDateTime, environment.formatDateTimeZone),
      // from: this.dateService.format(this.from.value, environment.formatDateTimeZone),
      // to: this.dateService.format(this.to.value, environment.formatDateTimeZone)
    })
    //this.toggleDatetimePop();
  }

  onClickDateRangeSubmit(event,inputType) {
    if(event.targetElement.value){
      if(inputType == 'matStartDate' && !this.range.controls.start.value){
        const dateObj = new Date(event.targetElement.value);
        this.range.controls.start.setValue(dateObj);
      }
      else if(inputType == 'matEndDate' && !this.range.controls.end.value){
        const dateObj = new Date(event.targetElement.value);
        this.range.controls.end.setValue(dateObj);
      }
    }
    let currentStartDate = this.range.controls.start.value;
    let currentEndDate = this.range.controls.end.value;
    if (currentStartDate && currentEndDate) {
      if (moment.isMoment(currentStartDate) && moment.isMoment(currentEndDate)) {
        currentStartDate = (<Moment>this.range.controls.start.value).utc().toDate();
        currentEndDate = (<Moment>this.range.controls.end.value).utc().toDate();
      }
    }

    if (this.range.controls.start.value && this.range.controls.end.value) {
      const startDate = this.dateService.format(currentStartDate, environment.formatDateTimeZone);
      const endDate = this.dateService.format(currentEndDate, environment.formatDateTimeZone);

      this.onFilter.emit({
        datetime: JSON.stringify({ startDate, endDate })
      })
    } else {
      this.refreshValue();
    }
  }
  refreshValue() {
    if (!this.range?.controls?.start?.value && !this.range?.controls?.end?.value) {
      this.onFilter.emit({
        datetime: "",
        noFilter: true
      })
    }
  }
  onClickDropdown(event) {
    if (!event) {
      const value = this.datetime.value ?? [];
      this.onFilter.emit({ datetime: value });
    }
  }

}
