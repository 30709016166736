import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Helper } from 'src/app/shared/utility/Helper';

@Pipe({
  name: 'maintenanceDatePipe'
})
export class MaintenanceDatePipe implements PipeTransform {

  transform(value: any, type: any): any {
    let result;
    try {
      switch (type) {
        case ("color"): {
          result = this.color(value);
          break;
        }
        case ("text"): {
          result = this.text(value);
          break;
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }

  color(value) {
    let result;
    try {
      let dateRange: MaintenanceType = this.dateCompare(value);
      switch (dateRange) {
        case (MaintenanceType.Below1Month): {
          result = "#dc3545";
          break;
        }
        case (MaintenanceType.Between1And3Month): {
          result = "#ff8c1c";
          break;
        }
        case (MaintenanceType.Above3Month): {
          result = "#28a745";
          break;
        }
        default: {
          result = "#c0c0c0";
          break;
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }

  text(value) {
    let result;
    try {
      let dateRange: MaintenanceType = this.dateCompare(value);
      switch (dateRange) {
        case (MaintenanceType.None): {
          result = "This item has no scheduled maintenance date";
          break;
        }
        default: {
          result = `Scheduled maintenance date: ${moment(value).format('MM/DD/YYYY')}`;
          break;
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }

  private dateCompare(value) {
    let result: MaintenanceType = MaintenanceType.None;
    try {
      if (Helper.isNullOrEmpty(value)) {
        return result;
      }

      //Only get the "dd/mm/yyyy" part of the date
      const currentDate = moment().startOf("day");
      const lastMaintenanceDate = moment(value).startOf("day");

      //Get difference in months
      const diffDateInt = lastMaintenanceDate.diff(currentDate, 'day', true);

      switch (true) {
        case (diffDateInt <= 30): {
          result = MaintenanceType.Below1Month;
          break;
        }
        case (diffDateInt > 30 && diffDateInt <= 90): {
          result = MaintenanceType.Between1And3Month;
          break;
        }
        case (diffDateInt > 90): {
          result = MaintenanceType.Above3Month;
          break;
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }
}

enum MaintenanceType {
  None = "None", //Case when MaintenanceDate is empty
  Below1Month = "Below1Month",
  Between1And3Month = "Between1And3Month",
  Above3Month = "Above3Month",
}
