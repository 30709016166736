import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ReturnResult } from '../../models/return-result';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-dynamic-content-location',
  templateUrl: './dynamic-content-location.component.html',
  styleUrls: ['./dynamic-content-location.component.scss']
})
export class DynamicContentLocationComponent implements OnInit, OnChanges, OnDestroy{

  @Input() label: string;
  @Input() control: FormControl;
  @Input() displayKey: string;
  @Input() searchArrayAPI: Observable<ReturnResult<any>>;
  @Input() options = [];
  @Input() errorMessage: string;
  @Input() placeholder: string = "";
  @Input() isReadOnly: boolean = false;

  @Output() selectionChange = new EventEmitter();
  @Output() setupFirstLoad = new EventEmitter();
  // @Output() checkValueInput = new EventEmitter();

  filteredOptions: Observable<string[]>;
  loading = false;
  previousValue: string;
  private _destroy: Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    this.setupData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const optionChange = changes.options;
    if (optionChange && optionChange.currentValue && optionChange.currentValue.length) {
      this.pushDataToStream();
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option[this.displayKey].toLowerCase().includes(filterValue));
  }
  async setupData() {
    try {
      if (this.searchArrayAPI) {
        this.loading = true;
        const data = await this.searchArrayAPI.toPromise();
        if (data.result) this.options = data.result;
        this.loading = false;
      }
      this.previousValue = this.control.value;
      this.pushDataToStream();
      this.setupFirstLoad.emit();
    }
    catch (e) {
      console.log(e);
      this.loading = false;
    }
  }
  pushDataToStream() {
    this.filteredOptions = this.control.valueChanges.pipe(
      takeUntil(this._destroy),
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value[this.displayKey];
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  displayFn(data) {
    if (data) {
      return data[this.displayKey] ? data[this.displayKey] : data;
    }
  }
  valueSelected(event: MatAutocompleteSelectedEvent) {
    const newValue = event.option.value;
    if (newValue !== this.previousValue) {
      this.previousValue = newValue;
      this.selectionChange.emit(newValue);
    }
  }
  checkSelectedOption() {
    if(!this.control.value){
      this.selectionChange.emit();
      this.control.setErrors(null);
    }else{
      const currentValue = this.control.value;
      if(this.options && this.options.length > 0){
        const isValid = this.options.some(
          option => option.value === currentValue
        );
    
        if (!isValid) {
          this.control.setErrors({ required: true });
          this.control.markAsTouched();
        }else{
          this.control.setErrors(null);
        }
      }
    }
  }
}
