import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { PrimasAllFilterOperator } from '../../enums/primas-all-filter-operator';
import { PrimasDatetimeFilterOperator } from '../../enums/primas-datetime-filter-operator';
import { PrimasBooleanFilterOperator, PrimasDropdownFilterOperator } from '../../enums/primas-dropdown-filter-operator';
import { PrimasMailActionFilterOperator } from '../../enums/primas-email-action-filter-operator';
import { PrimasNumberFilterOperator } from '../../enums/primas-number-filter-operator';
import { PrimasTextFilterOperator } from '../../enums/primas-text-filter-operator';
import { PrimasFilterType } from '../../enums/primas-value-type.enum';
import { Helper } from '../../utility/Helper';
import { PrimasJsonStringFilterOperator } from '../../enums/json-string-filter-operator';
import { PrimasStartWithTextFilterOperator } from '../../enums/start-with-text-filter-operator';

@Component({
  selector: 'app-dropdown-filter-operator',
  templateUrl: './dropdown-filter-operator.component.html',
  styleUrls: ['./dropdown-filter-operator.component.scss']
})
export class DropdownFilterOperatorComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() column;
  @Output() onFilter = new EventEmitter<any>();
  filterOperators = [];
  @Input()
  selectedFilter: string;
  @Input() isMobileTemplate: boolean = false;
  @Input() templateType: string = "default";

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.column && !changes.column.firstChange) {
    //   const prevChangeWidth = changes.column.previousValue;
    //   const currentChangeWidth = changes.column.currentValue;
    //   if (prevChangeWidth && currentChangeWidth && prevChangeWidth.width !== currentChangeWidth.width) return;
    // }
    const selectedFilterChange = changes.selectedFilter;
    if (selectedFilterChange && JSON.stringify(selectedFilterChange.currentValue) != JSON.stringify(selectedFilterChange))
      this.selectedFilter = Helper.createSpaceString(this.selectedFilter);

    if (this.column.filterOperator) {
      const enumOperator = Helper.createSpaceString(this.column.filterOperator);
      if (enumOperator) this.selectedFilter = enumOperator;
    }
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    setTimeout(() => {
      var filterType = this.column.filter.filterType
      const enumOperator = Helper.createSpaceString(this.column.filterOperator);
      switch (filterType) {
        case undefined:
        case PrimasFilterType.Text:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasTextFilterOperator)]
            : [...Object.values(PrimasTextFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasTextFilterOperator.Contains;
          break;
        case PrimasFilterType.Number:
        case PrimasFilterType.JsonNumber:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasNumberFilterOperator)]
            : [...Object.values(PrimasNumberFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasNumberFilterOperator.IsEqualTo;
          break;
        case PrimasFilterType.DateTime:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasDatetimeFilterOperator)]
            : [...Object.values(PrimasDatetimeFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasDatetimeFilterOperator.IsBefore;
          break;
        case PrimasFilterType.DropDown:
        case PrimasFilterType.DynamicContent:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasDropdownFilterOperator)]
            : [...Object.values(PrimasDropdownFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasDropdownFilterOperator.Contains;
          break;
        case PrimasFilterType.Boolean:
          this.filterOperators = [...Object.values(PrimasBooleanFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasBooleanFilterOperator.Contains;
          break;
        case PrimasFilterType.DropDownList:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasDropdownFilterOperator)]
            : [...Object.values(PrimasDropdownFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasDropdownFilterOperator.Contains;
          break;
        case PrimasFilterType.Date:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasDatetimeFilterOperator)]
            : [...Object.values(PrimasDatetimeFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasDatetimeFilterOperator.IsBefore;
          break;
        case PrimasFilterType.MailActions:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasMailActionFilterOperator)]
            : [...Object.values(PrimasMailActionFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasMailActionFilterOperator.Contains;
          break;

        case PrimasFilterType.JsonString:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasJsonStringFilterOperator)]
            : [...Object.values(PrimasJsonStringFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasJsonStringFilterOperator.Contains;
          break;
        case PrimasFilterType.StartWithText:
          this.filterOperators = this.column.disableDefaultFilterOperator
            ? [...Object.values(PrimasStartWithTextFilterOperator)]
            : [...Object.values(PrimasStartWithTextFilterOperator), ...Object.values(PrimasAllFilterOperator)];
          this.column.filterOperator = enumOperator ?? PrimasStartWithTextFilterOperator.StartsWith;
          break;

        default:
          break;
      }
    })

  }

  onChangeFilterOperator(value) {
    this.onFilter.emit({
      filterOperator: value
    })
  }

}
