import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Contact, SimpleCovertStepModel } from '../components/stand-alone-component/contact/contact.model';
import { ContactResponse } from '../models/response/contact-respone.model';
import { ReturnResult, ReturnSearchResult } from '../models/return-result';
import { Contact as DetailContact, ContactReferenceEntitiesViewModel, KeyPairsValueObjectAPI, ProfileContact, ProfileLocation } from '../../modules/admin/profile-management/profile-detail.model';
import { PagedData } from '../models/paging/paged-data';
import { ContactPage, Page } from '../models/paging/page';
import { ProducerContact } from 'src/app/modules/admin/producer-management/producer-model';
import { TreeFlatNode, TreeNode } from '../components/tree-structure-filter/tree-structure-filter.component';
import { ContactTypeFilter } from '../components/stand-alone-component/contact/contact-type';
import { DeleteResult } from '../models/delete-result.model';
import { LocationModel } from 'src/app/modules/admin/location-management/location-management.model';
import { ChangePrimaryContactPopModel } from '../components/stand-alone-component/pop-over-select-contacts/change-primary-contact-pop.model';
import { ListDataContactType, ListEmailContact, LookUpListEmailOutlook } from 'src/app/modules/admin/contact-type-management/contact-type.model';
@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl = environment.apiContactManagement;
  locationUrl = environment.locationManagement;
  contactListObservable = new Subject<DetailContact[]>();
  contactLinkProfile$ = new Subject<ReturnResult<ProfileContact[]>>();
  contactLinkProducer$ = new Subject<ReturnResult<ProducerContact[]>>();
  contactTypeList$: ReplaySubject<ContactTypeFilter[]> = new ReplaySubject<ContactTypeFilter[]>(1);
  constructor(private http: HttpClient) { }
  getContactByProfileId(profileId: string): Observable<ContactResponse> {
    return this.http.get<ContactResponse>(`${this.baseUrl}/GetContactListFromProfileId/${profileId}`).pipe(
      tap(item => console.log(item))
    );
  }
  saveContact(contactLst: Contact[], profileId?: string, producerId?: string, buyerId?: string, isUpdate: boolean = false): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveContactList`, {
      Contacts: contactLst,
      profileId: profileId ? profileId : null,
      producerId, buyerId,
      isUpdate
    });
  }

  contactTypeList(): Observable<ContactTypeFilter[]> {
    return this.contactTypeList$.asObservable();
  }

  getContactList(): Observable<DetailContact[]> {
    return this.contactListObservable.asObservable();
  }
  getAllContactsAsync() {
    return this.http.get<ReturnResult<DetailContact[]>>(`${this.baseUrl}/GetAllContactsAsync`).subscribe(reps => {
      if (reps.result) {
        this.contactListObservable.next(reps.result);
      }
    });
  }
  AddExistingContactAsync(contact: DetailContact, id: string, table: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AddExistingContactAsync`, {
      contact, id, table
    });
  }
  unLinkContactAsync(contact: DetailContact, id: string, table: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UnlinkContactAsync`, {
      contact, id, table
    });
  }
  getContactFromInputAsync(query: string) {
    return this.http.get<ReturnResult<DetailContact[]>>(`${this.baseUrl}/QueryContact?query=${query}`).subscribe(result => {
      if (result.result) {
        this.contactListObservable.next(result.result);
      }
    });
  }

  searchContact(query: string) {
    return this.http.get<ReturnSearchResult<DetailContact[]>>(`${this.baseUrl}/QueryContact?query=${query}`);
  }


  findContact(query: string, isFilterSaleLead: boolean = false): Observable<ReturnResult<DetailContact[]>> {
    return this.http.get<ReturnResult<DetailContact[]>>(`${this.baseUrl}/QueryContact?query=${query}&isFilterSaleLead=${isFilterSaleLead}`);
  }

  getContactPaging(page: Page): Observable<ReturnResult<PagedData<DetailContact>>> {
    return this.http.post<ReturnResult<PagedData<DetailContact>>>(`${this.baseUrl}/get`, page);
  }
  countingContactPaging(page: Page): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/GetTotalContact`,
      page);
  }
  saveContactByOwner(contact: DetailContact, isUpdate: boolean): Observable<ReturnResult<DetailContact>> {
    return this.http.post<ReturnResult<DetailContact>>(`${this.baseUrl}/SaveContactByOwner`, {
      contact, isUpdate
    });
  }
  getTotalContactAndGroup(): Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${this.baseUrl}/GetTotalContactAndGroup`);
  }
  getLinkedProfileFromContactAsyncObservable(): Observable<ReturnResult<ProfileContact[]>> {
    return this.contactLinkProfile$.asObservable();
  }
  refreshLinkedProfileFromContactAsync(contactId: number): void {
    this.http.get<ReturnResult<ProfileContact[]>>(`${this.baseUrl}/GetLinkedProfileFromContactAsync/${contactId}`).subscribe(resp => {
      this.contactLinkProfile$.next(resp);
    });
  }
  getLinkedProducerFromContactAsyncObservable(): Observable<ReturnResult<ProducerContact[]>> {
    return this.contactLinkProducer$.asObservable();
  }
  refreshLinkedProducerFromContactAsync(contactId: number): void {
    this.http.get<ReturnResult<ProducerContact[]>>(`${this.baseUrl}/GetLinkedProducerFromContactAsync/${contactId}`).subscribe(resp => {
      this.contactLinkProducer$.next(resp);
    });
  }

  getTreeGroup(): Observable<ReturnResult<TreeNode[]>> {
    return this.http.get<ReturnResult<TreeNode[]>>(`${this.baseUrl}/GetTreeGroupStructure`);
  }
  createContactGroupAsync(contacts: DetailContact[], groupName: string): Observable<ReturnResult<TreeNode>> {
    return this.http.post<ReturnResult<TreeNode>>(`${this.baseUrl}/CreateContactGroupAsync`, {
      contacts, groupName
    });
  }
  addContactGroupAsync(contacts: DetailContact[], group: any): Observable<ReturnResult<TreeNode>> {
    return this.http.post<ReturnResult<TreeNode>>(`${this.baseUrl}/AddContactGroupAsync`, { contacts, groupId: group.id, groupName: group.name });
  }

  removeOrUnlinkContactGroup(viewGroups: string[], viewContacts: TreeFlatNode[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/RemoveOrUnlinkContactGroup`, { viewGroups, viewContacts });
  }
  //2021-10-22 vuonglqn add start
  GetAllContactTypeAsync(type: string = "CONTACT"): Observable<ReturnResult<ContactTypeFilter[]>> {
    return this.http.get<ReturnResult<ContactTypeFilter[]>>(`${this.baseUrl}/GetAllContactTypeAsync?type=${type}`)
      .pipe(tap(e => this.contactTypeList$.next(e.result)));
  }

  getContactTypeList(type: string = "CONTACT") {
    this.http.get<ReturnResult<ContactTypeFilter[]>>(`${this.baseUrl}/GetAllContactTypeAsync?type=${type}`).subscribe(e => {
      this.contactTypeList$.next(e.result);
    });
  }
  //2021-10-22 vuonglqn add end
  exportContact(page): Observable<ReturnResult<PagedData<DetailContact>>> {
    return this.http.post<ReturnResult<PagedData<DetailContact>>>(`${this.baseUrl}/ExportContact`, page);
  }


  importContact(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ImportContact`, formData).pipe(
      timeout(1200000)
    );
  }
  exportContactSelected(selectedLst: number[], isSelected: boolean = false): Observable<ReturnResult<PagedData<DetailContact>>> {
    return this.http.post<ReturnResult<PagedData<DetailContact>>>(`${this.baseUrl}/ExportContactSelected`, selectedLst);
  }

  editInlineContact(contact: DetailContact): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/EditInlineContact`, contact);
  }

  deletedContactList(ids: number[]): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/DeleteContactList`, ids);
  }
  getContactById(contactId: number): Observable<ReturnResult<DetailContact>> {
    return this.http.get<ReturnResult<DetailContact>>(`${this.baseUrl}/GetContactById/${contactId}`);
  }

  editContactContactType(contactId: number, contactTypes: number[] = []): Observable<ReturnResult<boolean>> {
    let formData: FormData = new FormData();
    contactTypes.forEach(x => formData.append('contactTypes', `${x}`));
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/EditContactContactType?id=${contactId}`, formData);
  }

  getRelationshipContactToObjects(contactId: number): Observable<ReturnResult<KeyPairsValueObjectAPI[]>> {
    return this.http.get<ReturnResult<KeyPairsValueObjectAPI[]>>(`${this.baseUrl}/RelationshipContactToObjects?contactId=${contactId}`);
  }

  getContactRelationShip(relationshipId: string, type: string, page: Page) {
    let contactPage: ContactPage = JSON.parse(JSON.stringify(page));
    contactPage.relationshipId = relationshipId;
    contactPage.type = type;
    return this.http.post<ReturnResult<PagedData<DetailContact>>>(`${this.baseUrl}/ContactRelationShip`, contactPage);

  }

  getContactRelationShipV2(relationshipId: string, type: string, isIncludeEntity: boolean, page: Page) {
    let contactPage: ContactPage = {
      relationshipId: relationshipId,
      type: type,
      isIncludeEntity: isIncludeEntity,
      ...page
    };

    return this.http.post<ReturnResult<PagedData<DetailContact>>>(`${this.baseUrl}/ContactRelationshipV2`, contactPage);
  }

  deleteContact(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteContact/${id}`);
  }
  getReferenceContactRemove(id: number): Observable<ReturnResult<ContactReferenceEntitiesViewModel>> {
    return this.http.get<ReturnResult<ContactReferenceEntitiesViewModel>>(`${this.baseUrl}/getReferenceContactRemove/${id}`);
  }
  deleteContactByFilter(page: Page) {
    return this.http.post<ReturnResult<DeleteResult<number[]>>>(`${this.baseUrl}/deleteContactByFilter`, page);
  }
  deleteContactBySelect(ids: number[]) {
    return this.http.post<ReturnResult<DeleteResult<number[]>>>(`${this.baseUrl}/DeleteContactBySelect`, ids);

  }
  suggestRecentlyAddedContact(): Observable<ReturnResult<DetailContact[]>> {
    return this.http.get<ReturnResult<DetailContact[]>>(`${this.baseUrl}/SuggestRecentlyAddedContact`);
  }


  getLocationByProfileId(relationshipId: string, type: string, page: Page) {
    let contactPage: ContactPage = JSON.parse(JSON.stringify(page));
    contactPage.relationshipId = relationshipId;
    contactPage.type = type;
    return this.http.post<ReturnResult<PagedData<ProfileLocation>>>(`${this.locationUrl}/GetLocationPagingById`, contactPage);

  }
  changePrimaryOnPop(model: ChangePrimaryContactPopModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ChangePrimaryOnPop`, model);
  }
  unlinkContactOrExtendOnPop(model: ChangePrimaryContactPopModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UnlinkContactOrExtendOnPop`, model);
  }

  bulkSpecialAction(Ids, typeName: string, referenceId: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkSpecialAction`, {
      Ids, typeName, referenceId
    });
  }
  convertContactToEntity(model: SimpleCovertStepModel): Observable<ReturnResult<string>> {
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/ConvertContactToEntity`, model);
  }
  getListPropContactTypeById(listDataContactType: ListDataContactType[]): Observable<ReturnResult<ListDataContactType[]>> {
    return this.http.post<ReturnResult<ListDataContactType[]>>(`${this.baseUrl}/GetListPropContactTypeById`, listDataContactType);
  }
  lookupListEmailOutLook(listEmailContact: ListEmailContact): Observable<ReturnResult<[string, LookUpListEmailOutlook]>> {
    return this.http.post<ReturnResult<[string, LookUpListEmailOutlook]>>(`${this.baseUrl}/LookupListEmailOutLook`, listEmailContact);
  }
}
