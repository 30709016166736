<div class="inherit-size-parent">
    <nb-card id="cont" fxLayout="column" fxLayoutAlign="space-between center"
        (keydown.arrowleft)="previousMonthButtonClick()" (keydown.arrowright)="nextMonthButtonClick()"
        (swipeleft)="nextMonthButtonClick()" (swiperight)="previousMonthButtonClick()" class="inherit-size-parent">
        <nb-card-header style="width: 100%;">
            <div class="header-date" fxLayout="row" fxLayoutAlign="space-between stretch">
                <button mat-icon-button class="custom-icon-button" (click)="previousMonthButtonClick()">
                    <mat-icon>
                        arrow_back
                    </mat-icon>
                </button>
                <div *ngIf="!isSwitchMonth" fxLayout="row" fxLayoutAlign="space-between start"
                    [@switchAnimation]="actionSwitchAnimation">
                    <!-- <button *ngIf="btnAddShow" mat-icon-button class="custom-icon-button" (click)="addEventClicked()">
                        <mat-icon>add</mat-icon>
                    </button> -->
                    <button mat-icon-button class="custom-icon-button" style="margin-right: 5px;"
                        (click)="showChangeDate = !showChangeDate">
                        <mat-icon *ngIf="!showChangeDate">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="showChangeDate">keyboard_arrow_up</mat-icon>
                    </button>
                    <span>
                        {{ actDate }}
                    </span>
                    <button *ngIf="isViewFull" (click)="clickChangeView()" mat-icon-button class="custom-icon-button"
                        style="margin-left: 5px;">
                        <mat-icon *ngIf="!isFullView"> fullscreen</mat-icon>
                        <mat-icon *ngIf="isFullView"> fullscreen_exit</mat-icon>
                    </button>
                </div>
                <button mat-icon-button class="custom-icon-button" (click)="nextMonthButtonClick()">
                    <mat-icon>
                        arrow_forward
                    </mat-icon>
                </button>
            </div>

            <div class="header-select-date" fxLayout="row" fxLayoutAlign="center center" style="height: auto;"
                *ngIf="showChangeDate">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                    [@switchAnimation]="actionSwitchAnimation">
                    <mat-form-field style="margin: 5px;">
                        <mat-select (selectionChange)="onYearChange($event)" [(ngModel)]="currentYear">
                            <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="margin: 5px;">
                        <mat-select (selectionChange)="onMonthChange($event)" [(ngModel)]="currentMonth">
                            <mat-option *ngFor="let m of months" [value]="m.id">{{ m.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>
        </nb-card-header>

        <nb-card-body class="inherit-size-parent" (scroll)="onScroll($event)">
            <div class="calendar-container inherit-size-parent" fxLayout="column" fxFlex>
                <!-- Weekdays header -->
                <div fxLayout="row" fxLayoutAlign="space-between center" class="weekdays-container inherit-size-parent"
                    style="height: 30px;  position: -webkit-sticky; position: sticky; top: 0;">
                    <div class="weekdays" *ngFor="let day of weekdays" fxLayout="row" fxLayoutAlign="center center">
                        <span *ngIf="!isSmall">
                            {{day.name}}
                        </span>
                        <span *ngIf="isSmall">
                            {{day.shortName}}
                        </span>
                    </div>

                </div>

                <!-- Days body -->
                <!-- Months -->
                <div class="inherit-size-parent">
                    <div *ngIf="!isSwitchMonth" fxLayout="column" class="inherit-size-parent"
                        [@switchAnimation]="actionSwitchAnimation">
                        <div class="cell-container" *ngFor="let item of arrCalendar">
                            <div fxFlex class="cell-item select-date" fxLayout="row"
                                fxLayoutAlign="space-between stretch" (click)="openDialog(test)" [ngStyle]="{backgroundColor: test.day == actDay && test.month == actMonth - 1 && test.year == actYear
                                            ? 'rgb(48 122 189 / 30%)': 'white'}" *ngFor="let test of item"
                                style="height: 100%; width: 100%;"
                                [nbPopover]="test.events != null && test.events.length > 0 ? popOverTasks : null"
                                [nbPopoverPlacement]="position" [nbPopoverTrigger]="trigger" [nbPopoverContext]="test">
                                <div fxLayout="column" style="height: 100%; width: 100%;">
                                    <div>
                                        <b class="calendar-days"
                                            [ngStyle]="{color: test.month == currentMonth ? 'black' : 'lightgray'}">
                                            {{ test.day }}
                                        </b>
                                        <span *ngIf="test.events && test.events.length > 0 && isSmall">
                                            <div class="dot" style="margin-right:2px"
                                                *ngFor="let event of test.events; let i = index"
                                                [ngStyle]="{ backgroundColor: event.color }"
                                                [matTooltip]="event.task.taskTypeName + '-' + event.task.taskId + ' ' + event.title || 'No title display'">
                                            </div>
                                        </span>
                                    </div>
                                    <div fxFlex *ngIf="!isSmall" style="height: 100%; overflow-x: hidden;">
                                        <div class="row-items" *ngFor="let event of test.events; let i = index"
                                            [ngStyle]="{ backgroundColor: event.color }"
                                            [matTooltip]="event.desc | truncateText: [100, '...']">
                                            <span>
                                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewbox="0 0 16 16">
                                                    <g fill="none" fill-rule="evenodd">
                                                        <path
                                                            d="M0 1.777C0 .796.796 0 1.777 0h12.446C15.204 0 16 .796 16 1.777v12.446c0 .981-.796 1.777-1.777 1.777H1.777A1.778 1.778 0 0 1 0 14.223V1.777z"
                                                            [attr.fill]="event.task.taskType.colorCode" />
                                                        <path
                                                            d="M4.5 12.5V4.378c0-.485.392-.878.875-.878h5.25c.483 0 .875.393.875.878V12.5L8 8.988 4.5 12.5z"
                                                            fill="#FFF" />
                                                    </g>
                                                </svg>
                                                {{event.task.taskTypeName}} - {{event.task.taskId}}
                                            </span>
                                            <span style="font-weight: bold;">
                                                {{event.title || "No title display" | truncateText: [isFullView ? 35 :
                                                20,'...'] }}
                                            </span>
                                            <!-- <span *ngIf="test.day == (event.startDate | date: 'dd')">
                                                {{event.desc}}</span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div *ngIf="isScrollCalendar">
                <app-animation-arrow-scroll></app-animation-arrow-scroll>
            </div>
        </nb-card-body>
    </nb-card>
</div>


<!--[ngClass]="(event.startDate | date: 'dd') == (event.endDate | date: 'dd') ? 'row-items' 
                : ((event.startDate | date: 'dd') == test.day ? 'row-items-multi-first' 
                : ((event.endDate | date: 'dd') == test.day ? 'row-items-multi-last' : 'row-items-multi') )" 
              
                Multi day event:
              <span *ngIf="test.day == (event.startDate | date: 'dd')" >{{event.title}}</span> &nbsp;
                <span *ngIf="test.day == (event.startDate | date: 'dd')"> - {{event.desc}}</span> </div>
              -->

<ng-template #popOverTasks let-data>
    <div class="popover-card">
        <app-popover-detail-task [contextInput]="data"></app-popover-detail-task>
    </div>
</ng-template>