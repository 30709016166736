<ng-container *ngIf="!impressionLoad; else chartLoader">
  <div mat-dialog-content>
      <div style="display: flex; justify-content: space-between;">      
          <h3>Impression Statistics</h3>
          <app-date-range-picker-projection #summaryPicker="dateRangePickerProjection" [projection]="true" #weekPicker
              (dateChanged)="getSummaryDate($event)" [startDate]="impressionLineChartRequestModel.startDate"
              [endDate]="impressionLineChartRequestModel.endDate">
              <span class="underline-id" (click)="summaryPicker.dateRangePicker.open()">
              <span>{{impressionLineChartRequestModel?.startDate | date:'MM/dd/YYYY' || 'Please choose start date'}}</span>
              <span> - </span>
              <span>{{impressionLineChartRequestModel?.endDate | date:'MM/dd/YYYY' || 'Please choose end date'}}</span>
              </span>
          </app-date-range-picker-projection>
      </div>
      <div echarts (chartClick)="onChartEvent($event)"
      #eChart  [options]="lineChartOption" class="chart-config"></div>
  </div>
</ng-container>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</div>

<ng-template #chartLoader>
  <div class="item">
    <ngx-skeleton-loader count="7" animation="progress" [theme]="{
        height: '50px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>

<!-- Popover container -->
<div #popoverContainer class="popover-container" [style.display]="popoverVisible ? 'block' : 'none'">
  <div class="header">      
    <span>Activity in <b>{{ popoverDate }}</b></span>

    <button mat-icon-button (click)="closePopover()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <nb-list class="body">
    <nb-list-item *ngFor="let item of popoverContent; let i = index">      
      <ng-container *ngIf="checkDifferentActivity(item.propertyName)"
      [ngTemplateOutlet]='actFormat' [ngTemplateOutletContext]="{item: item, i: i}">
      </ng-container>
      
      <ng-container *ngIf="!checkDifferentActivity(item.propertyName)"
      [ngTemplateOutlet]='diffActFormat' [ngTemplateOutletContext]="{item: item}">
      </ng-container>
    </nb-list-item>
  </nb-list>
</div>

<ng-template #actFormat let-item="item" let-i="i">
  <span class="action-container">
    <span><b>{{item.userName}}</b> has <span *ngIf="item.action !== 'Delete'">{{item.action.toLowerCase()}}ed</span></span>
    <span *ngIf="item.action === 'Edit'">
      {{item.propertyName}} <b>from</b> 
      <ng-container [ngTemplateOutlet]='truncateText' [ngTemplateOutletContext]="{text: item.logInfo1, i: i, field: 'logInfo1'}"></ng-container>
      <b>to</b> 
      <ng-container [ngTemplateOutlet]='truncateText' [ngTemplateOutletContext]="{text: item.logInfo2, i: i, field: 'logInfo2'}"></ng-container>
    </span>

    <span *ngIf="item.action === 'Add'">
      new {{item.propertyName || item.subObjName}}
    </span>

    <span *ngIf="item.action === 'Delete'">
      {{item.action.toLowerCase()}}d {{item.propertyName || item.subObjName}}
    </span>
  </span>

  <ng-template #truncateText let-text="text" let-i="i" let-field="field">
    <span *ngIf="!isTruncated[i]?.[field]">
      <span *ngIf="text?.length >= 103">
        {{ (text || 'empty') | truncateText: [100, '']}} ...<span class="toggle-truncate" (click)="toggleTruncate(i, field)">Show more</span>&nbsp;
      </span>
      <span *ngIf="text?.length < 103">
        {{ text || 'empty' }}
      </span>
    </span>

    <span *ngIf="isTruncated[i]?.[field]">
      {{ text || 'empty' }} <span class="toggle-truncate" (click)="toggleTruncate(i, field)">Show less</span>&nbsp;
    </span>
  </ng-template>
</ng-template>

<ng-template #diffActFormat let-item="item">
  <span *ngIf="item.propertyName === 'PrimaryImage'">
    <b>{{item.userName}}</b> has changed Primary Image
  </span>

  <span *ngIf="item.propertyName === 'SeoInfoId'">
    <b>{{item.userName}}</b> has changed Primary SEO block
  </span>

  <span *ngIf="item.propertyName === 'AutomateDataStateId'">
    <b>{{item.userName}}</b> has changed Profile's State
  </span>
</ng-template>