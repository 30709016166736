import { formatDate } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { FileValidator } from 'ngx-material-file-input';
import { Subject } from 'rxjs';
import { SettingService } from 'src/app/shared/services/setting.service';
import { SpotlightrService } from 'src/app/shared/services/spotlightr.service';
import { environment } from 'src/environments/environment';
import { UploadVideoSpotlightr } from './upload-video-spotlightr-model';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent implements OnInit, OnDestroy {

  constructor(
    public dialModalRef: MatDialogRef<UploadVideoComponent>,
    private settingService: SettingService,
    private spotlightrService: SpotlightrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
    private frmBuilder: RxFormBuilder
  ) {
    this.profileId = data.profileId ?? this.profileId;
    this.displayName = data.displayName ?? this.displayName;
  }

  listVideo: File[] = [];
  isMultiple: boolean = false;
  messageError: string = '';
  profileId: string = null;
  displayName: string = '';

  public spotlightrKey = '';
  private destroy$: Subject<void> = new Subject<void>();
  private themeVideo: number = 0;
  frmVideo = this.frmBuilder.group({
    inputName: ['', RxwebValidators.required()]
  })
  videoGroupId: number;

  ngOnInit(): void {
    //Get setting
    this.settingService.getSpotlightrKey().subscribe(resp => {
      if (resp.result?.value) this.spotlightrKey = resp.result.value;
      else this.toast.danger("Spotlightr Key not existed!", "Error");
    });

    this.settingService.getSettingByKeyAndGroup("DEFAULT_THEME_VIDEO_ID", "MEDIA").subscribe(resp => {
      if (resp.result?.value) {
        var urlVideoTheme = resp.result.value;
        var encodeId = urlVideoTheme.split('/').pop();
        this.themeVideo = parseInt(atob(encodeId));
      }
      else this.toast.warning("Default theme not existed!", "Warning");
    })

    this.settingService.getSettingByKeyAndGroup("VIDEO_GROUP", "MEDIA").subscribe(resp => {
      if (resp.result && +resp.result.value) this.videoGroupId = +resp.result.value || 0;
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleFileInput(event) {
    this.messageError = "";
    if (!this.isMultiple) this.listVideo = [];
    if (event.rejectedFiles.length > 0)
      this.messageError = `${event.rejectedFiles[0].name} invalid!`;

    this.listVideo.push(...event.addedFiles);
    var nameVideo = this.formatVideoNameUpload(this.listVideo[0]);
    this.frmVideo.patchValue({ inputName: nameVideo ? nameVideo : '' });

  }

  onRemove(video: File) {
    this.listVideo.splice(this.listVideo.indexOf(video), 1);
  }

  closeDialog() {
    this.dialModalRef.close();
  }

  uploadVideo() {
    if (this.listVideo.length > 0 && this.profileId && this.spotlightrKey) {
      const formData = this.parseToFormDataSpotlightr(this.listVideo[0], this.spotlightrKey);
      this.spotlightrService.changeObsVideoUpload({ profileId: this.profileId, videos: [formData] } as UploadVideoSpotlightr);
      this.closeDialog();
    } else this.toast.danger("Error can't upload. Invalid profileId or video or api key!", "Error");
  }

  parseToFormDataSpotlightr(file: File, key: string) {
    const fileVideo = file;
    const formData = new FormData();
    formData.append('vooKey', key);
    formData.append('file', fileVideo);
    formData.append('name', this.frmVideo.controls.inputName.value ? this.frmVideo.controls.inputName.value : fileVideo.name);
    formData.append('customS3', '0');
    formData.append('hls', '1');
    formData.append('create', '1');
    if (this.videoGroupId)
      formData.append('videoGroup', this.videoGroupId.toString());

    if (this.themeVideo != 0) {
      formData.append('playerSettings', `${this.themeVideo}`);
    }

    return formData;
  }

  formatVideoNameUpload(file: File) {
    if (file) {
      var arrayName = file.name.split('.');
      const extensionFile = arrayName.pop();
      //const titleName = arrayName.join();
      const dateUpload = formatDate(new Date(), 'yyyy-MM-ddHHmmsszzz', 'en');
      const nameVideo = `${this.displayName ? this.displayName : 'Unknown'}_${this.profileId ? this.profileId : 'PID'}_${dateUpload}.${extensionFile}`;
      return nameVideo ? nameVideo : file.name;
    }
  }
}
