import { C } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Contact, ProfileContact } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { CampaignLinkscopeService } from 'src/app/modules/admin/profile-management/profile-detail/action-block/campaign-linkscope.service';
import { ReturnSuccessIdModel, Task } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task/task.model';
import { TaskBoardService } from 'src/app/modules/admin/task-management/task-board/task-board.service';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { ReturnResult } from '../models/return-result';
import { Helper } from '../utility/Helper';
import { SettingService } from './setting.service';
import { SettingPoolService } from './setting-pool.service';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  settingService: SettingService;
  userService: UserService;
  taskService: TaskBoardService;
  baseService: CampaignLinkscopeService;
  constructor(http: HttpClient, private settingPoolService: SettingPoolService ) {
    this.settingService = new SettingService(http);
    this.userService = new UserService(http);
    this.taskService = new TaskBoardService(http, settingPoolService);
    this.baseService = new CampaignLinkscopeService(http);
  }

  getFinalUrl(finalUrl: string, tokenid: string, userPhone: string) {
    let newUrl = finalUrl;
    if (!finalUrl.includes('tokenid')) {
      if (newUrl.includes('?') && newUrl.endsWith('?')) {
        newUrl += tokenid && tokenid !== '' ? `tokenid=${tokenid}` : '';
      } else {
        newUrl += tokenid && tokenid !== '' ? `&tokenid=${tokenid}` : '';
      }
    }
    if (!finalUrl.includes('userPhone')) {
      newUrl += userPhone && userPhone !== '' ? `&phoneNumber=${userPhone}` : '';
    }
    return newUrl;
  }
  // get domain call setting
  async getCallRequired(userId: string) {
    let result: CallRequiredModel;
    // get domain call setting
    let domain: string;
    let callerDomainResult = this.settingService.getSettingByKeyAndGroup("DIALER_URL", "DOMAIN").toPromise();
    const dialer = (await Promise.all([callerDomainResult]))[0];
    if (dialer.result) {
      const domainResult = dialer.result.value;
      if (domainResult.includes('?')) {
        domain = dialer.result.value;
      } else {
        domain = `${dialer.result.value}?`;
      }
    }
    // get current user:
    let user: UserModel;
    let userPromise = this.userService.getUserById(userId).toPromise();
    let userResult = (await Promise.all([userPromise]))[0];
    if (userResult.result) {
      user = userResult.result;
    }

    // return data:
    result = {
      callerDomain: domain,
      currentLoginUser: user
    }
    return result;
  }

  // is call is a flag to determine calling or leave message
  // taskTypeId: is a number in SETTING table. Represent for task type id phone call
  // entity: profile or buyer
  // entityId: id of the entity
  // targetPhone: phone number
  // doneTaskId: done task id in setting
  createTaskWhenCalling(taskTypeId: number, user: UserModel, entity: string, entityId: string, targetPhone: string, taskId: number = null, doneTaskId: number = null): Observable<ReturnResult<ReturnSuccessIdModel>> {
    let taskStatusId = 1;
    taskStatusId = taskId ? doneTaskId : 1;
    const task = new Task();
    if (taskId) {
      task.taskId = taskId;
    }
    task.relationshipId = entityId;
    task.taskName = 'Call ' + targetPhone;
    task.relationshipType = entity.toLowerCase();
    task.taskTypeId = taskTypeId;
    task.taskStatusId = taskStatusId;
    task.taskStartDate = new Date().toUTCString();
    task.taskEndDate = new Date().toUTCString();
    task.assigneeTo = user.id;
    return this.taskService.saveTask(task);
  }
  // NOTE: This method using for call only. Not amdcall
  // contact: contact to call: Required
  // alternativeContactList: list of alternative contact, optional
  // toastr: Service to show toast
  // pid: profileId or some id related to the profile or buyer...
  // isTaskScreen: this was called with Url 'configuration/task' or not: NEED TO DETECT EACH TIME CALL THIS FUNCTION (if task screen set it to true otherwise it will false)
  // isCalling: flag auto set, no need to input. (or could set default to false and get it state to change loading)
  // optionalTaskId: if isTaskScreen = true then this prop is required
  async makeACall(contact: Contact, alternativeContactList: ProfileContact[], userId: string, pid: string, isTaskScreen: boolean = false, optionalTaskId: number = null, isLoading: boolean = false, toastService: NbToastrService = null, isCalling: boolean = false, entity: string = 'profile') {
    // Get All Prefix Data:
    isLoading = true;
    let requiredResult = await this.getCallRequired(userId);
    let { callerDomain, currentLoginUser } = requiredResult
    // get the tokenid:
    const tokenid = window.sessionStorage.getItem('tokenid') ?? '0';
    // get user phone number:
    let userPhone = window.sessionStorage.getItem('phonenumber') ?? '';
    if (userPhone === '') {
      if (contact.contactPhone && contact.contactPhone !== '') {
        userPhone += contact.contactPhone;
      } else if (contact.cellPhone && contact.cellPhone !== '') {
        userPhone += contact.cellPhone;
      } else if (contact.secondaryPhone && contact.secondaryPhone !== '') {
        userPhone += contact.secondaryPhone;
      } else if (alternativeContactList && alternativeContactList.length > 0) {
        const subContact = alternativeContactList[0];
        if (subContact.contact) {
          // subContact
          if (subContact.contact.contactPhone && subContact.contact.contactPhone !== '') {
            userPhone += subContact.contact.contactPhone;
          } else if (subContact.contact.cellPhone && subContact.contact.cellPhone !== '') {
            userPhone += subContact.contact.cellPhone;
          } else if (subContact.contact.secondaryPhone && subContact.contact.secondaryPhone !== '') {
            userPhone += subContact.contact.secondaryPhone;
          }
        }
      }
    }
    // after check user phone:
    if (!userPhone || userPhone === '') {
      if (toastService) {
        toastService.danger('This profile has no phone number, please set profile phone number.', 'Error');
      }
      if (isLoading) {
        isLoading = false;
      }
      return;
    }
    isLoading = true;
    const finalUrl = `${callerDomain}`;
    let outBoundCallUrl = this.getFinalUrl(finalUrl, tokenid, Helper.trimUserPhoneNumber(userPhone));
    if (!outBoundCallUrl.includes('agentphonenumber=')) {
      if (currentLoginUser.phoneNumber) {
        outBoundCallUrl += `&agentphonenumber=${Helper.trimUserPhoneNumber(currentLoginUser.phoneNumber)}`;
      } else {
        toastService.danger('This user have no phone number, please set your phone number first.', 'Error');
        isLoading = false;
        return;
      }
    }
    // add caller id
    if (!outBoundCallUrl.includes('callerid')) {
      // append outbound caller id from user:
      if (currentLoginUser.outboundCallerId) {
        outBoundCallUrl += `&callerid=${currentLoginUser.outboundCallerId}`;
      }
      else {
        const defaultOutboundCallerId = this.settingService.getSettingByName('DEFAULT_CALLER_ID', 'SYSTEM').toPromise();
        const res = await Promise.all([defaultOutboundCallerId]);
        if (res[0].result && res[0].result.value && res[0].result.value != '') {
          outBoundCallUrl += `&callerid=${res[0].result.value}`
        } else {
          toastService.danger('No Outbound caller id provided!', 'Error');
          isLoading = false;
          return;
        }
      }
    }
    // add pid to caller id:
    if (!outBoundCallUrl.includes('pid')) {
      outBoundCallUrl += `&pid=${pid}`;
    }

    if (isTaskScreen) {
      // get task setting when create new task related to task calling required
      let phoneCallResult = (await Promise.all([this.settingService.getSettingByKeyAndGroup("PHONE_CALL_ID_TASKTYPE", "TASK").toPromise(), this.settingService.getSettingByName("DONE_TASK_ID", "TASK").toPromise()]));
      // task type id
      let callTaskTypeId = phoneCallResult[0].result ? parseInt(phoneCallResult[0].result.value) : 1;
      let doneTask = phoneCallResult[1].result ? (parseInt(phoneCallResult[1].result.value) ?? 1) : 1;

      this.createTaskWhenCalling(callTaskTypeId, currentLoginUser, entity, pid, userPhone, null, doneTask).subscribe(result => {
        if (result.result.success) {
          outBoundCallUrl += `&taskid=${result.result.id}&username=${currentLoginUser.userName}&userid=${userId}`;
        }
        if (!isCalling) {
          isCalling = true;
          this.baseService.makeACall(outBoundCallUrl, null, 'Call', currentLoginUser.userName, result.result.id, pid).pipe().subscribe(resp => {
            toastService.success('Requested a phone call', 'Success');
            if (+resp.result > 0) {
              window.localStorage.setItem('activityLogCall', `${resp.result}`);
            }
            if (isTaskScreen) {
              this.createTaskWhenCalling(callTaskTypeId, currentLoginUser, entity, pid, userPhone, result.result.id, doneTask).subscribe();
            }
            isLoading = false;
            isCalling = false;
          });
        }
      });
    } else {
      // call in task detail
      if (optionalTaskId) {
        outBoundCallUrl += `&taskid=${optionalTaskId}&username=${currentLoginUser.userName}&userid=${currentLoginUser.id}`;
      }
      if (!isCalling) {
        isCalling = true;
        this.baseService.makeACall(outBoundCallUrl, null, 'Call', currentLoginUser.userName, optionalTaskId, pid).pipe().subscribe(resp => {
          //2022-03-21 vuonglqn add start
          if (+resp.result > 0) {
            window.localStorage.setItem('activityLogCall', `${resp.result}`);
          }
          //2022-03-21 vuonnglqn add end
          toastService.success('Requested a phone call', 'Success');
          isLoading = false;
          isCalling = false;
        });
      }
    }
  }
}

class CallRequiredModel {
  callerDomain: string;
  currentLoginUser: UserModel
}
