import { EditSupportTicketComponent } from './edit-support-ticket/edit-support-ticket.component';
import { PrimasToolbarComponent } from 'src/app/shared/components/template-management/primas-toolbar/primas-toolbar.component';
import { PrimasTableComponent } from 'src/app/shared/components/template-management/primas-table/primas-table.component';
import { takeUntil } from 'rxjs/operators';
import { SupportTicketService } from './../profile-management/send-support-ticket/support-ticket.service';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { permissionSupportTicket } from 'src/app/shared/contances/permission';
import { SupportTicketDetailComponent } from './support-ticket-detail/support-ticket-detail.component';
import { Subject } from 'rxjs';
import { SupportTicketPage } from 'src/app/shared/models/paging/page';
// import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';

@Component({
  selector: 'app-support-ticket-management',
  templateUrl: './support-ticket-management.component.html',
  styleUrls: ['./support-ticket-management.component.scss']
})
export class SupportTicketManagementComponent implements OnInit,AfterViewInit {
  @ViewChild('primasToolbar', { static: true }) primasToolbar: PrimasToolbarComponent;
  @ViewChild('primasTable', { static: true }) primasTable: PrimasTableComponent;
  @ViewChild('dateCreated', { static: true }) dateCreated;
  @ViewChild('description', { static: true }) description;
  @ViewChild('titleName', { static: true }) titleName;
  @ViewChild('resolution', { static: true }) resolution;
  currentUrl: string = '';
  isShowInAdmin = true;
  columns;
  asteriskSub = null;
  resource = permissionSupportTicket;
  addEditComponet : any;

  constructor(private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private toastr: NbToastrService,
    private supportTicketService: SupportTicketService,
    public dialModalRef: MatDialogRef<SupportTicketManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private tabModeService: AdminTabModeService
  ) { 
    //2022-25-05 thoai add start
    this.isShowInAdmin = data.isShowInAdmin?? this.isShowInAdmin;
    if(this.isShowInAdmin == true){
      this.addEditComponet = EditSupportTicketComponent;
    }
    else{
      this.addEditComponet = SupportTicketDetailComponent;
    }
    // this.currentUrl = this.router.url; //get current url.
    // // check support-ticket
    // if(this.currentUrl === '/configuration/support-ticket' ){
    //   this.isShowInAdmin = true;
    // }

    //2022-25-05 thoai add end
    // this.tabModeService.toggleLoading(false);
  }
  ngAfterViewInit(): void {
    this.primasTable.page.pageNumber = 0;
  }
  closeDialog() {
    this.dialModalRef.close(this.dialModalRef);
  }
  ngOnInit(): void {
    // Columns
    this.columns = [
      {
        name: 'Date Created',
        prop: 'dateCreated',
        filter: {
          filterType: PrimasFilterType.Date
        },
        import: false,
        cellTemplate: this.dateCreated,
        headerClass: 'text-center filter-date-time-column',
        cellClass: 'text-left',
      },
      {
        name: 'Title',
        prop: 'title',
        filter: true,
        cellTemplate:this.titleName
      },
      // {
      //   name: 'User Name',
      //   prop: 'userName',
      //   filter: true,
      // },
      // {
      //   name: 'Description',
      //   prop: 'bodyHtml',
      //   filter: true,
      //   minWidth: 300
      // },
      {
        name: 'Resolution Code',
        prop: 'resolutionCode',
        filter: true,
        cellTemplate:this.resolution

      },
      {
        name: 'System Comment',
        prop: 'systemComment',
        filter: true,
      },
     
    ];
    

  }

  refreshData(reset: boolean = false): void {
    this.primasTable.isLoading = 1;
    var offSetX = this.primasTable.table.bodyComponent.offsetX;

    if (reset) {
      this.primasTable.page.pageNumber = 0;
      this.primasTable.cache = {};
      this.primasTable.rows = [];
      this.primasTable.selected = [];
      this.primasTable.table.offset = 0;
      this.primasTable.table.bodyComponent.offsetY = 0;
      this.primasTable.page.pageNumber = 0;
      offSetX = 0;
    }
    let customPage: SupportTicketPage = {...this.primasTable.page};
    customPage.showAllSupportTicket =this.isShowInAdmin;
    this.supportTicketService.getSupportTicketPaging(customPage).subscribe(e => {
      debugger
      this.primasTable.setData(e);
      setTimeout(() => {
        this.primasTable.table.recalculate();
        this.primasTable.table['cd'].markForCheck();
        document.body.style.width = 'auto';
        this.primasTable.table._offsetX.next(offSetX);
      }, 200);
      this.primasTable.isLoading = 0;

    })
  }
  convertHtmlToNormalStyle(data: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = data;
    return tmp.textContent || tmp.innerText || "";
  }
  openDetail(data) {
    this.primasTable.onClickEdit(data, null);
  }
  // onDeletes(){
  //   this.supportTicketService.deleteListSupportTicket(this.primasTable.selected.map(x=>x.ticketId)).pipe(takeUntil(this.destroy$))
  //   .subscribe(resp =>{
  //     if(resp.result){
  //       this.toastr.success(`Delete support ticket successfully`,'success');
  //       this.refreshData(true);
  //     }
  //   })
  // }
  // onClickDelete(data){
  //   this.supportTicketService.deleteSupportTicket(data.row.ticketId).pipe(takeUntil(this.destroy$))
  //   .subscribe(resp =>{
  //     if(resp.result){
  //       this.primasTable.page.pageNumber = Math.floor(data.rowIndex / this.primasTable.page.size);
  //       this.toastr.success(`Delete support ticket ${data.row.ticketId} successfully`,'success');
  //       this.refreshData();
  //     }
  //   })
  // }
}
