export const TaskFilterType = [
    { key: 1, value: 'Filter by today task' },
    { key: 2, value: 'Filter by task type' },
    { key: 3, value: 'Filter by date range' },
    { key: 4, value: 'Filter by search task' },
    { key: 5, value: 'Filter by task status' },
    { key: 6, value: 'Filter by unassigned' },
    { key: 7, value: 'Filter by my issue' }
];

export const TaskPropertyName = [
    { key: 'TaskId', value: 'Id' },
    { key: 'TaskTypeId', value: 'type Id' },
    { key: 'TaskName', value: 'Task Name' },
    { key: 'TaskDescription', value: 'Task Description' },
    { key: 'TaskPriority', value: 'Priority' },
    { key: 'TaskStatusId', value: 'Task Status' },
    { key: 'TaskName', value: 'Task Name' },
    { key: 'TaskStartDate', value: 'Task Start Date' },
    { key: 'TaskEndDate', value: 'Task End Date' },
    { key: 'AssigneeTo', value: 'Assignee To' },
    { key: 'UserName', value: 'User' },
    { key: 'TaskOrderId', value: 'Task Order' },
    { key: 'IsAutoTask', value: 'Auto Task' },
    { key: false, value: 'Disable' },
    { key: true, value: 'Enable' },
];
