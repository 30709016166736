import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { RestoreRevisionViewModel, RevisionStatus, RVRevisionMeta } from './revision-version.model';
import { map, tap } from 'rxjs/operators';
import { UserModel } from '../user-management/user-model';

@Injectable({
  providedIn: 'root'
})
export class RevisionVersionService {
  revisionVersionUrl: string = environment.apiRevisionVersion;
  profileManagementUrl: string = environment.apiProfile;

  constructor(
    private http: HttpClient,
  ) { }

  getRevisionVersionMetaPaging(page: Page, revisionStatus: RevisionStatus, revisionEntity: string): Observable<ReturnResult<PagedData<RVRevisionMeta>>> {
    let revisionVersionPage = {
      page: page,
      revisionStatus: revisionStatus,
      revisionEntity: revisionEntity
    }

    return this.http.post<ReturnResult<PagedData<RVRevisionMeta>>>(`${this.revisionVersionUrl}/GetRevisionMetaPaging`, revisionVersionPage);
  }

  getListUserBaseOnRevision(): Observable<ReturnResult<UserModel[]>> {
    return this.http.get<ReturnResult<UserModel[]>>(`${this.revisionVersionUrl}/GetListUserBaseOnRevision`);
  }

  submitProfileRevision(profileId) {
    return this.http.put<ReturnResult<any>>(`${this.revisionVersionUrl}/Profile/SubmitRevision/${profileId}`, {});
  }

  submitCategoryRevision(categoryId) {
    return this.http.put<ReturnResult<any>>(`${this.revisionVersionUrl}/Category/SubmitRevision/${categoryId}`, {});
  }

  changeStatusRevision(revisionId: number, revisionStatus: RevisionStatus, isPublish: boolean = false, rejectReasonData?:
     string,skipChangeProfilePublishStatus: boolean = false, fromRevisionSite: boolean = false) {
    let url = fromRevisionSite ? 'ApproveRevision' : 'ChangeStatusRevision';
    return this.http.put<ReturnResult<any>>(`${this.revisionVersionUrl}/${url}`, {
      revisionId: revisionId,
      revisionStatus: revisionStatus,
      isPublish: isPublish,
      skipChangeProfilePublishStatus: skipChangeProfilePublishStatus,
      rejectReason: rejectReasonData,
    });
  }

  getPublishedRevision(objectId: number) {
    return this.http.get<ReturnResult<any>>(`${this.profileManagementUrl}/GetPublishedRevision/${objectId}`)
      .pipe(map(x => {
        if (x.result) {
          if (x?.result?.reviewer != null) {
            x.result.reviewer = Object.assign({
              "fullName": (`${x?.result?.reviewer?.firstName?.trim()} ${x?.result?.reviewer?.lastName?.trim()}`).trim()
            }, x.result.reviewer)

          }

          if (x?.result?.submitter != null) {
            x.result.submitter = Object.assign({
              "fullName": (`${x?.result?.submitter?.firstName?.trim()} ${x?.result?.submitter?.lastName?.trim()}`).trim()
            }, x.result.submitter)
          }
        }
        return x;
      }));
  }

  profilePublishComparison(id: string) {
    return this.http.post<ReturnResult<{status: boolean, revisionStatus: string, mainDifferent: string, diffDifferent: string}>>(`${this.revisionVersionUrl}/ProfileComparison/${id}`, {});
  }

  getCategoryComparision(categoryId: string)
  {
    return this.http.get<ReturnResult<{isHaveDifferences : boolean, mainDifferences: string, diffDifferences: string}>>(`${this.revisionVersionUrl}/CategoryComparison/${categoryId}`);
  }

  isReviewer() {
    return this.http.get<ReturnResult<boolean>>(`${this.revisionVersionUrl}/IsReviewer`);
  }

  removeRevision(revision) {
    return this.http.delete<ReturnResult<boolean>>(`${this.revisionVersionUrl}/RemoveRevision/${revision}`);
  }

  changeRestoreNote(model: RestoreRevisionViewModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.revisionVersionUrl}/ChangeRestoreNote`, model);
  }

  restoreRevision(model: RestoreRevisionViewModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.revisionVersionUrl}/RestoreRevision`, model);
  }
}
