<div *ngIf="!isDisplayAsList;else rowView" class="issue-wrap issue-box">
  <div class="issue" (click)="clickDetail.emit($event); openTask()" (contextmenu)="onRightClick($event,task)"
    [ngStyle]="{'background-color': task.taskPriorities.colorCode && task.taskStatusId != doneTask? task.taskPriorities.colorCode: 'null'}">
    <div class="task-id">
      <div class="task-info">
        <span style="font-size:15px;color:black" matTooltip="Task name">
          {{task.taskName | truncateText: [55,'...']}}
        </span>
        &nbsp;
        <span *ngIf="task.taskStatusId == doneTask"
          [ngClass]="task.taskStatusId == doneTask && isExpirationExpired() ? 'float-right' : 'float-right'"
          matTooltip="Finished date" style="font-size:10px">
          {{ (task.taskEndDate) ? (task.taskEndDate | date : 'LL/dd HH:mm') : "Unknown" }}
        </span>
      </div>
    </div>
    <!-- profile or contact -->
    <div *ngIf="task.relationshipType && task.relationshipId" fxLayout="row" fxLayoutAlign=" center">
      <span [ngSwitch]="task.relationshipType" style="margin-right: 5px;color: #8f9bb37a;"
        matTooltipClass="custom-tooltip-relationship" [matTooltip]="task.relationshipType == 'profile' 
        ? (task.relationshipSubTypeName || 'Profile') 
        : task.relationshipType">
        <span *ngSwitchCase="task.relationshipType == 'profile' 
        || task.relationshipType == 'saleLeads' 
        || task.relationshipType == 'saleAccount'
        || task.relationshipType == 'opportunity'
        ? task.relationshipType : ''">
          <mat-icon *ngIf="task.relationshipDisplayName || task.relationshipName"
            [style.color]="task.colorRelationshipProfile ?? ''">
            account_circle
          </mat-icon>
        </span>
        <span *ngSwitchCase="'buyer'">
          <mat-icon *ngIf="task.relationshipDisplayName || task.relationshipName" style="color: #0078A8;">
            local_mall
          </mat-icon>
        </span>
        <span *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="contactIcon;context:{task: task}">
          </ng-container>
        </span>
      </span>
      <span *ngIf="task.relationshipDisplayName || task.relationshipName"
        [matTooltip]="findItemByKey(tooltipProp, 'displayName') ?? 'Display name'" style="color: #0078A8;">
        {{(task.relationshipDisplayName || task.relationshipName || 'No name') | truncateText: [36,'...']}}
      </span>
    </div>
    <div *ngIf="task.taskStatusId != doneTask; else TaskIsDone">
      <p *ngIf="task.taskDescription;else noDescription" class="text-15 text-textDarkest" class="parent-description"
        style="font-size: 12px !important;white-space: pre-wrap;font-style: italic !important;"
        matTooltip="Task description"
        [innerHTML]=" removeHtmlTag(task?.taskDescription | truncateText: [55,'...']) |safeHtml">
      </p>
    </div>
    <ng-template #TaskIsDone>
      <p class="text-15 text-textDarkest" class="parent-description"
        style="font-size: 12px !important;white-space: pre-wrap;font-style: italic !important;">
        <span *ngIf="task.resolutionCode?.resolutionCodeId >= 0" matTooltip="Resolution name">
          {{task.resolutionCode?.resolutionCodeName}}
        </span>
        <span *ngIf="task.resolution && task.resolutionCode?.resolutionCodeName">
          -
        </span>
        <span style="font-size: 12px !important;white-space: pre-wrap;font-style: italic !important;"
          *ngIf="task.resolution" [innerHTML]="removeHtmlTag(task.resolution) |safeHtml" matTooltip="Resolution">
        </span>
      </p>
    </ng-template>
    <div class="issue-footer">
      <!-- task image and id -->
      <div>
        <span [matTooltip]="task.taskType?.taskTypeName || task.taskTypeName">
          <span
            *ngIf="getHardCodePhoneCall(task.taskType?.taskTypeName || task.taskTypeName) != 'phonecall'; else phonecallIcon">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16">
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M0 1.777C0 .796.796 0 1.777 0h12.446C15.204 0 16 .796 16 1.777v12.446c0 .981-.796 1.777-1.777 1.777H1.777A1.778 1.778 0 0 1 0 14.223V1.777z"
                  [attr.fill]="task.taskType?.colorCode || task.taskTypeColorCode" />
                <path d="M4.5 12.5V4.378c0-.485.392-.878.875-.878h5.25c.483 0 .875.393.875.878V12.5L8 8.988 4.5 12.5z"
                  fill="#FFF" />
              </g>
            </svg>
          </span>
        </span>
        <span class="overflow " style=" font-size: 12px;margin-right: 10px;">
          {{task.taskId}}
        </span>
        <span matTooltip="Date created" class="task-id" style="margin-right: 10px;">{{task.dateCreated | date:
          'LL/dd'}}
        </span>

        <span class="task-id" matTooltip="Timezone based on primary phone number">
          {{(showTimeZonePrimaryPhone(task.primaryPhoneNumber))}}
        </span>
      </div>
      <!-- Date created and user -->
      <div fxLayout="row" fxLayoutAlign="end center" class="float-right due-date task-id">
        <img class="priority-img" [src]="taskPriorityImg | safeIframe" style="margin-right: 10px;"
          [matTooltip]="taskPriorityName">
        <span matTooltip="Due Date" *ngIf="task.taskStartDate; else noTaskStartDate"
          [ngClass]="task.taskStatusId != doneTask && isExpirationExpired() ? 'due-date-warning' : 'due-date-valid'"
          style="margin-right: 10px;">{{
          (task.taskStartDate) ? (task.taskStartDate | date : 'LL/dd') : "Unknown" }}</span>
        <ng-template #noTaskStartDate>
          <span matTooltip="Due Date">
            Unknown
          </span>
        </ng-template>
        <!-- <img alt="user" class="user-img" [src]="userImage | safeIframe" (error)="userImage ='assets/images/man.png'"> -->
        <nb-user *ngIf="task.assigneeTo; else noAssignee" [onlyPicture]="true" color="#8f9bb37a"
          [matTooltip]="tootipUser || userNameImage || task.user?.userName|| task.userName || 'Unknown'"
          [name]="userNameImage || task.user?.userName || task.userName || 'Unknown'" [picture]="userImage"
          size="small">
        </nb-user>
      </div>
    </div>

    <div class="col-12 task-id float-right">
    </div>
  </div>
</div>
<div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="menus"></div>
<mat-menu #menus="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="deleteTheTask(item)">Delete</button>
  </ng-template>
</mat-menu>
<!-- Display as row view -->
<ng-template #rowView>
  <div class=" box-color" (click)="clickDetail.emit($event); openTask()" (contextmenu)="onRightClick($event,task)">
    <div class="row issue-row" [ngClass]="{'undragable':!isDragable}">
      <div class="top">
        <div class="task-info">
          <span [matTooltip]="task.taskType?.taskTypeName || task.taskTypeName">
            <!-- icon -->
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16"
              style="margin-right: 5px;">
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M0 1.777C0 .796.796 0 1.777 0h12.446C15.204 0 16 .796 16 1.777v12.446c0 .981-.796 1.777-1.777 1.777H1.777A1.778 1.778 0 0 1 0 14.223V1.777z"
                  [attr.fill]="task.taskType?.colorCode || task.taskTypeColorCode" />
                <path d="M4.5 12.5V4.378c0-.485.392-.878.875-.878h5.25c.483 0 .875.393.875.878V12.5L8 8.988 4.5 12.5z"
                  fill="#FFF" />
              </g>
            </svg>
          </span>
          <img class="user-img" [src]="taskPriorityImg | safeIframe" style="margin-right: 5px;">
        </div>
        <span>
          <div class="task-id-blue">{{task.taskType?.taskTypeName || task.taskTypeName}}-{{task.taskId}}</div>
        </span>
        <span>
          <div class="text-15 text-textDarkest" style="margin-right: 5px;">
           
            {{ (truncateTaskName === 0 ) ? task.taskName : (task.taskName | truncateText: [truncateTaskName, '...'])}}
          </div>
        </span>
        <mat-chip-list>
          <mat-chip class="small-chip"
            [ngStyle]="{'background-color':showTaskStatusColor(task.taskStatusId), 'color': 'white'}">
            {{showTaskStatus(task.taskStatusId)}}</mat-chip>
        </mat-chip-list>
      </div>
      <div *ngIf="!removeAssigneeTaskList" class="tail">
        <span style="margin-right: 5px;">
          <i>{{task.userName || 'Not Assigned'}}</i>
        </span>
        <img *ngIf="task.assigneeTo" alt="user" class="user-img" [src]="userImage | safeIframe"
          (error)="userImage ='assets/images/man.png'">
      </div>
      <div *ngIf="removeAssigneeTaskList" style="width: 30px; height: 1px;">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noDescription>
  <p class="text-15 text-textDarkest" style=" font-size: 12px;white-space: pre-wrap;font-style: italic;"
    matTooltip="Task description">
    No Description
  </p>
</ng-template>
<ng-template #contactIcon>
  <mat-icon *ngIf="task.relationshipDisplayName || task.relationshipName" style="margin-right: 5px;color: #28a745;">
    contacts
  </mat-icon>
</ng-template>
<ng-template #noAssignee>
  <span style="margin-right:5px">Unassigned</span>
</ng-template>

<!-- phone call icon -->
<ng-template #phonecallIcon>
  <img src="/assets/images/phone-call.png" alt="call" style="width: 16px;">
</ng-template>