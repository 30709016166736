<div class="d-flex align-items-center border-category-button header-category-structure">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="space-around center">
            <form class="col-10 row d-flex align-items-start" [formGroup]="searchFrm">
                <mat-form-field style="width: 100%;" appearance="standard">
                    <mat-label>Search</mat-label>
                    <input matInput placeholder="Search" #searchCategory formControlName="input"
                        [matAutocomplete]="autocompleteSearchCate" [nbSpinner]="isLoading">
                    <mat-autocomplete #autocompleteSearchCate="matAutocomplete"
                        (optionSelected)="showSelected($event.option.value)">
                        <div *ngIf="searchFrm.controls.input.value != ''">
                            <mat-option class="col custom-option" *ngFor="let item of searchObject" [value]="item.key"
                                [disabled]="item.disableSelect">
                                {{item.value}}
                            </mat-option>
                            <mat-option *ngIf="searchObject.length == 0 && !isLoading" [value]="" disabled>
                                Not Found
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                    <mat-icon [hidden]="searchFrm.controls.input.value == '' ? true : false" matSuffix
                        (click)="clearSeach()" style="cursor: pointer;">clear</mat-icon>
                </mat-form-field>
            </form>
            <button mat-raised-button color="primary" style="margin-right: 4px;"
                (click)="applyFilterFunc()">Apply</button>
        </div>
        <mat-chip-list #chipCategory class="area-chip" multiple="true">
            <mat-chip *ngFor="let chip of chipFilter" removable="true" style="color: white; font-size: 13px;"
                (removed)="removeChip(chip)" [style.background-color]="chip.parentId ? '#28a745' : '#307abd'">
                {{chip.value | truncateText: [10,'...']}}
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
    <!-- <button mat-icon-button aria-label="Close the dialog" (click)="closeFilterFunc()" class="ml-auto">
        <mat-icon>arrow_back</mat-icon>
    </button> -->
</div>
<div [ngStyle]="{'height': 'calc(100% - (80px + ' + areaChip + 'px))'}" class="area-tree" [nbSpinner]="isLoading">
    <app-tree-structure-filter #treefilter [page]="page" (onRefresh)="refreshEventTree($event)"
        [(arrayFilterCategory)]="arrayFilter" (onRefreshMatChip)="refreshMatChipSelectCate($event)">
    </app-tree-structure-filter>
</div>