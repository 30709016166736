import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable, Subject } from 'rxjs';
import { shareReplay, take, tap, timeout } from 'rxjs/operators';
import { Category } from 'src/app/shared/models/category';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { CategorySeoRequestModel, ParentCategoryModel } from 'src/app/shared/models/request/category-seo-request.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { SeoInfoModel } from 'src/app/shared/models/seo-form-control.model';
import { environment } from 'src/environments/environment';
import { CategoryChartDataModel, CategoryChartRequestModel } from '../category-report/category-report.model';
import { Media, SeoCategory } from '../profile-management/profile-detail.model';
import { AddCategoryModel, HyperDatas } from './add-edit-category/add-category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryManagementService {
  constructor(private http: HttpClient) { }
  public static isCalling = false;
  baseUrl = environment.apiCategory;
  categoryLst = new Subject<Category[]>();
  categoryPaging = new Subject<PagedData<Category>>();
  parentCategory = new Subject<Category[]>();
  public cacheParentCategory: Category[] = [];
  public parentAlreadyCalled = false;
  getCategoryListAsync(): Observable<Category[]> {
    return this.categoryLst.asObservable();
  }
  getCatePaging(): Observable<PagedData<Category>> {
    return this.categoryPaging.asObservable();
  }
  getParentCategories(): Observable<Category[]> {
    return this.parentCategory.asObservable();
  }
  refreshCategoryList() {
    return this.http.get(`${this.baseUrl}/GetAllCategoryAsync`).subscribe((resp: any) => {
      if (resp.result) {
        this.categoryLst.next(resp.result.category);
      }
    });
  }
  refreshPaging(page: Page): void {
    this.http.post<ReturnResult<PagedData<Category>>>(`${this.baseUrl}/Get`, page).subscribe(resp => {
      if (resp.result) {
        this.categoryPaging.next(resp.result);
      }
    });
  }
  reFreshParentCategory(query: string): void {
    if (!this.parentAlreadyCalled) {
      this.parentAlreadyCalled = true;
      this.http.get<ReturnResult<Category[]>>(`${this.baseUrl}/GetParentCategories?query=${query}`).pipe(shareReplay()).subscribe(resp => {
        if (resp.result) {
          this.parentCategory.next(resp.result);
          this.cacheParentCategory = resp.result.slice();
        }
      });
    } else {
      this.parentCategory.next(this.cacheParentCategory.slice());
    }

  }
  getPagingCategory(page: Page): Observable<ReturnResult<PagedData<Category>>> {
    return this.http.post<ReturnResult<PagedData<Category>>>(`${this.baseUrl}/Get`, page);
  }
  // this function only use for multiple categories like table
  getQueryParentCategory(query: string): Observable<ReturnResult<Category[]>> {
    return this.http.get<ReturnResult<Category[]>>(`${this.baseUrl}/GetParentCategories?query=${query}`).pipe();
  }
  addCategoryAsync(category: CategorySeoRequestModel): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/AddCategoryAsync`, category);
  }
  updateCategoryAsync(category: CategorySeoRequestModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdateCategoryAsync`, category);
  }
  deleteCategoryAsync(id: string): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteAsync/${id}`);
  }
  //  export
  exportCategory(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/Export`, page);
  }
  // import:
  importCategory(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/import`, formData).pipe(
      timeout(1200000)
    );
  }
  deleteRanges(ids: string[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteRange`, ids);
  }

  changeCategoryImage(id: string, image: any): Observable<ReturnResult<Media>> {
    const bodyFormData = new FormData();
    bodyFormData.append('upload', image);
    return this.http.post<ReturnResult<Media>>(`${this.baseUrl}/UploadCategoryImage/${id}`, bodyFormData);
  }
  getCategoryChartData(model: CategoryChartRequestModel): Observable<ReturnResult<CategoryChartDataModel>> {
    return this.http.post<ReturnResult<CategoryChartDataModel>>(`${this.baseUrl}/GetCategoryChartData`, model);
  }
  activeAllCategoriesWithProfiles() {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/ActiveAllCategoriesWithProfiles`, {});
  }
  updateParentCategory(category: ParentCategoryModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdateParentCategory`, category);
  }
  mergeCategories(source: Category[], destination: Category): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MergeCategories`, {
      sources: source,
      destination
    });
  }
  mergeWithCreateNewCategory(source: Category[], destination: Category, createModel: CategorySeoRequestModel): Observable<ReturnResult<Category>> {
    return this.http.post<ReturnResult<Category>>(`${this.baseUrl}/MergeWithCreateNewCategory`, {
      sources: source,
      destination,
      createModel
    });
  }
  getHyperDataByReferenceId(id: string) {
    return this.http.get<ReturnResult<HyperDatas[]>>(`${this.baseUrl}/GetHyperDataByReferenceId/${id}`);
  }
  // getFaqsByCategoryId(categoryId: string) {
  //   return this.http.get<ReturnResult<FAQsCategoryModel[]>>(`${this.baseUrl}/GetFaqsByCategoryId/${categoryId}`);
  // }
  getSeoCategoryByCategoryId(page: Page, categoryId: string): Observable<ReturnResult<PagedData<SeoCategory>>> {
    return this.http.post<ReturnResult<PagedData<SeoCategory>>>(`${this.baseUrl}/GetSeoCategoryByCategoryId/${categoryId}`, page);
  }
  searchCategory(keyword: string): Observable<ReturnResult<Category[]>> {
    return this.http.post<ReturnResult<Category[]>>(`${this.baseUrl}/SearchCategory`, {keyword});
  }
  updateLockStatusCategory(categoryId: string, status: boolean): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/${status ? 'LockCategory' : 'UnlockCategory'}/${categoryId}`, null);
  }

  getCategoryById(categoryId: string): Observable<ReturnResult<Category>> {
    return this.http.get<ReturnResult<Category>>(`${this.baseUrl}/GetCategoryById/${categoryId}`);
  }
  
}

