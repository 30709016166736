<h2 mat-dialog-title>SEO Information</h2>
<mat-dialog-content *ngIf="formGroup; else loading" class="mat-typography">
    <form class="container" [formGroup]="formGroup">
        <div class="row">
            <div class="col-12 d-flex">
                <div [ngClass]="action == 1 && primarySeoInfo == true ? 'col-9' : 'col-12'" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <mat-form-field appearance="standard" style="width: 97%">
                        <mat-label>{{findItemByKey(tooltipProp, 'displayName') || 'Display name'}}</mat-label>
                        <input matInput formControlName="displayName" type="text" trim="blur">
                    </mat-form-field>
                </div>
                <div *ngIf="action == 1 && primarySeoInfo == true" class="col-3" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <nb-toggle formControlName="noIndex" class="no-index-toggle" style="margin-top: 20px;" status="success" [checked]="isNoIndexState" (click)="changeStateNoIndex($event)">
                        <span [matTooltip]="numberTruncateToggle == 1 ? 'Active' : ''">
                            {{'Do Not Index' | truncateText:[numberTruncateToggle, '']}}
                        </span>
                        <mat-icon class="icon-help-noindex-p"
                        matTooltip="This feature will affect the visibility of this profile to Google! By turning this feature on, it will no longer be ranked in Google searches, but it will still be visible on the website. If you turn this feature off, you will signal to Google that this profile should be ranked. So make serious decisions before using this feature">
                        help_outline
                        </mat-icon>
                    </nb-toggle>
                </div>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard" style="width: 97%">
                    <mat-label>Title</mat-label>
                    <input matInput (input)="countAndUpdateLengthCharacter($event,'title')" formControlName="title" type="text" trim="blur">
                    <mat-hint [ngStyle]="{'color': colorTitle ? colorTitle : ''}">
                        Characters:
                        <span class="titleCount">{{lengthCharacterTitle}}</span>
                        <span class="maxCharacter">/65</span>
                        recommended maximum
                    </mat-hint>
                </mat-form-field>
                <mat-icon class="help-icon" color="primary" style="font-size:18px"
                    title="A page title, also known as a title tag, is a short description of a webpage and appears at the top of a browser window and in SERPs. It is an important element of an optimized SEO page. A page title should include a page's keyword in the title tag.">
                    help</mat-icon>

            </div>
            <div class="col-12">
                <mat-form-field appearance="standard" style="width: 97%">
                    <mat-label>Friendly url</mat-label>
                    <input matInput required formControlName="friendlyUrl" type="text" trim="blur">
                    <mat-error
                        *ngIf="formGroup.controls.friendlyUrl.errors && (formGroup.controls.friendlyUrl.dirty || formGroup.controls.friendlyUrl.touched)">
                        {{formGroup.controls.friendlyUrl['errorMessage']}}</mat-error>
                </mat-form-field>
                <mat-icon class="help-icon" color="primary" style="font-size:18px"
                    title="Unique URL for profiles, we can change it to anything we want and this URL will become the URL for the profiles.">
                    help</mat-icon>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard" style="width: 97%">
                    <mat-label>Description</mat-label>
                    <textarea matInput (input)="countAndUpdateLengthCharacter($event,'description')" cdkTextareaAutosize formControlName="description" type="text"
                        trim="blur"></textarea>
                    <mat-hint [ngStyle]="{'color': colorDesc ? colorDesc : ''}">
                        Characters:
                            <span class="titleCount">{{lengthCharacterDesc}}</span>
                            <span class="maxCharacter">/155</span>
                        recommended maximum
                    </mat-hint>
                </mat-form-field>
                <mat-icon class="help-icon" color="primary" style="font-size:18px"
                    title="The SEO site description represents the page. Search engines show this description in search results for your homepage if they don't find content more relevant to a visitor's search terms.">
                    help</mat-icon>

            </div>
            <div class="col-12">
                <mat-form-field appearance="standard" style="width: 97%">
                    <mat-label>Keywords (Reference Only)</mat-label>
                    <textarea matInput cdkTextareaAutosize formControlName="keywords" trim="blur" [readonly]="isReadonlyKeyword"></textarea>
                </mat-form-field>
                <mat-icon class="help-icon" color="primary" style="font-size:18px"
                    title="SEO keywords are the keywords and phrases in your web content that make it possible for people to find your site via search engines. A website that is well optimized for search engines “speaks the same language” as its potential visitor base with keywords for SEO that help connect searchers to your site.">
                    help</mat-icon>
            </div>

            <div class="col-12">
                <mat-form-field appearance="standard" style="width: 97%">
                    <mat-label>Primary Keyword (SEO Audit Tool Support)</mat-label>
                    <input matInput  formControlName="primaryKeyword" trim="blur" type="text" [readonly]="isReadonlyKeyword">
                    <mat-error *ngIf="formGroup?.controls?.primaryKeyword?.errors && formGroup?.controls?.primaryKeyword?.errors?.maxWordsErrorMessage">
                        {{formGroup?.controls?.primaryKeyword?.errors?.maxWordsErrorMessage}}
                      </mat-error>
                </mat-form-field>
            </div>

            <div class="col-12">
                <mat-form-field appearance="standard" style="width: 97%">
                    <mat-label>Secondary Keyword (SEO Audit Tool Support)</mat-label>
                    <textarea matInput cdkTextareaAutosize formControlName="secondaryKeyword" trim="blur" [readonly]="isReadonlyKeyword"></textarea>
                </mat-form-field>
            </div>

        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="onCloseDialog()">Cancel</button>
    <button mat-raised-button color="primary" class="float-right" (click)="onSave()" [nbSpinner]="isLoading"
        [disabled]="isLoading">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
    </button>
</mat-dialog-actions>

<ng-template #loading>
    <ngx-skeleton-loader style="width: 40vw; display: block" count="5" [theme]="{ width: '100%'}"></ngx-skeleton-loader>
</ng-template>

<ng-template #confirmData>
    <p>This {{seoForm.friendlyUrl}} is incorrect format, would you like to change it to the new correct URL?</p>
</ng-template>
