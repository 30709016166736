import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'autoPlaceholder'
})
export class AutoPlaceHolderPipe implements PipeTransform {

  transform(value: string, placeHolder?: string): any {
    if (!placeHolder) {
      placeHolder = 'Unknown';
    }
    if (value) {
      if (value.trim() === '') {
        return placeHolder;
      } else {
        return value.trim();
      }
    } else {
      return placeHolder;
    }
  }

}
