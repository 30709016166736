import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrawlJobSetting, ExpandIndexingPage, FFCUrl, GSCResponse, GoogleSearchQueryRequestViewModel, IndexJobSetting, IndexSummaryBlockRequestModel, IndexSummaryBlockViewModel, IndexingChartData, IndexingJobInfo, IndexingProgress, IndexingQueue, IndexingStackChartResponseData, InspectJobSetting, PutToQueueJobSetting, QueueToProgressJobSetting, RequestSetting, MobileFriendlyTestResponse, IndexingHistory } from './indexing.model';
import { Observable } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IndexingService {
  baseUrl = environment.apiGoogleSearchConsole;
  constructor(private http: HttpClient) { }
  getIndexingPaging(page: Page, type: string): Observable<ReturnResult<PagedData<FFCUrl>>> {
    return this.http.post<ReturnResult<PagedData<FFCUrl>>>(
      `${this.baseUrl}/GetIndexingToolPaging/${type}`,
      page
    );
  }
  getIndexingQueuePaging(page: Page): Observable<ReturnResult<PagedData<IndexingQueue>>> {
    return this.http.post<ReturnResult<PagedData<IndexingQueue>>>(
      `${this.baseUrl}/GetIndexingQueuePaging`,
      page
    );
  }
  getIndexingProgressQueuePaging(page: Page): Observable<ReturnResult<PagedData<IndexingProgress>>> {
    return this.http.post<ReturnResult<PagedData<IndexingProgress>>>(
      `${this.baseUrl}/GetIndexingProgressQueuePaging`,
      page
    );
  }
  exportIndexingPaging(page: Page, type: string): Observable<ReturnResult<FFCUrl>> {
    return this.http.post<ReturnResult<FFCUrl>>(
      `${this.baseUrl}/export/${type}`,
      page
    );
  }
  importIndexingPaging(formData: FormData): Observable<ReturnResult<FFCUrl>> {
    return this.http
      .post<ReturnResult<FFCUrl>>(`${this.baseUrl}/import`, formData)
      .pipe(timeout(1200000));
  }
  requestBulkIndexing(data: Page): Observable<ReturnResult<FFCUrl>> {
    return this.http.post<ReturnResult<FFCUrl>>(
      `${this.baseUrl}/BulkSubmitUrls`, data
    );
  }
  putUrlToQueue(data: Page, type: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/PutUrlsToQueue/${type}`, data
    );
  }
  saveIndexingUrl(data: FFCUrl) {
    return this.http.post<ReturnResult<FFCUrl>>(
      `${this.baseUrl}/SaveIndexingToolUrl`,
      data
    );
  }
  deleteIndexingUrl(id: number) {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/DeleteIndexingToolUrl/${id}`, {}
    );
  }
  deletesIndexingUrl(ids: number[]) {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/DeleteIndexingToolUrls`,
      ids);
  }
  saveIndexingQueue(data: IndexingQueue) {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/SaveIndexingQueueUrl`,
      data
    );
  }
  deleteIndexingQueue(id: number) {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/DeleteIndexingQueueUrl/${id}`, {}
    );
  }
  deletesIndexingQueue(ids: number[]) {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/DeleteIndexingQueueUrls`,
      ids);
  }


  deleteIndexingProgressQueueUrl(id: number) {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/DeleteIndexingProgressQueueUrl/${id}`, {}
    );
  }
  deleteIndexingProgressQueueUrls(ids: number[]) {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/DeleteIndexingProgressQueueUrls`,
      ids);
  }
  requestBulkInspect(data: Page): Observable<ReturnResult<FFCUrl>> {
    return this.http.post<ReturnResult<FFCUrl>>(
      `${this.baseUrl}/InspectBulkUrls`, data
    );
  }
  getIndexingJobInformation(): Observable<ReturnResult<IndexingJobInfo>> {
    return this.http.get<ReturnResult<IndexingJobInfo>>(`${this.baseUrl}/GetIndexingJobInformation`);
  }
  getOverViewIndexBlock(model: GoogleSearchQueryRequestViewModel): Observable<ReturnResult<GSCResponse>> {
    return this.http.post<ReturnResult<GSCResponse>>(`${this.baseUrl}/GetOverViewIndexBlock`, model);
  }
  saveIndexingProgressQueueUrl(data: IndexingProgress) {
    return this.http.post<ReturnResult<boolean>>(
      `${this.baseUrl}/SaveIndexingProgressQueueUrl`,
      data
    );
  }
  getRequestSetting(): Observable<ReturnResult<RequestSetting>> {
    return this.http.get<ReturnResult<RequestSetting>>(
      `${this.baseUrl}/GetRequestSetting`
    );
  }
  saveRequestSetting(data: RequestSetting, key: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveRequestSetting/${key}`, data);
  }
  getInspectJobSetting(): Observable<ReturnResult<InspectJobSetting>> {
    return this.http.get<ReturnResult<InspectJobSetting>>(
      `${this.baseUrl}/GetInspectJobSetting`
    );
  }
  saveInspectJobSetting(data: InspectJobSetting, key: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveInspectJobSetting/${key}`, data);
  }
  getIndexJobSetting(): Observable<ReturnResult<IndexJobSetting>> {
    return this.http.get<ReturnResult<IndexJobSetting>>(
      `${this.baseUrl}/GetIndexJobSetting`
    );
  }
  saveIndexJobSetting(data: IndexJobSetting) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveIndexJobSetting`, data);
  }
  getPutToQueueJobSetting(): Observable<ReturnResult<PutToQueueJobSetting>> {
    return this.http.get<ReturnResult<PutToQueueJobSetting>>(
      `${this.baseUrl}/GetPutToQueueJobSetting`
    );
  }
  savePutToQueueJobSetting(data: PutToQueueJobSetting, key: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SavePutToQueueJobSetting/${key}`, data);
  }
  getCrawlJobSetting(): Observable<ReturnResult<CrawlJobSetting>> {
    return this.http.get<ReturnResult<CrawlJobSetting>>(
      `${this.baseUrl}/GetCrawlJobSetting`
    );
  }
  saveCrawlJobSetting(data: CrawlJobSetting, key: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveCrawlJobSetting/${key}`, data);
  }
  getQueueToProgressJobSetting(): Observable<ReturnResult<QueueToProgressJobSetting>> {
    return this.http.get<ReturnResult<QueueToProgressJobSetting>>(
      `${this.baseUrl}/GetQueueToProgressJobSetting`
    );
  }
  saveQueueToProgressJobSetting(data: QueueToProgressJobSetting, key: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveQueueToProgressJobSetting/${key}`, data);
  }
  getIndexingChartData(): Observable<ReturnResult<IndexingChartData>> {
    return this.http.get<ReturnResult<IndexingChartData>>(`${this.baseUrl}/GetIndexingChartData`);
  }
  getIndexingSummaryBlock(model: IndexSummaryBlockRequestModel): Observable<ReturnResult<IndexSummaryBlockViewModel>> {
    var formatModel: IndexSummaryBlockRequestModel = {
      startDate: model.startDate,
      endDate: model.endDate,
      filterCondition: null
    }
    return this.http.post<ReturnResult<IndexSummaryBlockViewModel>>(`${this.baseUrl}/GetIndexingSummaryBlock`, formatModel);
  }
  bulkChangeState(data: ExpandIndexingPage): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/BulkChangeState`, data);
  }
  getIndexingSummaryStackChart(model: IndexSummaryBlockRequestModel): Observable<ReturnResult<IndexingStackChartResponseData>> {
    return this.http.post<ReturnResult<IndexingStackChartResponseData>>(`${this.baseUrl}/GetIndexingSummaryStackChart`, model);
  }
  mobileTestUrl(url: string): Observable<ReturnResult<MobileFriendlyTestResponse>> {
    return this.http.post<ReturnResult<MobileFriendlyTestResponse>>(`${this.baseUrl}/MobileFriendlyTest`, {
      url: url
    });
  }
  getIndexingHistoryPaging(page: Page): Observable<ReturnResult<PagedData<IndexingHistory>>> {
    return this.http.post<ReturnResult<PagedData<IndexingHistory>>>(
      `${this.baseUrl}/GetIndexingHistoryPaging`,
      page
    );
  }
  exportIndexingQueue(page: Page): Observable<ReturnResult<IndexingQueue>> {
    return this.http.post<ReturnResult<IndexingQueue>>(
      `${this.baseUrl}/ExportIndexingQueue`,
      page
    );
  }
  exportIndexingProgress(page: Page): Observable<ReturnResult<IndexingProgress>> {
    return this.http.post<ReturnResult<IndexingProgress>>(
      `${this.baseUrl}/ExportIndexingProgress`,
      page
    );
  }
  exportIndexingHistory(page: Page): Observable<ReturnResult<IndexingHistory>> {
    return this.http.post<ReturnResult<IndexingHistory>>(
      `${this.baseUrl}/ExportIndexingHistory`,
      page
    );
  }
}
